import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteComponent } from '@app/route/route.component';
import { HeaderService } from '@app/service/header/header.service';
import {
  AuthUrlResponse,
  NoUserService,
} from '@app/service/no-user-service/no-user.service';
import { StateService } from '@app/service/state/state.service';
import { StandardCheckoutResponse } from '@app/service/user-service/user.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'no-auth',
  templateUrl: './no-auth.component.html',
  styleUrls: ['../../page-shared.css', './no-auth.component.scss'],
})
export class NoAuthComponent extends RouteComponent implements OnInit {
  form: FormGroup;

  ctaButtonText: string = 'identifisere';
  public authInProgress = false;
  public authComplete = false;

  public loadingText = 'Venter på BankID-identifikasjon...';

  showError: boolean;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected stateService: StateService,
    protected headerService: HeaderService,
    private noUserService: NoUserService,
    private formBuilder: FormBuilder
  ) {
    super(router, route, stateService, headerService);
  }

  async ngOnInit() {
    console.log(this.state);
    this.setupForm();
  }

  setupForm(): void {
    this.form = this.formBuilder.group({
      pnr: ['', []],
    });
  }

  handleCtaClick() {
    if (this.validateNorwegianIdNumber(this.form.value.pnr)) {
      this.authInProgress = true;

      var w = 500;
      var h = 600;
      var top = window.outerHeight / 2 + window.screenY - h / 2;
      var left = window.outerWidth / 2 + window.screenX - w / 2;

      var windowReference = window.open(
        '',
        'blank_',
        `
        scrollbars=yes,
        width=${w}, 
        height=${h}, 
        top=${top}, 
        left=${left},
        title: 'BankID'
        `
      );

      this.noUserService.initAuth(this.form.value.pnr).then((resp) => {
        this.popupCenter({
          self: this,
          url: resp.accessUrl,
          windowReference: windowReference,
        });
      });
    } else {
      this.showError = true;
    }
  }

  validateNorwegianIdNumber(idNumber: string): boolean {
    const trimmed = idNumber.trim();
    if (isNaN(Number(trimmed))) return false;
    if (trimmed.length !== 11) return false;
    if (this.isValidCheckDigits(trimmed)) return true;
  }

  isValidCheckDigits(elevenDigits: string): boolean {
    const staticSequenceFirstCheckDigit = [3, 7, 6, 1, 8, 9, 4, 5, 2, 1];
    const staticSequenceSecondCheckDigit = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2, 1];

    const elevenDigitsArray = elevenDigits.split('').map(Number);

    return (
      this.isValidCheckDigit(
        staticSequenceFirstCheckDigit,
        elevenDigitsArray
      ) &&
      this.isValidCheckDigit(staticSequenceSecondCheckDigit, elevenDigitsArray)
    );
  }

  isValidCheckDigit(staticSequence: number[], elevenDigits: number[]): boolean {
    const productSum = staticSequence.reduce(
      (acc, value, index) => acc + value * elevenDigits[index],
      0
    );

    return productSum % 11 === 0;
  }

  popupCenter({ self, url, windowReference }) {
    windowReference.location = url;

    if (window.focus) windowReference.focus();

    window.addEventListener('message', (e) => {
      if (e.data.success !== null && e.data.transactionId !== null) {
        this.handleCallback(e.data.success, e.data.transactionId);
      } else {
        this.authInProgress = false;
      }
    });

    //Hack used by Facebook to detect close on cross origin popup
    var timer = setInterval(function () {
      if (windowReference.closed) {
        clearInterval(timer);

        // needed because the state is somehow lost within the setInterval function
        // also does not work if a function is passed down
        self.authInProgress = false;
      }
    }, 1000);
  }

  async handleCallback(success: boolean, transactionId: string) {
    if (success) {
      this.authInProgress = false;
      this.authComplete = true;
      this.loadingText = 'Vente...';
      try {
        //maybe check if transaction id matches
        let resp: StandardCheckoutResponse =
          await this.noUserService.authorize();
        if (resp.success) {
          this.navigate();
        }
      } catch (err) {
        this.handleError(err);
      }
    } else {
      this.authInProgress = false;
      return;
    }
  }

  updateErrorMessages() {
    this.showError = !this.showError;
  }
}
