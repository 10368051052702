import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from '@app/service/header/header.service';
import { UserService } from '@app/service/user-service/user.service';
import { ResolveType, StateService } from 'src/app/service/state/state.service';
import { RouteComponent } from '../../route.component';

@Component({
  selector: 'app-de-verification-email',
  templateUrl: './de-rejected.component.html',
  styleUrls: ['../../page-shared.css', './de-rejected.component.scss'],
})
export class DeRejectedDeComponent extends RouteComponent implements OnInit {
  loading = false;
  navigationInProgress = false;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected stateService: StateService,
    protected headerService: HeaderService,
    private userService: UserService
  ) {
    super(router, route, stateService, headerService);
  }

  ngOnInit(): void {}

  async handleCtaClick(): Promise<void> {
    this.navigationInProgress = true;
    try {
      if (this.state.interruptionDetails.cancelUrlOverridesCta) {
        const resp = await this.userService.cancelPayment();
        if (resp.success) {
          window.location.href = this.state.merchantUrls?.onCancelUrl;
        }
      } else {
        await this.resolveInterruption(ResolveType.CTA);
      }
    } catch (error) {
      console.log(error);
    }
    this.navigationInProgress = false;
  }

  async handleLinkClick(): Promise<void> {
    this.navigationInProgress = true;
    try {
      if (this.state.interruptionDetails.cancelurloverrideslink) {
        const resp = await this.userService.cancelPayment();
        if (resp.success) {
          window.location.href = this.state.merchantUrls?.onCancelUrl;
        }
      } else if (this.state.interruptionDetails.linkexternaldestination) {
        window.open(
          this.state.interruptionDetails.linkexternaldestination,
          '_blank'
        );
      } else {
        await this.resolveInterruption(ResolveType.LINK);
      }
    } catch (error) {
      console.log(error);
    }
    this.navigationInProgress = false;
  }
}
