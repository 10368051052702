import { Component, OnInit } from '@angular/core';
import { Router, ROUTES } from '@angular/router';
import { Market } from '@app/model/payment-request/payment-request.dto';
import { LoggingService } from '@app/service/logging-service/logging.service';
import {
  AtRoute,
  DeRoute,
  NoRoute,
  routeNames,
} from '@assets/val/route-constants';

@Component({
  selector: 'navigation-catcher',
  templateUrl: './navigation-catcher.component.html',
  styleUrls: ['./navigation-catcher.component.css'],
})
export class NavigationCatcherComponent implements OnInit {
  constructor(private loggingService: LoggingService, private router: Router) {}

  ngOnInit() {
    this.loggingService.reportCrash('landed in navigation error catcher!');

    const market = localStorage.getItem('zco_market');
    let route;
    switch (market) {
      case Market.AT:
        route = AtRoute.HOME;
        break;
      case Market.DE:
        route = DeRoute.HOME;
        break;
      case Market.SE:
        route = routeNames.HOME;
        break;
      case Market.NO:
        route = NoRoute.HOME;
        break;
      default:
        route = routeNames.ERROR;
        break;
    }

    this.router.navigate([`/${route}`], {
      queryParamsHandling: 'preserve',
    });
  }
}
