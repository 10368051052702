import { Injectable } from '@angular/core';

@Injectable()
export class UserAgentService {
  private static readonly I_OS_DEVICE_MATCHER_PATTERN = /iPad|iPhone|iPod/;
  // Todo check compatibility with Galaxy devices for the Android pattern.
  private static readonly ANDROID_DEVICE_MATCHER_PATTERN = /[Aa]ndroid/;

  constructor() {}

  getDeviceOS(): DeviceOS {
    if (UserAgentService.ANDROID_DEVICE_MATCHER_PATTERN.test(navigator.userAgent)) {
      return DeviceOS.ANDROID;
    }

    if (UserAgentService.I_OS_DEVICE_MATCHER_PATTERN.test(navigator.userAgent)) {
      return DeviceOS.I_OS;
    }

    return null;
  }

  isMobile(): boolean {
    return this.getDeviceOS() != null;
  }

}

export enum DeviceOS {
  ANDROID,
  I_OS,
  WINDOWS,
  MAC_OS,
  LINUX,
}
