import { Component, Input, OnInit } from '@angular/core';
import { LineItem } from '@app/model/payment-request/payment-request.dto';

@Component({
  selector: 'app-selector',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.scss'],
})
export class SelectorComponent implements OnInit {
  @Input() title: string;
  @Input() selectorItems: SelectorItem[];
  @Input() selectedItem: SelectorItem;
  constructor() {}

  ngOnInit(): void {
    if (!this.selectedItem) {
      this.selectedItem = this.selectorItems[0];
    }
  }

  public selectItemByTab(tab: string) {
    this.setSelectedItem(this.selectorItems.find((item) => item.tab === tab));
  }

  setSelectedItem(selectedItem) {
    this.selectedItem = selectedItem;
  }
}

export interface SelectorItem {
  tab: string;
  title: string;
  descriptions?: Description[];
  lineItems?: LineItem[];
  totalValue?: string;
  links?: Link[];
}

interface Description {
  title?: string;
  text: string;
}

export interface Link {
  text: string;
  onClick: () => void;
}
