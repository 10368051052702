export interface Currency {
  amount: number;
  currencyCode: CurrencyCode;
}

export enum CurrencyCode {
  SEK = 'SEK',
  DKK = 'DKK',
  EUR = 'EUR',
  NOK = 'NOK',
}
