import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CheckoutState,
  StateService,
} from 'src/app/service/state/state.service';
import { CurrencyCode } from '@app/model/currency';
import { formatNumber } from '@angular/common';
import { RouteComponent } from '@app/route/route.component';
import { HeaderService } from '@app/service/header/header.service';

@Component({
  selector: 'app-de-precheck',
  templateUrl: './de-precheck.component.html',
  styleUrls: ['../../page-shared.css', './de-precheck.component.scss'],
})
export class DePrecheckComponent extends RouteComponent implements OnInit {
  loading = true;
  form: FormGroup;
  showError = false;
  state: CheckoutState;
  navigationInProgress = false;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected stateService: StateService,
    protected headerService: HeaderService,
    private fb: FormBuilder
  ) {
    super(router, route, stateService, headerService);
  }

  async ngOnInit(): Promise<void> {
    console.log(this.state.totalCheckoutValue.amount);
    this.form = this.fb.group({
      amount: [
        this.state.totalCheckoutValue &&
          formatNumber(this.state.totalCheckoutValue.amount, 'de', '1.0-2'),
        [
          inputFormatValidator(),
          Validators.required,
          Validators.min(1),
          Validators.max(20000),
        ],
      ],
    });

    this.loading = false;
  }

  async handleCtaClick(): Promise<void> {
    if (this.form.controls.amount.invalid) {
      this.showError = true;
      return;
    }

    this.showError = true;
    this.navigationInProgress = true;

    try {
      await this.stateService.setValue({
        amount: convertInputStringToNumber(this.form.value.amount),
        currencyCode: CurrencyCode.EUR,
      });
      this.navigate();
    } catch (err) {
      this.handleError(err);
    }

    this.navigationInProgress = false;
  }
}

export const convertInputStringToNumber = (inputStr: string): number => {
  // Remove thousands separator (".") and replace decimal separator (",") with a dot (".")
  var convertedStr = inputStr.replace(/\./g, '').replace(',', '.');

  // Convert the modified string to a floating-point number
  var result = parseFloat(convertedStr);
  return result;
};

export function inputFormatValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const inputValue: string = control.value;

    // Regular expression to match the input format
    const regex = /^(\d{1,3}(?:\.\d{3})?|\d+)(?:,\d{1,2})?$/;

    if (!regex.test(inputValue)) {
      return { invalidFormat: true };
    }

    return null;
  };
}
