const ACTION_SUBJECT_TAG = '[Installments]';

export class FetchAvailableInstallmentPlans {
  static readonly type = `${ACTION_SUBJECT_TAG} Fetch available options`;
  constructor() { }
}

export class SelectInstallmentPlanOption {
  static readonly type = `${ACTION_SUBJECT_TAG} Mark installment plan option as selected`;

  constructor(public selectedIndex: number) { }
}

export class ClearInstallmentPlanFetchingError {
  static readonly type = `${ACTION_SUBJECT_TAG} Clear the error identifier after an 
  installment plan fetching error is handled`;

  constructor() { }
}
