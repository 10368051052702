<app-de-wrapper *ngIf="internalServerError">
  <div style="display: flex; flex-direction: column; align-items: center">
    <img class="general-error-icon" src="assets/icon/smiley-sad-nerd.svg" />
    <h3 class="general-error-text">Etwas ist schief gelaufen.</h3>
  </div>
</app-de-wrapper>

<app-de-wrapper *ngIf="loading">
  <app-spinner></app-spinner>
</app-de-wrapper>

<app-de-wrapper *ngIf="!loading && !internalServerError">
  <h1 class="heading">Fast geschafft!</h1>

  <div class="img-progress-bar-wrapper">
    <img class="img-progress-bar" src="assets/icon/Progress-bar.svg" />
  </div>

  <p class="info-text">
    Ihre Bestellung wird jetzt bearbeitet. Um Ihren Kauf abzuschließen, klicken
    Sie bitte auf den Verifizierungslink, den
    wir&nbsp;Ihnen&nbsp;geschickt&nbsp;haben:
  </p>

  <p class="email-address">{{ emailaddress }}</p>

  <div class="button-wrapper">
    <button
      class="send-email-button"
      (click)="resendMail()"
      [ngClass]="{ 'resend-mail-button-pressed': buttonPressedState }"
    >
      E-mail erneut senden
    </button>
  </div>

  <div class="button-link-wrapper">
    <zaver-button-link
      [text]="'E-Mail Adresse nicht korrekt?'"
      (click)="onClickReset()"
    ></zaver-button-link>
  </div>
</app-de-wrapper>

<div class="modal-small-wrapper">
  <zaver-details-modal
    [active]="modalActive"
    (onClose)="setModalActive(false)"
    [color]="'#EF3466'"
    [title]="'Date ändern'"
    [titleColor]="'#ffffff'"
    id="part-success-modal"
  >
    <div class="small-modal-wrapper">
      <div class="modal-text">
        Um Ihre e-mail Adresse zu ändern müssen Sie die vorherigen Schritten
        erneut durchführen.
      </div>
      <div class="modal-button-container">
        <div class="abort-button-wrapper">
          <zaver-secondary-button-subtle
            style="width: auto"
            [text]="'Nein, abbrechen'"
            (click)="setModalActive(false)"
          ></zaver-secondary-button-subtle>
        </div>

        <zaver-primary-button
          style="width: auto"
          [text]="'Ja, ändern'"
          (click)="resetEmail()"
        ></zaver-primary-button>
      </div>
    </div>
  </zaver-details-modal>
</div>
