import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-installments-item',
  templateUrl: './installments-item.component.html',
  styleUrls: ['./installments-item.component.scss'],
})
export class InstallmentsItemComponent implements OnInit {
  @Input() selected: boolean;
  @Input() title: string;
  @Input() description: string;
  @Input() imgSrc: string;
  @Input() badgeText: string;
  @Input() bulletPoints: string[];
  @Input() isFirstItem: boolean;
  @Input() isLastItem: boolean;
  @Input() color: string;
  @Input() radioButtonColor: string;
  @Input() link: Link;
  constructor() {}

  ngOnInit(): void {}
}

export interface Link {
  text: string;
  onClick: () => void;
}
