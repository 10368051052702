import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { routeNames } from '../../../../assets/val/route-constants';
import { CustomerJourneyService } from '../../../service/customer-journey/customer-journey.service';
import { LoggingService } from 'src/app/service/logging-service/logging.service';
import {
  Employment,
  KalpStatus,
  LeqInfoDto,
  LeqService,
} from '@app/service/leq/leq.service';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.css', '../../page-shared.css'],
})
export class SummaryComponent implements OnInit {
  public loading = true;
  public occupationStatusLabel;
  public navigationInProgress = false;

  existingLeq: LeqInfoDto;

  constructor(
    private customerJourneyService: CustomerJourneyService,
    private router: Router,
    private leqService: LeqService,
    private loggingService: LoggingService
  ) {}

  async ngOnInit() {
    try {
      this.existingLeq = await this.leqService.fetchLeq();
      if (this.existingLeq.status === KalpStatus.PENDING) {
        this.navigateToLeqForm();
      }
      this.occupationStatusLabel = this.mapEmploymentToReadable(
        this.existingLeq.employment
      );
      this.loading = false;
    } catch (err) {
      this.loggingService.log(err);
      this.router.navigate([routeNames.ERROR], {
        queryParamsHandling: 'merge',
      });
    }
  }

  public continueToNextStepInJourney() {
    this.navigationInProgress = true;
    this.customerJourneyService.getNextRoute(true).subscribe((route) => {
      this.router
        .navigate([route], { queryParamsHandling: 'preserve' })
        .then(() => {
          this.navigationInProgress = false;
        });
    });
  }

  public navigateToLeqForm() {
    this.navigationInProgress = true;
    // the '1' denotes the first step of the loan eligibility questionnaire flow.
    this.router
      .navigate([`${routeNames.LEQ}/1`], { queryParamsHandling: 'preserve' })
      .then(() => {
        this.navigationInProgress = false;
      });
  }

  reroute() {
    this.router.navigate([routeNames.EMAIL_AND_INVOICE], {
      queryParamsHandling: 'merge',
    });
  }

  mapEmploymentToReadable(employment: Employment): string {
    if (employment === Employment.PERMANENT) {
      return 'Fastanställd';
    }
    if (employment === Employment.PART_TIME) {
      return 'Timanställd';
    }
    if (employment === Employment.RETIRED) {
      return 'Pensionär';
    }
    if (employment === Employment.STUDENT) {
      return 'Studerande';
    }
    if (employment === Employment.UNEMPLOYED) {
      return 'Arbetslös';
    }
    if (employment === Employment.ENTREPRENEUR) {
      return 'Egenföretagare';
    }
    return 'Error';
  }
}
