<div class="page-body" *ngIf="!loading">
  <div class="banner">
    <img
      src="assets/icon/Arrow-Left.svg"
      alt=""
      class="back-button"
      (click)="reroute()"
    />
    <span class="back-text" (click)="reroute()">Tillbaka</span>
  </div>

  <div class="contents-wrapper">
    <div class="contents-container" *ngIf="existingLeq">
      <ng-container>
        <h2 class="header">Dina lämnade uppgifter</h2>
        <p class="sub-header">Klicka på fortsätt om ingenting har ändrats</p>
      </ng-container>

      <hr class="details-divider" />
      <div class="details-container">
        <div class="details-key-entry">Antal barn du försörjer</div>
        <div class="details-value-entry pr-details">
          {{ existingLeq.numberOfChildren }} barn
        </div>
      </div>

      <hr class="details-divider" />
      <div class="details-container">
        <div class="details-key-entry">Kostnad för krediter per mån</div>
        <div class="details-value-entry pr-details">
          {{ existingLeq.creditCosts | currency: 'SEK ':'code':'1.0-0':'sv' }}
        </div>
      </div>

      <hr class="details-divider" />
      <div class="details-container">
        <div class="details-key-entry">Boendekostnad per mån</div>
        <div class="details-value-entry pr-details">
          {{ existingLeq.housingCosts | currency: 'SEK ':'code':'1.0-0':'sv' }}
        </div>
      </div>

      <hr class="details-divider" />
      <div class="details-container">
        <div class="details-key-entry">Månadsinkomst efter skatt</div>
        <div class="details-value-entry pr-details">
          {{ existingLeq.income | currency: 'SEK ':'code':'1.0-0':'sv' }}
        </div>
      </div>

      <hr class="details-divider" />
      <div class="details-container">
        <div class="details-key-entry">Övriga levnadskostnader per mån</div>
        <div class="details-value-entry pr-details">
          {{ existingLeq.miscExpenses | currency: 'SEK ':'code':'1.0-0':'sv' }}
        </div>
      </div>

      <hr class="details-divider" />
      <div class="details-container">
        <div class="details-key-entry">Sysselsättning</div>
        <div class="details-value-entry pr-details">
          {{ occupationStatusLabel }}
        </div>
      </div>

      <hr class="details-divider last-divider" />

      <div class="bottom-section-wrapper">
        <zaver-primary-button
          [text]="'Fortsätt'"
          (click)="continueToNextStepInJourney()"
        >
        </zaver-primary-button>

        <zaver-button-link
          class="button-link"
          [text]="'Uppdatera svaren'"
          (click)="navigateToLeqForm()"
        ></zaver-button-link>
      </div>
    </div>
  </div>
</div>

<div class="page-body" *ngIf="loading">
  <div class="contents-wrapper">
    <div class="contents-container">
      <app-spinner></app-spinner>
    </div>
  </div>
</div>
