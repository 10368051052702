import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import {
  Market,
  PaymentRequestDto,
  PaymentRequestStatus,
} from '../model/payment-request/payment-request.dto';
import { HttpClient } from '@angular/common/http';
import { SERVER_BASE_URL } from '../../environments/environment';
import { QueryParamsService } from '../service/query-params/query-params.service';
import { map, tap, catchError } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { RegisterPaymentRequestInfo } from '../ngxs/payment-request/payment-request.actions';
import { GuardRedirectService } from '../service/redirect/guard-redirect.service';
import { HeaderService } from '../service/header/header.service';
import { routeNames } from '../../assets/val/route-constants';
import { LoggingService } from '../service/logging-service/logging.service';
import * as Sentry from '@sentry/angular';
import {
  CheckoutStep,
  StateControlParameters,
  StateService,
} from '@app/service/state/state.service';

/**
 * Exists to make assertions about the payment request state before route
 * activation (it would for instance not make sense to allow the user access
 * to the concerned routes if the payment request represented by the
 * id is already settled...)
 */

@Injectable()
export class PaymentRequestGuard implements CanActivate {
  constructor(
    private queryParamsService: QueryParamsService,
    private guardRedirectService: GuardRedirectService,
    protected router: Router,
    private http: HttpClient,
    private store: Store,
    private loggingService: LoggingService,
    protected stateService: StateService,
    protected headerService: HeaderService,
    protected route: ActivatedRoute
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    let id = this.queryParamsService.getQueryParams().id;

    if (!id && !!this.store.snapshot().paymentRequest.request.id) {
      this.loggingService.reportCrash(
        'failed to get id from url in queryParamsService. Resetting from PR',
        'url=' + window.location.href,
        null
      );
      this.queryParamsService.setQueryParam(
        'id',
        this.store.snapshot().paymentRequest.request.id
      );
      id = this.queryParamsService.getQueryParams().id;
    }

    if (!id && !this.store.snapshot().paymentRequest.request.id) {
      this.queueRedirect('error', 'Ingen förfrågan.', true);
      return false;
    }
    return this.http
      .get<PaymentRequestDto>(`${SERVER_BASE_URL}/request/public/${id}`)
      .pipe(
        catchError((err) => {
          Sentry.captureException(err);
          this.loggingService.reportCrash(
            'error fetching payment-request',
            'message: ' + err.message + '. prid: ' + id
          );
          return of(null);
        })
      )
      .pipe(
        map((pr: PaymentRequestDto) => {
          if (pr) {
            this.headerService.setMarket(pr.market);
            this.headerService.setCancelUrl(pr.cancelUrl);
            if (pr.market !== Market.SE) {
              this.navigateToBECheckout();
              return;
            }
          }
          return this.registerPaymentRequestState(pr);
        })
      )
      .pipe(
        map((response) => {
          if (response) {
            return this.mapResponseToBool(response);
          } else {
            return true;
          }
        })
      );
  }

  async navigateToBECheckout(): Promise<void> {
    const controlParams: StateControlParameters = {
      currentPageName: CheckoutStep.HOME,
      token: this.queryParamsService.getQueryParams().id,
    };

    const state = await this.stateService.registerState(controlParams);
    const route = this.stateService.getRouteFromState(state);
    this.router.navigate([route], {
      queryParamsHandling: 'preserve',
    });
  }

  private registerPaymentRequestState(request: PaymentRequestDto) {
    if (request === null) {
      return this.store.snapshot().paymentRequest.request;
    }
    this.processPartnerLogoUri(request);
    this.store.dispatch(
      new RegisterPaymentRequestInfo({ paymentRequest: request })
    );
    return this.store.snapshot().paymentRequest.request;
  }

  private mapResponseToBool(response: any): boolean {
    if (
      !response ||
      !response.status ||
      response.status === PaymentRequestStatus.PROPRIETARY
    ) {
      this.queueRedirect(routeNames.ERROR, 'Förfrågan är ogiltig');
      return false;
    }

    if (response.status === PaymentRequestStatus.CANCELLED) {
      this.queueRedirect(routeNames.ERROR, 'Förfrågan har makulerats', true);
      return false;
    }

    if (
      response.availableMethods == null ||
      response.availableMethods.length === 0
    ) {
      this.queueRedirect(
        routeNames.ERROR,
        'Det finns inga tillgängliga betalsätt. Vänligen kontakta avsändaren av betalningsförfrågan.',
        true
      );
      return false;
    }

    if (response.status === PaymentRequestStatus.SETTLED) {
      this.queueRedirect(routeNames.SUCCESS);
      return false;
    }

    return true;
  }

  private processPartnerLogoUri(pr: PaymentRequestDto) {
    this.headerService.processReceivedPartnerLogoUri(
      pr.merchantDetails.logoUrl
    );
  }

  private queueRedirect(route: string, error?: string, fatal?: boolean) {
    this.loggingService.log(
      'Queuing a redirect from PR guard to ' +
        route +
        ', PR-ID=' +
        this.queryParamsService.getQueryParams().id +
        ', pr-id in store= ' +
        this.store.snapshot().paymentRequest.request.id
    );
    const params = {
      ...this.queryParamsService.getQueryParams(),
    };

    if (error) {
      params['error'] = error;
    }
    if (fatal) {
      params['fatal'] = 'true';
    }
    const redirectionCallback = () =>
      this.router
        .navigate([route], {
          queryParamsHandling: 'merge',
          queryParams: params,
        })
        .then(
          () => {},
          (rejection) => {
            this.loggingService.error(
              `Navigation from PR guard to ${route} was rejected.`,
              rejection
            );
          }
        );

    this.guardRedirectService.addRouteToRedirectionQueue(
      redirectionCallback,
      route
    );
  }
}
