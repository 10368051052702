
export interface environmentSettings {
    production: boolean,
    envIdentifier: EnvIdentifier,
    logSettings: LogSettings,
    transferApiBaseUrl: string,
    transferFrameBaseUrl: string;
}

export enum LogLevel {
    ALL = -1,
    DEBUG = 0,
    LOG = 1,
    WARN = 2,
    ERROR = 3,
    NONE = 10
}

export interface LogSettings {
    minLevels: {
        "console": LogLevel,
        "telemetry": LogLevel,
        "stash": LogLevel
    },
    routerLog: boolean;
}

export enum EnvIdentifier {
    DEV = 'DEV',
    LOCAL = 'LOCAL',
    STAGING = 'STAGING',
    TEST = 'TEST',
    PROD = 'PROD'
}

