import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { CustomerJourneyService } from '../../service/customer-journey/customer-journey.service';
import { PreventBackToSettlementOrAuthGuard } from '../../guard/prevent-back-to-settlement-or-auth-guard.service';
import { routeNames } from '../../../assets/val/route-constants';
import { email_validation_regexp } from '../../../assets/val/regexp';
import { PaymentRequestDto } from 'src/app/model/payment-request/payment-request.dto';
import { LoggingService } from 'src/app/service/logging-service/logging.service';
import { UserService } from '@app/service/user-service/user.service';
import {HttpClient} from '@angular/common/http';
import {QueryParamsService} from '@app/service/query-params/query-params.service';
import {SERVER_BASE_URL} from '../../../environments/environment';

@Component({
  selector: 'app-email-entry',
  templateUrl: './instant-debit-email.component.html',
  styleUrls: ['./instant-debit-email.component.css', '../page-shared.css'],
})
export class InstantDebitEmailComponent implements OnInit {
  public loading = true;
  public navigationInProgress: boolean;
  public shouldDisplayEmailError = false;
  public emailErrorMessages = [
    'Ett oväntat fel har uppstått.',
    'Vänligen försök igen.',
  ];
  public paymentRequest: PaymentRequestDto;
  public stateSubscription;

  public form: FormGroup;
  public showError = false;
  private instantDebitTaskId: string;


  constructor(
    private store: Store,
    private customerJourneyService: CustomerJourneyService,
    private router: Router,
    private preventBackToSettlementOrAuthGuard: PreventBackToSettlementOrAuthGuard,
    private loggingService: LoggingService,
    private userService: UserService,
    private queryParamsService: QueryParamsService,
    private http: HttpClient
  ) {}

  async ngOnInit(): Promise<void> {
    let resp;
    try { // we may not be logged in here - in that case, we'll get a 403
      resp = await this.userService.fetchRegisteredEmail();
    } catch (e) {
      resp = {};
    }

    this.form = new FormGroup({
      email: new FormControl(resp.email || '', [
        Validators.pattern(email_validation_regexp),
        Validators.required,
      ]),
    });

    this.paymentRequest = this.store.snapshot().paymentRequest.request;
    this.instantDebitTaskId = this.queryParamsService.getQueryParams().instantDebitId;
    this.loading = false;
  }

  async handleCtaClick() {
    if (this.navigationInProgress) {
      return;
    }

    if (this.form.valid) {
      try {
        await this.registerEmail(this.form.value.email);
        this.navigateOnEmailSubmitted();
      } catch (err) {
        this.loggingService.reportCrash(
          'front-end email validation disagrees with back-end, allowed ' +
          this.form.value.email +
          'which was rejected by back-end',
          null
        );
        this.shouldDisplayEmailError = true;
      }
    } else {
      this.showError = true;
    }
  }

  private navigateOnEmailSubmitted() {
    // Reaching this point will mean some form of navigation.
    this.navigationInProgress = true;
    this.preventBackToSettlementOrAuthGuard.deactivate();

    this.customerJourneyService.getNextRoute().subscribe((route) => {
      this.router
        .navigate([route], {
          queryParamsHandling: 'preserve',
        })
        .then(
          () => {
            this.navigationInProgress = false;
            this.preventBackToSettlementOrAuthGuard.activate();
          },
          (rejection) => {
            this.loggingService.error(
              `Navigation from ${routeNames.EMAIL_AND_INVOICE} to ${route} was rejected.`,
              rejection
            );
          }
        );
    });
  }

  private async registerEmail(email) {
    return this.http.post(`${SERVER_BASE_URL}/instantdebit/settle/`, {
      taskId: this.instantDebitTaskId,
      email: email
    }).toPromise();
  }


  reroute(): void {
    this.router.navigate([routeNames.HOME], {
      queryParamsHandling: 'merge',
    });
  }
}
