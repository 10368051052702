<app-de-wrapper *ngIf="loading">
  <app-spinner></app-spinner>
</app-de-wrapper>

<app-de-wrapper *ngIf="!loading">
  <div class="container">
    <h1 class="heading">Testen Sie Ihre Kaufkraft</h1>
    <p class="sub-heading">Bitte Betrag zwischen 1 und 20.000 € eingeben.</p>

    <form [formGroup]="form">
      <zaver-form-input
        [showContent]="true"
        [size]="'large'"
        [formControlName]="'amount'"
        [error]="form.controls.amount.invalid && showError"
        inputMode="numeric"
        placeholder="Betrag hinzufügen"
        errorMessage="Bitte geben Sie einen gültigen Betrag ein"
      >
        <span>€</span>
      </zaver-form-input>
    </form>
  </div>

  <div class="footer-wrapper">
    <div class="footer-container">
      <div class="button-wrapper">
        <zaver-primary-button
          (click)="handleCtaClick()"
          [text]="'Weiter'"
          [disabled]="navigationInProgress"
        ></zaver-primary-button>
      </div>
    </div>
  </div>
</app-de-wrapper>
