import {CreditOptionDto} from '../../model/credit/credit-option.dto';
import {Action, State, StateContext} from '@ngxs/store';
import {ClearInvoiceFetchingError, GetInvoiceInfo} from './invoice.actions';
import {HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {SERVER_BASE_URL} from '../../../environments/environment';
import {tap} from 'rxjs/operators';
import {UNKNOWN_ERROR} from '../../model/credit/credit-denied-decision.dto'
import {Injectable} from '@angular/core';

export class InvoiceStateModel {
  loaded: boolean;
  error: string;
  terms: CreditOptionDto;
}

@State({
  name: 'invoice',
  defaults: {
    loaded: false,
    error: null,
    terms: null
  }
})

@Injectable()
export class InvoiceState {
  constructor(private http: HttpClient) {}

  @Action(GetInvoiceInfo)
  getInvoiceInfo(ctx: StateContext<InvoiceStateModel>, action: GetInvoiceInfo) {
    const { patchState } = ctx;
    const { paymentRequestId: paymentMethodId } = action;

    return this.http.get<CreditOptionDto>(
      `${SERVER_BASE_URL}/request/invoice/terms/${paymentMethodId}`,
      { withCredentials: true, observe: 'response' }).pipe(
        tap((response: HttpResponse<CreditOptionDto>) => {
          if (response.status === 200) {
            const invoiceOptionDto: CreditOptionDto = response.body;

            patchState({
              loaded: true,
              terms: invoiceOptionDto
            });

            return;
          }

        }, (response: HttpErrorResponse) => {
          patchState({
            loaded: false,
            error: (response.error && response.error.type) ? response.error.type : UNKNOWN_ERROR
          });
        }
      )
    );
  }

  @Action(ClearInvoiceFetchingError)
  clearInvoiceFetchingError(ctx: StateContext<InvoiceStateModel>) {
    ctx.patchState({ error: null });
  }
}

