import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerJourneyService } from '../../service/customer-journey/customer-journey.service';
import {
  Employment,
  LeqFormDto,
  LeqService,
} from '../../service/leq/leq.service';
import { LoggingService } from 'src/app/service/logging-service/logging.service';
import { routeNames } from '../../../assets/val/route-constants';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserAgentService } from '@app/service/user-agent/user-agent.service';

interface OccutapionAnswer {
  label: string;
  value: Employment;
}

@Component({
  selector: 'app-loan-eligibility-questionnaire',
  templateUrl: './loan-eligibility-questionnaire.component.html',
  styleUrls: [
    './loan-eligibility-questionnaire.component.css',
    '../page-shared.css',
  ],
})
export class LoanEligibilityQuestionnaireComponent implements OnInit {
  sliderInputNumberOfChildren: number;
  sliderInputCreditCosts: number;
  sliderInputHousingCosts: number;
  sliderInputSalary: number;
  sliderInputMiscExpenses: number;

  numberOfChildren: number;
  creditCosts: number;
  housingCosts: number;
  salary: number;
  miscExpenses: number;
  employment: Employment;

  showErrors: boolean;

  originalLeq: LeqFormDto;
  currentLeq: LeqFormDto;
  setupReady;

  manualMode: boolean = false;

  updateLeqTimer: any = null;
  isPhoneOrTablet: boolean;
  firstForm: FormGroup;
  secondForm: FormGroup;

  leqKidsAnswers: any[] = [
    { label: 'Inga', value: 0 },
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
    { label: '6', value: 6 },
  ];
  leqCreditAnswers: any[] = [
    { label: '0 - 500 Kr', value: 500 },
    { label: '501 - 1000 Kr', value: 1000 },
    { label: '1001 - 1500 Kr', value: 1500 },
    { label: '1501 - 2000 Kr', value: 2000 },
    { label: '2001 - 2500 Kr', value: 2500 },
    { label: '2501 - 3000 Kr', value: 3000 },
    { label: '3001 - 3500 Kr', value: 3500 },
    { label: '3501 - 4000 Kr', value: 4000 },
    { label: '4001 - 4500 Kr', value: 4500 },
    { label: '4501 - 5000 Kr', value: 5000 },
  ];
  leqLivingAnswers: any[] = [
    { label: '0 - 1000 Kr', value: 1000 },
    { label: '1001 - 2000 Kr', value: 2000 },
    { label: '2001 - 3000 Kr', value: 3000 },
    { label: '3001 - 4000 Kr', value: 4000 },
    { label: '4001 - 5000 Kr', value: 5000 },
    { label: '5001 - 6000 Kr', value: 6000 },
    { label: '6001 - 7000 Kr', value: 7000 },
    { label: '7001 - 8000 Kr', value: 8000 },
    { label: '8001 - 9000 Kr', value: 9000 },
    { label: '9001 - 10 000 Kr', value: 10000 },
  ];
  leqSalaryAnswers: any[] = [
    { label: '10 000 - 12 500 Kr', value: 12500 },
    { label: '12 500 - 15 000 Kr', value: 15000 },
    { label: '15 000 - 17 500 Kr', value: 17500 },
    { label: '17 500 - 20 000 Kr', value: 20000 },
    { label: '20 000 - 22 500 Kr', value: 22500 },
    { label: '22 500 - 25 000 Kr', value: 25000 },
    { label: '25 000 - 27 500 Kr', value: 27500 },
    { label: '27 500 - 30 000 Kr', value: 30000 },
    { label: '30 000 - 32 500 Kr', value: 32500 },
    { label: '32 500 - 35 000 Kr', value: 35000 },
    { label: '35 000 - 37 500 Kr', value: 37500 },
    { label: '37 500 - 40 000 Kr', value: 40000 },
    { label: '40 000 - 42 500 Kr', value: 42500 },
    { label: '42 500 - 45 000 Kr', value: 45000 },
    { label: '45 000 - 47 500 Kr', value: 47500 },
    { label: '47 500 - 50 000 Kr', value: 50000 },
  ];
  leqMiscAnswers: any[] = [
    { label: '0 - 500 Kr', value: 500 },
    { label: '501 - 1000 Kr', value: 1000 },
    { label: '1001 - 1500 Kr', value: 1500 },
    { label: '1501 - 2000 Kr', value: 2000 },
    { label: '2001 - 2500 Kr', value: 2500 },
    { label: '2501 - 3000 Kr', value: 3000 },
    { label: '3001 - 3500 Kr', value: 3500 },
    { label: '3501 - 4000 Kr', value: 4000 },
    { label: '4001 - 4500 Kr', value: 4500 },
    { label: '4501 - 5000 Kr', value: 5000 },
  ];

  leqOccupationAnswers: OccutapionAnswer[] = [
    { label: 'Fastanställd', value: Employment.PERMANENT },
    { label: 'Timanställd', value: Employment.PART_TIME },
    { label: 'Pensionär', value: Employment.RETIRED },
    { label: 'Studerande', value: Employment.STUDENT },
    { label: 'Arbetslös', value: Employment.UNEMPLOYED },
    { label: 'Egenföretagare', value: Employment.ENTREPRENEUR },
  ];

  step: string;
  navigationInProgress: boolean;

  constructor(
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private customerJourneyService: CustomerJourneyService,
    private leqService: LeqService,
    private router: Router,
    private userAgentService: UserAgentService,
    private loggingService: LoggingService,
    private formBuilder: FormBuilder
  ) {}

  async ngOnInit() {
    this.isPhoneOrTablet = this.userAgentService.isMobile();

    this.originalLeq = await this.leqService.fetchLeq();

    this.sliderInputNumberOfChildren = this.mapToClosestListOption(
      Number(this.originalLeq.numberOfChildren),
      this.leqKidsAnswers
    );
    this.sliderInputCreditCosts = this.mapToClosestListOption(
      Number(this.originalLeq.creditCosts),
      this.leqCreditAnswers
    );
    this.sliderInputHousingCosts = this.mapToClosestListOption(
      Number(this.originalLeq.housingCosts),
      this.leqLivingAnswers
    );
    this.sliderInputSalary = this.mapToClosestListOption(
      Number(this.originalLeq.income),
      this.leqSalaryAnswers
    );
    this.sliderInputMiscExpenses = this.mapToClosestListOption(
      Number(this.originalLeq.miscExpenses),
      this.leqMiscAnswers
    );

    this.employment = this.originalLeq.employment;
    this.setupReady = true;
    this.setupForm();
    this.bindStepParameter();
  }

  setupForm(): void {
    const validators = [
      Validators.required,
      Validators.min(0),
      Validators.pattern(NUMBER_PATTERN),
    ];
    this.firstForm = this.formBuilder.group({
      numberOfChildren: [
        String(this.originalLeq.numberOfChildren) || '',
        [...validators, Validators.max(50)],
      ],
      creditCosts: [
        String(this.originalLeq.creditCosts) || '',
        [...validators, Validators.max(1000000)],
      ],
      housingCosts: [
        String(this.originalLeq.housingCosts) || '',
        [...validators, Validators.max(1000000)],
      ],
    });

    this.secondForm = this.formBuilder.group({
      salary: [
        String(this.originalLeq.income) || '',
        [...validators, Validators.max(1000000)],
      ],
      miscExpenses: [
        String(this.originalLeq.miscExpenses) || '',
        [...validators, Validators.max(1000000)],
      ],
    });
  }

  bindStepParameter() {
    this.activatedRoute.params.subscribe((params) => {
      this.step = params.step;
    });
  }

  handleContinueClick() {
    if (this.navigationInProgress) {
      console.log('Returning because navigation in progress');
      return;
    }

    //Timeout for updating LEQ in backend needs to be killed, even if manual input mode is activated.
    //Else we could potentially update the leq by slider-based timeout, after a manual input form has been submitted
    this.killTimeout();

    if (this.step === '1') {
      if (this.manualMode) {
        if (this.firstForm.invalid) {
          this.updateErrorMessages();
          return; //Invalid inputs, do not move on
        }
        this.updateTextInputs();
      } else {
        this.updateSliderInputs();
      }
      this.leqService.updateLeq(this.currentLeq);
      //Navigate on response instead?
      this.navigateToNext();
    }

    if (this.step === '2') {
      if (this.manualMode) {
        if (this.secondForm.invalid) {
          this.updateErrorMessages();
          return; //Invalid inputs, do not move on
        }
        this.updateTextInputs();
      } else {
        this.updateSliderInputs();
      }

      this.navigationInProgress = true;

      this.leqService.submitLeq(this.currentLeq).subscribe(
        (res) => {
          this.navigateToNext();
        },
        (err) => {
          this.navigationInProgress = false;
          console.error(err);
        }
      );

      return;
    }

    //this.navigateToNext();
  }

  updateErrorMessages() {
    //Toggling the show error attribute will make the manual input components update their error states
    //TODO this is not quite the way this was intended to work
    this.showErrors = !this.showErrors;
  }

  navigateToNext() {
    this.navigationInProgress = true;
    this.customerJourneyService.getNextRoute().subscribe((route) => {
      this.router
        .navigate([route], { queryParamsHandling: 'preserve' })
        .then(() => {
          this.navigationInProgress = false;
        });
    });
  }

  //Values need to be changed depending on step so the user can enter manual in one and by slider in another
  updateTextInputs() {
    if (this.step === '1') {
      this.numberOfChildren = Number(this.firstForm.value.numberOfChildren);
      this.creditCosts = Number(this.firstForm.value.creditCosts);
      this.housingCosts = Number(this.firstForm.value.housingCosts);
    }
    if (this.step === '2') {
      this.salary = Number(this.secondForm.value.salary);
      this.miscExpenses = Number(this.secondForm.value.miscExpenses);
    }

    this.updateLeqValues();
  }

  sliderValueChanged() {
    this.killTimeout();
    this.updateLeqTimer = setTimeout(() => {
      this.updateSliderInputs();
      this.leqService.updateLeq(this.currentLeq);
      clearTimeout(this.updateLeqTimer);
      this.updateLeqTimer = null;
    }, 2000);
  }

  //Values need to be changed depending on step so the user can enter manual in one and by slider in another
  updateSliderInputs() {
    if (this.step === '1') {
      this.numberOfChildren = this.sliderInputNumberOfChildren;
      this.creditCosts = this.sliderInputCreditCosts;
      this.housingCosts = this.sliderInputHousingCosts;
    }
    if (this.step === '2') {
      this.salary = this.sliderInputSalary;
      this.miscExpenses = this.sliderInputMiscExpenses;
    }

    this.updateLeqValues();
  }

  //TODO these null checks are probably no longer neccessary
  updateLeqValues() {
    this.currentLeq = {
      numberOfChildren:
        this.numberOfChildren ?? this.originalLeq.numberOfChildren,
      creditCosts: this.creditCosts ?? this.originalLeq.creditCosts,
      housingCosts: this.housingCosts ?? this.originalLeq.housingCosts,
      income: this.salary ?? this.originalLeq.income,
      miscExpenses: this.miscExpenses ?? this.originalLeq.miscExpenses,
      employment: this.employment ?? this.originalLeq.employment,
    };
  }

  toggleManualMode() {
    this.manualMode ? this.mapManualToSliders() : this.mapSlidersToManual();
    this.manualMode = !this.manualMode;
  }

  mapManualToSliders() {
    if (this.step === '1') {
      if (this.firstForm.controls.numberOfChildren.valid) {
        this.sliderInputNumberOfChildren = this.mapToClosestListOption(
          Number(this.firstForm.value.numberOfChildren),
          this.leqKidsAnswers
        );
      }
      if (this.firstForm.controls.creditCosts.valid) {
        this.sliderInputCreditCosts = this.mapToClosestListOption(
          Number(this.firstForm.value.creditCosts),
          this.leqCreditAnswers
        );
      }
      if (this.firstForm.controls.housingCosts.valid) {
        this.sliderInputHousingCosts = this.mapToClosestListOption(
          Number(this.firstForm.value.housingCosts),
          this.leqLivingAnswers
        );
      }
    }
    if (this.step === '2') {
      if (this.secondForm.controls.salary.valid) {
        this.sliderInputSalary = this.mapToClosestListOption(
          Number(this.secondForm.value.salary),
          this.leqSalaryAnswers
        );
      }
      if (this.secondForm.controls.miscExpenses.valid) {
        this.sliderInputMiscExpenses = this.mapToClosestListOption(
          Number(this.secondForm.value.miscExpenses),
          this.leqMiscAnswers
        );
      }
    }
  }

  //Heavily dependent on listOptions being sorted by ascending value
  //TODO should probably sort the list
  mapToClosestListOption(inputValue: number, listOptions: ListOption[]): any {
    if (inputValue <= listOptions[0].value) {
      return listOptions[0].value;
    }

    for (let i: number = 0; i < listOptions.length - 1; i++) {
      if (listOptions[i + 1].value === inputValue) {
        return listOptions[i + 1].value;
      } else if (
        inputValue > listOptions[i].value &&
        inputValue < listOptions[i + 1].value
      ) {
        return listOptions[i + 1].value;
      } else if (inputValue >= listOptions[listOptions.length - 1].value) {
        return listOptions[listOptions.length - 1].value;
      } else {
        continue;
      }
    }
    console.error('Could not map entered information to list option');
    return null;
  }

  mapSlidersToManual() {
    if (this.step === '1') {
      this.firstForm.controls.numberOfChildren.setValue(
        String(this.sliderInputNumberOfChildren)
      );
      this.firstForm.controls.creditCosts.setValue(
        String(this.sliderInputCreditCosts)
      );
      this.firstForm.controls.housingCosts.setValue(
        String(this.sliderInputHousingCosts)
      );
    }
    if (this.step === '2') {
      this.secondForm.controls.salary.setValue(String(this.sliderInputSalary));
      this.secondForm.controls.miscExpenses.setValue(
        String(this.sliderInputMiscExpenses)
      );
    }
  }

  killTimeout() {
    if (this.updateLeqTimer !== null) {
      clearTimeout(this.updateLeqTimer);
      this.updateLeqTimer = null;
    }
  }

  reroute() {
    if (this.step === '1') {
      this.navigationInProgress = true;
      this.router
        .navigate([routeNames.EMAIL_AND_INVOICE], {
          queryParamsHandling: 'merge',
        })
        .then(() => {
          this.navigationInProgress = false;
        });
    } else if (this.step === '2') {
      this.navigationInProgress = true;
      this.router
        .navigate([`/${routeNames.LEQ}/1`], { queryParamsHandling: 'preserve' })
        .then(() => {
          this.navigationInProgress = false;
        });
    }
  }

  removeAndroidKeyboardIfAny() {
    if (this.isPhoneOrTablet) {
      let field = document.createElement('input');
      field.setAttribute('type', 'text');
      document.body.appendChild(field);

      setTimeout(() => {
        field.focus();
        setTimeout(() => {
          field.setAttribute('style', 'display:none;');
        }, 50);
      }, 50);
    }
  }
}

export const NUMBER_PATTERN = /^[0-9]+$/;

export class ListOption {
  label: string;
  value: any;
}
