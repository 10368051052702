import { Injectable } from '@angular/core';

import {
  DENIED_AMOUNT_TOO_HIGH,
  DENIED_CREDIT_SCORE,
  DENIED_UNKNOWN_REASON,
  UNKNOWN_ERROR,
  DENIED_LEQ_ANSWERS,
} from '../../model/credit/credit-denied-decision.dto';
import { PurgeAvailablePaymentMethods } from '../../ngxs/payment-request/payment-request.actions';
import { CreditDecisionDialogComponent } from '../../component/dialog/credit-decision/credit-decision-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { routeNames } from '../../../assets/val/route-constants';
import {
  LeqType,
  PaymentMethod,
  PaymentRequestDto,
} from 'src/app/model/payment-request/payment-request.dto';
import { QueryParamsService } from '../query-params/query-params.service';
import { LoggingService } from '../logging-service/logging.service';

@Injectable({
  providedIn: 'root',
})
export class CreditDecisionService {
  constructor(
    private dialogService: MatDialog,
    private store: Store,
    private router: Router,
    private queryParamsService: QueryParamsService,
    private loggingService: LoggingService
  ) {}

  public handleCreditDecisionDenied(
    errorIdentifier: string,
    sourceRoute: string
  ) {
    this.loggingService.error(
      'opened credit decision denial dialog. Error identifier: ' +
        errorIdentifier +
        'source route: ' +
        sourceRoute
    );
    const dialogConfiguration = {
      maxHeight: '75vh',
      maxWidth: '90vw',
      data: {
        buttons: [
          {
            title: 'Välj en annan betalmetod',
            callback: () => {
              this.router
                .navigate([routeNames.HOME], {
                  queryParamsHandling: 'preserve',
                })
                .then(
                  () => this.purgePaymentMethodsIfNecessary(errorIdentifier),
                  (rejection) => {
                    this.loggingService.error(
                      `Navigation from ${sourceRoute} to ${routeNames.HOME} was rejected.`,
                      rejection
                    );
                  }
                );
            },
          },
        ],
      },
    };

    if (errorIdentifier === DENIED_UNKNOWN_REASON) {
      this.dialogService.open(
        CreditDecisionDialogComponent,
        dialogConfiguration
      );
    }

    if (!errorIdentifier || errorIdentifier === UNKNOWN_ERROR) {
      this.loggingService.reportCrash(
        'opened credit denial dialog for unknown reason. Erroridentifier: ' +
          errorIdentifier,
        ' sourceRoute:' + sourceRoute,
        null
      );
      this.dialogService.open(
        CreditDecisionDialogComponent,
        dialogConfiguration
      );
    }

    if (errorIdentifier === DENIED_CREDIT_SCORE) {
      this.dialogService.open(
        CreditDecisionDialogComponent,
        dialogConfiguration
      );
    }

    if (
      (errorIdentifier === DENIED_LEQ_ANSWERS ||
        errorIdentifier === DENIED_AMOUNT_TOO_HIGH) &&
      this.isLeqRequiredForSelectedPaymentMethod()
    ) {
      const config = {
        ...dialogConfiguration,
        data: {
          ...dialogConfiguration.data,
          buttons: [
            {
              title: 'Se över svar',
              callback: () => {
                const target = `${routeNames.LEQ}/1`;
                this.router
                  .navigate([target], { queryParamsHandling: 'preserve' })
                  .then(
                    () => {},
                    (rejection) => {
                      this.loggingService.error(
                        `Navigation from ${sourceRoute} to ${target} was rejected.`,
                        rejection
                      );
                    }
                  );
              },
            },
            ...dialogConfiguration.data.buttons,
          ],
        },
      };

      this.dialogService.open(CreditDecisionDialogComponent, config);
    }
  }

  private purgePaymentMethodsIfNecessary(errorIdentifier: string) {
    if (errorIdentifier === DENIED_CREDIT_SCORE) {
      this.store.dispatch(
        new PurgeAvailablePaymentMethods([
          PaymentMethod.CREDIT,
          PaymentMethod.INVOICE,
        ])
      );
    }
  }

  private isLeqRequiredForSelectedPaymentMethod(): boolean {
    const paymentRequest: PaymentRequestDto =
      this.store.snapshot().paymentRequest.request;
    const selectedMethodId = this.queryParamsService.getQueryParams().method;
    const selectedMethod = paymentRequest.availableMethods.find(
      (m) => m.id === selectedMethodId
    );

    if (!selectedMethod) {
      // if we could not find the selected payment method this is an error
      this.loggingService.error(
        'Could not find information about selected payment method:',
        selectedMethodId
      );
      return true; // "safe" default?
    }
    return selectedMethod.requiredLeqType !== LeqType.NONE;
  }
}
