const ACTION_SUBJECT_TAG = '[Payment Method]';

export class GetInvoiceInfo {
  static readonly type = `${ACTION_SUBJECT_TAG} Get invoice credit option information`;

  constructor(public paymentRequestId: string) {}
}

export class ClearInvoiceFetchingError {
  static readonly type = `${ACTION_SUBJECT_TAG} Clear the error identifier after an 
  invoice fetching error is handled`;

  constructor() { }
}
