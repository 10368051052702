<div class="page-body" *ngIf="!loading && error !== true">
  <div class="contents-wrapper">
    <div class="contents-container">
      <ng-container
        *ngFor="let item of presentedInstallmentPlans; let i = index"
      >
        <app-installments-item
          *ngIf="
            item.nominalInterest * 100 > 0.001 ||
            item.nominalInterest * 100 === 0.1
          "
          [title]="getTitleString(item.monthlyCost, item.numberOfMonths)"
          [selected]="presentedInstallmentPlans[i] === selectedInstallmentPlan"
          [bulletPoints]="getBulletPoints(item)"
          includeTermsLink="true"
          [link]="{ text: 'Se avtal', onClick: openChoiceTerms.bind(this) }"
          [isFirstItem]="isFirstItem(i)"
          [isLastItem]="isLastItem(i)"
          [color]="'#00ac84'"
          (click)="select(i)"
          [radioButtonColor]="'#e01d78'"
        ></app-installments-item>

        <app-installments-item
          *ngIf="item.nominalInterest * 100 < 0.001"
          [title]="getTitleString(item.monthlyCost, item.numberOfMonths)"
          [selected]="presentedInstallmentPlans[i] === selectedInstallmentPlan"
          badgeText="0% ränta"
          [bulletPoints]="getBulletPoints(item)"
          includeTermsLink="true"
          [link]="{ text: 'Se avtal', onClick: openChoiceTerms.bind(this) }"
          [isFirstItem]="isFirstItem(i)"
          [isLastItem]="isLastItem(i)"
          [color]="'#00ac84'"
          (click)="select(i)"
          [radioButtonColor]="'#e01d78'"
        ></app-installments-item>
      </ng-container>

      <button
        *ngIf="availableInstallmentPlans.length > 3"
        class="installments-button"
        (click)="toggleExpandedView()"
      >
        {{ allInstallmentPlansIsShown() ? 'Visa färre' : 'Visa fler' }}
        <img
          style="margin-left: 4px; display: inline-block"
          [src]="
            allInstallmentPlansIsShown()
              ? 'assets/icon/Arrow-Up.svg'
              : 'assets/icon/Arrow-Down.svg'
          "
        />
      </button>

      <app-payment-request-info
        [lineItems]="paymentRequest.lineItems"
        [market]="paymentRequest.market"
        [value]="paymentRequest.value"
        (onDetailsClick)="activateModal('Betalningsvillkor')"
      ></app-payment-request-info>

      <zaver-primary-button
        [text]="'Slutför köp'"
        (click)="initSign()"
        [disabled]="navigating"
        [loading]="loading"
        logo="/assets/icon/BankID-Vector-Logo-White.svg"
      ></zaver-primary-button>

      <p class="calculations">
        {{ currentLoanTermsStr }}
      </p>
    </div>
  </div>
</div>

<zaver-checkout-modal
  id="details-modal"
  [active]="modalActive"
  [title]="'Visa detaljer'"
  (onClose)="deactivateModal()"
  [buttonText]="'Stäng'"
>
  <app-selector
    #selector
    *ngIf="!!selectorItems"
    [selectorItems]="selectorItems"
  ></app-selector>
</zaver-checkout-modal>

<div class="page-body" *ngIf="loading">
  <div class="contents-wrapper">
    <div class="contents-container">
      <app-spinner></app-spinner>
    </div>
  </div>
</div>
