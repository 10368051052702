import { Injectable } from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import { Observable } from 'rxjs';

/**
 * Attach the payment request ID to the X-PRID header of every outgoing
 * request. Done in favor of using a session cookie or session attribute
 * as Google chrome abuses the http session standards by persisting
 * session data after the user closes the browser.
 */
@Injectable()
export class CredentialsInterceptor implements HttpInterceptor  {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const cloneReq = req.clone({ withCredentials: true });

    return next.handle(cloneReq);
  }
}
