export class AuthInitDto {
  preFilled: boolean;
  personalNumber: string;


  constructor(preFilled: boolean, personalNumber: string) {
    this.preFilled = preFilled;
    this.personalNumber = personalNumber;
  }
}
