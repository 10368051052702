<div class="page-body">
  <div class="contents-wrapper">
    <div class="contents-container">
      <div class="error-contents-wrapper">
        <img src="assets/icon/smiley-sad-nerd.svg" class="smiley-icon" alt="" />
        <p class="error-message">{{ errorMessage }}</p>
        <p *ngIf="infoMessage" class="info-message">{{ infoMessage }}</p>
      </div>
    </div>
  </div>
</div>
