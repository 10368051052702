import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { DeBaseComponent } from '@app/route/DE/de-base-component/de-base.component';
import { HeaderService } from '@app/service/header/header.service';
import { QueryParamsService } from '@app/service/query-params/query-params.service';
import {
  CheckoutState,
  StateControlParameters,
  StateService,
} from '@app/service/state/state.service';

@Injectable({
  providedIn: 'root',
})
export class StateResolver implements Resolve<CheckoutState> {
  constructor(
    private stateService: StateService,
    private queryParamsService: QueryParamsService,
    private headerService: HeaderService,
    private deBaseComponent: DeBaseComponent
  ) {}

  async resolve(route: ActivatedRouteSnapshot): Promise<CheckoutState> {
    try {
      const path: string = route.routeConfig.path;

      const token = this.queryParamsService.getQueryParams().id;

      if (token !== null) {
        // If a user refreshes a page within the flow.
        this.stateService.setToken(token);
      }

      const controlParams: StateControlParameters = {
        currentPageName: this.stateService.getStepFromUrl(path),
        token: this.stateService.getToken(),
      };

      if (controlParams.token) {
        const state = await this.stateService.fetchState(controlParams);
        this.headerService.setMarket(state.market);
        this.headerService.setCancelUrl(state.merchantUrls?.onCancelUrl);
        this.headerService.setCurrentPage(
          state.currentPageInformation?.pageName
        );
        this.headerService.processReceivedPartnerLogoUri(
          state?.checkoutCustomizations?.logoUrl
        );
        this.headerService.setCheckoutStatus(state.status);
        return state;
      }
    } catch (error) {
      this.deBaseComponent.handleError(error);
    }
  }
}
