import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  ClearPreFilledInfo,
  InitAuthProcess, RegisterAuthState, SetPreFilledCredentials
} from './auth.actions';
import { tap } from 'rxjs/operators';
import { BasicUserInfo } from './dto/basic-user-info.dto';
import { UpdateFormValue } from '@ngxs/form-plugin';
import { SERVER_BASE_URL } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {AuthService} from '../../service/auth/auth.service';
import { LoggingService } from 'src/app/service/logging-service/logging.service';
import {Inject, Injectable} from '@angular/core';

export class AuthStateModel {
  personalNumberFormMeta: {
    preFilled: boolean,
    checkedServerForPreFillInfo,
    preFillInfo: {
      name: string,
      personalNumber: string,
      truncatedPersonalNumber: string
    }
  };
  authenticated: boolean;
  status: string;
  inProgress: boolean;
  message: string;
  isError: boolean;
}

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    personalNumberFormMeta: {
      preFilled: false,
      checkedServerForPreFillInfo: false,
      preFillInfo: {
        name: '',
        personalNumber: '',
        truncatedPersonalNumber: ''
      }
    },
    authenticated: false,
    status: '',
    inProgress: false,
    message: '',
    isError: false,
  }
})

@Injectable()
export class AuthState {

  constructor(private http: HttpClient,
              private authService: AuthService,
              private loggingsService: LoggingService) { }

  @Selector()
  static getAuthState(state: AuthStateModel) {
    return state;
  }

  @Action(InitAuthProcess)
  initializeAuthProcess(ctx: StateContext<AuthStateModel>,
                        action: InitAuthProcess) {

    const { getState } = ctx;
    ctx.patchState({isError : false, message: '', status: ''});

    const prefilled =
      getState().personalNumberFormMeta.preFilled;


    this.authService.authenticate(
      action.personalNumber,
      prefilled
    );
  }

  @Action(RegisterAuthState)
  registerAuthState(ctx: StateContext<AuthStateModel>,
                    action: RegisterAuthState) {

    const { patchState } = ctx;
    const { authenticated, message, inProgress, status, isError } = action;


    patchState({
      authenticated,
      status,
      inProgress,
      message,
      isError,
    });
  }

  @Action(SetPreFilledCredentials)
  checkPreFilledCredentials(ctx: StateContext<AuthStateModel>) {

    return this.http.get(
      `${SERVER_BASE_URL}/user/public/info`,
      { withCredentials: true }
    ).pipe(
      tap((basicUserInfo: BasicUserInfo) => {
        if (basicUserInfo.populated) {
          ctx.patchState({
            personalNumberFormMeta: {
              checkedServerForPreFillInfo: true,
              preFilled: true,
              preFillInfo: {
                name: basicUserInfo.name,
                personalNumber: basicUserInfo.personalNumber,
                truncatedPersonalNumber: basicUserInfo.truncatedPersonalNumber
              }
            }
          });
          ctx.dispatch(new UpdateFormValue({
            value: {
              personalNumber: `${basicUserInfo.personalNumber}`
            },
            path: 'personalNumberForm'
          }));
        } else {
          ctx.patchState({
            personalNumberFormMeta: {
              ...ctx.getState().personalNumberFormMeta,
              checkedServerForPreFillInfo: true,
              preFilled: false,
            }
          });
        }
      })
    );
  }

  @Action(ClearPreFilledInfo)
  clearPreFilledInfo(ctx: StateContext<AuthStateModel>) {
    const { patchState, getState, dispatch } = ctx;

    const url = `${SERVER_BASE_URL}/user/public/clear`;
    this.http.post(url, null, { withCredentials: true })
      .subscribe(() => {
        // The request to clear the ASCID cookie was successful,
        // purge all of the pre-fill info and reset the form field value
        patchState({
          ...getState(),
          personalNumberFormMeta: {
            ...ctx.getState().personalNumberFormMeta,
            checkedServerForPreFillInfo: true,
            preFilled: false,
            preFillInfo: {
              name: '',
              personalNumber: '',
              truncatedPersonalNumber: ''
            }
          }
        });

        dispatch(new UpdateFormValue({
          value: {
            personalNumber: ''
          },
          path: 'personalNumberForm'
        }));
      }, (err) => {
        this.loggingsService.error(err);
      });
  }

}
