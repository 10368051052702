export interface SwishResponseDto {
    id: string;
    status: SwishStatus;
    token: string;
    errors: SwishError[];
}

export enum SwishStatus {
    CREATED = 'CREATED',
    ERROR = 'ERROR',
    PAID = 'PAID',
    DECLINED = 'DECLINED',
    CANCELLED = 'CANCELLED',
}

export interface SwishError {
    errorCode: SwishErrorCode;
    message: String;
    additionalInformation: String;
    unrecoverable: boolean;
}

export enum SwishErrorCode {
    BE18 = 'BE18',
    RP06 = 'RP06',
    ACMT03 = 'ACMT03',
    ACMT01 = 'ACMT01',
    AM06 = 'AM06',
    AM02 = 'AM02',
    FF10 = 'FF10',
    BANKID = 'BANKID',
    BANKIDUNKN = 'BANKIDUNKN',
    BANKIDONGOING = 'BANKIDONGOING',
    BANKIDCL = 'BANKIDCL',
    DS24 = 'DS24',
    RF07 = 'RF07',
    RP07 = 'RP07',
    TM01 = 'TM01',
    AM21 = 'AM21'
}


export function getErrorMessage(error: SwishErrorCode): string {

    switch (error) {
        case SwishErrorCode.BE18:
            return 'Ogiltigt telefonnummer';
        case SwishErrorCode.RP06:
            return 'Numret har en pågående betalningsförfrågan';
        case SwishErrorCode.ACMT03:
            return 'Numret är ej kopplat till Swish';
        case SwishErrorCode.ACMT01:
            return 'Swish är ej aktiverat';
        case SwishErrorCode.AM06:
            return 'Transaktionsbeloppet är för litet';
        case SwishErrorCode.AM02:
            return 'Transaktionsbeloppet är för stort';
        case SwishErrorCode.FF10:
            return 'Överföringsfel';
        case SwishErrorCode.BANKIDCL:
            return 'Signering avbruten';
        case SwishErrorCode.BANKIDONGOING:
            return 'Bankid upptaget';
        case SwishErrorCode.BANKIDUNKN:
            return 'Bankid misslyckades';
        case SwishErrorCode.DS24:
            return 'Överföringsfel, status okänd';
        case SwishErrorCode.RF07:
            return 'Transaktion nekad';
        case SwishErrorCode.RP07:
            return 'Transaktionen kunde inte avbrytas';
        case SwishErrorCode.TM01:
            return 'Överföringen tog för lång tid';
        case SwishErrorCode.AM21:
            return 'Beloppsgräns överskriden'
    }
    return 'Okänt swish-fel';
}
