<div class="page-body" *ngIf="loading === false">
  <div class="banner">
    <img
      src="assets/icon/Arrow-Left.svg"
      alt=""
      class="back-button"
      (click)="reroute()"
    />
    <span class="back-text" (click)="reroute()">Tillbaka</span>
  </div>

  <div class="contents-wrapper">
    <div class="contents-container">
      <div class="page-icon">
        <img src="/assets/icon/Icon-E-mail.svg" alt="" />
      </div>

      <div class="page-text">Vänligen ange din e-post</div>

      <zaver-alert-box
        *ngIf="shouldDisplayEmailError"
        [messageList]="emailErrorMessages"
        type="WARNING"
      ></zaver-alert-box>

      <form [formGroup]="form">
        <zaver-animated-form-input
          [size]="'large'"
          [placeholder]="'E-postadress'"
          [formControlName]="'email'"
          [error]="form.controls.email.invalid && showError"
          [errorMessage]="'Vänligen ange en giltig e-postadress'"
          [inputMode]="'email'"
          [autoComplete]="'email'"
          [inputType]="'email'"
          [errorIcon]="'assets/icon/icon_error.svg'"
        ></zaver-animated-form-input>
      </form>

      <div class="pr-info" *ngIf="paymentRequest">
        <label> Totalbelopp </label>
        <label>
          {{
            paymentRequest.amount.toLocaleString('sv', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })
          }}
          SEK
        </label>
      </div>

      <zaver-primary-button text="Fortsätt" (click)="handleCtaClick()">
      </zaver-primary-button>
    </div>
  </div>
</div>

<div class="page-body" *ngIf="loading === true">
  <div class="contents-wrapper">
    <div class="contents-container">
      <app-spinner></app-spinner>
    </div>
  </div>
</div>
