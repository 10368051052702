import { Currency as newCurr } from '../currency';
export interface PaymentRequestDto {
  id: string;
  status: string;
  value: newCurr;
  amount: number;
  currency: Currency;
  market: Market;
  language: string;
  lowestPossibleMonthlyPayment: number;
  amountDenotesCreditLimit: boolean;
  title: string;
  description: string;
  merchantDetails: {
    companyName: string;
    logoUrl: string;
  };
  availableMethods: AuthorizedPaymentMethod[];
  settlementMethod: PaymentMethod;
  phoneNumber: string;
  paymentChannel: PaymentChannel;
  lineItems: LineItem[];
  cancelUrl: string;
}
export interface LineItem {
  name: string;
  quantity: number;
  value: newCurr;
}

export const PaymentRequestStatus = {
  SETTLED: 'SETTLED',
  OPEN: 'OPEN',
  PROCESSING_SIGNATURE: 'PROCESSING_SIGNATURE',
  PROPRIETARY: 'PROPRIETARY',
  CANCELLED: 'CANCELLED',
};

export interface AuthorizedPaymentMethod {
  id: string;
  type: PaymentMethod;
  requiredLeqType: LeqType;
  promotionalInterestRate: number;
  promotionalNumberOfInstallments: number;
  promotionalStartFee: number;
  promotionalRecurringFee: number;
  promotionalDurationDays: number;
  promotionalDurationMonths: number;
  description: string;
  lowestPossibleMonthlyPayment: number;
}

export interface PaymentMethodVM {
  id: string;
  type: PaymentMethod;
  title: string;
  subTitle: string;
  imgSrc: string;
  bulletPoints: string[];
}

export enum PaymentMethod {
  TRUSTLY = 'TRUSTLY',
  INVOICE = 'INVOICE',
  CREDIT = 'CREDIT',
  SWISH_EXTERNAL = 'SWISH_EXTERNAL',
  VIPPS_EXTERNAL = 'VIPPS_EXTERNAL',
  INSTANT_DEBIT = 'INSTANT_DEBIT',
  TOKENIZED_CREDIT_LIMIT = 'TOKENIZED_CREDIT_LIMIT',
}

export enum LeqType {
  NONE = 'NONE',
  STANDARD = 'STANDARD',
}

export enum PaymentChannel {
  EMBEDDED_CHECKOUT = 'EMBEDDED_CHECKOUT',
  WEB_CHECKOUT = 'WEB_CHECKOUT',
}

export enum Currency {
  SEK,
  DKK,
  NOK,
  // TODO
}

export enum Market {
  AT = 'AT',
  DE = 'DE',
  DK = 'DK',
  NO = 'NO',
  SE = 'SE',
}
