<div
  id="container"
  [ngClass]="{
    'top-item': isFirstItem,
    'bottom-item': isLastItem,
    selected: selected
  }"
>
  <div class="main-information-wrapper">
    <div class="button-container">
      <zaver-radio-button
        [selected]="selected"
        [selectedColor]="color"
      ></zaver-radio-button>
    </div>

    <div class="text-container">
      <div class="title">
        {{ title }}
      </div>
      <div class="description" *ngIf="!!description">
        {{ description }}
      </div>
    </div>

    <div class="img-container" *ngIf="!!imgSrc">
      <zaver-badge *ngIf="!!imgSrc" imgSrc="{{ imgSrc }}" color="{{ color }}">
      </zaver-badge>
    </div>
  </div>

  <div class="bullet-points-wrapper" *ngIf="selected">
    <div class="bullet" *ngFor="let bullet of bulletPoints">
      <img src="/assets/icon/Checkmark.svg" alt="" />
      <p>{{ bullet }}</p>
    </div>
  </div>

  <a
    *ngIf="showTermsLink && selected"
    class="show-terms-link"
    (click)="emitTermsClick()"
  >
    {{ linkText }}
  </a>
</div>
