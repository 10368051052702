import {CreditOptionDto} from '../../model/credit/credit-option.dto';
import {Store, Action, State, StateContext} from '@ngxs/store';
import {
  ClearInstallmentPlanFetchingError,
  FetchAvailableInstallmentPlans,
  SelectInstallmentPlanOption} from './installments.actions';

import {HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {SERVER_BASE_URL} from '../../../environments/environment';
import {tap, catchError} from 'rxjs/operators';
import { QueryParamsService } from 'src/app/service/query-params/query-params.service';
import { PaymentRequestDto } from 'src/app/model/payment-request/payment-request.dto';
import { of } from 'rxjs';
import {Injectable} from '@angular/core';

export class InstallmentsStateModel {
  options: CreditOptionDto[];
  loading: boolean;
  error: string;
  selectedPlanIndex: number;
}

@State({
  name: 'installments',
  defaults: {
    options: [],
    loading: true,
    error: null,
    selectedPlanIndex: 0
  }
})

@Injectable()
export class InstallmentsState {
  constructor(private http: HttpClient, private store: Store, private queryParamsService: QueryParamsService) {}

  @Action(FetchAvailableInstallmentPlans)
  fetchAvailableInstallmentPlans(ctx: StateContext<InstallmentsStateModel>, _) {

    const { patchState } = ctx;

    patchState({
      loading: true
    });

    const paymentRequest: PaymentRequestDto = this.store.snapshot().paymentRequest.request;
    const selectedMethodId = this.queryParamsService.getQueryParams().method;
    // const selectedMethod = paymentRequest.availableMethods.find(m => m.id === selectedMethodId);


    return this.http.get(
      `${SERVER_BASE_URL}/request/installments/plans/${selectedMethodId}`,
      {
        withCredentials: true,
        observe: 'response'
      })
      .pipe(tap( (response: HttpResponse<CreditOptionDto[]>) => {
        const { body } = response;

        patchState({
          loading: false,
          options: !body ? [] : body,
          selectedPlanIndex: 0
        });
      }, (error: HttpErrorResponse) => {

        patchState({
          loading: false,
          error: (error.error && error.error.type) ? error.error.type : 'UNKNOWN_ERROR',
        });
      }
    ));
  }

  @Action(ClearInstallmentPlanFetchingError)
  clearInstallmentPlansFetchingError(ctx: StateContext<InstallmentsStateModel>) {
    const { getState, patchState } = ctx;

    patchState({
      ...getState(),
      error: null,
    });

  }


  @Action(SelectInstallmentPlanOption)
  selectInstallmentPlanOption(ctx: StateContext<InstallmentsStateModel>,
                              action: SelectInstallmentPlanOption) {
    const { getState, patchState } = ctx;

    patchState({
      ...getState(),
      selectedPlanIndex: action.selectedIndex
    });
  }

}
