import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SERVER_BASE_URL } from '../../../environments/environment';
import { AuthStatusDto } from './dto/auth-status.dto';
import { AuthInitDto } from './dto/auth-init.dto';
import { Store } from '@ngxs/store';
import { RegisterAuthState } from '../../ngxs/auth/auth.actions';
import { QueryParamsService } from '../query-params/query-params.service';
import { AppDeepLinkService } from '../app-deeplink/app-deep-link.service';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { LoggingService } from '../logging-service/logging.service';

/**
 * This component will provide the app with auth functionality for the IDAU
 * BankID auth endpoint, as a temporary development solution, a mock BankID
 * service is used instead. The server API implementation will change but the core
 * functionality (polling, observable handling) will remain the same.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private static readonly MAX_POLLING_ATTEMPTS = 120;

  private pollingIntervalHandle;
  private pollingAttemptsMade = 0;
  private serverAuthenticationProcessId: string;
  private lastAutoStartToken: string = null;

  constructor(
    private http: HttpClient,
    private store: Store,
    private queryParamsService: QueryParamsService,
    private deepLinkService: AppDeepLinkService,
    private loggingService: LoggingService
  ) {}

  /**
   * This payment-method will:
   * 1. Make a call to an IDAU auth endpoint. The response will contain a
   *    token that is a reference to the current auth process.
   *
   * 2. Set an interval for polling a status endpoint for updated regarding the
   *    current auth process, using the earlier returned token.
   *
   * @return an observable that progresses through the statuses which are published
   * by IDAU to the auth status endpoint for a specific token.
   */
  authenticate(personalIdentityNumber: string, preFilled: boolean) {
    this.lastAutoStartToken = null;
    const initDto = new AuthInitDto(preFilled, personalIdentityNumber);
    this.loggingService.warn(
      'initializing new auth, initDto=' + JSON.stringify(initDto)
    );
    /**
     * Init the BankID auth and observe the response.
     * withCredentials is set to true to avoid disregarding the returned session cookie.
     */
    this.http
      .post<AuthStatusDto>(
        `${SERVER_BASE_URL}/auth/public/init?autostart=false`,
        initDto,
        { withCredentials: true }
      )
      .subscribe(
        (response) => {
          this.handleAuthInitResponse(response);
        },
        (err) => {
          this.loggingService.reportCrash(
            'error response when starting bankid auth',
            JSON.stringify(err)
          );
          this.registerFailStatus(
            'Något gick fel. Legitimeringen misslyckades.'
          );
          return;
        }
      );
  }

  authenticateMobileUser() {
    this.lastAutoStartToken = null;
    this.store.dispatch(new RegisterAuthState('', false, false, ''));

    this.http
      .post<AuthStatusDto>(
        `${SERVER_BASE_URL}/auth/public/init?autostart=true`,
        new AuthInitDto(false, null),
        { withCredentials: true }
      )
      .subscribe(
        (resp: AuthStatusDto) => {
          this.queryParamsService.setQueryParam('bidRequestId', resp.id);
          this.lastAutoStartToken = resp.autoStartToken;
          this.deepLinkService
            .startBankId(resp.autoStartToken)
            .then(() => this.handleAuthInitResponse(resp));
        },
        (err) => {
          this.loggingService.reportCrash(
            'error response when starting bankid auth:',
            JSON.stringify(err)
          );
          this.registerFailStatus(
            'Något gick fel. Legitimeringen misslyckades.'
          );
          return;
        }
      );
  }

  resumeAuthenticationOfMobileUser() {
    this.initStatusChecking(
      this.queryParamsService.getQueryParams().bidRequestId
    );
  }

  openBankIdApp() {
    const autoStartToken = this.lastAutoStartToken
      ? this.lastAutoStartToken
      : '';
    this.deepLinkService
      .startBankId(autoStartToken)
      .then(() =>
        this.loggingService.log('tried to open bankid manually - success')
      );
  }

  stopPolling() {
    this.pollingAttemptsMade = 0;
    clearInterval(this.pollingIntervalHandle);
    this.pollingIntervalHandle = null;
  }

  initStatusChecking(bankIdRequestId: string) {
    this.serverAuthenticationProcessId = bankIdRequestId;
    if (
      this.queryParamsService.getQueryParams().id === null ||
      this.queryParamsService.getQueryParams().id === ''
    ) {
      this.loggingService.reportCrash(
        'missing id when starting status checking!'
      );
    }
    this.checkAuthStatus();
    this.pollingIntervalHandle = setInterval(
      () => this.checkAuthStatus(),
      1000
    );
  }

  private handleAuthInitResponse(response: AuthStatusDto) {
    this.initStatusChecking(response.id);
  }

  /**
   * Poll IDAU for current auth status using the serverAuthenticationProcessId and call a
   * feed publishing payment-method on the provided @param observer. The observer publishes to
   * the Observable that is subscribed to by consumers of the AuthService.
   */
  private checkAuthStatus() {
    // Fetch current auth status and subscribe to interpret the response.
    this.http
      .get<AuthStatusDto>(
        `${SERVER_BASE_URL}/auth/public/check/${this.serverAuthenticationProcessId}`,
        { withCredentials: true }
      )
      .subscribe(
        (response) => {
          this.pollingAttemptsMade++;

          this.registerAuthStatus(response);

          if (!response.active) {
            this.stopPolling();
          }

          if (response.authenticated) {
            this.stopPolling();
          }

          if (this.pollingAttemptsMade >= AuthService.MAX_POLLING_ATTEMPTS) {
            // Error out after 120 polls -> The user was probably too slow or abandoned the auth
            // process.
            this.loggingService.reportCrash(
              'Maximum number of BankID polling attempts was made.'
            );
            this.stopPolling();
          }
        },
        (err) => {
          this.loggingService.reportCrash(
            'error response when checking bankid status',
            JSON.stringify(err),
            null
          );
          this.registerFailStatus(
            'Något gick fel. Legitimeringen misslyckades.'
          );
          this.stopPolling();
          return;
        }
      );
  }

  private registerAuthStatus(response: AuthStatusDto) {
    const { active, message, authenticated, status } = response;

    this.store.dispatch(
      new RegisterAuthState(message, authenticated, active, status)
    );
  }

  private registerFailStatus(message: string, status?: string) {
    this.store.dispatch(
      new RegisterAuthState(message, false, false, status, true)
    );
  }
}
