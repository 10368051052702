<app-de-thin-wrapper>
  <div class="container">
    <div
      class="img-container"
      *ngIf="state.interruptionDetails?.shouldDisplaySadFredrik"
    >
      <img src="assets/icon/smiley-sad-nerd.svg" class="smiley-icon" alt="" />
    </div>

    <p class="heading">{{ state.interruptionDetails?.heading }}</p>

    <p class="info-text">{{ state.interruptionDetails?.message }}</p>

    <div class="button-wrapper">
      <zaver-secondary-button-outlined
        *ngIf="state.interruptionDetails.ctaLabel"
        style="max-width: 240px"
        (click)="handleCtaClick()"
        [text]="state.interruptionDetails.ctaLabel"
        [rounded]="true"
        [disabled]="navigationInProgress"
      ></zaver-secondary-button-outlined>
    </div>
    <zaver-button-link
      style="margin-top: 24px"
      *ngIf="!!state.interruptionDetails.linkLabel"
      (click)="handleLinkClick()"
      [text]="state.interruptionDetails.linkLabel"
      [disabled]="navigationInProgress"
    ></zaver-button-link>
  </div>
</app-de-thin-wrapper>
