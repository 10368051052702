<div class="page-body" *ngIf="!(loadingPreFillInfoFromServer || navigating)">
  <div class="banner">
    <img
      src="assets/icon/Arrow-Left.svg"
      alt=""
      class="back-button"
      (click)="reroute()"
    />
    <span class="back-text" (click)="reroute()">Tillbaka</span>
  </div>

  <div class="contents-wrapper">
    <div class="contents-container">
      <div class="page-icon">
        <img src="/assets/icon/BankID-Vector-Logo.svg" alt="" />
      </div>

      <!-- Mobile interface -->
      <ng-container *ngIf="onPlatformWhereBankIdIsAssumedInstalled">
        <ng-container *ngIf="authInProgress">
          <span class="status-message">{{ bankIdStatusMessage }}</span>
          <div class="dot-spinner-container">
            <app-dot-spinner></app-dot-spinner>
          </div>
        </ng-container>

        <div
          class="alert-container"
          *ngIf="manualDeeplinkEnabled || authErrorMessageList"
        >
          <div
            *ngIf="authInProgress && manualDeeplinkEnabled"
            class="deeplink-button"
            (click)="deeplinkToBankId()"
          >
            Öppna BankID appen
          </div>
          <zaver-alert-box
            type="WARNING"
            [messageList]="authErrorMessageList"
            *ngIf="!authInProgress && authErrorMessageList"
          >
          </zaver-alert-box>
        </div>

        <zaver-primary-button
          *ngIf="!authInProgress"
          [text]="ctaButtonText"
          [disabled]="authInProgress"
          (click)="handleCtaClick()"
        ></zaver-primary-button>
      </ng-container>

      <!-- Desktop interface -->
      <ng-container *ngIf="!onPlatformWhereBankIdIsAssumedInstalled">
        <div class="page-text" *ngIf="!authInProgress">
          Vänligen identifiera dig med mobilt BankID för att fortsätta.
        </div>

        <div class="open-app-text" *ngIf="authInProgress">
          Öppna BankID-appen i mobilen
        </div>

        <form [formGroup]="form">
          <div
            class="input-container"
            *ngIf="!authInProgress && !authErrorMessageList"
          >
            <zaver-animated-form-input
              [size]="'large'"
              [ariaLabel]="'Ange personnummer'"
              [formControlName]="'pnr'"
              [error]="form.controls.pnr.invalid && showFormErrors"
              inputMode="numeric"
              placeholder="ååååmmddnnnn"
              errorMessage="Vänligen ange ett giltigt personnummer"
              [errorIcon]="'assets/icon/icon_error.svg'"
            ></zaver-animated-form-input>
          </div>
        </form>
        <div class="alert-container" *ngIf="authInProgress">
          <zaver-alert-box type="INFO" [text]="bankIdStatusMessage">
          </zaver-alert-box>
        </div>

        <div
          class="alert-container"
          *ngIf="!authInProgress && authErrorMessageList"
        >
          <zaver-alert-box type="WARNING" [messageList]="authErrorMessageList">
          </zaver-alert-box>
        </div>

        <div class="pr-info" *ngIf="paymentRequest">
          <label> Totalbelopp </label>
          <label>
            {{
              paymentRequest.amount.toLocaleString('sv', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })
            }}
            {{ paymentRequest.currency }}
          </label>
        </div>

        <zaver-primary-button
          [text]="ctaButtonText"
          [disabled]="authInProgress"
          [loading]="authInProgress"
          [logo]="'/assets/icon/BankID-Vector-Logo-White.svg'"
          (click)="handleCtaClick()"
        ></zaver-primary-button>
      </ng-container>
    </div>
  </div>
</div>

<div class="page-body" *ngIf="loadingPreFillInfoFromServer || navigating">
  <app-spinner></app-spinner>
</div>
