import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SelectAccountUrlDto } from './select-account-url.dto';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import {
  environment,
  SERVER_BASE_URL,
} from '../../../environments/environment';
import { PreventBackToSettlementOrAuthGuard } from '../../guard/prevent-back-to-settlement-or-auth-guard.service';
import { EnvIdentifier } from '../../../environments/environment-types';
import { Store } from '@ngxs/store';
import { routeNames } from '../../../assets/val/route-constants';
import {
  Currency,
  PaymentMethod,
  PaymentRequestDto,
  PaymentRequestStatus,
} from '../../model/payment-request/payment-request.dto';
import { QueryParamsService } from '../../service/query-params/query-params.service';
import { LoggingService } from 'src/app/service/logging-service/logging.service';
import { ErrorDialogComponent } from 'src/app/component/dialog/error/error-dialog.component';
import { AppDeepLinkService } from 'src/app/service/app-deeplink/app-deep-link.service';
import { MatDialog } from '@angular/material/dialog';
import { DepositStatus } from '@app/service/query-params/query-params.class';
import {
  CreditService,
  InstantDebitFailureReason,
  InstantDebitPrecheckResponse,
  InstantDebitStatus,
} from '@app/service/credit/credit.service';
import { DepositReqDto } from '@app/route/trustly-direct-debit/deposit-req.dto';
import { Subscription, timer } from 'rxjs';
import { filter, startWith, switchMap } from 'rxjs/operators';
import { SwishError } from '@app/service/swish/swish-response.dto';
import { CreditDecisionDialogComponent } from '@app/component/dialog/credit-decision/credit-decision-dialog.component';

@Component({
  selector: 'app-tdd',
  templateUrl: './trustly-direct-debit.component.html',
  styleUrls: ['./trustly-direct-debit.component.css', '../page-shared.css'],
})
export class TrustlyDirectDebitComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  static readonly LOCAL_STORAGE_KEY_IFRAME_URL = 'frameurl';

  public trustlyUrl: any;
  public useIframe: boolean;
  private paymentRequestId: string;
  private autoNavigationTimeout: any;

  private runningInDevEnv: boolean =
    environment.envIdentifier === EnvIdentifier.STAGING ||
    environment.envIdentifier === EnvIdentifier.LOCAL ||
    environment.envIdentifier === EnvIdentifier.TEST;
  amount: any;
  currency: Currency;
  title: any;

  private instantDebitPaymentId: string;
  private pollingSubscription: Subscription;

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private router: Router,
    private store: Store,
    private dialogService: MatDialog,
    private preventBackToSettlementOrAuthGuard: PreventBackToSettlementOrAuthGuard,
    private loggingService: LoggingService,
    private deepLinkService: AppDeepLinkService,
    private queryParamsService: QueryParamsService,
    private creditService: CreditService
  ) {}

  ngOnDestroy(): void {
    localStorage.removeItem(
      TrustlyDirectDebitComponent.LOCAL_STORAGE_KEY_IFRAME_URL
    );
  }

  ngOnInit() {
    this.paymentRequestId = this.store.snapshot().paymentRequest.request.id;
    this.amount = this.store.snapshot().paymentRequest.request.amount;
    this.currency = this.store.snapshot().paymentRequest.request.currency;
    this.useIframe = false; // this.store.snapshot().paymentRequest.request.market !== Market.DK; // TODO

    const longTitle = this.store.snapshot().paymentRequest.request.title;
    this.title =
      longTitle.length > 16 ? longTitle.substring(0, 15) + '...' : longTitle;

    const depositStatus =
      this.queryParamsService.getQueryParams().depositStatus;
    if (depositStatus === DepositStatus.PENDING) {
      this.instantDebitPaymentId =
        this.queryParamsService.getQueryParams().instantDebitId;
      // start polling
      this.handleDepositPendingSuccess();
    } else if (depositStatus === DepositStatus.FAIL) {
      // let user go back to select payment methods
      this.handleDepositFailure();
    } else {
      // no deposit created yet
      this.creditService
        .initInstantDebit()
        .toPromise()
        .then((res) => {
          console.log(JSON.stringify(res));
          this.instantDebitPaymentId = res.instantDebitPaymentId;
          this.queryParamsService.setQueryParam(
            'instantDebitId',
            this.instantDebitPaymentId
          );
          this.setupRedirectFlow();
        });
    }

    if (this.useIframe) {
      if (this.runningInDevEnv) {
        this.http
          .get<boolean>(`${environment.transferApiBaseUrl}/enabled`)
          .subscribe((mockingEnabled: boolean) => {
            if (mockingEnabled) {
              this.finalizeIFrameSetupMock();
            } else {
              this.finalizeIFrameSetup();
            }
          });
        return;
      }
      this.finalizeIFrameSetup();
    } else {
      // using Trustly redirect (no iframe)
    }
  }

  private setupRedirectFlow() {
    // probably null, depending on market settings...
    // const personalNumber = localStorage.getItem(PERSONAL_NUMBER);

    const params = `id=${this.paymentRequestId}&methodType=${PaymentMethod.INSTANT_DEBIT}&instantDebitId=${this.instantDebitPaymentId}`;

    // here we set successUrl & failUrl for the redirect flow (i.e. no iframe)
    // return to this route but with a status=PENDING parameter for "success" and status=FAIL otherwise
    const dto = new DepositReqDto(
      null,
      `${window.location.origin}/${routeNames.TRUSTLY_DIRECT_DEBIT}/?${params}&depositStatus=${DepositStatus.PENDING}`,
      `${window.location.origin}/${routeNames.TRUSTLY_DIRECT_DEBIT}/?${params}&depositStatus=${DepositStatus.FAIL}`,
      this.instantDebitPaymentId
    );

    const successHandler = (response: SelectAccountUrlDto) => {
      const depositUrl = response.selectAccountUrl;
      this.trustlyUrl =
        this.sanitizer.bypassSecurityTrustResourceUrl(depositUrl);
      window.location.href = depositUrl;
    };
    this.initiateAccountSelection(dto, successHandler);
  }

  finalizeIFrameSetup() {
    const personalNumber = localStorage.getItem('pnr');
    // const iframeUrl = localStorage.getItem(TrustlyDirectDebitComponent.LOCAL_STORAGE_KEY_IFRAME_URL);
    const iframeUrl = null;
    const params = `id=${this.paymentRequestId}&shouldOpenBankId=false&methodType=${PaymentMethod.INSTANT_DEBIT}`;

    if (iframeUrl) {
      this.trustlyUrl =
        this.sanitizer.bypassSecurityTrustResourceUrl(iframeUrl);
      return;
    }

    const SuccessHandler = (response: SelectAccountUrlDto) => {
      const depositUrl = `${response.selectAccountUrl}&NotifyParent=1`;
      this.trustlyUrl =
        this.sanitizer.bypassSecurityTrustResourceUrl(depositUrl);

      localStorage.setItem(
        TrustlyDirectDebitComponent.LOCAL_STORAGE_KEY_IFRAME_URL,
        depositUrl
      );
    };

    const dto = new DepositReqDto(
      personalNumber,
      `${window.location.origin}/${routeNames.TRUSTLY_DIRECT_DEBIT}/?${params}&depositStatus=${DepositStatus.PENDING}`,
      `${window.location.origin}/${routeNames.TRUSTLY_DIRECT_DEBIT}/?${params}&depositStatus=${DepositStatus.FAIL}`,
      this.instantDebitPaymentId
    );

    this.initiateAccountSelection(dto, SuccessHandler);
  }

  private initiateAccountSelection(
    dto: DepositReqDto,
    successHandler: (response: SelectAccountUrlDto) => void
  ) {
    this.http
      .post(
        `${SERVER_BASE_URL}/instantdebit/directDebit/account/selectNew`,
        dto,
        { withCredentials: true }
      )
      .subscribe(successHandler, (err): void => {
        if (err.error === 'invalid NIN') {
          this.handleInvalidNiN(dto);
        } else if (err.error === 'invalid buyer') {
          this.handleInvalidBuyer(dto);
        } else {
          this.router.navigate([routeNames.ERROR], {
            queryParams: { error: null },
            queryParamsHandling: 'merge',
          });
        }
      });
  }

  finalizeIFrameSetupMock() {
    this.http
      .post(
        `${environment.transferApiBaseUrl}/init`,
        {
          paymentRequestId: this.paymentRequestId,
          nin: localStorage.getItem('pnr'),
        },
        { withCredentials: true }
      )
      .subscribe((n: { sessionId: string }) => {
        this.trustlyUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          `${environment.transferFrameBaseUrl}/${n.sessionId}`
        );
      });
  }

  handleInvalidBuyer(dto: DepositReqDto) {
    const dialogConfiguration = {
      maxHeight: '50vh',
      maxWidth: '80vw',
      data: {
        message: 'Tekniskt fel',
        buttons: [
          {
            title: 'Pröva en annan betalmetod',
            callback: () => {
              this.router.navigate([routeNames.HOME], {
                queryParamsHandling: 'preserve',
              });
            },
          },
        ],
      },
    };
    this.dialogService.open(ErrorDialogComponent, dialogConfiguration);
    this.loggingService.reportCrash(
      'got invalid-buyer-response in trustly transfer',
      'request=' + JSON.stringify(dto)
    );
  }

  private handleInvalidNiN(dto: DepositReqDto) {
    localStorage.removeItem('pnr');
    const dialogConfiguration = {
      maxHeight: '50vh',
      maxWidth: '80vw',
      data: {
        heading: 'Personnumret var ogiltigt',
        buttons: [
          {
            title: 'Försök igen',
            callback: () => {
              this.router.navigate([routeNames.PERSONAL_NUMBER_ENTRY], {
                queryParamsHandling: 'preserve',
              });
            },
          },
        ],
      },
    };
    this.dialogService.open(ErrorDialogComponent, dialogConfiguration);
    this.loggingService.reportCrash(
      'got invalid-NIN-response in trustly transfer',
      'request=' + JSON.stringify(dto)
    );
  }

  ngAfterViewInit(): void {
    this.navToSuccessPageIfPaymentSucceeded();
    this.setAutoNavigationOnSuccessPolling();
  }

  setAutoNavigationOnSuccessPolling() {
    this.autoNavigationTimeout = window.setTimeout(() => {
      this.navToSuccessPageIfPaymentSucceeded();
    }, 3000);
  }

  // If we have already recieved callback from Trustly then we can go directly to the success-page.
  navToSuccessPageIfPaymentSucceeded() {
    this.http
      .get<PaymentRequestDto>(
        `${SERVER_BASE_URL}/request/public/${this.paymentRequestId}`
      )
      .subscribe((response) => {
        if (response.status === PaymentRequestStatus.SETTLED) {
          clearTimeout(this.autoNavigationTimeout);
          this.navigate(routeNames.SUCCESS);
        }
      });
  }

  // This is when the payer has successfully completed the deposit with Trustly (iframe or no-frame).
  // The payment request however will not be settled until we recieve callback from Trustly.
  handleDepositPendingSuccess() {
    this.startPolling();
  }

  private stopPolling() {
    if (this.pollingSubscription) {
      this.pollingSubscription.unsubscribe();
    }
  }

  handleLateError(errorResponse: HttpErrorResponse): void {
    this.stopPolling();
    let errors: SwishError[];
    errors = errorResponse.error.errors;
    if (errors && errors.some((err) => err.unrecoverable)) {
    } else {
      const dialogConfiguration = {
        maxHeight: '50vh',
        maxWidth: '80vw',
        data: {
          buttons: [
            {
              title: 'Välj en annan betalmetod',
              callback: () => {
                this.router.navigate([routeNames.HOME], {
                  queryParamsHandling: 'preserve',
                });
              },
            },
          ],
        },
      };
      this.dialogService.open(ErrorDialogComponent, dialogConfiguration);
    }
  }

  handleUpdate(res: InstantDebitPrecheckResponse): void {
    this.stopPolling();
    if (res.status === InstantDebitStatus.SUCCESS) {
      this.navigate(routeNames.TRUSTLY_DIRECT_DEBIT_EMAIL);
    } else if (res.status === InstantDebitStatus.FAILED) {
      switch (res.failureReason) {
        case InstantDebitFailureReason.CREDIT_PRECHECK_FAILED:
          this.handleCreditDecisionDenial();
          break;
        case InstantDebitFailureReason.MANDATE_DENIED:
          this.handleDepositFailure(); // I don't think this should be possible here;
          break;
      }
    }
  }

  startPolling() {
    this.pollingSubscription = timer(100, 1000)
      .pipe(
        startWith(0),
        switchMap(() =>
          this.creditService.instantDebitPrecheck(this.instantDebitPaymentId)
        ),
        filter(
          (res) =>
            res.status !== InstantDebitStatus.PENDING_ACCOUNT_NOTIFICATION &&
            res.status !== InstantDebitStatus.PENDING_PERSON_CREATION &&
            res.status !== InstantDebitStatus.PENDING_PRECHECK
        )
      )
      .subscribe(
        (res) => this.handleUpdate(res),
        (err) => this.handleLateError(err)
      );
  }

  navigate(navigationTarget: string) {
    this.router
      .navigate([navigationTarget], {
        queryParams: {
          shouldOpenBankId: 'false',
          depositStatus: null, // clear depositStatus
        },
        queryParamsHandling: 'merge',
      })
      .then(
        () => this.preventBackToSettlementOrAuthGuard.activate(),
        (rejection) => {
          this.loggingService.error(
            `Navigation from ${routeNames.BANK_TRANSFER_FRAME} to ${navigationTarget} was\
                rejected.`,
            rejection
          );
        }
      );
  }

  private handleDepositFailure() {
    const dialogConfiguration = {
      maxHeight: '50vh',
      maxWidth: '80vw',
      data: {
        buttons: [
          {
            title: 'Välj en annan betalmetod',
            callback: () => {
              this.router
                .navigate([routeNames.HOME], {
                  queryParams: {
                    depositStatus: null, // clear depositStatus
                  },
                  queryParamsHandling: 'merge',
                })
                .then(
                  () => this.preventBackToSettlementOrAuthGuard.activate(),
                  (rejection) => {
                    this.loggingService.error(
                      `Navigation from ${routeNames.BANK_TRANSFER_FRAME} to ${routeNames.HOME} was rejected.`,
                      rejection
                    );
                  }
                );
            },
          },
        ],
      },
    };
    this.dialogService.open(ErrorDialogComponent, dialogConfiguration);
  }

  private handleCreditDecisionDenial() {
    const dialogConfiguration = {
      maxHeight: '50vh',
      maxWidth: '80vw',
      data: {
        buttons: [
          {
            title: 'Välj en annan betalmetod',
            callback: () => {
              this.router
                .navigate([routeNames.HOME], {
                  queryParams: {
                    depositStatus: null, // clear depositStatus
                  },
                  queryParamsHandling: 'merge',
                })
                .then(
                  () => this.preventBackToSettlementOrAuthGuard.activate(),
                  (rejection) => {
                    this.loggingService.error(
                      `Navigation from ${routeNames.BANK_TRANSFER_FRAME} to ${routeNames.HOME} was rejected.`,
                      rejection
                    );
                  }
                );
            },
          },
        ],
      },
    };
    this.dialogService.open(CreditDecisionDialogComponent, dialogConfiguration);
  }

  reroute(): void {
    this.router.navigate([routeNames.HOME], {
      queryParamsHandling: 'merge',
    });
  }
}

/**
 * Sent by the Trustly iframe when we need to open the BankId app - note that this has a different
 * (dynamic) format than the other messages sent from the frame which are simple strings.
 */
class BankIdFrameMessage {
  method: string;
  appURL: string;
}
