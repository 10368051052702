<div class="page-body" *ngIf="loading === false && error === false">
  <div class="banner">
    <img
      src="assets/icon/Arrow-Left.svg"
      alt=""
      class="back-button"
      (click)="reroute()"
    />
    <span class="back-text" (click)="reroute()">Tillbaka</span>
  </div>

  <div class="contents-wrapper">
    <div class="contents-container">
      <div class="page-icon">
        <img src="/assets/icon/Icon-Invoice-Black.svg" alt="" />
      </div>

      <div class="page-text">Betala inom 14 dagar - 0 SEK</div>

      <div style="width: 100%; padding-bottom: 36px">
        <hr class="details-divider" />
        <div class="details-container">
          <div class="details-key-entry">
            {{ paymentRequest.title }}
          </div>

          <zaver-button-link
            [text]="'Visa detaljer'"
            (click)="openDetailsModal()"
          ></zaver-button-link>
        </div>

        <hr class="details-divider" />
        <div class="details-container">
          <div class="details-key-entry">Totalbelopp</div>

          <div class="details-value-entry">
            {{
              paymentRequest.amount.toLocaleString('sv', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })
            }}
            SEK
          </div>
        </div>

        <hr class="details-divider" />
      </div>

      <zaver-primary-button
        [text]="'Slutför köp'"
        [disabled]="navigationInProgress"
        (click)="handleCtaClick()"
        [logo]="'/assets/icon/BankID-Vector-Logo-White.svg'"
      ></zaver-primary-button>

      <div class="terms-row">
        <zaver-button-link [text]="'Visa villkor'" (click)="openInvoiceTerms()">
        </zaver-button-link>

        <zaver-loading-dots
          style="display: flex; justify-content: center"
          *ngIf="showTermsSpinner"
          [color]="'#b7bcc8'"
        ></zaver-loading-dots>

        <div class="void-to-center-spinner"></div>
      </div>

      <div class="fallback-offer" *ngIf="fallbackInfo">
        {{ fallbackInfo }}
      </div>
    </div>
  </div>
</div>

<zaver-checkout-modal
  id="details-modal"
  [active]="modalActive"
  [buttonText]="'Stäng'"
  [title]="'Visa detaljer'"
  (onClose)="deactivateModal()"
>
  <app-selector
    *ngIf="!!selectorItems"
    [selectorItems]="selectorItems"
    [selectedItem]="selectedItem"
  >
  </app-selector>
</zaver-checkout-modal>

<div class="page-body" *ngIf="loading === true">
  <div class="contents-wrapper">
    <div class="contents-container">
      <app-spinner></app-spinner>
    </div>
  </div>
</div>
