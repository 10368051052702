<div class="page-body" *ngIf="trustlyUrl">
  <div class="contents-wrapper">
    <div class="contents-container">
      <iframe *ngIf="useIframe" id="frame" scrolling="yes" [src]="trustlyUrl"></iframe>
      <div *ngIf="!useIframe">
        <app-spinner></app-spinner>
      </div>

      <div class="pr-info" *ngIf="amount && useIframe">
        <label>
          Totalbelopp
        </label>
        <label *ngIf="amount">
          {{amount.toLocaleString('sv', {minimumFractionDigits : 2, maximumFractionDigits : 2})}} {{currency}}
        </label>
      </div>
    </div>
  </div>

</div>

<div class="page-body" *ngIf="!trustlyUrl">
  <div class="contents-wrapper">
    <div class="contents-container">
      <app-spinner></app-spinner>
    </div>
  </div>
</div>
