import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StateService } from 'src/app/service/state/state.service';
import { RouteComponent } from '@app/route/route.component';
import { HeaderService } from '@app/service/header/header.service';
import { UserService } from '@app/service/user-service/user.service';
import { Market } from '@app/model/payment-request/payment-request.dto';
import {
  SelectorComponent,
  SelectorItem,
} from '@app/component/selector/selector.component';
import { ValuePipe } from '@app/shared/pipe/value.pipe';
import { DeLoanOptionService } from '@app/service/de-loan-option/de-loan-option.service';
import { LoanOptionVM } from '@app/route/shared/LoanOptionVM';

@Component({
  selector: 'app-de-installments-selection',
  templateUrl: './de-installments-selection.component.html',
  styleUrls: [
    '../../page-shared.css',
    './de-installments-selection.component.scss',
  ],
})
export class DeInstallmentsSelectionComponent
  extends RouteComponent
  implements OnInit
{
  availableloanOptions: LoanOptionVM[] = null;
  presentedLoanOptions: LoanOptionVM[] = null;
  selectedLoanOption: LoanOptionVM = null;
  expanded: boolean = false;
  equalOrLessThanThreeLoanOptions: boolean = false;
  navigationInProgress: boolean = false;
  currentLoanTermsStr: string = '';
  Market = Market;
  selectorItems: SelectorItem[] = null;
  modalActive: boolean = false;
  loading: boolean = false;

  preferredInstallmentPlanDurations: number[] = null; // [6, 12, 48];
  @ViewChild('selector') modalSelector: SelectorComponent;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected stateService: StateService,
    protected headerService: HeaderService,
    private userService: UserService,
    private valuePipe: ValuePipe,
    private loanOptionService: DeLoanOptionService
  ) {
    super(router, route, stateService, headerService);
  }

  ngOnInit(): void {
    this.loading = true;
    this.availableloanOptions = this.loanOptionService.transformDtoTOVM(
      this.state.loanOptions.sort((a, b) => a.monthlyCost - b.monthlyCost),
      this.toggleModal.bind(this)
    );

    this.equalOrLessThanThreeLoanOptions =
      this.availableloanOptions.length <= 3;

    this.getPresentedLoanOptions();
    this.selectInstallment(this.presentedLoanOptions[0]);
    this.loading = false;
  }

  async handleCtaClick(): Promise<void> {
    this.navigationInProgress = true;
    try {
      await this.userService.selectInstallmentPlan(
        this.selectedLoanOption.termId
      );
      await this.navigate();
    } catch (error) {
      this.handleError(error);
    }
    this.navigationInProgress = false;
  }

  selectInstallment(loanOption: LoanOptionVM): void {
    this.selectedLoanOption = loanOption;
    this.currentLoanTermsStr = this.loanOptionService.buildLoantermsString(
      this.selectedLoanOption,
      this.state.totalCheckoutValue
    );

    this.selectorItems = [
      {
        tab: 'Ihr Kauf',
        title: 'Zusammenfassung',
        lineItems: this.state.paymentInformation?.lineItems,
        totalValue: `Gesamtbetrag inkl. MwSt: ${this.valuePipe.transform(
          this.state.totalCheckoutValue
        )}`,
      },
      {
        tab: 'Zahlungsbedingungen',
        title: 'Bedingungen für den Kauf auf Raten',
        descriptions: [{ text: this.currentLoanTermsStr }],
        ...(!!loanOption.loanTermsURL && {
          links: [
            {
              text: 'Bedingungen für Teilzahlungen',
              onClick: () => this.openURLinNewWindow(loanOption.loanTermsURL),
            },
            {
              text: this.state.checkoutCustomizations.merchantName + ' AGB',
              onClick: () =>
                this.openURLinNewWindow(
                  this.state.checkoutCustomizations.merchantTermsUrl
                ),
            },
            {
              ...(this.state.market === Market.AT && {
                text: 'Europäische Standardinformationen für Kreditierungen nach dem Verbraucherkreditgesetz',
                onClick: () => this.openURLinNewWindow(loanOption.secciURL),
              }),
            },
          ],
        }),
      },
    ];
  }

  openURLinNewWindow(url: string): void {
    window.open(url, '_blank');
  }

  getPresentedLoanOptions(): void {
    if (this.equalOrLessThanThreeLoanOptions) {
      this.presentedLoanOptions = this.availableloanOptions;
      return;
    }

    const middleMostIndex: number = Math.floor(
      this.availableloanOptions.length / 2
    );

    const longest: LoanOptionVM = this.availableloanOptions[0];
    const middlemost: LoanOptionVM = this.availableloanOptions[middleMostIndex];
    const shortest: LoanOptionVM =
      this.availableloanOptions[this.availableloanOptions.length - 1];

    this.presentedLoanOptions = [longest, middlemost, shortest];

    if (this.preferredInstallmentPlanDurations?.length > 0) {
      this.presentedLoanOptions = this.availableloanOptions.filter(
        (loanOption: LoanOptionVM) =>
          this.preferredInstallmentPlanDurations.includes(
            loanOption.numberOfMonths
          )
      );
    }

    // if we have selected installment, replace closest
    if (this.selectedLoanOption) {
      const closestLoanOption: LoanOptionVM = this.getClosestLoanOption(
        this.presentedLoanOptions,
        this.selectedLoanOption.monthlyCost
      );

      // replace closest installment with selected loan option
      this.presentedLoanOptions = this.presentedLoanOptions.map(
        (loanOption: LoanOptionVM) =>
          loanOption.monthlyCost === closestLoanOption.monthlyCost
            ? this.selectedLoanOption
            : loanOption
      );
    }
  }

  toggleModal(): void {
    this.modalSelector.selectItemByTab('Zahlungsbedingungen');
    this.modalActive = !this.modalActive;
  }

  viewAllLoanOptions(): void {
    this.expanded = true;
    this.presentedLoanOptions = this.availableloanOptions;
  }

  viewReducedLoanOptions(): void {
    this.expanded = false;
    this.getPresentedLoanOptions();
  }

  getClosestLoanOption(
    loanOption: LoanOptionVM[],
    selectedLoanOptionMonthlyCost: number
  ): LoanOptionVM {
    const monthlyCostDiff = (monthlyCost: number) =>
      Math.abs(monthlyCost - selectedLoanOptionMonthlyCost);
    return loanOption.reduce((prev: LoanOptionVM, curr: LoanOptionVM) =>
      monthlyCostDiff(curr.monthlyCost) < monthlyCostDiff(prev.monthlyCost)
        ? curr
        : prev
    );
  }
}
