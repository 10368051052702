import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import {
  Currency,
  PaymentMethod,
  PaymentRequestDto,
} from '../../model/payment-request/payment-request.dto';
import { QueryParamsService } from '../../service/query-params/query-params.service';
import { CreditService } from '../../service/credit/credit.service';
import { LoggingService } from '@app/service/logging-service/logging.service';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.css', '../page-shared.css'],
})
export class SuccessComponent implements OnInit {
  private paymentRequest: PaymentRequestDto;
  amount: number;
  currency: Currency;

  paymentMethod: string;
  settlementMessage: string;
  title: string;

  public readonly APP_STORE_LINK =
    'https://apps.apple.com/se/app/zaver-handla-på-dina-villkor/id1560858500';
  public readonly PLAY_STORE_LINK =
    'https://play.google.com/store/apps/details?id=io.zaver.app';

  constructor(
    private store: Store,
    private creditService: CreditService,
    private queryParamsService: QueryParamsService,
    private loggingService: LoggingService
  ) {}

  ngOnInit() {
    const paymentRequestId: string =
      this.queryParamsService.getQueryParams().id;
    this.loggingService.warn('loaded success page!');
    this.creditService
      .getPaymentRequestPublicInfo(paymentRequestId)
      .subscribe((res) => {
        this.paymentRequest = res;
        this.setupPaymentMethod(res.settlementMethod);
        this.amount = this.paymentRequest.amount;
        this.currency = this.paymentRequest.currency;
      });
  }

  public openTab(url: string): void {
    window.open(url);
  }

  setupPaymentMethod(type: PaymentMethod) {
    switch (type) {
      case PaymentMethod.TRUSTLY:
        this.paymentMethod = 'Banköverföring';
        this.title = 'Tack för din betalning!';
        this.settlementMessage =
          'Pengarna har dragits från ditt valda bankkonto.';
        break;
      case PaymentMethod.INVOICE:
        this.paymentMethod = 'Faktura';
        this.title = 'Tack för att du betalar med Zaver.';
        this.settlementMessage =
          'Fakturan dyker vanligtvis upp i Zaver-appen inom ca 1-2 dagar, ' +
          'och skickas även till din angivna mejladress.';
        break;
      case PaymentMethod.CREDIT:
        this.paymentMethod = 'Delbetalning';
        this.title = 'Tack för att du betalar med Zaver.';
        this.settlementMessage =
          'Innan det är dags att betala kommer dina fakturor dyka upp ' +
          'i Zaver-appen och skickas till din angivna mejladress.';
        break;
      case PaymentMethod.SWISH_EXTERNAL:
        this.paymentMethod = 'Swish';
        this.title = 'Tack för din betalning!';
        this.settlementMessage =
          'Ladda ned Zaver-appen och få full överblick över dina transaktioner.';
        break;
      case PaymentMethod.VIPPS_EXTERNAL:
        this.paymentMethod = 'Vipps';
        this.title = 'Takk for betalingen';
        this.settlementMessage =
          'Last ned Zaver-appen og få full oversikt over transaksjonene dine.'; //TODO(Markus): Hack!! Find why mobile goes to swedish success page..
        break;
      case PaymentMethod.INSTANT_DEBIT:
        this.paymentMethod = 'Direktbetalning';
        this.title = 'Tack för din betalning!';
        this.settlementMessage =
          'Pengarna dras från det valda kontot inom 1-3 bankdagar.';
        break;
    }
  }
}
