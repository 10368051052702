import { ErrorStateMatcher } from '@angular/material/core';
import {FormControl, FormGroupDirective, NgForm} from '@angular/forms';

/**
 * Matcher that allows an erroneous state for Angular Material based form
 * fields to be displayed when a form has been submitted rather than as soon
 * as the field is touched (which is the default behaviour).
 */
export class CustomErrorMatcher implements ErrorStateMatcher {

  private submitted = false;


  constructor(submitted: boolean) {
    this.submitted = submitted;
  }

  public setSubmitted(submitted: boolean) {
    this.submitted = submitted;
  }

  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return (control != null) && this.submitted && control.invalid;
  }

}
