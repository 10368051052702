<app-de-wrapper *ngIf="internalServerError">
  <div style="display: flex; flex-direction: column; align-items: center">
    <img class="general-error-icon" src="assets/icon/smiley-sad-nerd.svg" />
    <h3 class="general-error-text">Etwas ist schief gelaufen.</h3>
  </div>
</app-de-wrapper>

<app-de-thin-wrapper *ngIf="!loading && !internalServerError">
  <h1 class="heading">Kundeninformationen</h1>

  <div
    class="preview-wrapper"
    [ngClass]="{
      'no-input-displayed': noInputDisplayed
    }"
  >
    <div
      class="link-wrapper"
      *ngIf="
        !editPreviewData && state.zaverProduct == 'ECOM_CHECKOUT'
          ? true
          : infoIsEditable && !displayInput(userRegistrationVM.birthDate)
      "
    >
      <zaver-button-link
        *ngIf="!editPreviewData"
        text="Ändern"
        (click)="infoIsEditable ? editData() : setModalActive(true)"
      ></zaver-button-link>
    </div>
    <div class="customer-name">
      <span *ngIf="!displayInput(userRegistrationVM.firstName)">
        {{ userRegistrationVM.firstName.value }}
      </span>
      <span *ngIf="!displayInput(userRegistrationVM.familyName)">
        {{ userRegistrationVM.familyName.value }}
      </span>
    </div>
    <div
      [class.not-editable]="editPreviewData"
      *ngIf="!displayInput(userRegistrationVM.birthDate)"
    >
      Geburtsdatum: {{ userRegistrationVM.birthDate.value }}
    </div>
    <div>
      <span *ngIf="!displayInput(userRegistrationVM.streetName)">
        {{ userRegistrationVM.streetName.value }}
      </span>
      <span *ngIf="!displayInput(userRegistrationVM.houseNumber)">
        {{ userRegistrationVM.houseNumber.value }}
      </span>
    </div>
    <div>
      <span *ngIf="!displayInput(userRegistrationVM.zipCode)">
        {{ userRegistrationVM.zipCode.value }}
      </span>
      <span *ngIf="!displayInput(userRegistrationVM.cityName)">
        {{ userRegistrationVM.cityName.value }}
      </span>
    </div>
    <div *ngIf="!displayInput(userRegistrationVM.countryCode)">
      <span *ngIf="userRegistrationVM.countryCode.value === 'AT'"
        >&Ouml;sterreich</span
      >
      <span *ngIf="userRegistrationVM.countryCode.value === 'DE'"
        >Deutschland</span
      >
    </div>
    <div
      *ngIf="!displayInput(userRegistrationVM.zipCode)"
      style="margin-top: 12px"
    ></div>
    <div
      [class.not-editable]="!userRegistrationVM.email.editable"
      class="not-editable"
      *ngIf="!displayInput(userRegistrationVM.email)"
    >
      {{ userRegistrationVM.email.value }}
    </div>
    <div class="not-editable">
      {{ userRegistrationVM.phoneNumber.value }}
    </div>
  </div>

  <form [formGroup]="form" id="user-registration-form">
    <div class="inputs-row" *ngIf="displayInput(userRegistrationVM.email)">
      <div
        class="input-container"
        [class.child-has-error]="form.controls['email'].invalid && showError"
      >
        <zaver-animated-form-input
          inputRef
          [size]="inputSize"
          [placeholder]="'E-Mail Adresse'"
          [formControlName]="'email'"
          [error]="form.controls['email'].invalid && showError"
          [errorMessage]="
            inputSize === 'large'
              ? 'Bitte geben Sie eine gültige E-Mail Adresse ein'
              : ''
          "
          [inputMode]="'email'"
          [autoComplete]="'email'"
          [inputType]="'email'"
          [errorIcon]="'assets/icon/icon_error.svg'"
        ></zaver-animated-form-input>
      </div>
    </div>

    <div class="inputs-row">
      <div
        class="input-container"
        *ngIf="displayInput(userRegistrationVM.firstName)"
        [class.child-has-error]="
          form.controls['firstName'].invalid && showError
        "
      >
        <zaver-animated-form-input
          inputRef
          [size]="inputSize"
          [formControlName]="'firstName'"
          [error]="form.controls['firstName'].invalid && showError"
          [placeholder]="'Vorname'"
          [errorMessage]="
            inputSize === 'large' ? 'Bitte geben Sie Ihren Vornamen ein' : ''
          "
          [inputMode]="'text'"
          [autoComplete]="'given-name'"
          [errorIcon]="'assets/icon/icon_error.svg'"
        ></zaver-animated-form-input>
      </div>

      <div
        class="input-container"
        *ngIf="displayInput(userRegistrationVM.familyName)"
        [class.child-has-error]="
          form.controls['familyName'].invalid && showError
        "
      >
        <zaver-animated-form-input
          inputRef
          [size]="inputSize"
          [formControlName]="'familyName'"
          [error]="form.controls['familyName'].invalid && showError"
          [placeholder]="'Nachname'"
          [errorMessage]="
            inputSize === 'large' ? 'Bitte geben Sie Ihren Nachnamen ein' : ''
          "
          [inputMode]="'text'"
          [autoComplete]="'family-name'"
          [errorIcon]="'assets/icon/icon_error.svg'"
        ></zaver-animated-form-input>
      </div>
    </div>

    <div class="inputs-row">
      <div
        class="input-container"
        *ngIf="displayInput(userRegistrationVM.birthDate)"
        [class.child-has-error]="
          form.controls['birthDate'].invalid && showError
        "
      >
        <zaver-animated-form-input
          [size]="inputSize"
          [formControlName]="'birthDate'"
          [error]="form.controls['birthDate'].invalid && showError"
          [placeholder]="'Geburtsdatum (TT.MM.JJJJ)'"
          [errorMessage]="
            inputSize === 'large'
              ? 'Bitte geben Sie ein gültiges Geburtsdatum ein'
              : ''
          "
          [maxLength]="10"
          [inputMode]="'numeric'"
          [autoComplete]="'bday'"
          [errorIcon]="'assets/icon/icon_error.svg'"
        ></zaver-animated-form-input>
      </div>
    </div>

    <div class="inputs-row">
      <div
        *ngIf="displayInput(userRegistrationVM.streetName)"
        class="input-container"
        [class.child-has-error]="
          form.controls['streetName'].invalid && showError
        "
      >
        <zaver-animated-form-input
          inputRef
          [size]="inputSize"
          [formControlName]="'streetName'"
          [error]="form.controls['streetName'].invalid && showError"
          [placeholder]="'Straße'"
          [errorMessage]="
            inputSize === 'large' ? 'Bitte geben Sie Ihre Straße ein' : ''
          "
          [inputMode]="'text'"
          [autoComplete]="'address-line1'"
          [errorIcon]="'assets/icon/icon_error.svg'"
        ></zaver-animated-form-input>
      </div>

      <div
        class="input-container small-input"
        *ngIf="displayInput(userRegistrationVM.houseNumber)"
        [class.child-has-error]="
          form.controls['houseNumber'].invalid && showError
        "
      >
        <zaver-animated-form-input
          inputRef
          [size]="inputSize"
          [autoComplete]="'address-level4'"
          [formControlName]="'houseNumber'"
          [error]="form.controls['houseNumber'].invalid && showError"
          [placeholder]="'Haus Nr.'"
          [errorMessage]="
            inputSize === 'large' ? 'Bitte geben Sie Ihre Hausnummer ein' : ''
          "
          [inputMode]="'text'"
          [errorIcon]="'assets/icon/icon_error.svg'"
        ></zaver-animated-form-input>
      </div>
    </div>

    <div class="inputs-row">
      <div
        class="input-container small-input"
        *ngIf="displayInput(userRegistrationVM.zipCode)"
        [class.child-has-error]="form.controls['zipCode'].invalid && showError"
      >
        <zaver-animated-form-input
          inputRef
          [size]="inputSize"
          [formControlName]="'zipCode'"
          [error]="form.controls['zipCode'].invalid && showError"
          [placeholder]="'PLZ'"
          [errorMessage]="
            inputSize === 'large' ? 'Bitte geben Sie Ihre Postleitzahl ein' : ''
          "
          [inputMode]="'numeric'"
          [autoComplete]="'postal-code'"
          [errorIcon]="'assets/icon/icon_error.svg'"
        ></zaver-animated-form-input>
      </div>
      <div
        class="input-container"
        *ngIf="displayInput(userRegistrationVM.cityName)"
        [class.child-has-error]="form.controls['cityName'].invalid && showError"
      >
        <zaver-animated-form-input
          inputRef
          [size]="inputSize"
          [formControlName]="'cityName'"
          [error]="form.controls['cityName'].invalid && showError"
          [placeholder]="'Ort'"
          [errorMessage]="inputSize === 'large' ? 'Bitte Ort eingeben' : ''"
          [inputMode]="'text'"
          [autoComplete]="'address-level2'"
          [errorIcon]="'/assets/icon/icon_error.svg'"
        ></zaver-animated-form-input>
      </div>
    </div>

    <div class="inputs-row">
      <div
        class="input-container"
        *ngIf="displayInput(userRegistrationVM.countryCode)"
      >
        <zaver-dropdownv2
          id="dropdown"
          [size]="inputSize"
          [selectableItems]="countries"
          [selectedItem]="selectedCountry"
          (inputModelChange)="setSelectedCountry($event)"
          [error]="form.controls['countryCode'].invalid && showError"
          [errorMessage]="inputSize === 'large' ? 'Bitte wählen Sie Land' : ''"
          [helpText]="'Land'"
        ></zaver-dropdownv2>
      </div>
    </div>

    <div
      class="select-error"
      *ngIf="
        (inputSize === 'medium' || !editPreviewData) &&
        form.invalid &&
        showError &&
        !invalidDataInPreview
      "
      style="display: flex; align-items: center; margin-bottom: 16px"
    >
      <img
        class="icon-checkmark"
        src="assets/icon/icon_error.svg"
        style="height: 16px; margin-right: 8px"
      />
      <span>Bitte überprüfen Sie Ihre Eingaben</span>
    </div>
  </form>

  <app-general-error
    *ngIf="invalidDataInPreview"
    style="margin-bottom: 16px"
    [text]="
      'Der eingegebene Datensatz (' +
      invalidForms +
      ')' +
      ' scheint nicht korrekt zu sein. Bitte gehen Sie zurück zum Warenkorb und korrigieren diesen.'
    "
  ></app-general-error>

  <app-general-error
    style="margin-bottom: 16px"
    *ngIf="form.valid && noResponseError"
  ></app-general-error>

  <div class="footer-wrapper">
    <div class="footer-container">
      <div class="total-amount" *ngIf="state.totalCheckoutValue">
        <p>Gesamtbetrag:</p>
        <p>{{ state.totalCheckoutValue | value }}</p>
      </div>

      <div class="button-container" *ngIf="!editPreviewData">
        <zaver-primary-button
          [buttonType]="'submit'"
          [formId]="'user-registration-form'"
          [text]="
            state.zaverProduct !== 'PRECHECK' &&
            state.paymentInformation.paymentMethod === 'INVOICE'
              ? 'Kauf abschließen'
              : 'Weiter'
          "
          [disabled]="navigationInProgress"
          (click)="handleCtaClick()"
        ></zaver-primary-button>
      </div>

      <ng-container *ngIf="editPreviewData">
        <div class="button-container">
          <zaver-primary-button
            [buttonType]="'submit'"
            [formId]="'user-registration-form'"
            [text]="'Änderungen speichern'"
            [disabled]="navigationInProgress"
            (click)="saveFormData()"
          ></zaver-primary-button>
        </div>

        <div class="secondary-button-container">
          <zaver-secondary-button-outlined
            [disabled]="navigationInProgress"
            (click)="abortEditInfo()"
            [text]="'Abbrechen'"
          ></zaver-secondary-button-outlined>
        </div>
      </ng-container>

      <p
        *ngIf="
          state.zaverProduct !== 'PRECHECK' &&
          state.paymentInformation.paymentMethod === 'INVOICE'
        "
        class="agb-info"
      >
        Indem Sie Kauf abschließen klicken,<br />
        stimmen Sie den
        <a
          [href]="state?.checkoutCustomizations?.merchantTermsUrl"
          target="_blank"
          >AGB</a
        >
        von {{ state.checkoutCustomizations.merchantName }} zu.
      </p>

      <app-de-footer
        *ngIf="state.currentPageInformation.homePage"
        [selectorItems]="selectorItems"
      ></app-de-footer>
    </div>
  </div>
</app-de-thin-wrapper>

<app-de-thin-wrapper *ngIf="loading">
  <app-spinner></app-spinner>
</app-de-thin-wrapper>

<div class="modal-small-wrapper">
  <zaver-details-modal
    [active]="modalActive"
    (onClose)="setModalActive(false)"
    [color]="'#EF3466'"
    [title]="'Date ändern'"
    [titleColor]="'#ffffff'"
    id="user-registration-modal"
  >
    <div class="small-modal-wrapper">
      <div class="modal-text">
        Sind Sie sicher, dass Sie zu Ihrem Warenkorb zurückgehen wollen um die
        eingegebenen Daten zu ändern?
      </div>
      <div class="modal-button-container">
        <div class="abort-button-wrapper">
          <zaver-secondary-button-subtle
            style="width: auto"
            [text]="'Nein, abbrechen'"
            (click)="setModalActive(false)"
          ></zaver-secondary-button-subtle>
        </div>

        <zaver-primary-button
          [disabled]="navigationInProgress"
          style="width: auto"
          [text]="'Ja, ändern'"
          (click)="routeToCancelUrl()"
        ></zaver-primary-button>
      </div>
    </div>
  </zaver-details-modal>
</div>
