import { Component, OnInit, OnDestroy } from '@angular/core';
import { PaymentRequestDto } from '../../../model/payment-request/payment-request.dto';
import {
  IVippsError,
  getErrorMessage,
  VippsResponse,
  VippsStatus,
  VippsRequestParams,
} from 'src/app/service/vipps/vipps-response.dto';
import { ActivatedRoute, Router } from '@angular/router';
import {
  DeviceOS,
  UserAgentService,
} from 'src/app/service/user-agent/user-agent.service';
import { QueryParamsService } from 'src/app/service/query-params/query-params.service';
import {
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  CountryCodes,
  formatPhoneNumber,
  phoneNumberValidator,
} from 'web-component-library/projects/component-library/src/public-api';
import { RouteComponent } from '../../route.component';
import { HeaderService } from '@app/service/header/header.service';
import {
  CheckoutState,
  StateControlParameters,
  StateService,
} from '@app/service/state/state.service';
import { startWith, switchMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from '@app/component/dialog/error/error-dialog.component';
import { VippsService } from '@app/service/vipps/vipps.service';
import { Subscription, timer } from 'rxjs';
import { LoggingService } from '@app/service/logging-service/logging.service';

@Component({
  selector: 'no-vipps',
  templateUrl: './no-vipps.component.html',
  styleUrls: ['../../page-shared.css', './no-vipps.component.scss'],
})
export class VippsComponent
  extends RouteComponent
  implements OnInit, OnDestroy
{
  resumePolling(vippsId: string) {
    if (!!vippsId) {
      try {
        this.startPolling();
      } catch (err) {
        this.handleError(err);
        console.warn('resuming polling got error', err);
      }
    }
  }

  phoneNumber: string;
  isPhone: boolean;
  error: IVippsError[] = null;
  modalActive: boolean = false;
  resumeToken: string;
  form: FormGroup;
  paymentRequest: PaymentRequestDto;
  loading: boolean = true;
  paymentInProgress: boolean;
  showError: boolean = false;
  validators: ValidatorFn[];
  activityTimer: any = null;
  formLayout: boolean = false;
  manualDeeplinkEnabled: boolean = false;
  mobileStatusMessage: string = 'Forbereder betaling...';
  paymentStatusOtherDevice: string;
  activeDepositId: string;
  vippsLandingRedirectUrl: string = null;
  firstTimeCaptured: boolean = true;
  returnedFromVipps: boolean = false;
  vippsPaymentStatus: VippsStatus = VippsStatus.VOID;
  pollingSubscription: Subscription;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected stateService: StateService,
    protected headerService: HeaderService,
    private userAgentService: UserAgentService,
    private queryParamsService: QueryParamsService,
    private dialogService: MatDialog,
    private formBuilder: FormBuilder,
    private loggingService: LoggingService,
    private vippsService: VippsService
  ) {
    super(router, route, stateService, headerService);
  }

  async ngOnInit() {
    this.validators = [
      Validators.required,
      phoneNumberValidator(CountryCodes.ALL),
    ];

    this.paymentRequest = await this.vippsService.getPaymentRequest();
    this.phoneNumber = this.paymentRequest.phoneNumber || '';

    if (this.userAgentService.isMobile()) {
      this.isPhone = true;
    }

    // Vipps payment is navigated here in one of two ways:
    // a) First time visiting the vipps page in the checkout flow
    //    Create a "resumable state token" so we can resume checkout-flow state when redirected to vipps (from vipps app and fallback URL)
    if (!this.queryParamsService.getQueryParams().vippsId) {
      await this.fetchState();
      this.resumeToken = await this.createResumeToken();
      if (this.resumeToken === null) {
        this.loggingService.warn(
          'vipps frontend: vippsId found as URL param, but no resume checkoutState token could be found'
        );
      }
    }

    // b) Already been in the checkout-flow and is redirected back from vipps
    if (this.queryParamsService.getQueryParams().vippsId) {
      if (!!this.queryParamsService.getClientSideParams()) {
        // Recover the state from "resumable state token"
        this.resumeToken = this.queryParamsService.getClientSideParams();
        await this.resumeState(this.resumeToken);
      }

      this.activeDepositId = this.queryParamsService.getQueryParams().vippsId;
      this.resumePolling(this.activeDepositId);
      this.mobileStatusMessage = 'Bekrefter betaling...';
      this.loading = false;

      // If paying on mobile and returning back to the checkout
      if (this.queryParamsService.getQueryParams().poppedVipps) {
        this.mobileStatusMessage = 'Fullfør betaling...';
        this.returnedFromVipps = true;
        this.manualDeeplinkEnabled = true;
      } else {
        if (!this.activityTimer) {
          this.setActivityTimer();
        }
      }
    } else if (this.isPhone) {
      this.loading = false;
      this.setActivityTimer();
      this.initPaymentMobile();
    } else {
      this.formLayout = true;
      this.setupForm();
      this.loading = false;
    }
  }

  async initPayment({ isMobile }: { isMobile: boolean }) {
    this.paymentInProgress = true;
    let resp: VippsResponse;

    try {
      if (!isMobile) {
        this.error = null;
      }

      let vippsRequestParams: VippsRequestParams = {
        fallbackUrl: window.location.href,
        isMobile: isMobile,
        phoneNumber: this.phoneNumber,
        resumeTokenId: this.resumeToken,
      };

      try {
        resp = await this.vippsService.initPayment(vippsRequestParams);
      } catch (err) {
        this.loggingService.warn(
          'vipps frontend: calling vipps api failed',
          err
        );
        this.handleError(err);
      }

      if (!this.handleVippsErrors(resp)) {
        // Start polling for vipps payment completion
        this.vippsLandingRedirectUrl = resp.redirectUrl;
        this.activeDepositId = resp.id;

        // When completing a vipps deposit om mobile, we can not garantee
        // that we will be redirected back to the same broweser tab or browser
        // as the one a payment request was started from -
        // because vipps adds their own query params to the fallback URL we give them!!
        // ...
        // We may, therefore, have multiple browsers or tabs open attempting to get a payment's status
        // By adding there query parameters, we will be able to still poll for the the payment's status.
        // If a vipps deposit was successful, then we will be redirected to the success page
        // in both browsers or tabs without issue.
        if (isMobile) {
          this.queryParamsService.setQueryParam('vippsId', resp.id);
          this.queryParamsService.setClientSideParam(this.resumeToken);
        }

        this.startPolling();

        // Open vipps landing page and or app
        if (!resp.checkoutSuccess) {
          if (isMobile) {
            this.clearActivityTimer();
            this.stopPolling();
            this.loading = false;
            this.mobileStatusMessage =
              'Trykk på knappen under for å åpne Vipps';
            this.openVippsMobile();
          } else {
            this.paymentStatusOtherDevice = 'Åpne Vipps-appen på mobilen';
            this.stopPolling();
            this.openVippsDesktop();
          }
        }
      }
    } catch (err) {
      this.loggingService.warn(
        'vipps frontend: got error trying to open vipps',
        err
      );
      this.paymentInProgress = false;
      this.handleFatalError(err);
    }
  }

  async initPaymentMobile() {
    await this.initPayment({ isMobile: true });
  }

  async initPaymentDesktop(): Promise<void> {
    this.updateErrorMessages();

    if (this.form.invalid) {
      return;
    }

    this.phoneNumber = formatPhoneNumber(this.form.value.phone.trim());
    this.paymentInProgress = true;
    this.paymentStatusOtherDevice = 'Forbereder betaling...';

    await this.initPayment({ isMobile: false });
  }

  async openVippsMobile() {
    return this.vippsService.openVippsMobile(this.vippsLandingRedirectUrl);
  }

  async openVippsDesktop() {
    return this.vippsService.openVippsDesktop(this.vippsLandingRedirectUrl);
  }

  // Happens on mobile after time delay
  setActivityTimer() {
    if (!this.activityTimer) {
      this.activityTimer = setTimeout(() => {
        this.manualDeeplinkEnabled = true;
        this.clearActivityTimer();
      }, 5_000);
    }
  }

  clearActivityTimer() {
    clearTimeout(this.activityTimer);
    this.activityTimer = null;
  }

  setupForm(): void {
    this.formLayout = true;
    this.loading = false;

    this.form = this.formBuilder.group({
      phone: [
        String(this.phoneNumber) || '',
        [Validators.required, phoneNumberValidator([47])],
      ],
    });
  }

  updateErrorMessages() {
    this.showError = !this.showError;
  }

  async ngOnDestroy() {
    await this.cancelPayment(this.activeDepositId);
    this.stopPolling();
    this.loggingService.warn(
      'vipps frontend: 99: current url',
      window.location.href
    );
  }

  async forceNavigateHome() {
    await this.cancelPayment(this.activeDepositId);
    this.stopPolling();
    this.vippsService.clearAllQueryParameters();
    this.error = null;
    this.paymentInProgress = false;
    this.reset();
  }

  async cancelPayment(vippsId: string) {
    // vipps payments can only be cancelled in a specific state
    if (
      this.vippsPaymentStatus === VippsStatus.INITIATE ||
      this.vippsPaymentStatus === VippsStatus.RESERVE
    ) {
      try {
        return await this.vippsService.cancelCurrentVippsPayment(vippsId);
      } catch (err) {
        this.loggingService.warn(
          'vipps frontend: cancel payment failed with error',
          err
        );
      }
    }
  }

  restartOnOtherDevice(): void {
    this.vippsService.cancelCurrentVippsPayment(this.activeDepositId);
    this.error = null;
    this.paymentStatusOtherDevice = null;
    this.paymentInProgress = false;
    this.setupForm();
    this.formLayout = true;
  }

  async restartPaymentMobile() {
    this.paymentStatusOtherDevice = null;
    this.error = null;
    this.formLayout = false;
    this.mobileStatusMessage = 'Forbereder betaling...';
    this.paymentInProgress = true;
  }

  startPolling() {
    this.pollingSubscription = timer(2_000)
      .pipe(
        startWith(0),
        switchMap(() => this.vippsService.getStatus())
      )
      .subscribe(
        (res) => this.handleUpdate(res),
        (err) => this.handleLateError(err)
      );
  }

  public isPolling(): boolean {
    return (
      this.pollingSubscription !== undefined && !this.pollingSubscription.closed
    );
  }

  public stopPolling() {
    if (this.pollingSubscription) {
      this.pollingSubscription.unsubscribe();
    }

    this.activeDepositId = null;
    this.manualDeeplinkEnabled = true;
  }

  async handleUpdate(res: VippsResponse) {
    this.vippsPaymentStatus = res.status;

    // A payment can give response as captured multiple times if open in multiple tabs.
    // On mobile, this is the default for safari on redirected back from the app
    if (res.checkoutSuccess && this.firstTimeCaptured) {
      this.firstTimeCaptured = !this.firstTimeCaptured;

      try {
        await this.fetchState();
        await this.navigateFromState();
      } catch (err) {
        this.handleError(err);
      }
    } else if (res.status === VippsStatus.ERROR) {
      this.loggingService.warn(
        'vipps frontend: something went wrong from the backend when handling the polling and vipps deposit'
      );
      this.vippsService.clearAllQueryParameters();
      this.stopPolling();
      this.handleFatalError(res);
    } else if (res.status === VippsStatus.CANCEL) {
      this.vippsService.clearAllQueryParameters();
      this.stopPolling();
      this.handleVippsCancelled(res);
    }
  }

  async restartFormBasedPayment() {
    this.paymentStatusOtherDevice = null;
    this.error = null;
    this.formLayout = true;

    try {
      await this.initPaymentDesktop();
    } catch (err) {
      this.handleFatalError(err);
    }
  }

  getVippsErrorText(error: IVippsError): string {
    return getErrorMessage(error);
  }

  getErrorMessages(): string {
    return this.getVippsErrorText(this.error[0]);
  }

  handleFatalError(err?: any): void {
    this.modalActive = true;
    this.stopPolling();

    const errorMessage = 'Vennligst prøv igjen eller kontakt kundestøtte.';
    if (!!err) {
      this.loggingService.warn('vipps frontend: 94: error', err);
      console.error('fatal vipps error', err);
    }

    const dialogConfiguration = {
      maxHeight: '50vh',
      maxWidth: '80vw',
      data: {
        heading: 'Noe gikk galt',
        buttons: [
          {
            title: errorMessage,
            callback: () => {
              this.forceNavigateHome();
            },
          },
        ],
      },
    };
    this.dialogService.open(ErrorDialogComponent, dialogConfiguration);
  }

  handleLateError(errorResponse?: HttpErrorResponse): void {
    this.modalActive = true;
    this.stopPolling();

    let errors: IVippsError[];
    if (!!errorResponse) {
      errors = errorResponse.error.errors;
    }

    let message = '';
    if (this.userAgentService.getDeviceOS() === DeviceOS.I_OS) {
      message +=
        'Betalingen mislyktes. Prøv igjen eller be om en ny betalingslenke';
    }

    if (!!errors && errors.some((err) => err)) {
      this.loggingService.warn('vipps frontend: late error ', errors[0]);

      let error = getErrorMessage(errors[0]);
      this.queryParamsService.setQueryParam('error', error);
    } else {
      const dialogConfiguration = {
        maxHeight: '50vh',
        maxWidth: '80vw',
        data: {
          heading: 'Betalingen kunne ikke fullføres',
          message: message,
          buttons: [
            {
              title: `Prøv igjen`,
              callback: () => {
                this.forceNavigateHome();
              },
            },
          ],
        },
      };
      this.dialogService.open(ErrorDialogComponent, dialogConfiguration);
    }
  }

  handleVippsCancelled(resp: VippsResponse) {
    this.modalActive = true;
    this.paymentInProgress = false;
    this.stopPolling();

    const dialogConfiguration = {
      maxHeight: '50vh',
      maxWidth: '80vw',
      data: {
        heading: 'Avbrutt betaling',
        message:
          'Betalingen mislyktes. Prøv igjen eller be om en ny betalingslenke',
        buttons: [
          {
            title: `Prøv igjen`,
            callback: () => {
              this.forceNavigateHome();
            },
          },
        ],
      },
    };
    this.dialogService.open(ErrorDialogComponent, dialogConfiguration);
  }

  handleVippsErrors(resp: VippsResponse): boolean {
    let isErrors = false;
    let errors: IVippsError[] = resp.errors;

    if (
      (errors && errors.some((err) => err)) ||
      resp.status === VippsStatus.ERROR
    ) {
      this.loggingService.warn('vipps frontend: handle vipps errors');
      let errorMessages = errors.map((e) => this.getVippsErrorText(e));

      isErrors = true;
      this.modalActive = true;
      this.paymentInProgress = false;
      this.stopPolling();

      const dialogConfiguration = {
        maxHeight: '50vh',
        maxWidth: '80vw',
        data: {
          heading: 'Betalingen kunne ikke fullføres',
          message: errorMessages[0],
          buttons: [
            {
              title: `Prøv igjen`,
              callback: () => {
                this.forceNavigateHome();
              },
            },
          ],
        },
      };
      this.dialogService.open(ErrorDialogComponent, dialogConfiguration);
    }

    return isErrors;
  }

  // Statemanagement and navigation for vipps and other payment methods that can be redirected to the payment flow and "lose" state
  // All applications that are redirected-from- and sent-back-to the Zaver checkout and using the backend-driven state management would need this
  async fetchState(): Promise<CheckoutState> {
    const path: string = this.route.routeConfig.path;

    const token = this.queryParamsService.getQueryParams().id;
    this.stateService.setToken(token);

    const controlParams: StateControlParameters = {
      currentPageName: this.stateService.getStepFromUrl(path),
      token: this.stateService.getToken(),
    };

    if (controlParams.token) {
      const state = await this.stateService.fetchState(controlParams);
      this.headerService.setMarket(state.market);
      this.headerService.setCancelUrl(state.merchantUrls?.onCancelUrl);
      this.headerService.setCurrentPage(state.currentPageInformation.pageName);
      this.state = state;
      return state;
    }
  }

  async createResumeToken(): Promise<string> {
    const path: string = this.route.routeConfig.path;

    const controlParams: StateControlParameters = {
      currentPageName: this.stateService.getStepFromUrl(path),
      token: this.stateService.getToken(),
    };

    if (controlParams.token) {
      const state = await this.stateService.createResumeStateToken(
        controlParams
      );
      return state;
    }
  }

  async resumeState(resumeToken: string) {
    const path: string = this.route.routeConfig.path;

    const controlParams: StateControlParameters = {
      currentPageName: this.stateService.getStepFromUrl(path),
      token: this.stateService.getToken(),
    };

    try {
      const state = await this.stateService.resume(resumeToken);
      this.stateService.setToken(state.checkoutToken);
      this.headerService.setMarket(state.market);
      this.headerService.setCancelUrl(state.merchantUrls?.onCancelUrl);
      this.headerService.setCurrentPage(state.currentPageInformation.pageName);
      this.state = state;
      return state;
    } catch (err) {
      this.loggingService.warn(
        'vipps frontend: encounteded an error when resuming state',
        err
      );
      this.handleFatalError(err);
    }
  }

  async navigateFromState() {
    // Normally we would want to use `this.navigate()` to proceed to SUCCESS
    // Reson that we do this is that Vipps uses polling to check if we have succeeded in fullfilling all requirements for a payment
    // The checks if a vipps payment's status is in the correct state, then settles the payment request and assigns a user
    if (this.state && this.state.forceNavigation) {
      const route: string = this.stateService.getRouteFromState(this.state);
      this.router.navigate([route], { queryParamsHandling: 'preserve' });
    }
  }
}
