<div class="pr-information">
  <div class="pr-details-container">
    <span class="pr-details-text">{{ detailsText }}</span>
    <zaver-button-link (click)="detailsClick()" [text]="detailsLabel">
    </zaver-button-link>
  </div>

  <div class="pr-amount">
    {{ amountLabel | value }}
  </div>
</div>
