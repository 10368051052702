import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InfoPrefillService {

  public setLocalNin(nin: string) {
    if (nin.indexOf('*') === -1) {
      localStorage.setItem('zco_nin', nin);
    }
  }

  public getNin() {
    return localStorage.getItem('zco_nin') ? localStorage.getItem('zco_nin') : null;
  }

}

