<app-de-wrapper *ngIf="internalServerError">
  <div style="display: flex; flex-direction: column; align-items: center">
    <img class="general-error-icon" src="assets/icon/smiley-sad-nerd.svg" />
    <h3 class="general-error-text">Etwas ist schief gelaufen.</h3>
  </div>
</app-de-wrapper>

<app-de-wrapper *ngIf="loading">
  <app-spinner></app-spinner>
</app-de-wrapper>

<app-de-wrapper *ngIf="!loading && state && !internalServerError">
  <img class="icon-user-img" src="assets/icon/Icon-Phone.svg" />

  <h1 class="heading">Handynummer verifizieren</h1>

  <p class="info-text">Ein Verifizierungscode wurde gesendet&nbsp;an:</p>

  <div class="phone-number-wrapper">
    <span>{{ phoneNumberString }}</span>
    <div class="button-link-wrapper">
      <zaver-button-link [text]="'Ändern'" (click)="back()"></zaver-button-link>
    </div>
  </div>

  <div class="token-input-wrapper">
    <zaver-token-input
      (valueChange)="onOtpChange($event)"
      [showError]="showError"
      [isMobile]="isMobile"
      [IOS]="IOS"
    ></zaver-token-input>
  </div>

  <div class="display-error" *ngIf="showError">
    <img src="assets/icon/icon_error.svg" alt="" class="info-icon" />
    <p>Der Verifizierungscode ist falsch oder bereits&nbsp;abgelaufen</p>
  </div>

  <div class="resend-code" [ngClass]="showError ? 'error' : ''">
    <zaver-button-link
      [text]="'SMS erneut senden'"
      (click)="reSendCode()"
      [disabled]="navigationInProgress"
      [interactive]="true"
    ></zaver-button-link>
  </div>

  <div class="footer-wrapper">
    <div class="footer-container">
      <app-general-error
        style="margin-bottom: 16px"
        *ngIf="!showError && noResponseError"
      ></app-general-error>

      <div class="button-container">
        <zaver-primary-button
          [buttonType]="'submit'"
          [formId]="'otp-input-form'"
          [text]="'Weiter'"
          [disabled]="navigationInProgress"
          (click)="handleCtaClick()"
        ></zaver-primary-button>
      </div>
    </div>
  </div>
</app-de-wrapper>
