import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Currency } from '@app/model/currency';

@Component({
  selector: 'app-de-tokenized-onboarding-success',
  templateUrl: './de-tokenized-onboarding-success.component.html',
  styleUrls: [
    '../../../../route/page-shared.css',
    './de-tokenized-onboarding-success.component.scss',
  ],
})
export class DeTokenizedOnboardingSuccessComponent {
  @Output() onDetailsClick = new EventEmitter();
  @Input() params: any;

  ngOnInit(): void {}

  detailsClick() {
    this.onDetailsClick.emit();
  }
}
