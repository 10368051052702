export class RedirectionQueueEntry {
  public redirectionCallback: Function;
  public priority: number;


  constructor(redirectionCallback: Function, priority: number) {
    this.redirectionCallback = redirectionCallback;
    this.priority = priority;
  }
}
