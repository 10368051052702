import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from '@app/service/header/header.service';
import { StateService } from 'src/app/service/state/state.service';
import { RouteComponent } from '../../route.component';

@Component({
  selector: 'app-de-pre-fts',
  templateUrl: './de-pre-fts.component.html',
  styleUrls: ['../../page-shared.css', './de-pre-fts.component.scss'],
})
export class DePreFtsComponent extends RouteComponent implements OnInit {
  loading = false;
  navigationInProgress = false;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected headerService: HeaderService,
    protected stateService: StateService
  ) {
    super(router, route, stateService, headerService);
  }

  ngOnInit(): void {}

  async handleCtaClick() {
    this.navigationInProgress = true;
    try {
      await this.navigate();
    } catch (err) {
      this.handleError(err);
    }
    this.navigationInProgress = false;
  }
}
