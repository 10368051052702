import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Currency } from '@app/model/currency';
import { RouteComponent } from '@app/route/route.component';
import { HeaderService } from '@app/service/header/header.service';
import { PaymentMethodDetailService } from '@app/service/payment-method-detail/payment-method-details.service';
import { StateService } from '@app/service/state/state.service';
import { UserService } from '@app/service/user-service/user.service';

@Component({
  selector: 'no-success',
  templateUrl: './no-success.component.html',
  styleUrls: ['../../page-shared.css', './no-success.component.scss'],
})
export class NoSuccessComponent extends RouteComponent implements OnInit {
  amount: number;
  currency: Currency;
  paymentMethod: string;
  public readonly APP_STORE_LINK =
    'https://apps.apple.com/se/app/zaver-handla-på-dina-villkor/id1560858500';
  public readonly PLAY_STORE_LINK =
    'https://play.google.com/store/apps/details?id=io.zaver.app';

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected stateService: StateService,
    protected headerService: HeaderService,
    private paymentDetail: PaymentMethodDetailService
  ) {
    super(router, route, stateService, headerService);
  }

  ngOnInit(): void {
    this.paymentMethod = this.paymentDetail.getMethodTitle(
      this.state.paymentInformation.paymentMethod,
      this.state.market
    );
  }

  public openTab(url: string): void {
    window.open(url);
  }
}
