import { CurrencyPipe, registerLocaleData } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Currency, CurrencyCode } from '@app/model/currency';

@Pipe({ name: 'value' })
export class ValuePipe implements PipeTransform {
  transform(value: Currency): string {
    if (!value) {
      return 'null';
    }

    switch (value.currencyCode) {
      case CurrencyCode.SEK:
        return new CurrencyPipe('sv', 'SEK').transform(
          value.amount,
          value.currencyCode,
          'code',
          '1.2-2',
          'sv'
        );
      case CurrencyCode.DKK:
        return new CurrencyPipe('da', 'DKK').transform(
          value.amount,
          value.currencyCode,
          'code',
          '1.2-2',
          'da'
        );
      case CurrencyCode.EUR:
        return new CurrencyPipe('de').transform(
          value.amount,
          value.currencyCode,
          'symbol',
          '1.2-2'
        );
      case CurrencyCode.NOK:
        return new CurrencyPipe('nb', 'NOK').transform(
          value.amount,
          value.currencyCode,
          'code',
          '1.2-2',
          'nb'
        );
      default:
        console.error(
          'Unimplemented currency in value pipe: ',
          value.currencyCode
        );
    }
  }
}
