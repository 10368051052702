import {AbstractControl, ValidatorFn} from '@angular/forms';

export function personalNumberValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    const valid = luhn(control.value.slice(2));
    return valid ? null : {'invalidPersonalNumber': {value: control.value}};
  };
}

/**
 * Rewritten for TS compatibility using:
 * https://gist.github.com/ShirtlessKirk/2134376
 */
const luhn = (number: string) => {
  const arr = [0, 2, 4, 6, 8, 1, 3, 5, 7, 9];
  let
    len = number.length,
    bit = 1,
    sum = 0;
  let
    val;

  while (len) {
    val = parseInt(number.charAt(--len), 10);
    sum += (bit ^= 1) ? arr[val] : val;
  }

  return sum && sum % 10 === 0;
};
