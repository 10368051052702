<div
  class="page-body"
  *ngIf="
    market === Market.SE &&
    availablePaymentMethods &&
    paymentRequest &&
    !modalActive &&
    !loading
  "
>
  <div class="contents-wrapper">
    <div class="contents-container">
      <app-expandable-list-item
        *ngFor="let method of availablePaymentMethods; let i = index"
        [title]="getPaymentMethodViewContext(method).title"
        [description]="getPaymentMethodViewContext(method).subTitle"
        [imgSrc]="getPaymentMethodViewContext(method).imgSrc"
        [selected]="availablePaymentMethods[i] === selectedPaymentMethod"
        [bulletPoints]="getPaymentMethodViewContext(method).bulletPoints"
        [isFirstItem]="i === 0"
        [isLastItem]="i === availablePaymentMethods.length - 1"
        [showTermsLink]="shouldShowTermsLink(method)"
        (onTermsClick)="setModalInfo('terms')"
        [color]="'#e01d78'"
        (click)="handlePaymentMethodSelection(i)"
      ></app-expandable-list-item>

      <div class="bottom-section-wrapper">
        <app-payment-request-info
          *ngIf="!paymentRequest.amountDenotesCreditLimit"
          [lineItems]="paymentRequest.lineItems"
          [market]="paymentRequest.market"
          [value]="paymentRequest.value"
          (onDetailsClick)="setModalInfo()"
        ></app-payment-request-info>

        <div
          class="credit-limit-info"
          *ngIf="paymentRequest.amountDenotesCreditLimit"
        >
          <label>{{ paymentRequest.title }}</label>
          <label
            >Kreditutrymme prövas på
            {{
              paymentRequest.amount.toLocaleString('sv', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })
            }}
            SEK för att ge dig en marginal ifall du önskar göra ändringar på
            installationen i efterhand.
          </label>
        </div>

        <zaver-primary-button
          [text]="ctaLabel"
          (click)="handleContinueClick()"
          [disabled]="navigationInProgress"
        ></zaver-primary-button>

        <p class="disclaimer-paragraph" *ngIf="market === Market.SE">
          Genom att fortsätta godkänner jag
          <a class="disclaimer-link" target="_blank" [href]="getTermsLink()"
            >Zavers villkor</a
          >, samt bekräftar att jag läst Zavers
          <a
            class="disclaimer-link"
            target="_blank"
            [href]="getPrivacyPolicyLink()"
            >Integritetspolicy</a
          >
          och
          <a
            class="disclaimer-link"
            target="_blank"
            [href]="getCookiePolicyLink()"
            >Cookiepolicy</a
          >, samt accepterar betalningsansvar.
          <a class="disclaimer-link" target="_blank" [href]="getSekkiLink()"
            >Visa&nbsp;SEKKI.</a
          >
        </p>
      </div>
    </div>
  </div>
</div>

<zaver-checkout-modal
  id="details-modal"
  [active]="modalActive"
  [buttonText]="'Stäng'"
  [title]="'Visa detaljer'"
  (onClose)="deactivateModal()"
  *ngIf="market === Market.SE"
>
  <app-selector
    *ngIf="!!selectorItems"
    #selector
    [selectorItems]="selectorItems"
    [selectedItem]="selectedItem"
  ></app-selector>
</zaver-checkout-modal>
