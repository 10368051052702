import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-expandable-list-item',
  templateUrl: './expandable-list-item.component.html',
  styleUrls: ['./expandable-list-item.component.css'],
})
export class ExpandableListItemComponent implements OnInit {
  @Input() selected: boolean;
  @Input() title: string;
  @Input() description: string;
  @Input() imgSrc: string;
  @Input() bulletPoints: string[];
  @Input() isFirstItem: boolean;
  @Input() isLastItem: boolean;
  @Input() color: string;
  @Input() showTermsLink: boolean;
  @Input() linkText: string = 'Läs villkor';

  @Output() onTermsClick = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  emitTermsClick() {
    this.onTermsClick.emit();
  }
}
