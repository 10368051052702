<div>
  <zaver-button-link
    style="
      display: flex;
      justify-content: center;
      margin-top: 24px;
      margin-bottom: 16px;
    "
    [color]="Color.Black"
    (click)="activateModal()"
    [text]="'Details anzeigen'"
  ></zaver-button-link>

  <p class="disclaimer-paragraph">
    Indem ich fortfahre, stimme ich,
    <a
      class="disclaimer-link"
      target="_blank"
      [href]="'https://zaver.com/de/bedingungen'"
      >Zaver´s Nutzungsbedingungen</a
    >,
    <a
      class="disclaimer-link"
      target="_blank"
      [href]="'https://zaver.com/de/datenschutz'"
      >Datenschutz
    </a>
    und
    <a
      class="disclaimer-link"
      target="_blank"
      [href]="'https://zaver.com/de/cookies'"
      >Cookie-Richtlinie </a
    >&nbsp;zu.&nbsp;<a
      class="disclaimer-link"
      target="_blank"
      [href]="'https://zaver.com/de/impressum'"
      >Impressum</a
    >.
  </p>

  <div class="footer-logo-wrapper">
    <div>
      <img
        src="/assets/img/Zaver_Logotype_Circle_Coal_DE.svg"
        alt="Zaver logo"
      />
    </div>
  </div>
</div>

<zaver-checkout-modal
  id="details-modal"
  [buttonText]="'Schließen'"
  *ngIf="selectorItems"
  [active]="modalActive"
  [logo]="'assets/img/Zaver_Logotype_Circle_Coal_DE.svg'"
  (onClose)="deactivateModal()"
>
  <app-selector *ngIf="!!selectorItems" [selectorItems]="selectorItems">
    <zaver-button-link [text]="'Allgemeine Zahlungsbedingungen'">
    </zaver-button-link>
  </app-selector>
</zaver-checkout-modal>
