import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import * as Sentry from '@sentry/angular';

import { AppComponent } from './app.component';
import { Router, RouterModule, Routes } from '@angular/router';
import { PaymentMethodSelectionComponent } from './route/payment-method-selection/payment-method-selection.component';
import { NgxsModule } from '@ngxs/store';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { EmailAndInvoiceComponent } from './route/email-and-invoice/email-and-invoice.component';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoanEligibilityQuestionnaireComponent } from './route/loan-eligibility-questionnaire/loan-eligibility-questionnaire.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthComponent } from './route/auth/auth.component';
import { AuthState } from './ngxs/auth/auth.state';
import { PaymentRequestState } from './ngxs/payment-request/payment-request.state';
import { HeaderComponent } from './component/header/header.component';
import { PaymentRequestInfoComponent } from './component/payment-request-info/payment-request-info.component';
import { ExpandableListItemComponent } from './component/expandable-list-item/expandable-list-item.component';
import { InstallmentsItemComponent } from './component/installments-item/installments-item.component';
import { SelectorComponent } from './component/selector/selector.component';
import { SpinnerComponent } from './component/spinner/spinner.component';
import { DotSpinnerComponent } from './component/dot-spinner/dot-spinner.component';
import { AuthService } from './service/auth/auth.service';
import { CustomerJourneyService } from './service/customer-journey/customer-journey.service';
import { ErrorComponent } from './route/error/error.component';
import { PaymentRequestIdInterceptor } from './interceptors/payment-request-id.interceptor';
import { SuccessComponent } from './route/success/success.component';
import { InstallmentsSelectionComponent } from './route/installments-selection/installments-selection.component';
import { InstallmentsState } from './ngxs/installments/installments.state';
import { TransferComponent } from './route/transfer/transfer.component';
import { ErrorState } from './ngxs/error/error.state';
import { InvoiceState } from './ngxs/invoice/invoice.state';
import { QueryParamsService } from './service/query-params/query-params.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatDialogModule,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { UserAgentService } from './service/user-agent/user-agent.service';
import { CreditDecisionDialogComponent } from './component/dialog/credit-decision/credit-decision-dialog.component';
import { SummaryComponent } from './route/loan-eligibility-questionnaire/summary/summary.component';
import { environment } from '../environments/environment';
import { CreditService } from './service/credit/credit.service';
import { CreditDecisionService } from './service/credit-decision/credit-decision.service';
import { SettleComponent } from './route/settle/settle.component';
import { AuthGuard } from './guard/auth.guard';
import { GuardRedirectService } from './service/redirect/guard-redirect.service';
import { PaymentRequestGuard } from './guard/payment-request.guard';
import { SuccessGuard } from './guard/success.guard';
import { CredentialsInterceptor } from './interceptors/credentials.interceptor';
import { HeaderService } from './service/header/header.service';
import { AppLoaderComponent } from './route/app-loader/app-loader.component';
import { PreventBackToSettlementOrAuthGuard } from './guard/prevent-back-to-settlement-or-auth-guard.service';
import { RoutingHistoryService } from './service/routing-history/routing-history.service';
import { SettlementService } from './service/settlement/settlement.service';
import {
  AtRoute,
  DeRoute,
  NoRoute,
  routeNames,
  routeParamNames,
} from '../assets/val/route-constants';
import { PnrEntryComponent } from './route/pnr-entry/pnr-entry.component';
import { InvoiceConfirmComponent } from './route/invoice-confirm/invoice-confirm.component';
import { SwishComponent } from './route/swish/swish.component';
import { SwishService } from './service/swish/swish.service';
import { VippsComponent } from './route/NO/no-vipps/no-vipps.component';
import { VippsService } from './service/vipps/vipps.service';
import { ErrorDialogComponent as ErrorDialogComponent } from './component/dialog/error/error-dialog.component';
import { LoggingService } from './service/logging-service/logging.service';
import { ErrorResponseInterceptor } from './interceptors/error-response-interceptor';
import { NavigationCatcherComponent as NavigationErrorComponent } from './component/navigation-catcher/navigation-catcher.component';
import { InfoPrefillService } from '@app/service/info-prefill/info-prefill.service';
import { ComponentLibraryModule } from 'web-component-library/projects/component-library/src/public-api';
import { ValuePipe } from './shared/pipe/value.pipe';

import { StateService } from './service/state/state.service';
import { StateResolver } from './resolver/state.resolver';
import { FtsComponent } from './route/DE/de-fts/de-fts.component';

import localeSv from '@angular/common/locales/sv';
import localeDa from '@angular/common/locales/da';
import localeEn from '@angular/common/locales/en';
import localeDe from '@angular/common/locales/de';
import localeNb from '@angular/common/locales/nb';

import { InstantDebitStartComponent } from '@app/route/tink/instant-debit-start.component';
import { AccountsComponent } from '@app/route/accounts/accounts.component';
import { AccountsLandingComponent } from '@app/route/accounts-landing/accounts-landing.component';
import { InstantDebitService } from '@app/service/details/instant-debit.service';
import { BankinfoService } from '@app/service/bankinfo/bankinfo.service';
import { CurrencyPipe, registerLocaleData } from '@angular/common';
import { DeEmailAndPhoneRegistrationComponent } from './route/DE/de-email-and-phone-registration/de-email-and-phone-registration.component';
import { UserService } from './service/user-service/user.service';
import { DeUserRegistrationComponent } from './route/DE/de-user-registration/de-user-registration.component';
import { DePhoneVerificationComponent } from './route/DE/de-phone-verification/de-phone-verification.component';
import { DePreFtsComponent } from './route/DE/de-pre-fts/de-pre-fts.component';
import { DeErrorComponent } from './route/DE/de-error/de-error.component';
import { DeSuccessComponent } from './route/DE/de-success/de-success.component';
import { DePartSuccessComponent } from './route/DE/de-part-success/de-part-success.component';
import { DeVerifyUserComponent } from './route/DE/de-verify-user/de-verify-user.component';
import { TrustlyDirectDebitComponent } from './route/trustly-direct-debit/trustly-direct-debit.component';
import { InstantDebitEmailComponent } from '@app/route/instant-debit-email/instant-debit-email.component';
import { DeEditPhoneComponent } from './route/DE/de-edit-phone/de-edit-phone.component';
import { DeRejectedDeComponent } from './route/DE/de-rejected/de-rejected.component';
import { DeEmailVerificationComponent } from './route/DE/de-email-verification/de-email-verification.component';
import { DePrecheckComponent } from './route/DE/de-precheck/de-precheck.component';
import { NoEmailAndInvoiceComponent } from './route/NO/no-email-and-invoice/no-email-and-invoice.component';
import { NoInvoiceConfirmComponent } from './route/NO/no-invoice-confirm/no-invoice-confirm.component';
import { NoSuccessComponent } from './route/NO/no-success/no-success.component';
import { NoInstallmentsSelectionComponent } from './route/NO/no-installments-selection/no-installments-selection.component';
import { NoAuthCallbackComponent } from './route/NO/no-auth/no-auth-callback/no-auth-callback.component';
import { DeInstallmentsSelectionComponent } from './route/DE/de-installments-selection/de-installments-selection.component';
import { NoErrorComponent } from './route/NO/no-error/no-error.component';
import { NoUserService } from './service/no-user-service/no-user.service';
import { PaymentMethodDetailService } from './service/payment-method-detail/payment-method-details.service';
import { DePrecheckSuccessComponent } from './route/DE/de-success/precheck/de-precheck-success.component';
import { DeCheckoutSuccessComponent } from './route/DE/de-success/checkout/de-checkout-success.component';
import { GeneralErrorComponent } from './component/general-error-de/general-error.component';
import { InputRefDirective } from './provider/trim-input-directive';
import { DeWrapperComponent } from './route/DE/de-wrapper-component/de-wrapper.component';
import { DeThinWrapperComponent } from './route/DE/de-thin-wrapper-component/de-thin-wrapper.component';
import { DeTokenizedOnboardingSuccessComponent } from './route/DE/de-success/tokenized-onboarding/de-tokenized-onboarding-success.component';
import { DeMonthlyInvoiceComponent } from './route/DE/de-monthly-invoice/de-monthly-invoice.component';
import { DeFooterComponent } from './route/DE/de-footer/de-footer.component';
import { DeAccountRestorationComponent } from './route/DE/de-account-restoration/de-account-restoration.component';
import { UserFlowSelectionComponent } from './route/user-flow-selection/user-flow-selection.component';
import { RegisterStateResolver } from './resolver/register-state.resolver';
import { NoRejectedComponent } from './route/NO/no-rejected/no-rejected.component';
import { NoAuthComponent } from './route/NO/no-auth/no-auth.component';

registerLocaleData(localeSv);
registerLocaleData(localeDa);
registerLocaleData(localeEn);
registerLocaleData(localeDe);
registerLocaleData(localeNb);

const appRoutes: Routes = [
  {
    path: routeNames.EMAIL_AND_INVOICE,
    component: EmailAndInvoiceComponent,
    canActivate: [AuthGuard, PaymentRequestGuard],
    canDeactivate: [PreventBackToSettlementOrAuthGuard],
  },
  {
    path: routeNames.TINK_LAUNCH,
    component: InstantDebitStartComponent,
    canActivate: [AuthGuard, PaymentRequestGuard],
    canDeactivate: [PreventBackToSettlementOrAuthGuard],
  },
  {
    path: routeNames.TRUSTLY_DIRECT_DEBIT,
    component: TrustlyDirectDebitComponent,
    canActivate: [PaymentRequestGuard],
    canDeactivate: [PreventBackToSettlementOrAuthGuard],
  },
  {
    path: routeNames.TRUSTLY_DIRECT_DEBIT_EMAIL,
    component: InstantDebitEmailComponent,
    canActivate: [PaymentRequestGuard],
    canDeactivate: [PreventBackToSettlementOrAuthGuard],
  },
  {
    path: routeNames.ACCOUNT_LANDING,
    component: AccountsLandingComponent,
    canActivate: [AuthGuard],
    canDeactivate: [PreventBackToSettlementOrAuthGuard],
  },
  {
    path: `${routeNames.ACCOUNT_SELECT}/:${routeParamNames.TASK_ID}`,
    component: AccountsComponent,
    canActivate: [AuthGuard],
    canDeactivate: [PreventBackToSettlementOrAuthGuard],
  },
  {
    path: routeNames.INVOICE_CONFIRM,
    component: InvoiceConfirmComponent,
    canActivate: [AuthGuard, PaymentRequestGuard],
    canDeactivate: [PreventBackToSettlementOrAuthGuard],
  },
  {
    path: routeNames.LEQ_SUMMARY,
    pathMatch: 'full',
    component: SummaryComponent,
    canActivate: [AuthGuard, PaymentRequestGuard],
  },
  {
    path: routeNames.LEQ,
    pathMatch: 'full',
    redirectTo: routeNames.LEQ_SUMMARY,
  },
  {
    path: `${routeNames.LEQ}/:${routeParamNames.LEQ_STEP}`,
    component: LoanEligibilityQuestionnaireComponent,
    canActivate: [AuthGuard, PaymentRequestGuard],
  },
  {
    path: routeNames.SELECT_INSTALLMENT_PLAN,
    component: InstallmentsSelectionComponent,
    canActivate: [AuthGuard, PaymentRequestGuard],
    canDeactivate: [PreventBackToSettlementOrAuthGuard],
  },
  {
    path: routeNames.PERSONAL_NUMBER_ENTRY,
    component: PnrEntryComponent,
    canActivate: [PaymentRequestGuard],
  },
  {
    path: routeNames.BANK_TRANSFER_FRAME,
    component: TransferComponent,
    canActivate: [PaymentRequestGuard],
    canDeactivate: [PreventBackToSettlementOrAuthGuard],
  },
  {
    path: routeNames.SETTLE,
    component: SettleComponent,
    canActivate: [PaymentRequestGuard],
  },
  {
    path: routeNames.SUCCESS,
    component: SuccessComponent,
    canActivate: [SuccessGuard],
    pathMatch: 'full',
  },
  {
    path: routeNames.SWISH,
    component: SwishComponent,
    canActivate: [PaymentRequestGuard],
    pathMatch: 'full',
  },
  {
    path: routeNames.HOME,
    component: PaymentMethodSelectionComponent,
    canActivate: [PaymentRequestGuard],
    pathMatch: 'full',
    data: {
      requireAuth: false,
      requirePaymentMethodSelected: false,
    },
  },
  {
    path: routeNames.AUTH,
    component: AuthComponent,
  },
  {
    path: 'app-loader',
    component: AppLoaderComponent,
    pathMatch: 'full',
  },
  {
    path: routeNames.ERROR,
    component: ErrorComponent,
  },
  {
    path: AtRoute.HOME,
    resolve: {
      state: RegisterStateResolver,
    },
    component: UserFlowSelectionComponent,
  },
  {
    path: AtRoute.EMAIL_AND_PHONE_REGISTRATION,
    resolve: {
      state: StateResolver,
    },
    component: DeEmailAndPhoneRegistrationComponent,
  },
  {
    path: AtRoute.PHONE_VERIFICATION,
    resolve: {
      state: StateResolver,
    },
    component: DePhoneVerificationComponent,
  },
  {
    path: AtRoute.USER_REGISTRATION,
    resolve: {
      state: StateResolver,
    },
    component: DeUserRegistrationComponent,
  },
  {
    path: AtRoute.PRE_FTS,
    resolve: {
      state: StateResolver,
    },
    component: DePreFtsComponent,
  },
  {
    path: AtRoute.FTS,
    resolve: {
      state: StateResolver,
    },
    component: FtsComponent,
  },
  {
    path: AtRoute.PART_SUCCESS,
    resolve: {
      state: StateResolver,
    },
    component: DePartSuccessComponent,
  },
  {
    path: AtRoute.VERIFY_USER,
    resolve: {
      state: StateResolver,
    },
    component: DeVerifyUserComponent,
  },
  {
    path: AtRoute.SUCCESS,
    resolve: {
      state: StateResolver,
    },
    component: DeSuccessComponent,
  },
  {
    path: AtRoute.ACCOUNT_RESTORATION,
    resolve: {
      state: StateResolver,
    },
    component: DeAccountRestorationComponent,
  },
  {
    path: AtRoute.EDIT_PHONE,
    resolve: {
      state: StateResolver,
    },
    component: DeEditPhoneComponent,
  },
  {
    path: AtRoute.ERROR,
    resolve: {
      state: StateResolver,
    },
    component: DeErrorComponent,
  },
  {
    path: AtRoute.REJECTED,
    resolve: {
      state: StateResolver,
    },
    component: DeRejectedDeComponent,
  },
  {
    path: AtRoute.VERIFY_EMAIL,
    resolve: {
      state: StateResolver,
    },
    component: DeEmailVerificationComponent,
  },
  {
    path: AtRoute.PRECHECK,
    resolve: {
      state: RegisterStateResolver,
    },
    component: DePrecheckComponent,
  },
  {
    path: AtRoute.INSTALLMENTS_SELECTION,
    resolve: {
      state: StateResolver,
    },
    component: DeInstallmentsSelectionComponent,
  },
  {
    path: DeRoute.HOME,
    resolve: {
      state: RegisterStateResolver,
    },
    component: UserFlowSelectionComponent,
  },
  {
    path: DeRoute.EMAIL_AND_PHONE_REGISTRATION,
    resolve: {
      state: StateResolver,
    },
    component: DeEmailAndPhoneRegistrationComponent,
  },
  {
    path: DeRoute.PHONE_VERIFICATION,
    resolve: {
      state: StateResolver,
    },
    component: DePhoneVerificationComponent,
  },
  {
    path: DeRoute.USER_REGISTRATION,
    resolve: {
      state: StateResolver,
    },
    component: DeUserRegistrationComponent,
  },
  {
    path: DeRoute.PRE_FTS,
    resolve: {
      state: StateResolver,
    },
    component: DePreFtsComponent,
  },
  {
    path: DeRoute.FTS,
    resolve: {
      state: StateResolver,
    },
    component: FtsComponent,
  },
  {
    path: DeRoute.PART_SUCCESS,
    resolve: {
      state: StateResolver,
    },
    component: DePartSuccessComponent,
  },
  {
    path: DeRoute.VERIFY_USER,
    resolve: {
      state: StateResolver,
    },
    component: DeVerifyUserComponent,
  },
  {
    path: DeRoute.SUCCESS,
    resolve: {
      state: StateResolver,
    },
    component: DeSuccessComponent,
  },
  {
    path: DeRoute.ACCOUNT_RESTORATION,
    resolve: {
      state: StateResolver,
    },
    component: DeAccountRestorationComponent,
  },
  {
    path: DeRoute.EDIT_PHONE,
    resolve: {
      state: StateResolver,
    },
    component: DeEditPhoneComponent,
  },
  {
    path: DeRoute.ERROR,
    resolve: {
      state: StateResolver,
    },
    component: DeErrorComponent,
  },
  {
    path: DeRoute.REJECTED,
    resolve: {
      state: StateResolver,
    },
    component: DeRejectedDeComponent,
  },
  {
    path: DeRoute.VERIFY_EMAIL,
    resolve: {
      state: StateResolver,
    },
    component: DeEmailVerificationComponent,
  },
  {
    path: DeRoute.PRECHECK,
    resolve: {
      state: RegisterStateResolver,
    },
    component: DePrecheckComponent,
  },
  {
    path: DeRoute.INSTALLMENTS_SELECTION,
    resolve: {
      state: StateResolver,
    },
    component: DeInstallmentsSelectionComponent,
  },
  {
    path: NoRoute.HOME,
    resolve: {
      state: RegisterStateResolver,
    },
    component: UserFlowSelectionComponent,
  },
  {
    path: NoRoute.EMAIL_AND_INVOICE,
    resolve: {
      state: StateResolver,
    },
    component: NoEmailAndInvoiceComponent,
  },
  {
    path: NoRoute.INVOICE_CONFIRM,
    resolve: {
      state: StateResolver,
    },
    component: NoInvoiceConfirmComponent,
  },
  {
    path: NoRoute.INSTALLMENTS_SELECTION,
    resolve: {
      state: StateResolver,
    },
    component: NoInstallmentsSelectionComponent,
  },
  {
    path: NoRoute.SUCCESS,
    resolve: {
      state: StateResolver,
    },
    component: NoSuccessComponent,
  },
  {
    path: NoRoute.AUTH,
    resolve: {
      state: StateResolver,
    },
    component: NoAuthComponent,
  },
  {
    path: NoRoute.AUTH_CALLBACK,
    resolve: {
      state: StateResolver,
    },
    component: NoAuthCallbackComponent,
  },
  {
    path: NoRoute.ERROR,
    resolve: {
      state: StateResolver,
    },
    component: NoErrorComponent,
  },
  {
    path: NoRoute.REJECTED,
    resolve: {
      state: StateResolver,
    },
    component: NoRejectedComponent,
  },
  {
    path: DeRoute.INSTALLMENTS_SELECTION,
    resolve: {
      state: StateResolver,
    },
    component: DeInstallmentsSelectionComponent,
  },
  {
    path: NoRoute.VIPPS,
    component: VippsComponent,
  },
  {
    path: '**',
    component: NavigationErrorComponent,
  },
];

const devModules = environment.production
  ? []
  : [
      /**
       * Log ngxs mutations
       */
      NgxsLoggerPluginModule.forRoot(),
    ];

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    PaymentMethodSelectionComponent,
    ExpandableListItemComponent,
    InstallmentsItemComponent,
    SelectorComponent,
    PaymentRequestInfoComponent,
    EmailAndInvoiceComponent,
    InvoiceConfirmComponent,
    LoanEligibilityQuestionnaireComponent,
    AuthComponent,
    SpinnerComponent,
    DotSpinnerComponent,
    ErrorComponent,
    SuccessComponent,
    InstallmentsSelectionComponent,
    TransferComponent,
    CreditDecisionDialogComponent,
    ErrorDialogComponent,
    SummaryComponent,
    SettleComponent,
    AppLoaderComponent,
    PnrEntryComponent,
    SwishComponent,
    VippsComponent,
    NavigationErrorComponent,
    ValuePipe,
    DeUserRegistrationComponent,
    FtsComponent,
    DeEmailAndPhoneRegistrationComponent,
    DePhoneVerificationComponent,
    DePreFtsComponent,
    DeErrorComponent,
    DeSuccessComponent,
    DePartSuccessComponent,
    DeVerifyUserComponent,
    DeEditPhoneComponent,
    InstantDebitStartComponent,
    AccountsComponent,
    AccountsLandingComponent,
    TrustlyDirectDebitComponent,
    InstantDebitEmailComponent,
    DeRejectedDeComponent,
    DePrecheckComponent,
    DeEmailVerificationComponent,
    DePrecheckSuccessComponent,
    DeCheckoutSuccessComponent,
    GeneralErrorComponent,
    InputRefDirective,
    NoEmailAndInvoiceComponent,
    NoAuthComponent,
    NoInvoiceConfirmComponent,
    NoErrorComponent,
    NoSuccessComponent,
    DeWrapperComponent,
    DeThinWrapperComponent,
    DeTokenizedOnboardingSuccessComponent,
    DeMonthlyInvoiceComponent,
    DeFooterComponent,
    DeAccountRestorationComponent,
    NoAuthCallbackComponent,
    DeInstallmentsSelectionComponent,
    NoInstallmentsSelectionComponent,
    UserFlowSelectionComponent,
    NoRejectedComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'checkout' }),
    BrowserAnimationsModule,
    MatInputModule,
    MatIconModule,
    MatFormFieldModule,
    MatDialogModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    ComponentLibraryModule,
    /**
     * Configure app ngxs management with NgXS.
     */
    NgxsModule.forRoot([
      AuthState,
      PaymentRequestState,
      InstallmentsState,
      ErrorState,
      InvoiceState,
    ]),
    /**
     * Utilize ngxs binding for forms
     */
    NgxsFormPluginModule.forRoot(),
    RouterModule.forRoot(appRoutes, {
      enableTracing: environment.logSettings.routerLog,
    }),
    ...devModules,
  ],
  providers: [
    CustomerJourneyService,
    AuthService,
    SettlementService,
    QueryParamsService,
    UserAgentService,
    GuardRedirectService,
    CreditService,
    CreditDecisionService,
    HeaderService,
    AuthGuard,
    PaymentRequestGuard,
    SuccessGuard,
    RoutingHistoryService,
    SwishService,
    VippsService,
    LoggingService,
    InfoPrefillService,
    StateService,
    NoUserService,
    PaymentMethodDetailService,
    StateResolver,
    RegisterStateResolver,
    InstantDebitService,
    BankinfoService,
    UserService,
    CurrencyPipe,
    ValuePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PaymentRequestIdInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CredentialsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorResponseInterceptor,
      multi: true,
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        hasBackdrop: true,
        disableClose: true,
        closeOnNavigation: true,
      },
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
  ],
  bootstrap: [AppComponent],
  entryComponents: [CreditDecisionDialogComponent, ErrorDialogComponent],
})
export class AppModule {}
