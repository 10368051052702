const ACTION_SUBJECT_TAG = '[Authentication]';

export class SetPreFilledCredentials {
  static readonly type = `${ACTION_SUBJECT_TAG}
  Check if personal number can be pre filled from server and display it if true`;

  constructor() {}
}

export class ClearPreFilledInfo {
  static readonly type = `${ACTION_SUBJECT_TAG} Clear auth form pre-filled info.`;

  constructor() {}
}

export class RegisterAuthState {
  static readonly type = `${ACTION_SUBJECT_TAG} Update pending auth state`;
  constructor(
    public message: string,
    public authenticated: boolean,
    public inProgress: boolean,
    public status: string,
    public isError: boolean = false,
  ) {}
}

export class InitAuthProcess {
  static readonly type = `${ACTION_SUBJECT_TAG} Init auth process`;

  constructor(public personalNumber: string) {}
}
