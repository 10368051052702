import { Injectable } from '@angular/core';
import {
  AuthorizedPaymentMethod,
  Market,
  PaymentMethod,
  PaymentRequestDto,
} from '@app/model/payment-request/payment-request.dto';

@Injectable({
  providedIn: 'root',
})
export class PaymentMethodDetailService {
  constructor() {}

  getPaymentMethodViewContext(
    method: AuthorizedPaymentMethod,
    market: Market,
    paymentRequest: PaymentRequestDto
  ) {
    switch (method.type) {
      case PaymentMethod.INSTANT_DEBIT:
        return {
          identifier: PaymentMethod.INSTANT_DEBIT,
          title: this.getMethodTitle(PaymentMethod.INSTANT_DEBIT, market),
          subTitle: 'Extra smidigt vid större köp',
          imgSrc: '/assets/icon/Icon-Bolt.svg',
          bulletPoints: ['Pengarna dras inom 1-2 bankdagar'],
        };
      case PaymentMethod.TRUSTLY:
        return {
          identifier: PaymentMethod.TRUSTLY,
          title: this.getMethodTitle(PaymentMethod.TRUSTLY, market),
          subTitle: this.getMethodSubTitle(
            method,
            PaymentMethod.TRUSTLY,
            market,
            paymentRequest
          ),
          imgSrc: '/assets/icon/Icon-Bank.svg',
          bulletPoints: ['Godkänn med BankID eller dosa'],
        };

      case PaymentMethod.TOKENIZED_CREDIT_LIMIT:
        return {
          identifier: PaymentMethod.TOKENIZED_CREDIT_LIMIT,
          title: this.getMethodTitle(
            PaymentMethod.TOKENIZED_CREDIT_LIMIT,
            market
          ),
          subTitle: this.getMethodSubTitle(
            method,
            PaymentMethod.TOKENIZED_CREDIT_LIMIT,
            market,
            paymentRequest
          ),
          imgSrc: '/assets/icon/Icon-Invoice.svg',
          bulletPoints: ['Tokenized fejktura'],
        };

      case PaymentMethod.SWISH_EXTERNAL:
        return {
          identifier: PaymentMethod.SWISH_EXTERNAL,
          title: this.getMethodTitle(PaymentMethod.SWISH_EXTERNAL, market),
          subTitle: 'Perfekt för mindre belopp',
          imgSrc: '/assets/icon/Swish_Symbol_White_SVG.svg',
          bulletPoints: ['Pengarna dras från ditt bankkonto'],
        };

      case PaymentMethod.VIPPS_EXTERNAL:
        return {
          identifier: PaymentMethod.VIPPS_EXTERNAL,
          title: this.getMethodTitle(PaymentMethod.VIPPS_EXTERNAL, market),
          subTitle: 'Enkel måte å betale',
          imgSrc: '/assets/icon/Vipps_Symbol_White_SVG.svg',
          bulletPoints: ['Pengene trekkes fra bankkontoen din'],
        };

      case PaymentMethod.INVOICE:
        return {
          identifier: PaymentMethod.INVOICE,
          title: this.getMethodTitle(PaymentMethod.INVOICE, market),
          subTitle: this.getMethodSubTitle(
            method,
            PaymentMethod.INVOICE,
            market,
            paymentRequest
          ),
          imgSrc: '/assets/icon/Icon-Invoice.svg',
          bulletPoints: this.getMethodBulletPoints(
            PaymentMethod.INVOICE,
            market
          ),
        };

      case PaymentMethod.CREDIT:
        return {
          identifier: PaymentMethod.CREDIT,
          title: this.getMethodTitle(PaymentMethod.CREDIT, market),
          // safe default if payment request fails to load or is loaded after the view
          subTitle: this.getMethodSubTitle(
            method,
            PaymentMethod.CREDIT,
            market,
            paymentRequest
          ),
          imgSrc: '/assets/icon/Icon-Cake.svg',
          bulletPoints: ['Betala i din egen takt'],
          // choiceTerms with "Visa SEKKI" is added by getChoiceTerms()
        };
    }
  }

  getMethodTitle(method: PaymentMethod, market: Market) {
    switch (method) {
      // Market independent titles
      case PaymentMethod.SWISH_EXTERNAL:
        return 'Swish';
      case PaymentMethod.VIPPS_EXTERNAL:
        return 'Vipps';

      // Market dependent titles
      case PaymentMethod.INSTANT_DEBIT:
        switch (market) {
          case Market.SE:
            return 'Direktbetalning';
          case Market.DE:
            return 'Direkte Zahlung';
          case Market.DK:
            return 'Direkte betaling';
          case Market.NO:
            return 'Direkte betaling';
        }
      case PaymentMethod.TRUSTLY:
        switch (market) {
          case Market.SE:
            return 'Banköverföring';
          case Market.DE:
            return 'Direktüberweisung';
          case Market.DK:
            return 'Direkte bankoverførsel';
          case Market.NO:
            return 'Direkte bankoverføring';
        }
      case PaymentMethod.INVOICE:
        switch (market) {
          case Market.SE:
            return 'Faktura';
          case Market.DE:
            return 'Rechnung';
          case Market.DK:
            return 'Faktura';
          case Market.NO:
            return 'Faktura';
        }
      case PaymentMethod.CREDIT:
        switch (market) {
          case Market.SE:
            return 'Delbetalning';
          case Market.DE:
            return 'Teilzahlung';
          case Market.DK:
            return 'Delvis betaling';
          case Market.NO:
            return 'Delbetaling';
        }
      case PaymentMethod.TOKENIZED_CREDIT_LIMIT:
        switch (market) {
          case Market.DE:
            return 'Månads rechnung';
        }
    }
  }

  getMethodSubTitle(
    authorizedMethod: AuthorizedPaymentMethod,
    method: PaymentMethod,
    market: Market,
    paymentRequest: PaymentRequestDto
  ) {
    switch (method) {
      case PaymentMethod.TRUSTLY:
        switch (market) {
          case Market.SE:
            return 'Pengarna dras från din bank';
          case Market.DE:
            return 'Das Geld wird von Ihrer Bank abgebucht';
          case Market.DK:
            return 'Pengene trækkes i din bank';
          case Market.NO:
            return 'Pengene blir trukket fra banken din';
        }
      case PaymentMethod.INVOICE:
        switch (market) {
          case Market.SE:
            return 'Betala inom 14 dagar - 0 SEK';
          case Market.DE:
            return 'Später bezahlen';
          case Market.DK:
            return 'Betala inom 14 dagar - 0 DKK';
          case Market.NO:
            return 'Betal innen 14 dager - NOK 0';
        }
      case PaymentMethod.CREDIT:
        switch (market) {
          case Market.SE:
            return paymentRequest
              ? `Från ${authorizedMethod.lowestPossibleMonthlyPayment.toLocaleString(
                  'sv',
                  {
                    maximumFractionDigits: 0,
                  }
                )} SEK/mån`
              : '';
          case Market.DE:
            return paymentRequest
              ? `Von ${authorizedMethod.lowestPossibleMonthlyPayment.toLocaleString(
                  'de',
                  {
                    maximumFractionDigits: 0,
                  }
                )} € / Monat`
              : '';
          case Market.DK:
            return paymentRequest
              ? `Fra ${authorizedMethod.lowestPossibleMonthlyPayment.toLocaleString(
                  'dk',
                  {
                    maximumFractionDigits: 0,
                  }
                )} DKK/md`
              : '';
          case Market.NO:
            return paymentRequest
              ? `Fra ${authorizedMethod.lowestPossibleMonthlyPayment.toLocaleString(
                  'nb',
                  {
                    maximumFractionDigits: 0,
                  }
                )} NOK/mnd`
              : '';
        }

      case PaymentMethod.TOKENIZED_CREDIT_LIMIT:
        switch (market) {
          case Market.DE:
            return 'En rechnung i månaden!';
        }
    }
  }

  getMethodBulletPoints(method: PaymentMethod, market: Market): string[] {
    let result: string[] = [];
    switch (method) {
      case PaymentMethod.INVOICE:
        switch (market) {
          case Market.SE:
            result.push(`Betala senare, helt gratis!`);
            return result;
          case Market.DE:
            result.push('In 14 Tagen bezahlen - 0 EUR');
            return result;
          case Market.DK:
            result.push('Betal senere, helt gratis');
            return result;
          case Market.NO:
            result.push(`Betal senere, helt gratis!`);
            return result;
        }
      default:
        console.error(
          'Attempted to fetch bullet points for unimplemented method'
        );
    }
  }

  setupMarketBasedLabels(market: Market) {
    let ctaLabel = null;
    switch (market) {
      case Market.SE:
        ctaLabel = 'Fortsätt';
        break;
      case Market.DE:
        ctaLabel = 'Weiter';
        break;
      case Market.DK:
        ctaLabel = 'Fortsette';
      case Market.NO:
        ctaLabel = 'Fortsette';
        break;
      default:
        console.error('Unimplemented market: ', market);
    }
    return ctaLabel;
  }
}
