import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {Store} from '@ngxs/store';
import {DENIED_CREDIT_SCORE, DENIED_LEQ_ANSWERS} from '../../../model/credit/credit-denied-decision.dto';
import {ClearInstallmentPlanFetchingError} from '../../../ngxs/installments/installments.actions';
import {ClearInvoiceFetchingError} from '../../../ngxs/invoice/invoice.actions';

@Component({
  selector: 'app-credit-decision',
  templateUrl: './credit-decision-dialog.component.html',
  styleUrls: ['./credit-decision-dialog.component.css']
})
export class CreditDecisionDialogComponent {

  public reason: string;
  public buttons: any[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CreditDecisionDialogComponent>,
    private store: Store) {

    this.buttons = data.buttons;
  }


  handleButtonClick(button) {
    button.callback.call();
    this.closeSelf();
  }

  private closeSelf() {
    this.dialogRef.close();
    this.store.dispatch(new ClearInstallmentPlanFetchingError());
    this.store.dispatch(new ClearInvoiceFetchingError());
  }

}
