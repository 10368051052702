<div class="selectable-items-container">
  <div
    *ngFor="let item of selectorItems"
    class="selectable-item"
    (click)="setSelectedItem(item)"
    [class.active]="item === selectedItem"
  >
    {{ item.tab }}
  </div>
</div>

<ng-container *ngIf="!!selectedItem.lineItems">
  <p class="selected-title">{{ selectedItem.title }}</p>
  <hr class="details-divider" />
  <div *ngFor="let item of selectedItem.lineItems">
    <div class="line-item-container">
      <p>{{ item.quantity }} x {{ item.name }}</p>
      <p class="value">{{ item.value | value }}</p>
    </div>
    <hr class="details-divider" />
  </div>
  <p class="total-amount line-items">{{ selectedItem.totalValue }}</p>
</ng-container>

<ng-container *ngIf="!selectedItem.lineItems">
  <p class="selected-title">{{ selectedItem.title }}</p>
  <div
    class="descriptions"
    *ngFor="let description of selectedItem.descriptions"
  >
    <p class="selected-title" *ngIf="description.title">
      {{ description.title }}
    </p>
    <p class="selected-text" *ngIf="description.text">{{ description.text }}</p>
  </div>
  <ng-container *ngIf="selectedItem.totalValue">
    <hr class="details-divider" />
    <p class="total-amount">
      {{ selectedItem.totalValue }}
    </p>
  </ng-container>

  <div *ngFor="let link of selectedItem.links" style="margin-top: 16px">
    <zaver-button-link
      [text]="link.text"
      (click)="link.onClick()"
    ></zaver-button-link>
  </div>
</ng-container>
