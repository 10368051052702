import {
  AfterViewInit,
  Component,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';
import { HeaderService } from '../../service/header/header.service';
import { LoggingService } from 'src/app/service/logging-service/logging.service';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  Router,
} from '@angular/router';
import { routeNames } from '@assets/val/route-constants';
import { CustomerJourneyService } from '@app/service/customer-journey/customer-journey.service';
import { Market } from '@app/model/payment-request/payment-request.dto';
import { UserService } from '@app/service/user-service/user.service';
import { CheckoutStatus } from '@app/service/state/state.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  public partnerLogoUri: string;
  @Input() dynamic = true;

  private clickCounter = 0;
  displayMerchantLogo = true;
  displayBackButton: boolean;
  innerWidth: number;
  market: Market;
  cancelUrl: string;
  currentPage: string;
  navigationInProgress: boolean = false;
  checkoutStatus: CheckoutStatus;
  constructor(
    private headerService: HeaderService,
    private loggingService: LoggingService,
    private customerJourneyService: CustomerJourneyService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService
  ) {
    // check if back or forward button is pressed.
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  ngOnInit() {
    if (this.dynamic) {
      this.headerService.registerHeaderInstance(this);
    }
  }

  ngAfterViewInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        this.displayBackButton = this.route.children[0]
          ? this.route.children[0].snapshot.data.displayBackButton
          : false;
      }
      this.market = this.headerService.market;
      this.cancelUrl = this.headerService.cancelUrl;
      this.checkoutStatus = this.headerService.checkoutStatus;
    });
    this.innerWidth = window.innerWidth;
  }

  public setPartnerLogoUri(logoUri: string) {
    this.partnerLogoUri = logoUri;
  }

  public async routeToCancelUrl() {
    this.navigationInProgress = true;
    try {
      const resp = await this.userService.cancelPayment();
      if (resp.success) {
        window.location.href = this.cancelUrl;
      } else {
        this.navigationInProgress = false;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public report() {
    if (this.clickCounter < 8) {
      this.clickCounter++;
      return;
    }
    this.loggingService.manualReport();
    this.clickCounter = 0;
    console.log('sent report manually to zaver');
  }

  public back() {
    let previousRoute = this.customerJourneyService.getPreviousRoute();
    if (!!previousRoute) {
      // this.router.navigate([routeNames.PERSONAL_NUMBER_ENTRY], {
      //   queryParamsHandling: 'merge',
      // });
      let currentUrl = this.router.url;
      previousRoute.subscribe((navRoute) => {
        this.router
          .navigate([navRoute], {
            queryParamsHandling: 'preserve',
          })
          .then(
            () => {
              // navigation success
            },
            (rejection) => {
              this.loggingService.error(
                `Navigation back from ${currentUrl} to ${navRoute} was rejected.`,
                rejection
              );
            }
          );
      });
    } else {
      this.router.navigate([routeNames.HOME], {
        queryParamsHandling: 'merge',
      });
    }
  }

  displayZaverLogo() {
    return (
      !(this.displayBackButton && this.displayMerchantLogo) &&
      !!this.market &&
      this.market !== 'DE'
    );
  }

  public hideMerchantLogo() {
    this.displayMerchantLogo = false;
  }
}
