import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectorItem } from '@app/component/selector/selector.component';
import { Market } from '@app/model/payment-request/payment-request.dto';
import { RouteComponent } from '@app/route/route.component';
import { LoanOption } from '@app/route/shared/LoanOption';
import { LoanOptionVM } from '@app/route/shared/LoanOptionVM';
import { HeaderService } from '@app/service/header/header.service';
import { NoUserService } from '@app/service/no-user-service/no-user.service';
import { StateService } from '@app/service/state/state.service';
import { ValuePipe } from '@app/shared/pipe/value.pipe';

@Component({
  selector: 'no-installments-selection',
  templateUrl: './no-installments-selection.component.html',
  styleUrls: [
    '../../page-shared.css',
    './no-installments-selection.component.scss',
  ],
})
export class NoInstallmentsSelectionComponent
  extends RouteComponent
  implements OnInit
{
  availableloanOptions: LoanOptionVM[] = null;
  presentedLoanOptions: LoanOptionVM[] = null;
  selectedLoanOption: LoanOptionVM = null;
  expanded: boolean = false;
  equalOrLessThanThreeLoanOptions: boolean = false;
  navigationInProgress: boolean = false;
  currentLoanTermsStr: string = '';
  Market = Market;
  selectorItems: SelectorItem[] = null;
  modalActive: boolean = false;
  loading: boolean = false;

  preferredInstallmentPlanDurations: number[] = null; // [6, 12, 48];

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected stateService: StateService,
    protected headerService: HeaderService,
    private noUserService: NoUserService,
    private valuePipe: ValuePipe
  ) {
    super(router, route, stateService, headerService);
  }

  ngOnInit(): void {
    this.loading = true;
    this.availableloanOptions = this.transformDtoTOVM(
      this.state.loanOptions.sort((a, b) => a.monthlyCost - b.monthlyCost)
    );

    this.equalOrLessThanThreeLoanOptions =
      this.availableloanOptions.length <= 3;

    this.getPresentedLoanOptions();
    this.selectInstallment(this.presentedLoanOptions[0]);
    this.loading = false;
  }

  async handleCtaClick(): Promise<void> {
    try {
      await this.noUserService.selectInstallmentPlan(
        this.selectedLoanOption.termId
      );
      this.navigate();
    } catch (error) {
      console.log(error);
    }
  }

  selectInstallment(loanOption: LoanOptionVM): void {
    this.selectedLoanOption = loanOption;
    this.currentLoanTermsStr = this.buildLoantermsString(
      this.selectedLoanOption
    );
    this.selectorItems = [
      {
        tab: 'Ditt kjøp',
        title: 'Sammendrag',
        lineItems: this.state.paymentInformation?.lineItems,
        totalValue: `Totalt inkl. moms: ${this.valuePipe.transform(
          this.state.totalCheckoutValue
        )}`,
      },
      {
        tab: 'Betalingsbetingelser',
        title: 'Villkor för direktbetalning',
        descriptions: [{ text: this.currentLoanTermsStr }],
      },
    ];
  }

  zeroInterestString(loanOption: LoanOption): string {
    return loanOption.nominalInterest === 0 ? '0% renter' : '';
  }

  buildLoanOptionTitle(loanOption: LoanOption): string {
    return `${loanOption.monthlyCost.toLocaleString('no')} NOK/mnd i ${
      loanOption.numberOfMonths
    } måneder`;
  }

  getBulletPoints(item: LoanOption): string[] {
    return [`Årsränta ${this.getFormattedInterest(item.nominalInterest)}%`];
  }

  getFormattedInterest(interest: number): string {
    return (interest * 100).toFixed(2);
  }

  getPresentedLoanOptions(): void {
    if (this.equalOrLessThanThreeLoanOptions) {
      this.presentedLoanOptions = this.availableloanOptions;
      return;
    }

    const middleMostIndex: number = Math.floor(
      this.availableloanOptions.length / 2
    );

    const longest: LoanOptionVM = this.availableloanOptions[0];
    const middlemost: LoanOptionVM = this.availableloanOptions[middleMostIndex];
    const shortest: LoanOptionVM =
      this.availableloanOptions[this.availableloanOptions.length - 1];

    this.presentedLoanOptions = [longest, middlemost, shortest];

    if (this.preferredInstallmentPlanDurations?.length > 0) {
      this.presentedLoanOptions = this.availableloanOptions.filter(
        (loanOption: LoanOptionVM) =>
          this.preferredInstallmentPlanDurations.includes(
            loanOption.numberOfMonths
          )
      );
    }

    // if we have selected installment, replace closest
    if (this.selectedLoanOption) {
      const closestLoanOption: LoanOptionVM = this.getClosestLoanOption(
        this.presentedLoanOptions,
        this.selectedLoanOption.monthlyCost
      );

      // replace closest installment with selected loan option
      this.presentedLoanOptions = this.presentedLoanOptions.map(
        (loanOption: LoanOptionVM) =>
          loanOption.monthlyCost === closestLoanOption.monthlyCost
            ? this.selectedLoanOption
            : loanOption
      );
    }
  }

  toggleModal(): void {
    this.modalActive = !this.modalActive;
  }

  viewAllLoanOptions(): void {
    this.expanded = true;
    this.presentedLoanOptions = this.availableloanOptions;
  }

  viewReducedLoanOptions(): void {
    this.expanded = false;
    this.getPresentedLoanOptions();
  }

  getClosestLoanOption(
    loanOption: LoanOptionVM[],
    selectedLoanOptionMonthlyCost: number
  ): LoanOptionVM {
    const monthlyCostDiff = (monthlyCost: number) =>
      Math.abs(monthlyCost - selectedLoanOptionMonthlyCost);
    return loanOption.reduce((prev: LoanOptionVM, curr: LoanOptionVM) =>
      monthlyCostDiff(curr.monthlyCost) < monthlyCostDiff(prev.monthlyCost)
        ? curr
        : prev
    );
  }

  buildLoantermsString(selectedLoanOption: LoanOptionVM): string {
    const { effectiveInterest, nominalInterest, initialFee, monthlyFee } =
      selectedLoanOption;

    return `Beregninger: Totalt utbetalingsbeløp er ${this.state.totalCheckoutValue.amount.toLocaleString(
      'no'
    )} NOK og effektiv rente er ${effectiveInterest}%. Årlig rente ${nominalInterest}%, etableringsgebyr ${initialFee.toLocaleString(
      'no'
    )} NOK og administrasjonsgebyr ${monthlyFee.toLocaleString('no')} NOK/mån`;
  }

  transformDtoTOVM(loanOptions: LoanOption[]): LoanOptionVM[] {
    return loanOptions.map((loanOption) => ({
      badgeText: this.zeroInterestString(loanOption),
      bulletPoints: this.getBulletPoints(loanOption),
      color: '#00ac84',
      link: { text: 'Se avtale', onClick: this.toggleModal.bind(this) },
      monthlyCost: loanOption.monthlyCost,
      nominalInterest: loanOption.nominalInterest,
      numberOfMonths: loanOption.numberOfMonths,
      radioButtonColor: '#db305e',
      title: this.buildLoanOptionTitle(loanOption),
      termId: loanOption.termId,
      effectiveInterest: loanOption.effectiveInterest,
      initialFee: loanOption.initialFee,
      monthlyFee: loanOption.monthlyFee,
      totalAmount: loanOption.totalAmount,
      loanTermsURL: loanOption.loanTermsURL,
    }));
  }
}
