import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.css'],
})
export class ErrorDialogComponent implements OnInit {
  public reason: string = '';
  public heading: string = '';
  public buttons: any[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ErrorDialogComponent>
  ) {
    this.buttons = data.buttons;
    this.heading = data.heading || 'Betalningen kunde inte genomföras';
  }

  ngOnInit() {
    this.displayReason();
  }

  handleButtonClick(button) {
    button.callback.call();
    this.closeSelf();
  }

  private closeSelf() {
    this.dialogRef.close();
  }

  displayReason() {
    this.reason = this.data.message ? this.data.message : '';
  }
}
