import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  OnDestroy,
  Output,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'zaver-token-input',
  templateUrl: './token-input.component.html',
  styleUrls: ['./token-input.component.scss'],
})
export class TokenInputComponent implements OnInit, OnDestroy {
  constructor() {}

  @Input() showError = false;
  @Input() isMobile = false;
  @Input() IOS = false;

  @Output() valueChange = new EventEmitter<string>();

  @ViewChild('input') inputField: ElementRef;

  public form: FormGroup;
  // TOOD:  Error:
  // Property '_parentOrParents' is protected but type 'Subscription' is not a class derived from 'Subscription'.
  formSubscription; //Subscription;

  ngOnInit(): void {
    this.form = new FormGroup({
      token: new FormControl('', [Validators.required]),
    });

    this.formSubscription = this.form.controls.token.valueChanges.subscribe(
      (value) => {
        const onlyNumbers = value.replace(/[^0-9]+/g, '');
        this.form.patchValue({ token: onlyNumbers }, { emitEvent: false });
        this.valueChange.emit(onlyNumbers);
      }
    );
  }

  ngAfterViewInit() {
    if (!this.isMobile) {
      this.inputField.nativeElement.focus();
    }
  }

  ngOnDestroy(): void {
    this.formSubscription.unsubscribe();
  }

  onPaste(event) {
    event.preventDefault();
    let clipboardData = event.clipboardData.getData('text');
    if (clipboardData.length > 6) {
      clipboardData = clipboardData.slice(0, 6);
    }
    this.form.controls.token.setValue(clipboardData);
  }
}
