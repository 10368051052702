<div class="page-body">
  <div class="banner">
    <img
      src="assets/icon/Arrow-Left.svg"
      alt=""
      class="back-button"
      (click)="reroute()"
    />
    <span class="back-text" (click)="reroute()">Tillbaka</span>
  </div>

  <div class="contents-wrapper">
    <div class="contents-container">
      <div class="page-icon">
        <img src="/assets/icon/Icon-User-Single-Shadow.svg" alt="" />
      </div>

      <div class="page-text">
        Ange ditt personnummer för att fortsätta betalningen.
      </div>

      <form [formGroup]="form">
        <div class="input-container">
          <zaver-animated-form-input
            [size]="'large'"
            [ariaLabel]="'Ange personnummer'"
            [formControlName]="'pnr'"
            [error]="form.controls.pnr.invalid && showError"
            inputMode="numeric"
            placeholder="ååååmmddnnnn"
            errorMessage="Vänligen ange ett giltigt personnummer"
            [errorIcon]="'assets/icon/icon_error.svg'"
          ></zaver-animated-form-input>
        </div>
      </form>
      <div class="pr-info" *ngIf="paymentRequest">
        <label> Totalbelopp </label>
        <label>
          {{
            paymentRequest.amount.toLocaleString('sv', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })
          }}
          {{ paymentRequest.currency }}
        </label>
      </div>

      <zaver-primary-button text="Välj din bank" (click)="continue()">
      </zaver-primary-button>
    </div>
  </div>
</div>
