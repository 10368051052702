<div class="page-body" *ngIf="loading">
  <div class="contents-wrapper">
    <div class="contents-container">
      <app-spinner></app-spinner>
    </div>
  </div>
</div>

<div class="page-body" *ngIf="!loading">
  <div class="contents-wrapper">
    <div class="contents-container">
      <div class="img-container">
        <img src="assets/icon/smiley-sad-nerd.svg" class="smiley-icon" alt="" />
      </div>

      <p class="heading">{{ state.interruptionDetails.heading }}</p>

      <p class="info-text">{{ state.interruptionDetails.message }}</p>

      <div class="button-wrapper">
        <button class="secondary-button" (click)="handleCtaClick()">
          {{ state.interruptionDetails.ctaLabel }}
        </button>
      </div>
    </div>
  </div>
</div>
