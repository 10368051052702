export interface BankIdSignatureStatusDto {
  status: string;
  autoStartToken?: string;
  message: string;
}

export const BankIdSignatureStatusIdentifier = {
  STARTED: 'STARTED',
  FAILED: 'FAILED',
  COMPLETED_SUCCESSFULLY: 'COMPLETED_SUCCESSFULLY',
  IN_PROGRESS: 'IN_PROGRESS'
};
