import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-de-wrapper',
  templateUrl: './de-wrapper.component.html',
  styleUrls: ['./de-wrapper.component.scss'],
})
export class DeWrapperComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
