<div *ngIf="!loadingDetails" class="page-body">
  <div class="contents-wrapper">
    <div class="contents-container">
      <div *ngIf="hasRegisteredAccount()">
        <div>Bekräfta kontoval:</div>
        <dl>
          <dt>kontonummer:</dt>
          <dd>{{ details.approvedAccount.accountNumber }}</dd>
        </dl>
        <zaver-primary-button
          (click)="confirmAccount()"
          [text]="'Bekräfta konto'"
        ></zaver-primary-button>
        <a (click)="changeAccount()">Jag vill betala från ett annat konto</a>
      </div>
      <div *ngIf="!hasRegisteredAccount()"></div>
      <div class="expanding-box-conforming">
        <h2 id="page-title">Legitimera dig hos din bank och välj konto</h2>

        <zaver-primary-button (click)="openTinkLink()"> </zaver-primary-button>
      </div>
    </div>
  </div>
</div>

<div class="page-body" *ngIf="loadingDetails">
  <div class="contents-wrapper">
    <div class="contents-container">
      <app-spinner></app-spinner>
    </div>
  </div>
</div>
