<div class="page-body">

  <div class="go-back-wrapper">
    <img
    src="assets/img/icon/Arrow-Left.svg"
    class="go-back"
    (click)="reroute()">

    <span class="back-text" (click)="reroute()">
      Tillbaka
    </span>
  </div>

    <div class="expanding-box-conforming">
        <h2 id="page-title" *ngIf="!!accounts">
          Välj konto
        </h2>

      <div *ngIf="loading">
        <app-spinner></app-spinner>
      </div>

      <div class="details-list-wrapper" *ngIf="!loading">

        <div *ngIf="!!accounts" class="details-list">
          <ng-container *ngFor="let account of accounts">
            <hr class="account-info-divider"/>
            <div class="account-info-container" (click)="selectAccount(account)">

              <img src="{{account.bankLogoUrl}}" class="bank-logo">

              <div class="details-item-account-info">
                <div>
                  <span class="account-name">{{account.accountName}}</span>
                </div>
                <div class="account-number-wrapper">
                  <span class="account-number">
                    {{account.clearingNumber}} - {{account.accountNumber}}
                  </span>
                </div>
              </div>

              <div>
                <span class="account-balance">
                  🦄
                  <!--
                  SEK {{account.balance}}
                  -->
                </span>
              </div>
            </div>
          </ng-container>
          <hr class="account-info-divider" />
        </div>

        <div *ngIf="!accounts || accounts.length === 0" class="no-accounts-message">
          Tyvärr finns det inga konton att visa som är giltiga för utbetalning.
          Klicka på knappen nedan för att återgå till bankmenyn.

          <div class="return-button" (click)="reroute()">
            Tillbaka
          </div>

        </div>

    </div>
  </div>
</div>
