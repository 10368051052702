<app-de-wrapper *ngIf="loading">
  <app-spinner></app-spinner>
</app-de-wrapper>

<app-de-wrapper *ngIf="!loading">
  <img class="icon-user-img" src="assets/icon/Icon-E-mail-Circle.svg" />

  <h1 class="heading">Prüfen Sie Ihr E-Mail-Postfach</h1>

  <p class="info-text">
    Bitte überprüfen Sie Ihre E-Mail und<br />
    übermitteln Sie den 6-stelligen Code hier.
  </p>

  <div class="email-wrapper">{{ email }}</div>

  <div class="token-input-wrapper">
    <zaver-token-input
      (valueChange)="onOtpChange($event)"
      [showError]="showError"
    ></zaver-token-input>
  </div>

  <div class="display-error" *ngIf="showError">
    <img src="assets/icon/icon_error.svg" alt="" class="info-icon" />
    <p>Der Verifizierungscode ist falsch oder&nbsp;bereitsabgelaufen</p>
  </div>

  <app-general-error
    style="margin-top: 16px"
    *ngIf="!showError && noResponseError"
  ></app-general-error>

  <div class="resend-code" [ngClass]="showError ? 'error' : ''">
    <zaver-button-link
      [text]="'E-Mail erneut senden'"
      (click)="resendCode()"
      [disabled]="navigationInProgress"
      [interactive]="true"
    ></zaver-button-link>
  </div>

  <div class="footer-wrapper">
    <div class="footer-container">
      <div class="button-container">
        <zaver-primary-button
          text="Weiter"
          [disabled]="navigationInProgress"
          (click)="handleCtaClick()"
        ></zaver-primary-button>
      </div>
    </div>
  </div>
</app-de-wrapper>
