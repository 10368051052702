import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SERVER_BASE_URL } from '../../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { CreditOptionDto } from '../../model/credit/credit-option.dto';
import { StandardCheckoutResponse } from '../user-service/user.service';
import { VippsRequestParams, VippsResponse } from '../vipps/vipps-response.dto';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class NoUserService {
  private readonly httpReqOptions = { withCredentials: true };

  constructor(private http: HttpClient, private dialogService: MatDialog) {}

  initAuth(nin: string): Promise<AuthUrlResponse> {
    let params = new HttpParams();

    params = params.append('nin', nin);
    return this.http
      .get<AuthUrlResponse>(`${SERVER_BASE_URL}/user/no/noBankID/initAuth/`, {
        withCredentials: true,
        params: params,
      })
      .toPromise();
  }

  authorize(): Promise<StandardCheckoutResponse> {
    return this.http
      .post<StandardCheckoutResponse>(
        `${SERVER_BASE_URL}/user/no/noBankID/authentication/`,
        {
          withCredentials: true,
        }
      )
      .toPromise();
  }

  registerEmail(email: string): Promise<StandardCheckoutResponse> {
    let emailWrapper: EmailWrapper = {
      email: email,
    };

    return this.http
      .post<StandardCheckoutResponse>(
        `${SERVER_BASE_URL}/user/no/registerEmail/`,
        emailWrapper,
        {
          withCredentials: true,
        }
      )
      .toPromise();
  }

  selectInstallmentPlan(loanOfferId: string): Promise<String> {
    const loanOffer = {
      id: loanOfferId,
    };

    return this.http
      .post<string>(
        `${SERVER_BASE_URL}/user/no/selectInstallmentPlan/`,
        loanOffer,
        {
          withCredentials: true,
        }
      )
      .toPromise();
  }

  vippsDeposit(param: VippsRequestParams): Promise<VippsResponse> {
    return this.http
      .post<VippsResponse>(
        `${SERVER_BASE_URL}/user/no/vipps/initiatePayment/`,
        {
          phoneNumber: param.phoneNumber,
          isMobile: param.isMobile,
          fallbackUrl: param.fallbackUrl,
          resumeTokenId: param.resumeTokenId,
        },
        { withCredentials: true }
      )
      .toPromise();
  }

  vippsGetStatus(): Observable<VippsResponse> {
    return this.http.post<VippsResponse>(
      `${SERVER_BASE_URL}/user/no/vipps/checkStatusAndSettlePayment/`,
      {
        withCredentials: true,
      }
    );
  }

  vippsCancel(vippsId: string): Observable<StandardCheckoutResponse> {
    let params = new HttpParams();
    params = params.append('vippsId', vippsId);

    return this.http.post<StandardCheckoutResponse>(
      `${SERVER_BASE_URL}/user/no/vipps/cancelPayment/`,
      {
        withCredentials: true,
        params: params,
      }
    );
  }

  openVippsMobile(deepLinkUrl: string): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(() => {
        window.location.assign(deepLinkUrl);
        resolve();
      }, 200);
    });
  }

  openVippsDesktop(url: string): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(() => {
        const deepLink: string = url;
        window.location.assign(url);
        resolve();
      }, 200);
    });
  }

  commit(): Promise<StandardCheckoutResponse> {
    return this.http
      .post<StandardCheckoutResponse>(`${SERVER_BASE_URL}/user/no/commit`, {
        withCredentials: true,
      })
      .toPromise();
  }
}

export class EmailWrapper {
  email: string;
}

export class AuthUrlResponse {
  accessUrl: string;
  id: string;
}
