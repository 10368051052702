<div
  class="container"
  [ngClass]="{
    'top-item': isFirstItem,
    'bottom-item': isLastItem,
    selected: selected
  }"
>
  <div class="main-information-wrapper">
    <div class="button-container">
      <zaver-radio-button
        [selected]="selected"
        [selectedColor]="radioButtonColor"
      ></zaver-radio-button>
    </div>

    <div class="text-container">
      <div class="title">
        {{ title }}
      </div>
    </div>

    <div
      class="img-container"
      [class.small-mobile-hide]="!!badgeText"
      *ngIf="!!imgSrc || !!badgeText"
    >
      <zaver-badge
        *ngIf="!!badgeText"
        badgeText="{{ badgeText }}"
        color="{{ color }}"
      >
      </zaver-badge>
    </div>
  </div>

  <div class="bullet-points-wrapper" *ngIf="selected">
    <div class="bullet" *ngFor="let bullet of bulletPoints">
      <img src="/assets/icon/Checkmark.svg" alt="" />
      <p>{{ bullet }}</p>
    </div>
  </div>

  <div class="button-link" *ngIf="selected && link">
    <zaver-button-link
      [text]="link.text"
      (click)="link.onClick()"
    ></zaver-button-link>
  </div>
</div>
