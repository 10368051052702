import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SERVER_BASE_URL } from '../../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { CreditOptionDto } from '../../model/credit/credit-option.dto';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly httpReqOptions = { withCredentials: true };

  constructor(private http: HttpClient, private dialogService: MatDialog) {}

  submitForm(form: UserRegistrationForm): Promise<StandardCheckoutResponse> {
    return this.http
      .post<StandardCheckoutResponse>(
        `${SERVER_BASE_URL}/user/de/userRegistrationForm/`,
        form,
        { withCredentials: true }
      )
      .toPromise();
  }

  getActiveUserInfo(): Promise<UserRegistrationDto> {
    let params = new HttpParams();

    return this.http
      .get<UserRegistrationDto>(
        `${SERVER_BASE_URL}/user/de/getActiveUserInfo/`,
        {
          ...this.httpReqOptions,
          params: params,
        }
      )
      .toPromise();
  }

  verifyEmail(code: string): Promise<StandardCheckoutResponse> {
    let verificationCode: VerificationCode = {
      code: code,
    };

    return this.http
      .post<StandardCheckoutResponse>(
        `${SERVER_BASE_URL}/user/de/verifyEmail/`,
        verificationCode,
        {
          withCredentials: true,
        }
      )
      .toPromise();
  }

  verifyPhone(code: string): Promise<StandardCheckoutResponse> {
    let verificationCode: VerificationCode = {
      code: code,
    };

    return this.http
      .post<StandardCheckoutResponse>(
        `${SERVER_BASE_URL}/user/de/verifyPhone/`,
        verificationCode,
        {
          withCredentials: true,
        }
      )
      .toPromise();
  }

  verifyUser(
    code: string,
    paymentRequestId: string
  ): Promise<StandardCheckoutResponse> {
    let verificationCode: UserVerificationData = {
      paymentRequestId: paymentRequestId,
      emailVerificationCode: code,
    };

    console.log('Calling verify user');

    return this.http
      .post<StandardCheckoutResponse>(
        `${SERVER_BASE_URL}/user/de/verifyUser/`,
        verificationCode,
        {
          withCredentials: true,
        }
      )
      .toPromise();
  }

  registerEmailAndPhone(
    form: EmailAndPhone
  ): Promise<StandardCheckoutResponse> {
    return this.http
      .post<StandardCheckoutResponse>(
        `${SERVER_BASE_URL}/user/de/registerEmailAndPhone/`,
        form,
        { withCredentials: true }
      )
      .toPromise();
  }

  checkExistingUser(form: EmailAndPhone): Promise<StandardCheckoutResponse> {
    return this.http
      .post<StandardCheckoutResponse>(
        `${SERVER_BASE_URL}/user/de/checkExistingUser/`,
        form,
        { withCredentials: true }
      )
      .toPromise();
  }

  registerUser(form: UserRegistrationForm): Promise<StandardCheckoutResponse> {
    return this.http
      .post<StandardCheckoutResponse>(
        `${SERVER_BASE_URL}/user/de/registerUser/`,
        form,
        {
          withCredentials: true,
        }
      )
      .toPromise();
  }

  sendPurchaseConfirmationEmail(): Promise<StandardCheckoutResponse> {
    return this.http
      .post<StandardCheckoutResponse>(
        `${SERVER_BASE_URL}/user/de/sendPurchaseConfirmationEmail/`,
        {
          withCredentials: true,
        }
      )
      .toPromise();
  }

  sendEmailVerificationEmail(): Promise<StandardCheckoutResponse> {
    return this.http
      .post<StandardCheckoutResponse>(
        `${SERVER_BASE_URL}/user/de/sendEmailVerificationEmail/`,
        {
          withCredentials: true,
        }
      )
      .toPromise();
  }

  resendEmailVerificationEmail(): Promise<StandardCheckoutResponse> {
    return this.http
      .post<StandardCheckoutResponse>(
        `${SERVER_BASE_URL}/user/de/resendEmailVerificationEmail/`,
        {
          withCredentials: true,
        }
      )
      .toPromise();
  }

  sendAccountRestorationEmail(): Promise<StandardCheckoutResponse> {
    return this.http
      .post<StandardCheckoutResponse>(
        `${SERVER_BASE_URL}/user/de/sendAccountRestorationEmail/`,
        {
          withCredentials: true,
        }
      )
      .toPromise();
  }

  restoreAccount(code: string): Promise<StandardCheckoutResponse> {
    let verificationCode: VerificationCode = {
      code: code,
    };

    return this.http
      .post<StandardCheckoutResponse>(
        `${SERVER_BASE_URL}/user/de/restoreAccount/`,
        verificationCode,
        {
          withCredentials: true,
        }
      )
      .toPromise();
  }

  updatePhone(code: string): Promise<StandardCheckoutResponse> {
    let verificationCode: UpdatePhoneNumber = {
      phoneNumber: code,
    };

    return this.http
      .post<StandardCheckoutResponse>(
        `${SERVER_BASE_URL}/user/de/updatePhone/`,
        verificationCode,
        {
          withCredentials: true,
        }
      )
      .toPromise();
  }

  resendPhoneVerificationSms(): Promise<StandardCheckoutResponse> {
    return this.http
      .post<StandardCheckoutResponse>(
        `${SERVER_BASE_URL}/user/de/resendPhoneVerificationSms/`,
        {
          withCredentials: true,
        }
      )
      .toPromise();
  }

  resendAccountRestorationEmail(): Promise<StandardCheckoutResponse> {
    return this.http
      .post<StandardCheckoutResponse>(
        `${SERVER_BASE_URL}/user/de/resendAccountRestorationEmail/`,
        {
          withCredentials: true,
        }
      )
      .toPromise();
  }

  resendPurchaseConfirmationEmail(): Promise<StandardCheckoutResponse> {
    return this.http
      .post<StandardCheckoutResponse>(
        `${SERVER_BASE_URL}/user/de/resendPurchaseConfirmationEmail/`,
        {
          withCredentials: true,
        }
      )
      .toPromise();
  }

  fetchFtsReport(transactionId: string): Promise<StandardCheckoutResponse> {
    let params = new HttpParams();
    if (transactionId) {
      params = params.append('transactionId', transactionId);
    }

    return this.http
      .get<StandardCheckoutResponse>(
        `${SERVER_BASE_URL}/user/de/fetchFtsResult/`,
        {
          ...this.httpReqOptions,
          params: params,
        }
      )
      .toPromise();
  }

  getX2saSession(): Promise<Xs2aSessionDto> {
    return this.http
      .get<Xs2aSessionDto>(`${SERVER_BASE_URL}/user/de/getFtsSessionKey`)
      .toPromise();
  }

  logFtsError(errorCode: string, messages: string, recoverable: string) {
    let params = new HttpParams();
    if (errorCode) {
      params = params.append('errorCode', errorCode);
    }

    if (messages) {
      params = params.append('messages', messages);
    }

    if (recoverable) {
      params = params.append('recoverable', recoverable);
    }

    return this.http.get(`${SERVER_BASE_URL}/user/de/logFtsError/`, {
      ...this.httpReqOptions,
      params: params,
    });
  }

  fetchRegisteredEmail(): Promise<EmailDto> {
    return this.http
      .get<EmailDto>(`${SERVER_BASE_URL}/user/email`, {
        withCredentials: true,
      })
      .toPromise();
  }

  registerEmail(email: string): Promise<EmailDto> {
    const emailDto: EmailDto = {
      email: email,
    };

    return this.http
      .post<EmailDto>(`${SERVER_BASE_URL}/user/email/register`, emailDto, {
        withCredentials: true,
      })
      .toPromise();
  }

  selectInstallmentPlan(loanOfferId: string): Promise<String> {
    const loanOffer = {
      id: loanOfferId,
    };

    return this.http
      .post<string>(
        `${SERVER_BASE_URL}/user/de/selectInstallmentPlan/`,
        loanOffer,
        {
          withCredentials: true,
        }
      )
      .toPromise();
  }

  cancelPayment(): Promise<StandardCheckoutResponse> {
    return this.http
      .post<StandardCheckoutResponse>(
        `${SERVER_BASE_URL}/user/de/cancelPayment/`,
        {
          withCredentials: true,
        }
      )
      .toPromise();
  }
}

export interface UpdatePhoneNumber {
  phoneNumber: string;
}
export class InstallmentOptionsDto {
  preferredInstallmentPlanDurations: number[];
  installmentOptions: CreditOptionDto[];
}

export class UserRegistrationForm {
  firstName: string;
  familyName: string;
  streetName: string;
  houseNumber: string;
  zipCode: string;
  cityName: string;
  countryCode: string;
  birthDate: string;
  email: string;
}

export interface UserFormData {
  value: string;
  editable: boolean;
}

export class UserRegistrationDto {
  firstName: UserFormData;
  familyName: UserFormData;
  streetName: UserFormData;
  houseNumber: UserFormData;
  zipCode: UserFormData;
  cityName: UserFormData;
  countryCode: UserFormData;
  birthDate: string;
  mayEditBirthDate: boolean;
  email: UserFormData;
  phoneNumber: UserFormData;
}

export class StandardCheckoutResponse {
  success: boolean;
  error: boolean;
  rejected: boolean;
}

export interface UserRegistrationVM {
  firstName: UserFormDataVM;
  familyName: UserFormDataVM;
  streetName: UserFormDataVM;
  houseNumber: UserFormDataVM;
  zipCode: UserFormDataVM;
  cityName: UserFormDataVM;
  countryCode: UserFormDataVM;
  birthDate: UserFormDataVM;
  email: UserFormDataVM;
  phoneNumber: UserFormDataVM;
}
export interface UserFormDataVM {
  value: string;
  editable: boolean;
}

export class UserInfo {
  userExists: boolean;
  formInfo: UserRegistrationForm;
}

export class Xs2aSessionDto {
  sessionKey: string;
  transactionId: string;
}

export class EmailAndPhone {
  email: string;
  phoneNumber: string;
}

export class VerificationCode {
  code: string;
}

export class UserVerificationData {
  paymentRequestId: string;
  emailVerificationCode: string;
}

export interface EmailDto {
  email: string;
}
