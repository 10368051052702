import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '@app/service/user-service/user.service';
import { StateService } from 'src/app/service/state/state.service';
import { RouteComponent } from '../../route.component';
import { Location } from '@angular/common';
import {
  DeviceOS,
  UserAgentService,
} from '@app/service/user-agent/user-agent.service';
import { HeaderService } from '@app/service/header/header.service';

@Component({
  selector: 'app-de-phone-verification',
  templateUrl: './de-phone-verification.component.html',
  styleUrls: [
    '../../page-shared.css',
    './de-phone-verification.component.scss',
  ],
})
export class DePhoneVerificationComponent
  extends RouteComponent
  implements OnInit
{
  loading = true;
  phoneNumberString: string;
  navigationInProgress = false;
  resendCodeInProgress = false;
  showError = false;
  verificationCode: string;
  validVerificationCodeFormat: boolean;
  isMobile: boolean;
  IOS: boolean;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected stateService: StateService,
    protected headerService: HeaderService,
    private userService: UserService,
    private location: Location,
    private userAgentService: UserAgentService
  ) {
    super(router, route, stateService, headerService);
  }

  onOtpChange(e: any) {
    this.showError = false;
    this.verificationCode = e;
    this.validVerificationCodeFormat = e.length === 6;
    if (this.validVerificationCodeFormat) {
      this.handleCtaClick();
    }
  }

  ngOnInit(): void {
    this.isMobile = this.userAgentService.isMobile();
    this.IOS = this.userAgentService.getDeviceOS() === DeviceOS.I_OS;
    this.getinfo();
  }

  back() {
    this.location.back();
  }

  async getinfo(): Promise<void> {
    try {
      const userInfo = await this.userService.getActiveUserInfo();
      const phoneNumber = userInfo.phoneNumber.value;
      this.phoneNumberString = `******${phoneNumber.slice(-4)}`;
      this.loading = false;
    } catch (err) {
      this.handleError(err);
    }
  }

  async reSendCode(): Promise<void> {
    if (this.resendCodeInProgress) {
      return;
    } else {
      try {
        this.resendCodeInProgress = true;
        await this.userService.resendPhoneVerificationSms();
        this.resendCodeInProgress = false;
      } catch (err) {
        this.resendCodeInProgress = false;
        this.handleError(err);
      }
    }
  }

  async verifyCode(): Promise<void> {
    try {
      const resp = await this.userService.verifyPhone(
        (this.verificationCode && this.verificationCode.trim()) || ''
      );
      if (resp.success) {
        await this.navigate();
      } else {
        this.showError = true;
      }
    } catch (err) {
      this.handleError(err);
    }
  }

  async handleCtaClick() {
    this.resetError();
    if (this.navigationInProgress || !this.validVerificationCodeFormat) {
      this.showError = true;
    } else {
      this.navigationInProgress = true;
      await this.verifyCode();
    }
    this.navigationInProgress = false;
  }
}
