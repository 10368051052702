import { Injectable } from '@angular/core';
import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import { Observable } from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import { LoggingService } from '@app/service/logging-service/logging.service';
import {versions} from '../../environments/versions';

/**
 * Attach the payment request ID to the X-PRID header of every outgoing
 * request. Done in favor of using a session cookie or session attribute
 * as Google chrome abuses the http session standards by persisting
 * session data after the user closes the browser.
 */
@Injectable()
export class PaymentRequestIdInterceptor implements HttpInterceptor  {

  constructor(private route: ActivatedRoute, private loggingService : LoggingService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const id: string = this.route.snapshot.queryParams.id;

    // Set the id header to default to an empty string as the Angular HttpClient
    // will throw an error if a header value is undefined
    let headers = new HttpHeaders();
    headers = headers.append('X-PRID', id ? id : '');
    headers = headers.append('X-FRONTEND-CONTEXT-ID', this.loggingService.getContextID());
    headers = headers.append('X-FRONTEND-PERSISTENT-ID', this.loggingService.getPersistentId());
    headers = headers.append('x-client-version', versions.branch + ':' + versions.revision);


    const cloneReq = req.clone({
       headers: headers
      });

    return next.handle(cloneReq);
  }
}

