<app-de-wrapper *ngIf="internalServerError">
  <div style="display: flex; flex-direction: column; align-items: center">
    <img class="general-error-icon" src="assets/icon/smiley-sad-nerd.svg" />
    <h3 class="general-error-text">Etwas ist schief gelaufen.</h3>
  </div>
</app-de-wrapper>

<app-de-wrapper *ngIf="loading">
  <app-spinner></app-spinner>
</app-de-wrapper>

<div class="page-body" *ngIf="!loading && !internalServerError">
  <div class="contents-wrapper">
    <div class="contents-container">
      <div class="installments-container">
        <ng-container
          *ngFor="let loanOption of presentedLoanOptions; let i = index"
        >
          <app-installments-item
            [isFirstItem]="i === 0"
            [isLastItem]="
              i === availableloanOptions.length - 1 &&
              equalOrLessThanThreeLoanOptions
            "
            [title]="loanOption.title"
            [selected]="loanOption === selectedLoanOption"
            (click)="
              loanOption !== selectedLoanOption && selectInstallment(loanOption)
            "
            [bulletPoints]="loanOption.bulletPoints"
            [link]="loanOption.link"
            [radioButtonColor]="loanOption.radioButtonColor"
            [color]="loanOption.color"
            [badgeText]="loanOption.badgeText"
          ></app-installments-item>
        </ng-container>
        <button
          *ngIf="!equalOrLessThanThreeLoanOptions"
          class="installments-button"
          (click)="expanded ? viewReducedLoanOptions() : viewAllLoanOptions()"
        >
          {{ expanded ? 'Weniger anzeigen' : 'Mehr anzeigen' }}
          <img
            style="margin-left: 4px; display: inline-block"
            [src]="
              expanded
                ? 'assets/icon/Arrow-Up.svg'
                : 'assets/icon/Arrow-Down.svg'
            "
          />
        </button>
      </div>

      <p class="agb-info">
        Indem Sie Kauf abschließen klicken, stimmen<br />
        Sie den
        <a
          [href]="state?.checkoutCustomizations?.merchantTermsUrl"
          target="_blank"
          >AGB</a
        >
        von {{ state.checkoutCustomizations.merchantName }} zu.
      </p>

      <div class="bottom-secetion-wrapper">
        <div class="total-amount" *ngIf="state.totalCheckoutValue">
          <p>Gesamtbetrag:</p>
          <p>{{ state.totalCheckoutValue | value }}</p>
        </div>

        <div class="button-container">
          <zaver-primary-button
            [text]="'Kauf abschließen'"
            [disabled]="navigationInProgress"
            (click)="handleCtaClick()"
          ></zaver-primary-button>
        </div>

        <p class="loan-terms-string">{{ currentLoanTermsStr }}</p>
      </div>
    </div>
  </div>
</div>

<zaver-checkout-modal
  [buttonText]="'Schließen'"
  *ngIf="selectorItems"
  id="details-modal"
  [active]="modalActive"
  [buttonText]="'Schließen'"
  [logo]="'assets/img/Zaver_Logotype_Circle_Coal_DE.svg'"
  (onClose)="toggleModal()"
>
  <app-selector #selector [selectorItems]="selectorItems"></app-selector>
</zaver-checkout-modal>
