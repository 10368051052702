<app-de-wrapper *ngIf="internalServerError">
  <div style="display: flex; flex-direction: column; align-items: center">
    <img class="general-error-icon" src="assets/icon/smiley-sad-nerd.svg" />
    <h3 class="general-error-text">Etwas ist schief gelaufen.</h3>
  </div>
</app-de-wrapper>

<app-de-wrapper *ngIf="loading">
  <app-spinner></app-spinner>
</app-de-wrapper>

<app-de-wrapper *ngIf="!loading && !internalServerError">
  <h1 class="heading">Bitte verifizieren Sie Ihre Handynummer</h1>

  <p class="info-text">
    Ein Verifizierungscode wird an die
    angegebene&nbsp;Handynummer&nbsp;gesendet.
  </p>

  <form [formGroup]="form">
    <div class="input-container">
      <zaver-animated-form-input
        [size]="'large'"
        [placeholder]="'Handynummer'"
        [formControlName]="'phoneNumber'"
        [error]="form.controls.phoneNumber.invalid && showError"
        [errorMessage]="'Bitte geben Sie eine gültige Handynummer ein'"
        [inputMode]="'tel'"
        [autoComplete]="'tel'"
        [inputType]="'tel'"
        [showContent]="true"
        [errorIcon]="'assets/icon/icon_error.svg'"
        ><zaver-select-country
          [buttonId]="'select-country-code'"
          [items]="AVAILABLECOUNTRYCODES"
          (itemSelected)="setCountry($event)"
          [selectedItem]="selectedcountry"
        ></zaver-select-country>
      </zaver-animated-form-input>
    </div>
  </form>

  <div class="footer-wrapper">
    <div class="footer-container">
      <div class="button-container">
        <zaver-primary-button
          [text]="'Weiter'"
          [disabled]="navigationInProgress"
          (click)="handleCtaClick()"
        ></zaver-primary-button>
      </div>
    </div>
  </div>
</app-de-wrapper>
