import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { PaymentRequestDto } from '../../model/payment-request/payment-request.dto';
import { AppDeepLinkService } from 'src/app/service/app-deeplink/app-deep-link.service';
import { SwishService } from 'src/app/service/swish/swish.service';
import {
  SwishError,
  getErrorMessage,
  SwishErrorCode,
} from 'src/app/service/swish/swish-response.dto';
import { Router } from '@angular/router';
import { routeNames } from 'src/assets/val/route-constants';
import { UserAgentService } from 'src/app/service/user-agent/user-agent.service';
import { QueryParamsService } from 'src/app/service/query-params/query-params.service';
import { LoggingService } from 'src/app/service/logging-service/logging.service';
import {
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  CountryCodes,
  formatPhoneNumber,
  phoneNumberValidator,
} from 'web-component-library/projects/component-library/src/public-api';

@Component({
  selector: 'app-swish',
  templateUrl: './swish.component.html',
  styleUrls: ['./swish.component.css', '../page-shared.css'],
})
export class SwishComponent implements OnInit, OnDestroy {
  public phoneNumber: string;
  public isPhone: boolean;
  public error: SwishError[] = null;
  private token: string;

  //TEMP
  public amount: number;
  public isPolling2: boolean = true;
  public alertStatusMessage: string = 'Öppna Swish appen i din mobil';

  private paymentRequest: PaymentRequestDto;
  public paymentInProgress: boolean;
  initialPhone: string;
  returningFromSwishApp: boolean = false;

  inputValid: boolean = false;
  validators: ValidatorFn[];
  showError: boolean = false;

  activityTimer: any = null;
  loading: boolean = true;
  formLayout: boolean = false;
  manualDeeplinkEnabled: boolean = false;
  mobileStatusMessage: string = 'Förbereder betalning...';
  paymentStatusOtherDevice: string;
  form: FormGroup;

  constructor(
    private store: Store,
    private swishService: SwishService,
    private deepLinkService: AppDeepLinkService,
    private router: Router,
    private userAgentService: UserAgentService,
    private queryParamsService: QueryParamsService,
    private loggingService: LoggingService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.validators = [
      Validators.required,
      phoneNumberValidator(CountryCodes.ALL),
    ];

    this.paymentRequest = this.store.snapshot().paymentRequest.request;
    this.amount = this.paymentRequest.amount;

    this.phoneNumber = this.paymentRequest.phoneNumber || '';
    this.initialPhone = this.paymentRequest.phoneNumber;
    if (this.userAgentService.isMobile()) {
      this.isPhone = true;
    }
    if (this.queryParamsService.getQueryParams().swishId) {
      this.swishService.resumePolling(
        this.queryParamsService.getQueryParams().swishId
      );
      this.mobileStatusMessage = 'Inväntar signering...';
      this.loading = false;
      if (this.queryParamsService.getQueryParams().poppedSwish) {
        // On returning from the swish app
        this.mobileStatusMessage = 'Slutför betalning...';
        this.returningFromSwishApp = true;
        this.manualDeeplinkEnabled = true;
      } else {
        if (!this.activityTimer) {
          this.setActivityTimer();
        }
      }
    } else if (this.userAgentService.isMobile()) {
      this.loading = false;
      this.setActivityTimer();
      this.initMobilePayment();
    } else {
      this.formLayout = true;
      this.setupForm();
      this.loading = false;
    }
  }

  setActivityTimer() {
    if (!this.activityTimer) {
      this.activityTimer = setTimeout(() => {
        this.manualDeeplinkEnabled = true;
        clearTimeout(this.activityTimer);
        this.activityTimer = null;
      }, 5000);
    }
  }

  ngOnDestroy(): void {
    this.swishService.cancelCurrentSwishPayment();
    this.queryParamsService.clearQueryParam('poppedSwish');
  }

  setupForm(): void {
    this.form = this.formBuilder.group({
      phone: [
        String(this.phoneNumber) || '',
        [Validators.required, phoneNumberValidator(CountryCodes.ALL)],
      ],
    });
  }

  public initPayment(): void {
    this.updateErrorMessages();

    if (this.form.invalid) {
      return;
    } else {
      this.phoneNumber = formatPhoneNumber(this.form.value.phone.trim());
    }

    this.paymentInProgress = true;
    this.paymentStatusOtherDevice = 'Förbereder betalning...';
    this.error = null;

    this.swishService.initSwishPayment(this.phoneNumber).subscribe(
      () => {
        this.paymentStatusOtherDevice = 'Öppna Swish-appen i din mobil.';
      },
      (err) => {
        if (err.status === 400 || err.error.status === 500) {
          this.handleFatalError(err);
        }
        this.paymentInProgress = false;
        this.error = err.error.errors;
      }
    );
  }

  //TODO not beautiful
  updateErrorMessages() {
    this.showError = !this.showError;
  }

  public initMobilePayment() {
    this.paymentInProgress = true;
    this.loggingService.log(
      'initiating mobile payment, swishId=' +
        this.queryParamsService.getQueryParams().swishId
    );
    this.swishService.initSwishMobilePayment().subscribe(
      (res) => {
        this.token = res.token;
        this.deepLinkService.startSwish(res.token);
      },
      (err) => this.handleFatalError(err)
    );
  }

  handleFatalError(err: any): void {
    this.router.navigate([routeNames.ERROR], {
      queryParamsHandling: 'merge',
    });
  }

  restartOnOtherDevice(): void {
    this.setupForm();
    this.swishService.cancelCurrentSwishPayment();
    this.error = null;
    this.paymentStatusOtherDevice = null;
    this.paymentInProgress = false;
    this.formLayout = true;
  }

  restartMobilePayment() {
    this.paymentStatusOtherDevice = null;
    this.error = null;
    this.formLayout = false;
    this.mobileStatusMessage = 'Förbereder betalning...';
    this.initMobilePayment();
  }

  restartFormBasedPayment() {
    this.paymentStatusOtherDevice = null;
    this.error = null;
    this.formLayout = true;
    this.initPayment();
  }

  public getSwishErrorText(error: SwishErrorCode): string {
    return getErrorMessage(error);
  }

  public getErrorMessages(): string[] {
    return this.error.map((err: SwishError) =>
      this.getSwishErrorText(err.errorCode)
    );
  }

  isPolling(): boolean {
    return this.swishService.isPolling();
  }

  public startSwish() {
    this.deepLinkService.startSwish(this.token);
  }

  reroute(): void {
    this.router.navigate([routeNames.HOME], {
      queryParamsHandling: 'merge',
    });
  }
}
