import { HeaderComponent } from '../../component/header/header.component';
import { Injectable } from '@angular/core';
import { Market } from '@app/model/payment-request/payment-request.dto';
import { CheckoutStatus } from '../state/state.service';

@Injectable()
export class HeaderService {
  private headerInstance: HeaderComponent;
  private partnerLogoUri: string;
  public market: Market;
  public cancelUrl: string;
  public currentPage: string;
  public checkoutStatus: CheckoutStatus;
  public setMarket(market: Market) {
    this.market = market;
  }
  public setCancelUrl(url: string) {
    this.cancelUrl = url;
  }

  public setCurrentPage(page) {
    this.currentPage = page;
  }
  public setCheckoutStatus(status: CheckoutStatus) {
    this.checkoutStatus = status;
  }

  public registerHeaderInstance(instance: HeaderComponent): void {
    this.headerInstance = instance;
    if (this.partnerLogoUri) {
      this.headerInstance.setPartnerLogoUri(this.partnerLogoUri);
    }
  }

  public processReceivedPartnerLogoUri(logoUri: string): void {
    if (this.partnerLogoUri === logoUri) {
      return;
    }

    this.partnerLogoUri = logoUri;

    if (this.headerInstance) {
      this.headerInstance.setPartnerLogoUri(this.partnerLogoUri);
    }
  }
}
