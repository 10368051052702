export interface VippsRequestParams {
  phoneNumber: string;
  isMobile: boolean;
  fallbackUrl: string;
  resumeTokenId: string;
}

export interface VippsResponse {
  id: string;
  status: VippsStatus;
  redirectUrl: string; // only present when creating a vipps payment from the server
  errors: IVippsError[];
  checkoutSuccess: boolean;
}

export enum VippsStatus {
  INITIATE = 'INITIATE',
  RESERVE = 'RESERVE',
  CAPTURE = 'CAPTURE',
  CANCEL = 'CANCEL',
  VOID = 'VOID',
  REFUND = 'REFUND',
  ERROR = 'ERROR',
  SETTLED = 'SETTLED',
}

export interface IVippsError {
  errorCode: string;
  errorMessage: string;
  unrecoverable: boolean;
}

class VippsError implements IVippsError {
  static E33 = new VippsError(
    '33',
    'Antall forespørsler er overskredet. Vent (ca. 60 sek) og prøv igjen',
    true
  );
  static E41 = new VippsError(
    '41',
    'Ditt tilknyttede betalingskort er ikke gyldig. Vennligst sjekk kortdetaljene dine i Vipps-appen',
    true
  );
  static E42 = new VippsError(
    '42',
    'Betalingen ble nektet av banken din. Kontakt banken din hvis problemet vedvarer',
    true
  );
  static E43 = new VippsError(
    '43',
    'Betalingen ble avvist av banken din. Vennligst sjekk kontosaldoen din.',
    true
  );
  static E44 = new VippsError(
    '44',
    'Kredittkortet ditt er ikke lenger gyldig. Vennligst sjekk kortdetaljene dine i Vipps-appen.',
    true
  );
  static E61 = new VippsError(
    '61',
    'Denne økten har gått ut. Vær så snill, prøv på nytt.',
    true
  );
  static E82 = new VippsError(
    '82',
    'Den nåværende appversjonen din støttes ikke lenger. Vennligst oppdater Vipps-appen.',
    true
  );
  static E1501 = new VippsError(
    '1501',
    'Du må være over 15 år for å betale med Vipps.',
    true
  );
  static EVippsFailedOnTheirEnd = new VippsError(
    '999',
    'Noe ser ut til å ha gått galt med Vipps, prøv igjen med en ny Zaver betalingslenke',
    true
  );
  errorCode: string;
  errorMessage: string;
  unrecoverable: boolean;

  constructor(errorCode: string, errorMessage: string, unrecoverable: boolean) {
    this.errorCode = errorCode;
    this.errorMessage = errorMessage;
    this.unrecoverable = unrecoverable;
  }
  toString() {
    return `VippsError.${this.errorCode}:, ${this.errorMessage}`;
  }
}

export function getErrorMessage(error: IVippsError): string {
  let e = Object.values(VippsError).find(
    (e) => e.errorCode === error.errorCode
  );
  if (!!e) {
    return e.errorMessage;
  }

  return 'Oops! Noe gikk galt. Vær så snill, prøv på nytt. Kontakt oss hvis problemet vedvarer';
}
