<app-de-wrapper *ngIf="internalServerError">
  <div style="display: flex; flex-direction: column; align-items: center">
    <img class="general-error-icon" src="assets/icon/smiley-sad-nerd.svg" />
    <h3 class="general-error-text">Etwas ist schief gelaufen.</h3>
  </div>
</app-de-wrapper>

<app-de-thin-wrapper *ngIf="!loading && !internalServerError">
  <div class="img-wrapper">
    <img src="/assets/img/Icon-Checkmark-Badge.svg" class="img-size" />
  </div>

  <app-de-checkout-success
    *ngIf="isType('CHECKOUT')"
    [amount]="state.totalCheckoutValue"
    [email]="email"
    (onDetailsClick)="toggleModal()"
    [paymentMethodString]="paymentMethodString"
  ></app-de-checkout-success>

  <app-de-precheck-success
    *ngIf="isType('PRECHECK')"
    [amount]="state.totalCheckoutValue"
  ></app-de-precheck-success>

  <app-de-tokenized-onboarding-success
    *ngIf="isType('TOKENIZED_ONBOARDING')"
    [params]="state.currentPageInformation.pageSpecificParameters"
    (onDetailsClick)="toggleModal()"
  ></app-de-tokenized-onboarding-success>
</app-de-thin-wrapper>

<div *ngIf="!!backToMerchantPageUrl">
  <zaver-secondary-button-outlined
    style="margin: 8px auto; width: 240px"
    [rounded]="true"
    (click)="leave(backToMerchantPageUrl)"
    [text]="'Weiter'"
  ></zaver-secondary-button-outlined>
</div>

<zaver-checkout-modal
  id="details-modal"
  [active]="detailsMode"
  [buttonText]="'Schließen'"
  [logo]="'assets/img/Zaver_Logotype_Circle_Coal_DE.svg'"
  (onClose)="toggleModal()"
  [buttonText]="'Schließen'"
>
  <app-selector *ngIf="!!selectorItems" [selectorItems]="selectorItems">
    <zaver-button-link [text]="'Allgemeine Zahlungsbedingungen'">
    </zaver-button-link
  ></app-selector>
</zaver-checkout-modal>

<app-de-thin-wrapper *ngIf="loading || waitingForUpdate">
  <div class="spinner" style="margin: 8px auto; width: 56px; height: 56px">
    <object
      type="image/svg+xml"
      data="/assets/Zaver_Pageloader_Spinner.svg"
    ></object>
  </div>
</app-de-thin-wrapper>

<div class="footer-logo-wrapper" *ngIf="!detailsMode && !loading">
  <img src="/assets/img/Zaver_Logotype_Circle_Coal_DE.svg" alt="" />
</div>
