import { Injectable } from '@angular/core';
import { DeviceOS, UserAgentService } from '../user-agent/user-agent.service';
import { LoggingService } from '../logging-service/logging.service';

@Injectable({
  providedIn: 'root',
})
export class AppDeepLinkService {
  constructor(
    private loggingService: LoggingService,
    private userAgentService: UserAgentService
  ) {}

  public startBankId(autoStartToken?: string): Promise<any> {
    let fullUrl: string;
    if (autoStartToken) {
      fullUrl = 'bankid:///?autostarttoken=' + autoStartToken + '&redirect=';
    } else {
      fullUrl = 'bankid:///?redirect=';
    }
    return this.startBankIdFullUrl(fullUrl);
  }

  public startBankIdFullUrl(url: string): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(() => {
        // ios safari reloads page on return UNLESS we return to a new (!= to the current hash) anchor/hash/fragment
        // we therefore set the current hash to an empty hash, and return to a known non-empty hash.
        let redirectTarget: string =
          this.userAgentService.getDeviceOS() === DeviceOS.I_OS
            ? window.location.href.split('#')[0] + '#return'
            : '';

        let deepLink = url + encodeURIComponent(redirectTarget);
        if (redirectTarget) {
          window.location.hash = '#bankid';
        }
        this.loggingService.warn('launching deep link: ' + deepLink);
        window.location.replace(deepLink);
        resolve();
      }, 200);
    });
  }

  public startSwish(autoStartToken: string): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(() => {
        const redirectTarget: string =
          window.location.href + '&poppedSwish=true';
        const deepLink: string =
          'swish://paymentrequest?token=' +
          autoStartToken +
          '&callbackurl=' +
          encodeURIComponent(redirectTarget);
        this.loggingService.warn('launching deep link: ' + deepLink);
        window.location.assign(deepLink);
        resolve();
      }, 200);
    });
  }
}
