import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { supportedCountryCodes } from '@app/common/de-common';
import {
  availableCountryCodes,
  setCountryFlag,
} from '@app/common/select-country';
import { HeaderService } from '@app/service/header/header.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { StateService } from 'src/app/service/state/state.service';
import { UserService } from 'src/app/service/user-service/user.service';
import {
  phoneNumberValidator,
  formatPhoneNumber,
  Item,
} from 'web-component-library/projects/component-library/src/public-api';
import { RouteComponent } from '../../route.component';

@Component({
  selector: 'app-de-edit-phone',
  templateUrl: './de-edit-phone.component.html',
  styleUrls: ['../../page-shared.css', './de-edit-phone.component.scss'],
})
export class DeEditPhoneComponent
  extends RouteComponent
  implements OnInit, OnDestroy
{
  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected stateService: StateService,
    protected headerService: HeaderService,
    private userService: UserService
  ) {
    super(router, route, stateService, headerService);
  }

  loading = false;
  showError = false;
  form: FormGroup;
  navigationInProgress = false;
  imgSrc: string;
  readonly AVAILABLECOUNTRYCODES = availableCountryCodes;
  selectedcountry: Item = this.AVAILABLECOUNTRYCODES[2];
  formSubscription: Subscription;

  async ngOnInit() {
    this.loading = true;
    const userInfo = await this.userService.getActiveUserInfo();
    this.form = new FormGroup({
      phoneNumber: new FormControl(userInfo?.phoneNumber?.value || '+49 ', [
        phoneNumberValidator(supportedCountryCodes),
        Validators.required,
      ]),
    });

    this.selectedcountry = setCountryFlag(
      userInfo.phoneNumber.value,
      this.selectedcountry
    );

    this.formSubscription =
      this.form.controls.phoneNumber.valueChanges.subscribe((value) => {
        this.selectedcountry = setCountryFlag(value, this.selectedcountry);
      });

    this.loading = false;
  }

  ngOnDestroy() {
    this.formSubscription?.unsubscribe();
  }

  setCountry(selected) {
    this.form.controls.phoneNumber.setValue(selected.value);
  }

  async handleCtaClick() {
    this.navigationInProgress = true;
    if (this.form.valid) {
      try {
        await this.userService.updatePhone(
          formatPhoneNumber(this.form.controls.phoneNumber.value)
        );
        await this.navigate();
      } catch (err) {
        this.handleError(err);
      }
    } else {
      this.showError = true;
    }
    this.navigationInProgress = false;
  }
}
