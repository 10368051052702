import { Injectable } from '@angular/core';
import {Store} from '@ngxs/store';
import {
  RegisterBankIdSignatureStatus, RegisterPaymentRequestInfo
} from '../../ngxs/payment-request/payment-request.actions';
import { PaymentRequestStateModel } from '../../ngxs/payment-request/payment-request.state';
import {UserAgentService} from '../user-agent/user-agent.service';
import {SERVER_BASE_URL} from '../../../environments/environment';
import {BankIdSignatureStatusDto, BankIdSignatureStatusIdentifier} from '../../model/payment-request/bank-id-signature-status.dto';
import {HttpClient} from '@angular/common/http';
import {DisplayFullScreenError} from '../../ngxs/error/error.actions';
import {AppDeepLinkService} from '../app-deeplink/app-deep-link.service';
import {QueryParamsService} from '../query-params/query-params.service';
import {PaymentRequestDto, PaymentRequestStatus} from '../../model/payment-request/payment-request.dto';
import {routeNames} from '../../../assets/val/route-constants';
import { LoggingService } from '../logging-service/logging.service';

@Injectable({
  providedIn: 'root'
})
export class SettlementService {

  private bankIdSignaturePollingIntervalHandle = null;
  private settlementPollingIntervalHandle = null;

  constructor(private store: Store,
              private userAgentService: UserAgentService,
              private http: HttpClient,
              private deepLinkService: AppDeepLinkService,
              private queryParamsService: QueryParamsService,
              private loggingService: LoggingService) {

    this.store.subscribe(({ paymentRequest }) => {
      this.handlePaymentRequestState(paymentRequest);
    });
  }

  public initInvoiceSettlement(loanOfferId: string) {
    this.initBankIdSignatureDependantSettlement(
      `${SERVER_BASE_URL}/request/settle/invoice/${loanOfferId}`
    );
  }


  public initInstallmentsSettlement(loanOfferId: string) {
    this.initBankIdSignatureDependantSettlement(
      `${SERVER_BASE_URL}/request/settle/installments/${loanOfferId}`
    );
  }

  public resumeBankIdSignatureSettlementPolling() {
    this.startBankIdSignaturePolling();
  }

  public initSettlementPolling() {
    this.updateSettlementStatus();

    this.settlementPollingIntervalHandle =
      setInterval(
        () => this.updateSettlementStatus(),
        1000
      );
  }

  public cancelSettlement() {
    this.stopSettlementPolling();
    this.stopBankIdSignaturePolling();
  }

  private initBankIdSignatureDependantSettlement(initUrl: string) {
    this.queryParamsService.setQueryParam('shouldOpenBankId', 'false');
    this.loggingService.log("initializing bankid settlement");
    this.http.post(initUrl,
      {}, { withCredentials: true })
      .subscribe((result: BankIdSignatureStatusDto) => {
        this.store.dispatch(new RegisterBankIdSignatureStatus(result));
        if (result.status === BankIdSignatureStatusIdentifier.STARTED) {
          this.openBankIdIfMobile(result.autoStartToken);
          this.startBankIdSignaturePolling();
        }
      }, () => {
        this.store.dispatch(
          new DisplayFullScreenError('Det gick inte att signera.', routeNames.SETTLE)
        );
      }
    );
  }

  private updateSettlementStatus() {
    const { id } = this.store.snapshot().paymentRequest.request;
    const sub = this.http.get<PaymentRequestDto>(`${SERVER_BASE_URL}/request/public/${id}`)
      .subscribe((res: PaymentRequestDto) => {
        this.registerPaymentRequestState(res);
        sub.unsubscribe();
      }, errorResponse => {
        if (errorResponse && errorResponse.status === 403 && errorResponse.error.message) {
          alert(errorResponse.error.message);
        }
      });
  }

  private openBankIdIfMobile(autoStartToken: string) {
    if (!this.userAgentService.isMobile()) {
      return;
    }

    this.deepLinkService.startBankId(autoStartToken);
  }

  private startBankIdSignaturePolling() {
   this.updateBankIdSignatureDependantSettlementState();

    this.bankIdSignaturePollingIntervalHandle =
      setInterval(
        () => this.updateBankIdSignatureDependantSettlementState(),
        1000
      );
  }

  private stopBankIdSignaturePolling() {
    if (this.bankIdSignaturePollingIntervalHandle) {
      clearInterval(this.bankIdSignaturePollingIntervalHandle);
    }
  }

  private stopSettlementPolling() {
    clearInterval(this.settlementPollingIntervalHandle);
  }

  private updateBankIdSignatureDependantSettlementState() {
    this.http.get<BankIdSignatureStatusDto>(
      `${SERVER_BASE_URL}/request/settlement/`,
      { withCredentials: true }
      ).subscribe((res: BankIdSignatureStatusDto) => {
        // Register the fetched info
        this.store.dispatch(new RegisterBankIdSignatureStatus(res));
      });
  }

  private handlePaymentRequestState(paymentRequestStateModel: PaymentRequestStateModel) {
    if (paymentRequestStateModel.bankIdStatus.status ===
      BankIdSignatureStatusIdentifier.COMPLETED_SUCCESSFULLY) {
      this.stopBankIdSignaturePolling();
    }

    if (paymentRequestStateModel.request.status ===
      PaymentRequestStatus.SETTLED) {
      this.stopSettlementPolling();
    }
  }

  private registerPaymentRequestState(request: PaymentRequestDto) {
    this.store.dispatch(
      new RegisterPaymentRequestInfo({ paymentRequest: request })
    );
  }

}
