import {Component, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {InstantDebitService} from '../../service/details/instant-debit.service';
import {routeNames} from '../../../assets/val/route-constants';
import {ActivatedRoute, Router} from '@angular/router';
import {HeaderService} from '../../service/header/header.service';
import {BankinfoService} from '@app/service/bankinfo/bankinfo.service';
import {CreditService} from '@app/service/credit/credit.service';
import {InstantDebitTaskDetails} from '@app/service/details/instantDebitTaskDetails';

@Component({
  templateUrl: './instant-debit-start.component.html',
  styleUrls: ['./instant-debit-start.component.css',
  ]
})
export class InstantDebitStartComponent implements OnInit {

  public loadingDetails: boolean;

  public readonly LOADING_VAL_MSG = 'Vänta...';

  private taskId: string;

  success: string;

  tinkLink;
  public details: InstantDebitTaskDetails;
  private errorMessage: string;

  constructor(private detailsService: InstantDebitService,
              private bankinfoService: BankinfoService,
              private route: ActivatedRoute,
              private router: Router,
              private activatedRoute: ActivatedRoute
  ) {

    this.loadingDetails = true;

  }

  ngOnInit() {
    this.detailsService.getDetails(null)
      .subscribe(
        {
          next: (res) => this.handleLoadedDetails(res),
          error: (err) => this.handleLoadingError(err)
        });

//    this.creditService.instantDebitPrecheck().toPromise()
//      .then(() => this.bankinfoService.getTinkLink());

    //  this.taskId = this.route.snapshot.paramMap.get(routeParamNames.TASK_ID);
    //  const market = 'SE';


    // const tinkLinkStr: string = sessionStorage.getItem('tinkLink');
    // console.log(tinkLinkStr);
    // this.tinkLink = this.sanitizer.bypassSecurityTrustResourceUrl(tinkLinkStr);

  }

  openTinkLink() {
    window.open(this.tinkLink);
  }

  private handlePrecheckDenial() {
    return this.router.navigate([routeNames.HOME], {
      queryParamsHandling: 'merge'
    });
  }

  hasRegisteredAccount() {
    return this.details != null && this.details.approvedAccount != null;
  }

  private handleLoadedDetails(res: InstantDebitTaskDetails) {
    this.details = res;
    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: {taskId: res.taskId},
        queryParamsHandling: 'merge'
      }).then(
      () => this.bankinfoService.getTinkLink(this.details.taskId)
        .subscribe(
          (response) => this.tinkLink = response.link
        ));

    this.loadingDetails = false;
  }

  private handleLoadingError(err: Error) {
    this.errorMessage = 'Något gick fel';
    this.loadingDetails = false;
  }

  changeAccount() {

  }

  confirmAccount() {

  }
}
