<h1 class="heading">{{ params.heading }}</h1>

<div class="text">
  {{ params.subHeading }}
</div>

<zaver-button-link
  class="details-link"
  (click)="detailsClick()"
  [text]="'Details und Bedingungen ansehen'"
></zaver-button-link>
