<div class="page-body" *ngIf="!authInProgress && !authComplete">
  <div class="contents-wrapper">
    <div class="contents-container">
      <div class="page-icon">
        <img
          class="no-bankid-logo"
          src="/assets/icon/BankID-Logo-NO.svg"
          alt=""
        />
      </div>

      <div class="page-text">
        Vennligst identifiser deg med mobil BankID for å fortsette
      </div>

      <div class="input-container">
        <form [formGroup]="form" id="form">
          <zaver-animated-form-input
            [size]="'large'"
            [ariaLabel]="'Sende inn personnummer'"
            [formControlName]="'pnr'"
            [error]="showError"
            inputMode="numeric"
            placeholder="ddmmåånnnnn"
            errorMessage="Vennligst skriv inn et gyldig personnummer"
            [errorIcon]="'assets/icon/icon_error.svg'"
          ></zaver-animated-form-input>
        </form>
      </div>

      <div class="pr-info">
        <label>Totale mengden</label>
        <label>
          {{ state.totalCheckoutValue | value }}
        </label>
      </div>

      <zaver-primary-button
        [buttonType]="'submit'"
        [formId]="'form'"
        [text]="ctaButtonText"
        [disabled]="authInProgress"
        [loading]="authInProgress"
        [logo]="'/assets/icon/BankID-Logo-NO-Symbol.svg'"
        (click)="handleCtaClick()"
      ></zaver-primary-button>
    </div>
  </div>
</div>

<div class="page-body" *ngIf="authInProgress || authComplete">
  <div class="contents-wrapper">
    <div class="contents-container">
      <div class="page-icon-loading">
        <img
          class="no-bankid-logo"
          src="/assets/icon/BankID-Logo-NO.svg"
          alt=""
        />
      </div>
      <div class="loading-container">
        <div class="loading-text">{{ loadingText }}</div>
        <zaver-loading-dots [color]="'#b7bcc8'" [diameter]="14">
        </zaver-loading-dots>
      </div>
    </div>
  </div>
</div>
