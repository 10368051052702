import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {PaymentRequestDto, PaymentRequestStatus} from '../model/payment-request/payment-request.dto';
import {HttpClient} from '@angular/common/http';
import {SERVER_BASE_URL} from '../../environments/environment';
import {QueryParamsService} from '../service/query-params/query-params.service';
import {map, tap} from 'rxjs/operators';
import {Store} from '@ngxs/store';
import {RegisterPaymentRequestInfo} from '../ngxs/payment-request/payment-request.actions';

/**
 * Ensures that a user is unable to display the success page
 * for a non-settled payment request.
 */
@Injectable()
export class SuccessGuard implements CanActivate {

  constructor(private queryParamsService: QueryParamsService,
              private http: HttpClient,
              private store: Store) {}

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    let id = this.queryParamsService.getQueryParams().id;

    if (!id) {
      id = state.root.queryParams.id;
    }

    return this.http.get<PaymentRequestDto>(`${SERVER_BASE_URL}/request/public/${id}`)
      .pipe(tap((response) => this.store.dispatch(new RegisterPaymentRequestInfo({
        paymentRequest: response
      }))))
      .pipe(map((response) => this.mapResponseToBool(response)));
  }


  private mapResponseToBool(response: any): boolean {
    return (response.status === PaymentRequestStatus.SETTLED);
  }
}

