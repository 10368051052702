import { Host, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SERVER_BASE_URL } from '../../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { CreditOptionDto } from '../../model/credit/credit-option.dto';
import { PaymentRequestDto } from '@app/model/payment-request/payment-request.dto';

@Injectable({
  providedIn: 'root',
})
export class CreditService {
    private readonly httpReqOptions = { withCredentials: true };

  constructor(private http: HttpClient, private dialogService: MatDialog) {}

  getPaymentRequestPublicInfo(
    paymentRequestId: string
  ): Observable<PaymentRequestDto> {
    return this.http.get<PaymentRequestDto>(
      `${SERVER_BASE_URL}/request/public/${paymentRequestId}`
    );
  }

  fetchInvoiceTerms(paymentMethodId: string): Observable<CreditOptionDto> {
    return this.http.get<CreditOptionDto>(
      `${SERVER_BASE_URL}/request/invoice/terms/${paymentMethodId}`,
      { withCredentials: true }
    );
  }

  fetchInstallments(
    selectedMethodId: string
  ): Observable<InstallmentOptionsDto> {
    return this.http.get<InstallmentOptionsDto>(
      `${SERVER_BASE_URL}/request/installments/plans/${selectedMethodId}`,
      { withCredentials: true }
    );
  }

  instantDebitPrecheck(instantDebitId: string): Observable<InstantDebitPrecheckResponse> {
      return this.http.get<InstantDebitPrecheckResponse>(`${SERVER_BASE_URL}/instantdebit/precheck?taskId=${instantDebitId}`);
  }

  initInstantDebit(): Observable<InstantDebitInitResponse> {
      return this.http.get<InstantDebitInitResponse>(`${SERVER_BASE_URL}/instantdebit/init`);
  }
}

export interface InstantDebitInitResponse {
  instantDebitPaymentId: string;
}

export enum InstantDebitStatus {
  CREATED = 'CREATED',
  PENDING_ACCOUNT_NOTIFICATION = 'PENDING_ACCOUNT_NOTIFICATION',
  PENDING_PRECHECK = 'PENDING_PRECHECK',
  PENDING_PERSON_CREATION = 'PENDING_PERSON_CREATION',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS'
}

export enum InstantDebitFailureReason {
  CREDIT_PRECHECK_FAILED = 'CREDIT_PRECHECK_FAILED',
  MANDATE_DENIED = 'MANDATE_DENIED',
}

export interface InstantDebitPrecheckResponse {
  result: string;
  error: string;
  instantDebitPaymentId: string;
  status: InstantDebitStatus;
  failureReason: InstantDebitFailureReason;
}

export class InstallmentOptionsDto {
  preferredInstallmentPlanDurations: number[];
  installmentOptions: CreditOptionDto[];
}

export class SuccessResponse {
  success: boolean;
}

export class LoanApplicationResponse {
  granted: boolean;
}
