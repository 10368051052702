import { Router } from '@angular/router/router';
import {
  CheckoutState,
  InterruptionControlParameters,
  ResolveType,
  StateControlParameters,
  StateService,
} from '@app/service/state/state.service';
import { ActivatedRoute } from '@angular/router';
import { DeBaseComponent } from '@app/route/DE/de-base-component/de-base.component';
import { HeaderService } from '@app/service/header/header.service';

export class RouteComponent extends DeBaseComponent {
  public state: CheckoutState;
  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected stateService: StateService,
    protected headerService: HeaderService
  ) {
    super();
    this.state = this.route.snapshot.data['state'];

    if (this.state && this.state.forceNavigation) {
      const route: string = this.stateService.getRouteFromState(this.state);
      this.router.navigate([route], { queryParamsHandling: 'preserve' });
    }
  }

  async navigate() {
    try {
      const controlParams: StateControlParameters = {
        currentPageName: this.stateService.getStepFromUrl(this.router.url),
        token: this.stateService.getToken(),
      };

      const updatedState = await this.stateService.navigate(controlParams);

      const route = this.stateService.getRouteFromState(updatedState);
      this.router.navigate([route], { queryParamsHandling: 'preserve' });
    } catch (err) {
      this.handleError(err);
    }
  }

  async reset() {
    const path: string = this.route.routeConfig.path;
    const controlParams: StateControlParameters = {
      currentPageName: this.stateService.getStepFromUrl(path),
      token: this.stateService.getToken(),
    };

    const updatedState = await this.stateService.reset(controlParams);
    const route = this.stateService.getRouteFromState(updatedState);
    this.router.navigate([route], { queryParamsHandling: 'preserve' });
  }

  async resolveInterruption(resolveType: ResolveType) {
    const controlParams: InterruptionControlParameters = {
      currentPageName: this.stateService.getStepFromUrl(this.router.url),
      token: this.stateService.getToken(),
      resolveType: resolveType,
    };

    const updatedState = await this.stateService.resolveInterruption(
      controlParams
    );
    const route = this.stateService.getRouteFromState(updatedState);
    this.router.navigate([route], { queryParamsHandling: 'preserve' });
  }
}
