import { Injectable } from '@angular/core';
import { LoanOption } from '@app/route/shared/LoanOption';
import { LoanOptionVM } from '@app/route/shared/LoanOptionVM';

@Injectable({
  providedIn: 'root',
})
export class DeLoanOptionService {
  constructor() {}

  getFormattedInterest(interest: number): string {
    return (interest * 100).toFixed(2);
  }

  getBulletPoints(item: LoanOption): string[] {
    return [`Zinssatz ${this.getFormattedInterest(item.nominalInterest)}%`];
  }

  zeroInterestString(loanOption: LoanOption): string {
    return loanOption.nominalInterest === 0 ? '0% Zinsen' : '';
  }

  buildLoanOptionTitle(loanOption: LoanOption): string {
    return `${loanOption.monthlyCost.toLocaleString('de')} € / Monat über ${
      loanOption.numberOfMonths
    } Monate`;
  }

  transformDtoTOVM(
    loanOptions: LoanOption[],
    toggleModal: () => void
  ): LoanOptionVM[] {
    return loanOptions.map((loanOption) => ({
      badgeText: this.zeroInterestString(loanOption),
      bulletPoints: this.getBulletPoints(loanOption),
      color: '#00ac84',
      link: { text: 'AGB lesen', onClick: toggleModal },
      monthlyCost: loanOption.monthlyCost,
      nominalInterest: loanOption.nominalInterest,
      numberOfMonths: loanOption.numberOfMonths,
      radioButtonColor: '#db305e',
      title: this.buildLoanOptionTitle(loanOption),
      termId: loanOption.termId,
      effectiveInterest: loanOption.effectiveInterest,
      initialFee: loanOption.initialFee,
      monthlyFee: loanOption.monthlyFee,
      totalAmount: loanOption.totalAmount,
      loanTermsURL: loanOption.loanTermsURL,
      secciURL: loanOption.secciURL,
    }));
  }

  buildLoantermsString(
    selectedLoanOption: LoanOptionVM,
    totalCheckoutValue
  ): string {
    const {
      numberOfMonths,
      effectiveInterest,
      nominalInterest,
      initialFee,
      monthlyFee,
      monthlyCost,
      totalAmount,
    } = selectedLoanOption;

    return `Berechnungs: Gesamtbetrag des Kredits ${totalCheckoutValue.amount.toLocaleString(
      'de'
    )} 
    EUR, Ratenzahlung ${numberOfMonths} Monate. Der effektive Zinssatz beträgt ${this.getFormattedInterest(
      effectiveInterest
    )}%. 
    Variabler Jahreszinssatz ${this.getFormattedInterest(
      nominalInterest
    )}%, Startgebühr ${initialFee.toLocaleString('de')} EUR, Verwaltungsgebühr 
    ${monthlyFee.toLocaleString(
      'de'
    )} EUR pro Monat und monatlicher Betrag von ${monthlyCost.toLocaleString(
      'de'
    )} EUR. 
    Der Gesamtbetrag, den Sie zu zahlen haben, beträgt ${totalAmount.toLocaleString(
      'de'
    )} EUR.`;
  }
}
