import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteComponent } from 'src/app/route/route.component';
import { StateService, WaitThen } from '../../../service/state/state.service';
import { UserService } from '@app/service/user-service/user.service';
import {
  Market,
  PaymentMethod,
} from '@app/model/payment-request/payment-request.dto';
import { HeaderService } from '@app/service/header/header.service';
import { SelectorItem } from '@app/component/selector/selector.component';
import { ValuePipe } from '@app/shared/pipe/value.pipe';
import { DeLoanOptionService } from '@app/service/de-loan-option/de-loan-option.service';
import { deInvoiceTermsUrl } from '@app/common/de-common';
import { LoanOption } from '@app/route/shared/LoanOption';

@Component({
  selector: 'app-de-success',
  templateUrl: './de-success.component.html',
  styleUrls: ['../../page-shared.css', './de-success.component.scss'],
})
export class DeSuccessComponent extends RouteComponent implements OnInit {
  detailsMode: boolean = false;

  public loading = true;
  public waitingForUpdate = false;
  public backToMerchantPageUrl: string = null;
  public email: string;
  selectorItems: SelectorItem[];
  paymentMethodString: string;
  precheckLoanOptions = null;
  currentLoanTermsStr: string;
  waitingSince: number;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected headerService: HeaderService,
    protected stateService: StateService,
    protected userService: UserService,
    private valuePipe: ValuePipe,
    private loanOptionService: DeLoanOptionService
  ) {
    super(router, route, stateService, headerService);
  }

  async ngOnInit(): Promise<void> {
    // Check if the user should be forwarded somewhere, e.g. back to the web shop
    this.waitingSince = Date.now();
    this.getReadyToLeave();

    this.getinfo();
    this.paymentMethodString = this.getPaymentMethodString();
    this.prepareModalInformation();
  }

  toggleModal(): void {
    this.detailsMode = !this.detailsMode;
  }

  getPaymentMethodString(): string {
    switch (this.state.paymentInformation?.paymentMethod) {
      case PaymentMethod.CREDIT:
        return 'Ratenzahlung';
      case PaymentMethod.INVOICE:
        return 'Rechnung';
      default:
        return '';
    }
  }

  getReadyToLeave() {
    this.waitingForUpdate = true;
    let hasWaitedFor = Date.now() - this.waitingSince;
    this.stateService
      .getReadyToLeave(hasWaitedFor)
      .then((stayOrLeave) => {
        switch (stayOrLeave.then) {
          case WaitThen.WAIT:
            setTimeout(() => {
              this.getReadyToLeave();
            }, stayOrLeave.waitForMs);
            return;

          case WaitThen.STAY:
            this.waitingForUpdate = false;
            if (!!stayOrLeave.url) {
              setTimeout(
                () => {
                  this.backToMerchantPageUrl = stayOrLeave.url;
                },
                !!stayOrLeave.waitForMs ? stayOrLeave.waitForMs : 50
              );
            }
            return;

          case WaitThen.LEAVE:
            if (stayOrLeave.waitForMs > 1000) {
              // If the wait time is too low we do not flash the success page for the customer,
              // instead we send them directly to the merchants page.
              // The reasoning behind this is that the consumer will be confused and feel a bit uneasy if
              // they see information but do not have time to read it.
              // Instead we keep the spinner spinning and redirect after the given waiting time.
              this.waitingForUpdate = false;
            }
            setTimeout(
              () => {
                window.location.href = stayOrLeave.url;
              },
              !!stayOrLeave.waitForMs ? stayOrLeave.waitForMs : 50
            );
            return;
        }
      })
      .catch(() => {
        // if we can't get a response then we try again for a while and then give up
        let errorAfter = Date.now() - this.waitingSince;
        if (errorAfter < 30000) {
          // 30000 = 30 seconds
          // if not enough time has passed we try again
          setTimeout(() => {
            this.getReadyToLeave();
          }, 50);
          return;
        }
        // we have given up, show the payment details
        this.waitingForUpdate = false;
        if (!!this.state?.merchantUrls?.onSuccessUrl) {
          // show the "Weiter"-button ...
          this.backToMerchantPageUrl = this.state?.merchantUrls?.onSuccessUrl;
          // and  redirect automatically after a delay
          setTimeout(() => {
            window.location.href = this.state?.merchantUrls?.onSuccessUrl;
          }, 5000);
          return;
        }
      });
  }

  leave(url: string): void {
    window.location.href = url;
  }

  prepareModalInformation(): void {
    this.selectorItems = [
      {
        tab: 'Ihr Kauf',
        title: 'Zusammenfassung',
        lineItems: this.state.paymentInformation?.lineItems,
        totalValue: `Gesamtbetrag inkl. MwSt: ${this.valuePipe.transform(
          this.state.totalCheckoutValue
        )}`,
      },
    ];
    if (
      this.state.paymentInformation.paymentMethod === PaymentMethod.CREDIT &&
      !!this.state.loanOfferId
    ) {
      const selectedLoanOption = this.state.loanOptions.find(
        (loanOption: LoanOption) =>
          loanOption.termId === this.state.loanOfferId.replace(/\"/g, '')
      );

      const selectedLoanOptionVM = this.loanOptionService.transformDtoTOVM(
        [selectedLoanOption],
        this.toggleModal.bind(this)
      );

      this.currentLoanTermsStr = this.loanOptionService.buildLoantermsString(
        selectedLoanOptionVM[0],
        this.state.totalCheckoutValue
      );

      this.selectorItems = [
        ...this.selectorItems,
        {
          tab: 'Zahlungsbedingungen',
          title: 'Bedingungen für den Kauf auf Raten',
          descriptions: [{ text: this.currentLoanTermsStr }],
          links: [
            {
              text: 'Bedingungen für Teilzahlungen',
              onClick: () =>
                this.openURLinNewWindow(selectedLoanOption.loanTermsURL),
            },
            {
              text: this.state.checkoutCustomizations.merchantName + ' AGB',
              onClick: () =>
                this.openURLinNewWindow(
                  this.state.checkoutCustomizations.merchantTermsUrl
                ),
            },
            {
              ...(this.state.market === Market.AT && {
                text: 'Europäische Standardinformationen für Kreditierungen nach dem Verbraucherkreditgesetz',
                onClick: () =>
                  this.openURLinNewWindow(selectedLoanOption.secciURL),
              }),
            },
          ],
        },
      ];
    }

    if (this.state.paymentInformation.paymentMethod === PaymentMethod.INVOICE) {
      this.selectorItems = [
        ...this.selectorItems,
        {
          tab: 'Zahlungsbedingungen',
          title: 'Bedingungen für den Kauf auf Rechnung',
          descriptions: [
            {
              text: 'Wenn Sie sich für die Zahlung per Rechnung entscheiden, bieten wir Ihnen die Möglichkeit, innerhalb von 14 Tagen ab dem Datum der Rechnungsstellung zu zahlen.',
            },
          ],
          links: [
            {
              text: 'Allgemeine Zahlungsbedingungen',
              onClick: () => this.openInvoiceTerms(),
            },
            {
              text: this.state.checkoutCustomizations.merchantName + ' AGB',
              onClick: () =>
                this.openURLinNewWindow(
                  this.state.checkoutCustomizations.merchantTermsUrl
                ),
            },
          ],
        },
      ];
    }
  }

  openInvoiceTerms(): void {
    window.location.href = deInvoiceTermsUrl;
  }

  openURLinNewWindow(url: string): void {
    window.open(url, '_blank');
  }

  getSelectedLoanOption(): LoanOption {
    return this.state.loanOptions.find(
      (loanOption) => loanOption.termId === this.state.loanOfferId
    );
  }

  isType(type) {
    return (
      this.state.currentPageInformation.pageSpecificParameters.pageTemplate ===
      type
    );
  }

  async getinfo() {
    const userInfo = await this.userService.getActiveUserInfo();
    this.email = userInfo?.email?.value;
    this.loading = false;
  }
}
