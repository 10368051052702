<div class="page-body" *ngIf="loading === false">
  <div class="contents-wrapper">
    <div class="contents-container">
      <div class="page-icon">
        <img src="/assets/icon/Icon-E-mail.svg" alt="" />
      </div>

      <div class="page-text">Vennligst skriv inn e-postadresse&nbsp;din</div>

      <zaver-alert-box
        *ngIf="shouldDisplayEmailError"
        [messageList]="emailErrorMessages"
        type="WARNING"
      >
      </zaver-alert-box>
      <form [formGroup]="form" id="form">
        <zaver-animated-form-input
          [ariaLabel]="'Skriv inn e-postadressen'"
          [formControlName]="'email'"
          [error]="form.controls.email.invalid && showError"
          (onKeyboardEnter)="handleCtaClick()"
          inputMode="email"
          placeholder="E-postadresse"
          errorMessage="Vennligst send inn en gyldig e-postadresse"
          [errorIcon]="'assets/icon/icon_error.svg'"
        ></zaver-animated-form-input>
      </form>
      <div class="pr-info">
        <label> Totale mengden </label>
        <label>
          {{ state.totalCheckoutValue | value }}
        </label>
      </div>

      <zaver-primary-button
        [formId]="'form'"
        [buttonType]="'submit'"
        [text]="'Neste'"
        (click)="handleCtaClick()"
      >
      </zaver-primary-button>
    </div>
  </div>
</div>

<div class="page-body" *ngIf="loading === true">
  <div class="contents-wrapper">
    <div class="contents-container">
      <app-spinner></app-spinner>
    </div>
  </div>
</div>
