import {PaymentRequestDto} from '../../model/payment-request/payment-request.dto';
import {BankIdSignatureStatusDto} from '../../model/payment-request/bank-id-signature-status.dto';

const ACTION_SUBJECT_TAG = '[Payment Request]';

export class RegisterPaymentRequestInfo {
  static readonly type = `${ACTION_SUBJECT_TAG} Get payment request info`;

  constructor(public payload: { paymentRequest: PaymentRequestDto }) {}
}

export class InitInvoiceSettlement {
  static readonly type = `${ACTION_SUBJECT_TAG} Initialize settlement of payment with invoice`;

  constructor() {}
}

export class PurgeAvailablePaymentMethods {
  static readonly type = `${ACTION_SUBJECT_TAG} Remove an array of payment methods from those listed
  as available`;

  constructor(public paymentMethodIdentifiers: string[]) {}
}

export class RegisterBankIdSignatureStatus {
  static readonly type = `${ACTION_SUBJECT_TAG} Register a BID signature status`;

  constructor(public statusDto: BankIdSignatureStatusDto) {}
}
