import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from '@app/service/header/header.service';
import { UserService } from '@app/service/user-service/user.service';
import { take } from 'rxjs/operators';
import { StateService } from 'src/app/service/state/state.service';
import { RouteComponent } from '../../route.component';

@Component({
  selector: 'app-de-verify-user',
  templateUrl: './de-verify-user.component.html',
  styleUrls: ['../../page-shared.css', './de-verify-user.component.scss'],
})
export class DeVerifyUserComponent extends RouteComponent implements OnInit {
  loading = true;
  error = false;
  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected stateService: StateService,
    protected headerService: HeaderService,
    private userService: UserService
  ) {
    super(router, route, stateService, headerService);
  }

  async ngOnInit(): Promise<void> {
    const params = await this.route.queryParams.pipe(take(1)).toPromise();
    const succesResp = await this.userService.verifyUser(
      params.emailVerificationCode,
      this.stateService.token
    );
    if (!succesResp) {
      this.error = true;
    } else {
      this.navigate();
    }
  }
}
