import {Action, State, StateContext} from '@ngxs/store';
import {Router} from '@angular/router';
import {DisplayFullScreenError} from './error.actions';
import {environment} from '../../../environments/environment';
import {routeNames} from '../../../assets/val/route-constants';
import { LoggingService } from 'src/app/service/logging-service/logging.service';
import {Injectable} from '@angular/core';

export class ErrorStateModel {
  fullScreenError: string;
}

@State({
  name: 'error',
  defaults: {
    fullScreenError: ''
  }
})

@Injectable()
export class ErrorState {

  constructor(private router: Router,
              private loggingService: LoggingService) { }

  @Action(DisplayFullScreenError)
  displayFullScreenError(ctx: StateContext<ErrorStateModel>, action: DisplayFullScreenError) {
    const { patchState, getState } = ctx;

    console.log('Inside displayFullScreenError');
    this.router.navigate([routeNames.ERROR], { queryParamsHandling: 'preserve' })
      .then(() => {
        patchState({
          ...getState(),
          fullScreenError: action.message
        });
      }, rejection => {
        if (!environment.production) {
          this.loggingService.error(`Navigation from ${action.sourceRoute} to ${routeNames.ERROR} failed.`, rejection);
        }
      });
  }
}
