import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { AppModule } from './app/app.module';
import { environment, SERVER_BASE_URL } from './environments/environment';
import * as StackTrace from 'stacktrace-js';
import { Integrations } from '@sentry/tracing/dist/index.bundle';
import { EnvIdentifier } from './environments/environment-types';

if (environment.production) {
  enableProdMode();
}

if (environment.envIdentifier !== EnvIdentifier.LOCAL) {
  Sentry.init({
    dsn: 'https://2ceb74f1d79444a98e6c524366c5cda9@o1026969.ingest.sentry.io/5993698',
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: [
          'localhost',
          'https://checkout.staging.zaver.se',
          'https://server.staging.zaver.se',
        ],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    environment: environment.envIdentifier,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.05,
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => handle(err));

function handle(err: Error): void {
  let id = null;
  try {
    id = window.location.href.split('id=')[1];
  } catch (e) {}
  StackTrace.fromError(err)
    .then((frame) =>
      fetch(`${SERVER_BASE_URL}/log/`, {
        method: 'POST',
        body: JSON.stringify({
          timestamp: Date.now(),
          level: 'ERROR',
          entry:
            'error bootstrapping checkout: ' +
            err.name +
            ' message: ' +
            err.message,
          stackTrace: frame,
          userAgent: navigator
            ? navigator.userAgent
            : 'navigator undefined/null',
        }),
        headers: {
          'Content-Type': 'application/json',
          'X-PRID': id,
        },
      })
    )
    .then(() =>
      alert(
        'Något gick fel, prova att ladda om sidan. Om felet kvarstår, kontakta kundtjänst'
      )
    )
    .catch((err2) => {
      alert(
        'Något gick fel, prova att ladda om sidan. Om felet kvarstår, kontakta kundtjänst'
      );
      console.log('error uploading error report', err2);
    });
}
