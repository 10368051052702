import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteComponent } from '@app/route/route.component';
import { HeaderService } from '@app/service/header/header.service';
import { ResolveType, StateService } from '@app/service/state/state.service';
import { UserService } from '@app/service/user-service/user.service';

@Component({
  selector: 'no-error',
  templateUrl: './no-error.component.html',
  styleUrls: ['../../page-shared.css', './no-error.component.scss'],
})
export class NoErrorComponent extends RouteComponent implements OnInit {
  loading = false;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected stateService: StateService,
    private userService: UserService,
    protected headerService: HeaderService
  ) {
    super(router, route, stateService, headerService);
  }

  ngOnInit(): void {}

  handleCtaClick() {
    this.resolveInterruption(ResolveType.CTA);
  }
}
