import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from '@app/service/header/header.service';
import {
  StateControlParameters,
  StateService,
} from 'src/app/service/state/state.service';
import { UserService } from 'src/app/service/user-service/user.service';
import { RouteComponent } from '../../route.component';

@Component({
  selector: 'app-de-part-success',
  templateUrl: './de-part-success.component.html',
  styleUrls: ['../../page-shared.css', './de-part-success.component.scss'],
})
export class DePartSuccessComponent extends RouteComponent implements OnInit {
  loading = true;
  showError: boolean = false;
  form: FormGroup;
  emailaddress: string;
  navigationInProgress = false;
  modalActive = false;
  buttonPressedState = false;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected stateService: StateService,
    protected headerService: HeaderService,
    private userService: UserService
  ) {
    super(router, route, stateService, headerService);
  }

  async ngOnInit(): Promise<void> {
    const userInfo = await this.userService.getActiveUserInfo();
    this.emailaddress = userInfo.email.value;
    await this.userService.sendPurchaseConfirmationEmail();
    this.loading = false;
  }

  async resendMail(): Promise<void> {
    try {
      this.buttonPressedState = true;
      setTimeout(() => {
        this.buttonPressedState = false;
      }, 500);
      await this.userService.resendPurchaseConfirmationEmail();
    } catch (err) {
      this.handleError(err);
    }
  }

  onClickReset() {
    this.modalActive = true;
  }

  async resetEmail(): Promise<void> {
    const path: string = this.route.routeConfig.path;

    const controlParams: StateControlParameters = {
      currentPageName: this.stateService.getStepFromUrl(path),
      token: this.stateService.getToken(),
    };
    const updatedState = await this.stateService.reset(controlParams);
    const route = this.stateService.getRouteFromState(updatedState);
    this.router.navigate([route], { queryParamsHandling: 'preserve' });
  }
  setModalActive(value: boolean): void {
    this.modalActive = value;
  }
}
