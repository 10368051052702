<div class="page-body">
  <div class="contents-wrapper">
    <div class="contents-container">
      <div class="success-icon-container">
        <img src="/assets/img/Icon-Checkmark-Badge.svg" alt="" />
      </div>

      <div
        class="success-message"
        *ngIf="state.paymentInformation.paymentMethod == 'INVOICE'"
      >
        Takk for at du betaler med Zaver.
      </div>
      <div
        class="success-message"
        *ngIf="state.paymentInformation.paymentMethod == 'CREDIT'"
      >
        Takk for at du betaler med Zaver.
      </div>
      <div
        class="success-message"
        *ngIf="state.paymentInformation.paymentMethod == 'VIPPS_EXTERNAL'"
      >
        Takk for betalingen!
      </div>

      <div
        class="info-message"
        *ngIf="state.paymentInformation.paymentMethod == 'INVOICE'"
      >
        Fakturaen din vil bli sendt til den angitte e-postadressen innen 1-2
        dager.
      </div>
      <div
        class="info-message"
        *ngIf="state.paymentInformation.paymentMethod == 'CREDIT'"
      >
        Dine fakturaer vil bli sendt til den angitte e-postadressen din før det
        er på tide å betale.
      </div>

      <div class="details-list-wrapper smallest-mobile-hide">
        <hr class="details-divider" *ngIf="state" />
        <div class="details-container" *ngIf="state">
          <div class="details-key-entry">Totale mengden</div>

          <div class="details-value-entry" *ngIf="state">
            {{ state.totalCheckoutValue | value }}
          </div>
        </div>

        <hr class="details-divider" *ngIf="paymentMethod" />
        <div class="details-container" *ngIf="paymentMethod">
          <div class="details-key-entry">Betalingsmetoder</div>

          <div class="details-value-entry">
            {{ paymentMethod }}
          </div>
        </div>

        <hr class="details-divider" *ngIf="paymentMethod || state" />
      </div>
    </div>
  </div>
</div>
