import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Currency } from '@app/model/currency';
import { Market } from '@app/model/payment-request/payment-request.dto';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-payment-request-info',
  templateUrl: './payment-request-info.component.html',
  styleUrls: ['./payment-request-info.component.scss'],
})
export class PaymentRequestInfoComponent implements OnInit {
  @Input() lineItems;
  @Input() market;
  @Input() value;
  @Output() onDetailsClick = new EventEmitter();

  detailsLabel: string;
  amountLabel: Currency;
  hasLineItems: boolean;
  detailsText: string;

  constructor(private store: Store) {}

  ngOnInit() {
    this.hasLineItems = this.lineItems?.length > 0;

    switch (this.market) {
      case Market.SE:
        this.detailsLabel = 'Visa detaljer';
        this.amountLabel = this.value;
        this.detailsText = this.hasLineItems
          ? 'Totalbelopp inkl. moms'
          : 'Totalt belopp';
        break;
      case Market.AT:
      case Market.DE:
        this.detailsLabel = 'Details anzeigen';
        this.amountLabel = this.value;
        this.detailsText = this.hasLineItems
          ? 'Gesamtbetrag inkl. MwSt'
          : 'Gesamtbetrag';
        break;
      case Market.NO:
        this.detailsLabel = 'Vis detaljer';
        this.amountLabel = this.value;
        this.detailsText = 'Totale mengden';
        break;
    }
  }

  detailsClick() {
    this.onDetailsClick.emit();
  }
}
