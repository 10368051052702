<app-de-wrapper *ngIf="internalServerError">
  <div style="display: flex; flex-direction: column; align-items: center">
    <img class="general-error-icon" src="assets/icon/smiley-sad-nerd.svg" />
    <h3 class="general-error-text">Etwas ist schief gelaufen.</h3>
  </div>
</app-de-wrapper>

<app-de-wrapper *ngIf="loading">
  <app-spinner></app-spinner>
</app-de-wrapper>

<app-de-wrapper *ngIf="!loading && !internalServerError">
  <img class="icon-user-img" src="assets/icon/Icon-User-Single-2.svg" />

  <h1 class="heading">Identität bestätigen</h1>

  <p class="info-text">
    Im nächsten Schritt bitten wir Sie, sich per Online&#8209;Banking
    anzumelden, um Ihre Identität&nbsp;zu&nbsp;bestätigen.
  </p>
  <p class="sub-info-text">
    Dieser Schritt ist notwendig, um Ihnen die gewählte
    Zahlungsmethode&nbsp;zu&nbsp;gewähren.
  </p>

  <div class="footer-wrapper">
    <div class="footer-container">
      <div class="button-container">
        <zaver-primary-button
          text="Weiter"
          [disabled]="navigationInProgress"
          (click)="handleCtaClick()"
        ></zaver-primary-button>
      </div>
    </div>
  </div>
</app-de-wrapper>
