import { AfterViewInit, Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import {
  PaymentMethod,
  PaymentRequestStatus,
} from '../../model/payment-request/payment-request.dto';
import { SettlementService } from '../../service/settlement/settlement.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { QueryParamsService } from '../../service/query-params/query-params.service';

import { BankIdSignatureStatusIdentifier } from '../../model/payment-request/bank-id-signature-status.dto';
import { routeNames } from '../../../assets/val/route-constants';
import { AppDeepLinkService } from 'src/app/service/app-deeplink/app-deep-link.service';
import { LoggingService } from 'src/app/service/logging-service/logging.service';
import { UserAgentService } from 'src/app/service/user-agent/user-agent.service';

@Component({
  selector: 'app-credit-settlement',
  templateUrl: './settle.component.html',
  styleUrls: ['./settle.component.css', '../page-shared.css'],
})
export class SettleComponent implements OnInit, AfterViewInit, OnDestroy {
  public signatureStatus: string;
  public paymentRequestStatus: string;
  public showStartBankIdButton: boolean;
  public signatureRequired: boolean;
  private stateSubscription: Subscription;
  public waitFor: Awaiting;

  public arr: string[] = [];
  skipUpdates: number;

  onDesktop: boolean = false;

  constructor(
    private store: Store,
    private settlementService: SettlementService,
    private router: Router,
    private queryParamsService: QueryParamsService,
    private deepLinkService: AppDeepLinkService,
    private loggingService: LoggingService,
    private userAgentService: UserAgentService
  ) {}

  ngOnInit() {
    this.bindInstanceToState();
    this.onDesktop = !this.userAgentService.isMobile();
  }

  ngAfterViewInit() {
    this.resumeMethodDependantSettlement();
    if (this.userAgentService.isMobile() && this.signatureRequired) {
      setTimeout(() => {
        this.showStartBankIdButton = true;
      }, 5000);
    }
  }

  ngOnDestroy(): void {
    this.loggingService.log(
      'ngondestroy, unsubscribing ' + this.stateSubscription.closed
    );
    if (this.stateSubscription) {
      this.stateSubscription.unsubscribe();
    }
    this.settlementService.cancelSettlement();
  }

  private resumeMethodDependantSettlement() {
    const selectedMethodType =
      this.queryParamsService.getQueryParams().methodType;

    if (selectedMethodType === PaymentMethod.CREDIT) {
      this.signatureRequired = true;
      this.waitFor = Awaiting.BANKID_SIGNATURE;
      this.resumeInstallmentsSettlement();
    } else if (selectedMethodType === PaymentMethod.INVOICE) {
      this.signatureRequired = true;
      this.waitFor = Awaiting.BANKID_SIGNATURE;
      this.resumeInvoiceSettlement();
    } else if (selectedMethodType === PaymentMethod.TRUSTLY) {
      this.signatureRequired = false;
      this.waitFor = Awaiting.STATUS_SETTLED;
      this.settlementService.initSettlementPolling();
    } else {
      this.loggingService.warn(
        'Customer is on settle-page with unrecognized payment method type: ' +
          selectedMethodType +
          ', polling for SETTLED status as fallback'
      );
      this.signatureRequired = false;
      this.waitFor = Awaiting.STATUS_SETTLED;
      this.settlementService.initSettlementPolling();
    }
  }

  public deeplinkToBankId() {
    this.deepLinkService.startBankId(); // no autostart token...
  }

  private resumeInstallmentsSettlement() {
    const termId = this.queryParamsService.getQueryParams().termId;
    const shouldOpenBankId =
      this.queryParamsService.getQueryParams().shouldOpenBankId;

    if (shouldOpenBankId === 'true') {
      this.settlementService.initInstallmentsSettlement(termId);
    } else {
      this.settlementService.resumeBankIdSignatureSettlementPolling();
    }
  }

  private resumeInvoiceSettlement() {
    const shouldOpenBankId =
      this.queryParamsService.getQueryParams().shouldOpenBankId;
    const loanOfferId = this.queryParamsService.getQueryParams().termId;

    if (shouldOpenBankId === 'true') {
      this.settlementService.initInvoiceSettlement(loanOfferId);
    } else {
      this.settlementService.resumeBankIdSignatureSettlementPolling();
    }
  }

  private bindInstanceToState() {
    this.skipUpdates = 2;
    this.stateSubscription = this.store.subscribe((state) => {
      this.signatureStatus = state.paymentRequest.bankIdStatus.status;
      this.loggingService.log(
        'in signature state polling function, signature status= ' +
          this.signatureStatus
      );
      if (this.skipUpdates-- > 0) {
        // on resume, store always returns old failed status first, since we haven't started polling yet
        return;
      }

      this.paymentRequestStatus = state.paymentRequest.request.status;

      this.loggingService.log(
        this.paymentRequestStatus,
        this.paymentRequestStatus === PaymentRequestStatus.SETTLED
      );

      this.loggingService.log(
        this.signatureStatus,
        this.signatureStatus ===
          BankIdSignatureStatusIdentifier.COMPLETED_SUCCESSFULLY
      );

      if (
        this.paymentRequestStatus === PaymentRequestStatus.SETTLED ||
        this.signatureStatus ===
          BankIdSignatureStatusIdentifier.COMPLETED_SUCCESSFULLY
      ) {
        this.router
          .navigate([routeNames.SUCCESS], { queryParamsHandling: 'preserve' })
          .then(
            () => {},
            (rejection) => {
              this.loggingService.error(
                `Navigation from ${routeNames.SETTLE} to ${routeNames.SUCCESS} was rejected.`,
                rejection
              );
            }
          );

        if (this.stateSubscription) {
          this.stateSubscription.unsubscribe();
        }
        return;
      }

      if (this.signatureStatus === BankIdSignatureStatusIdentifier.FAILED) {
        const selectedMethod =
          this.queryParamsService.getQueryParams().methodType;

        this.settlementService.cancelSettlement();
        if (this.stateSubscription) {
          this.stateSubscription.unsubscribe();
        }

        // Navigate to the invoice summary.
        if (selectedMethod === PaymentMethod.INVOICE) {
          this.router
            .navigate([routeNames.INVOICE_CONFIRM], {
              queryParamsHandling: 'preserve',
            })
            .then(
              () => {},
              (rejection) => {
                this.loggingService.error(
                  `Navigation from ${routeNames.SETTLE} to ${routeNames.INVOICE_CONFIRM} was rejected.`,
                  rejection
                );
              }
            );
        }

        // Navigate to the installment plan selection view.
        if (selectedMethod === PaymentMethod.CREDIT) {
          this.router
            .navigate([routeNames.SELECT_INSTALLMENT_PLAN], {
              queryParamsHandling: 'preserve',
            })
            .then(
              () => {},
              (rejection) => {
                this.loggingService.error(
                  `Navigation from ${routeNames.SETTLE} to ${routeNames.SELECT_INSTALLMENT_PLAN} was rejected.`,
                  rejection
                );
              }
            );
        }
      }
    });
  }

  public getMessage(status: string) {
    if (this.waitFor == Awaiting.BANKID_SIGNATURE) {
      switch (status) {
        case PaymentRequestStatus.OPEN:
          return 'Inväntar BankID-signatur';
        case PaymentRequestStatus.PROCESSING_SIGNATURE:
          return 'Behandlar signatur';
        case PaymentRequestStatus.SETTLED:
          return 'Signaturen är registrerad';
        case PaymentRequestStatus.PROPRIETARY:
          return 'Ett fel har uppstått';
        default:
          return 'Vänta...';
      }
    } else {
      switch (status) {
        case PaymentRequestStatus.OPEN:
          return 'Inväntar svar';
        case PaymentRequestStatus.PROCESSING_SIGNATURE:
          return 'Inväntar svar';
        case PaymentRequestStatus.SETTLED:
          return 'Betalningen är registrerad';
        case PaymentRequestStatus.PROPRIETARY:
          return 'Ett fel har uppstått';
        default:
          return 'Vänta...';
      }
    }
  }

  reroute(): void {
    if (this.stateSubscription) {
      this.stateSubscription.unsubscribe();
    }
    this.settlementService.cancelSettlement();
    this.router.navigate([routeNames.INVOICE_CONFIRM], {
      queryParamsHandling: 'merge',
    });
  }
}

enum Awaiting {
  BANKID_SIGNATURE = 'BANKID',
  STATUS_SETTLED = 'STATUS_SETTLED',
}
