import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectorItem } from '@app/component/selector/selector.component';
import { CreditOptionDto } from '@app/model/credit/credit-option.dto';
import { RouteComponent } from '@app/route/route.component';
import { CreditDecisionService } from '@app/service/credit-decision/credit-decision.service';
import { CreditService } from '@app/service/credit/credit.service';
import { HeaderService } from '@app/service/header/header.service';
import { NoUserService } from '@app/service/no-user-service/no-user.service';
import { QueryParamsService } from '@app/service/query-params/query-params.service';
import { StateService } from '@app/service/state/state.service';
import { StandardCheckoutResponse } from '@app/service/user-service/user.service';
import { ValuePipe } from '@app/shared/pipe/value.pipe';

@Component({
  selector: 'no-invoice-confirm',
  templateUrl: './no-invoice-confirm.component.html',
  styleUrls: ['../../page-shared.css', './no-invoice-confirm.component.scss'],
})
export class NoInvoiceConfirmComponent
  extends RouteComponent
  implements OnInit
{
  modalActive: boolean;

  public loading = true;
  public navigationInProgress: boolean;
  public invoiceInfo: any;
  public showTermsSpinner: boolean;
  public fallbackOffer: CreditOptionDto;

  fallbackInfo: string;
  loanTermsString: string = '';
  selectorItems: SelectorItem[];

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected stateService: StateService,
    protected headerService: HeaderService,
    protected creditService: CreditService,
    protected creditDecisionService: CreditDecisionService,
    protected queryParamsService: QueryParamsService,
    private noUserService: NoUserService,
    private valuePipe: ValuePipe
  ) {
    super(router, route, stateService, headerService);
  }

  ngOnInit(): void {
    this.prepareModalInformation();
    this.loading = false;
    this.loanTermsString = this.buildLoanTermsStr();
  }

  async handleCtaClick() {
    try {
      let resp: StandardCheckoutResponse = await this.noUserService.commit();
      if (resp.success) {
        this.navigate();
      }
    } catch (err) {
      this.handleError(err);
    }
  }

  openInvoiceTerms() {
    const creditOption: CreditOptionDto = this.invoiceInfo;
    this.showTermsSpinner = true;
  }

  getFormattedInterest(interest: number): string {
    if (!interest) {
      interest = 0;
    }
    return (interest * 100).toFixed(2);
  }

  toggleModal() {
    this.modalActive = !this.modalActive;
  }

  prepareModalInformation() {
    this.selectorItems = [
      {
        tab: 'Ditt kjøp',
        title: 'Sammendrag',
        lineItems: this.state.paymentInformation?.lineItems,
        totalValue: `Totalt inkl. moms: ${this.valuePipe.transform(
          this.state.totalCheckoutValue
        )}`,
      },
    ];
  }

  buildLoanTermsStr(): string {
    return `Dersom du ikke betaler fakturaen innen ${
      this.invoiceInfo.numberOfDaysUntilFinalDueDate
    } dager, blir betalingsplanen en ${
      this.fallbackOffer.numberOfMonths
    }-måneders delbetaling. Et slikt annuitetslån har en årlig rente på ${this.getFormattedInterest(
      this.fallbackOffer.nominalInterest
    )} % iht. ${this.fallbackOffer.initialFee.toFixed(
      0
    )} NOK, admin. abg. ${this.fallbackOffer.monthlyFee.toFixed(
      0
    )} NOK/mnd, effektiv rente ${this.getFormattedInterest(
      this.fallbackOffer.effectiveInterest
    )} % og total sum av betalinger ${this.fallbackOffer.totalAmount.toFixed(
      0
    )} NOK.`;
  }
}
