import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { LocationStrategy } from '@angular/common';
import { QueryParamsService } from '../query-params/query-params.service';
import { DisplayFullScreenError } from '../../ngxs/error/error.actions';
import { routeNames } from '../../../assets/val/route-constants';
import {
  PaymentRequestDto,
  LeqType,
  Market,
  PaymentMethod,
} from 'src/app/model/payment-request/payment-request.dto';
import { LoggingService } from '../logging-service/logging.service';
import { ErrorDialogComponent } from '@app/component/dialog/error/error-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TransferComponent } from '@app/route/transfer/transfer.component';

/**
 * This service provides it's consumers with a simple interface to retrieve the
 * next route in a defined customer journey.
 *
 * In order to determine which user journey that is the current one it checks
 * the current path (using the router) and the ngxs (using the NgXS store).
 * */
@Injectable({
  providedIn: 'root',
})
export class CustomerJourneyService {
  /**
   * Each array defined in this static member is responsible for providing the
   * natural order of route that a user is expected to visit given a specific
   * user journey defined by business requirements.
   * */
  private readonly PAYMENT_METHOD_CUSTOMER_JOURNEYS: Journeys = {
    TRUSTLY: {
      initJourney: () => {
        localStorage.removeItem(TransferComponent.LOCAL_STORAGE_KEY_IFRAME_URL);
      },
      routes: [
        { route: `/${routeNames.HOME}` },
        {
          route: `/${routeNames.PERSONAL_NUMBER_ENTRY}`,
          shouldInclude: this.isNinRequired.bind(this),
        },
        { route: `/${routeNames.BANK_TRANSFER_FRAME}` },
      ],
    },
    INVOICE: {
      routes: [
        { route: `/${routeNames.HOME}` },
        { route: `/${routeNames.AUTH}`, shouldInclude: () => false },
        { route: `/${routeNames.EMAIL_AND_INVOICE}` },
        {
          route: `/${routeNames.LEQ_SUMMARY}`,
          shouldInclude: this.isLeqRequiredForSelectedPaymentMethod.bind(this),
        },
        {
          route: `/${routeNames.LEQ}/1`,
          mandatory: false,
          shouldInclude: this.isLeqRequiredForSelectedPaymentMethod.bind(this),
        },
        {
          route: `/${routeNames.LEQ}/2`,
          mandatory: false,
          shouldInclude: this.isLeqRequiredForSelectedPaymentMethod.bind(this),
        },
        { route: `/${routeNames.INVOICE_CONFIRM}` },
        { route: `/${routeNames.SETTLE}` },
        { route: `/${routeNames.SUCCESS}` },
      ],
    },
    CREDIT: {
      routes: [
        { route: `/${routeNames.HOME}` },
        { route: `/${routeNames.AUTH}`, shouldInclude: () => false },
        { route: `/${routeNames.EMAIL_AND_INVOICE}` },
        {
          route: `/${routeNames.LEQ_SUMMARY}`,
          shouldInclude: this.isLeqRequiredForSelectedPaymentMethod.bind(this),
        },
        {
          route: `/${routeNames.LEQ}/1`,
          mandatory: false,
          shouldInclude: this.isLeqRequiredForSelectedPaymentMethod.bind(this),
        },
        {
          route: `/${routeNames.LEQ}/2`,
          mandatory: false,
          shouldInclude: this.isLeqRequiredForSelectedPaymentMethod.bind(this),
        },
        { route: `/${routeNames.SELECT_INSTALLMENT_PLAN}` },
        { route: `/${routeNames.SUCCESS}` },
      ],
    },
    INSTALLMENTS: {
      routes: [
        { route: `/${routeNames.HOME}` },
        { route: `/${routeNames.AUTH}`, shouldInclude: () => false },
        { route: `/${routeNames.EMAIL_AND_INVOICE}` },
        {
          route: `/${routeNames.LEQ_SUMMARY}`,
          shouldInclude: this.isLeqRequiredForSelectedPaymentMethod.bind(this),
        },
        {
          route: `/${routeNames.LEQ}/1`,
          mandatory: false,
          shouldInclude: this.isLeqRequiredForSelectedPaymentMethod.bind(this),
        },
        {
          route: `/${routeNames.LEQ}/2`,
          mandatory: false,
          shouldInclude: this.isLeqRequiredForSelectedPaymentMethod.bind(this),
        },
        { route: `/${routeNames.SELECT_INSTALLMENT_PLAN}` },
        { route: `/${routeNames.SUCCESS}` },
      ],
    },
    SWISH_EXTERNAL: {
      routes: [
        { route: `/${routeNames.HOME}` },
        { route: `/${routeNames.SWISH}` },
        { route: `/${routeNames.SUCCESS}` },
      ],
    },
    INSTANT_DEBIT: {
      initJourney: () => {},
      routes: [
        { route: `/${routeNames.HOME}` },
        { route: `/${routeNames.TRUSTLY_DIRECT_DEBIT}` },
        { route: `/${routeNames.TRUSTLY_DIRECT_DEBIT_EMAIL}` },
        { route: `/${routeNames.SUCCESS}` },
      ],
    },
  };

  constructor(
    private store: Store,
    private queryParamsService: QueryParamsService,
    private locationStrategy: LocationStrategy,
    private loggingService: LoggingService,
    private dialogService: MatDialog,
    private router: Router
  ) {}

  /**
   * @return The next route in a given user journey specification array,
   * given a current path.
   *
   * Works by iterating over the user journey route objects and returning
   * the next one if a match is found.
   * */
  private getSucceedingRoute(
    journey: CustomerJourney,
    currentRoute: string,
    skipOptionalSteps?: boolean
  ): string {
    const routeIndex = journey.routes.findIndex(
      (r) => r.route === currentRoute
    );
    if (routeIndex === -1) {
      this.loggingService.log('could not find current route=' + currentRoute);
      // could not find the current route
      return;
    }
    const considerRoutes = journey.routes.slice(routeIndex + 1);

    //TEMP
    let routes: string[] = [];
    for (let routeObj of considerRoutes) {
      routes.push(routeObj.route);
    }
    this.loggingService.warn(
      'Finding next route in routes: ' + routes.join(', ')
    );

    const nextRoute = considerRoutes.find(
      (r) =>
        (!r.shouldInclude || r.shouldInclude()) &&
        !(skipOptionalSteps === true && r.mandatory === false)
    );
    this.loggingService.warn('Next route found: ' + nextRoute.route); //TEMP
    if (!nextRoute) {
      // could not find a next route
      return;
    }
    return nextRoute.route;
  }

  /**
   * @return The previous route in a given user journey specification array,
   * given a current path.
   */
  private getPrecedingRoute(
    journey: CustomerJourney,
    currentRoute: string,
    skipOptionalSteps?: boolean
  ): string {
    const routeIndex = journey.routes.findIndex(
      (r) => r.route === currentRoute
    );
    if (routeIndex === -1) {
      this.loggingService.log('could not find current route=' + currentRoute);
      // could not find the current route
      return;
    }
    const considerRoutes = journey.routes.slice(0, routeIndex - 1).reverse();

    //TEMP
    let routes: string[] = [];
    for (let routeObj of considerRoutes) {
      routes.push(routeObj.route);
    }
    this.loggingService.warn(
      'Finding previous route in routes: ' + routes.join(', ')
    );

    const previousRoute = considerRoutes.find(
      (r) =>
        (!r.shouldInclude || r.shouldInclude()) &&
        !(skipOptionalSteps === true && r.mandatory === false)
    );
    this.loggingService.warn('Previous route found: ' + previousRoute.route);
    if (!previousRoute) {
      // could not find a next route
      return;
    }
    return previousRoute.route;
  }

  private isLeqRequiredForSelectedPaymentMethod(): boolean {
    const paymentRequest: PaymentRequestDto =
      this.store.snapshot().paymentRequest.request;
    const selectedMethodId = this.queryParamsService.getQueryParams().method;
    const selectedMethod = paymentRequest.availableMethods.find(
      (m) => m.id === selectedMethodId
    );

    if (!selectedMethod) {
      // if we could not find the selected payment method this is an error
      this.handleNavigationError();
    }
    return selectedMethod.requiredLeqType !== LeqType.NONE;
  }

  private isNinRequired(): boolean {
    const paymentRequest: PaymentRequestDto =
      this.store.snapshot().paymentRequest.request;
    // this is for market DK
    return paymentRequest.market === Market.SE || paymentRequest.market == null;
  }

  private handleNavigationError() {
    const dialogConfiguration = {
      maxHeight: '50vh',
      maxWidth: '80vw',
      data: {
        heading: 'Navigering misslyckades',
        message: 'Vänligen försök igen',
        defaultHeading: false,
        buttons: [
          {
            title: 'Försök igen',
            callback: () => {
              this.dialogService.closeAll();
              location.reload();
            },
          },
        ],
      },
    };

    this.loggingService.warn(
      "Opening error dialog because next route couldn't be determined"
    );
    this.router
      .navigate([routeNames.HOME], { queryParamsHandling: 'merge' })
      .then(() => {
        this.dialogService.open(ErrorDialogComponent, dialogConfiguration);
      });
  }

  getNextRoute(skipOptionalSteps?: boolean): Observable<any> {
    // string?
    const baseHref = '/' + this.locationStrategy.getBaseHref();
    const currentUrlPath = window.location.pathname.startsWith(baseHref)
      ? window.location.pathname.substring(baseHref.length)
      : window.location.pathname;
    const selectedPaymentMethodType =
      this.queryParamsService.getQueryParams().methodType;

    //TEMP
    this.loggingService.warn(
      'Attempting to navigate with base href, currentUrlPath and selectedMethodtype: ' +
      [baseHref, currentUrlPath, selectedPaymentMethodType].join(', ')
    );

    return new Observable((observer) => {
      const journey =
        this.PAYMENT_METHOD_CUSTOMER_JOURNEYS[selectedPaymentMethodType];
      const nextRoute = this.getSucceedingRoute(
        journey,
        currentUrlPath,
        skipOptionalSteps
      );
      if (nextRoute) {
        observer.next(nextRoute);
        observer.complete();
      } else {
        this.handleNavigationError();
      }
    });
  }

  getPreviousRoute(skipOptionalSteps?: boolean): Observable<any> {
    const baseHref = '/' + this.locationStrategy.getBaseHref();
    const currentUrlPath = window.location.pathname.startsWith(baseHref)
      ? window.location.pathname.substring(baseHref.length)
      : window.location.pathname;
    const selectedPaymentMethodType =
      this.queryParamsService.getQueryParams().methodType;

    //TEMP
    this.loggingService.warn(
      'Attempting to navigate with base href, currentUrlPath and selectedMethodtype: ' +
      [baseHref, currentUrlPath, selectedPaymentMethodType].join(', ')
    );

    return new Observable((observer) => {
      const journey =
        this.PAYMENT_METHOD_CUSTOMER_JOURNEYS[selectedPaymentMethodType];
      const previousRoute = this.getPrecedingRoute(
        journey,
        currentUrlPath,
        skipOptionalSteps
      );
      if (previousRoute) {
        observer.next(previousRoute);
        observer.complete();
      } else {
        this.handleNavigationError();
      }
    });
  }

  beginJourney(selectedPaymentMethod: PaymentMethod): Observable<any> {
    const journey: CustomerJourney =
      this.PAYMENT_METHOD_CUSTOMER_JOURNEYS[selectedPaymentMethod];
    if (!!journey.initJourney) {
      journey.initJourney();
    }
    return this.getNextRoute();
  }
}

type CustomerJourney = {
  initJourney?: () => void;
  routes: CustomerJourneySteps[];
};

type CustomerJourneySteps = {
  route: string;
  mandatory?: boolean;
  shouldInclude?: () => boolean;
};

interface Journeys {
  [key: string]: CustomerJourney;
}
