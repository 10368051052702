<div class="title" *ngIf="!!title">{{ title }}</div>
<div class="dropdown">
  <button
    type="button"
    (click)="toggleDropDown()"
    [ngClass]="size"
    class="dropdown-button"
    [class.isActive]="showDropDown"
    [class.focused]="showDropDown"
    [class.invalid]="error"
    [class.disabled]="disabled"
    (keydown)="onKeydown($event)"
  >
    <div class="content-wrapper">
      <img *ngIf="error" src="assets/icon/icon_error.svg" class="error-icon" />

      <img *ngIf="!!selectedItem.imgSrc" src="{{ selectedItem.imgSrc }}" />

      <div class="text-container">
        <span class="help-text" *ngIf="!!selectedItem.value && !!helpText">
          {{ helpText }}
        </span>

        <span [ngClass]="{ 'invalid-value': !selectedItem.value }">
          {{ selectedItem.label }}
        </span>
      </div>
    </div>
  </button>

  <div [class.disabled]="disabled" (click)="toggleDropDown()" class="arrow">
    <img class="img" src="assets/icon/Icon-Navigation-Arrow-Down.svg" />
  </div>

  <ng-container *ngIf="!!selectableItems && showDropDown">
    <zaver-custom-select
      style="width: 100%"
      [showDropdown]="showDropDown"
      [selectedItem]="selectedItem"
      [items]="selectableItems"
      (dropdownClosed)="toggleDropDown()"
      (itemSelected)="selectItem($event)"
      [shorterDropdown]="shorterDropdown"
    ></zaver-custom-select>
  </ng-container>
</div>
<div class="error" *ngIf="error">
  <span>{{ errorMessage }}</span>
</div>
