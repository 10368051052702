<app-de-thin-wrapper *ngIf="loading">
  <app-spinner></app-spinner>
</app-de-thin-wrapper>

<app-de-thin-wrapper *ngIf="!loading">
  <div class="container">
    <p class="heading">{{ state.interruptionDetails.heading }}</p>

    <p class="info-text">{{ state.interruptionDetails.message }}</p>

    <div class="button-wrapper">
      <zaver-secondary-button-outlined
        [text]="'Velg en annen betalingsmetode'"
        (click)="handleCtaClick()"
        [rounded]="true"
      ></zaver-secondary-button-outlined>
    </div>
  </div>
</app-de-thin-wrapper>
