import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Currency } from '@app/model/currency';
import {
  LineItem,
  Market,
  PaymentMethod,
  PaymentRequestDto,
} from '@app/model/payment-request/payment-request.dto';
import { SERVER_BASE_URL } from 'src/environments/environment';
import { Router } from '@angular/router';
import { LoanOption } from '@app/route/shared/LoanOption';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  token: string;

  constructor(private http: HttpClient, private router: Router) {}

  setToken(id: string) {
    this.token = id;
  }

  getToken() {
    return this.token;
  }

  fetchState(controlParams: StateControlParameters): Promise<CheckoutState> {
    return this.http
      .post<CheckoutState>(`${SERVER_BASE_URL}/request/state/`, controlParams, {
        withCredentials: true,
      })
      .toPromise();
  }

  createResumeStateToken(
    controlParams: StateControlParameters
  ): Promise<string> {
    return this.http
      .post<string>(
        `${SERVER_BASE_URL}/request/state/resume/createToken`,
        controlParams,
        {
          withCredentials: true,
        }
      )
      .toPromise();
  }

  resume(resumeToken: string): Promise<CheckoutState> {
    let params = new HttpParams();

    params = params.append('resumeToken', resumeToken);

    return this.http
      .get<CheckoutState>(`${SERVER_BASE_URL}/request/state/resume/`, {
        withCredentials: true,
        params: params,
      })
      .toPromise();
  }

  reset(controlParams: StateControlParameters): Promise<CheckoutState> {
    return this.http
      .post<CheckoutState>(
        `${SERVER_BASE_URL}/request/state/reset`,
        controlParams,
        { withCredentials: true }
      )
      .toPromise();
  }

  registerState(controlParams: StateControlParameters): Promise<CheckoutState> {
    return this.http
      .post<CheckoutState>(
        `${SERVER_BASE_URL}/request/state/register/`,
        controlParams,
        {
          withCredentials: true,
        }
      )
      .toPromise();
  }

  setValue(currency: Currency): Promise<any> {
    let body: PrecheckUpdateRequest = {
      value: currency,
    };

    return this.http
      .post<PrecheckUpdateRequest>(
        `${SERVER_BASE_URL}/precheck/de/setValue/`,
        body,
        {
          withCredentials: true,
        }
      )
      .toPromise();
  }

  fetchPaymentRequest(token): Promise<PaymentRequestDto> {
    return this.http
      .get<PaymentRequestDto>(`${SERVER_BASE_URL}/request/public/${token}`, {
        withCredentials: true,
      })
      .toPromise();
  }

  getPrecheckLoanOptions(): Promise<any> {
    return this.http
      .get(`${SERVER_BASE_URL}/precheck/de/getPrecheckLoanOptions/`, {
        withCredentials: true,
      })
      .toPromise();
  }

  getPreExistingContactInfo(): Promise<any> {
    let params = new HttpParams();

    return this.http
      .get(`${SERVER_BASE_URL}/user/de/getPreExistingContactInfo/`, {
        withCredentials: true,
        params: params,
      })
      .toPromise();
  }

  getAvailablePaymentMethods(token: string): Promise<any> {
    let params = new HttpParams();

    params = params.append('token', token);

    return this.http
      .get(`${SERVER_BASE_URL}/request/state/availablePaymentMethods/`, {
        withCredentials: true,
        params: params,
      })
      .toPromise();
  }

  async registerChosenMethod(methodId: string) {
    let registrationParams: StateRegistration = {
      paymentMethodId: methodId,
      token: this.token,
    };

    const checkoutState = await this.http
      .post<CheckoutState>(
        `${SERVER_BASE_URL}/request/state/registerChosenMethod/`,
        registrationParams,
        { withCredentials: true }
      )
      .toPromise();

    const route = this.getRouteFromState(checkoutState);
    this.router.navigate([route], { queryParamsHandling: 'preserve' });
  }

  navigate(controlParams: StateControlParameters): Promise<CheckoutState> {
    return this.http
      .post<CheckoutState>(
        `${SERVER_BASE_URL}/request/state/navigate/`,
        controlParams,
        { withCredentials: true }
      )
      .toPromise();
  }

  resolveInterruption(
    controlParams: StateControlParameters
  ): Promise<CheckoutState> {
    return this.http
      .post<CheckoutState>(
        `${SERVER_BASE_URL}/request/state/resolveInterruption/`,
        controlParams,
        { withCredentials: true }
      )
      .toPromise();
  }

  getReadyToLeave(beenWaitingForMs: number): Promise<StayOrLeave> {
    let params = new HttpParams().append(
      'beenWaitingForMs',
      '' + beenWaitingForMs
    );
    return this.http
      .get<StayOrLeave>(`${SERVER_BASE_URL}/request/state/readyToLeave`, {
        params: params,
        withCredentials: true,
      })
      .toPromise();
  }

  //TODO store this on route component somehow? Seems hard to access route component from service
  // these methods are rather ugly and can most likely be streamlined quite severely
  getRouteFromState(state: CheckoutState): string {
    var base: string;

    switch (state.market) {
      case Market.AT:
        base = 'at/';
        break;
      case Market.DE:
        base = 'de/';
        break;
      case Market.NO:
        base = 'no/';
        break;
      default:
        console.error(
          'Attempted to fetch route from state for unimplemented market: ',
          state.market
        );
    }

    var route: string;
    switch (state.currentPageInformation.pageName) {
      case CheckoutStep.HOME:
        route = '';
        break;
      case CheckoutStep.EMAIL_AND_PHONE_REGISTRATION:
        route = 'email-and-phone-registration';
        break;
      case CheckoutStep.PHONE_VERIFICATION:
        route = 'phone-verification';
        break;
      case CheckoutStep.USER_REGISTRATION:
        route = 'user-registration';
        break;
      case CheckoutStep.PRE_FTS:
        route = 'pre-fts';
        break;
      case CheckoutStep.FTS:
        route = 'fts';
        break;
      case CheckoutStep.PART_SUCCESS:
        route = 'part-success';
        break;
      case CheckoutStep.VERIFY_USER:
        route = 'verify-user';
        break;
      case CheckoutStep.SUCCESS:
        route = 'success';
        break;
      case CheckoutStep.RESTORE_ACCOUNT:
        route = 'account-restoration';
        break;
      case CheckoutStep.EDIT_PHONE:
        route = 'edit-phone';
        break;
      case CheckoutStep.ERROR:
        route = 'error';
        break;
      case CheckoutStep.REJECTED:
        route = 'rejected';
        break;
      case CheckoutStep.PRECHECK:
        route = 'precheck';
        break;
      case CheckoutStep.VERIFY_EMAIL:
        route = 'verify-email';
        break;
      case CheckoutStep.EMAIL_REGISTRATION:
        route = 'email';
        break;
      case CheckoutStep.NO_BANKID:
        route = 'auth';
        break;
      case CheckoutStep.NO_VIPPS:
        route = 'vipps';
        break;
      case CheckoutStep.CONFIRM_INVOICE:
        route = 'invoice/confirm';
        break;
      case CheckoutStep.INSTALLMENTS_SELECTION:
        route = 'installments-selection';
        break;
      default:
        console.error(
          'Attempted to fetch route from unimplemented step: ',
          state.currentPageInformation.pageName
        );
    }

    return base + route;
  }

  //TODO ugly method
  getStepFromUrl(url: string) {
    if (url.indexOf('/email-and-phone-registration') !== -1) {
      return CheckoutStep.EMAIL_AND_PHONE_REGISTRATION;
    }
    if (url.indexOf('/phone-verification') !== -1) {
      return CheckoutStep.PHONE_VERIFICATION;
    }
    if (url.indexOf('/user-registration') !== -1) {
      return CheckoutStep.USER_REGISTRATION;
    }
    if (url.indexOf('/pre-fts') !== -1) {
      return CheckoutStep.PRE_FTS;
    }
    if (url.indexOf('/fts') !== -1) {
      return CheckoutStep.FTS;
    }
    if (url.indexOf('/part-success') !== -1) {
      return CheckoutStep.PART_SUCCESS;
    }
    if (url.indexOf('/verify-user') !== -1) {
      return CheckoutStep.VERIFY_USER;
    }
    if (url.indexOf('/success') !== -1) {
      return CheckoutStep.SUCCESS;
    }
    if (url.indexOf('/account-restoration') !== -1) {
      return CheckoutStep.RESTORE_ACCOUNT;
    }
    if (url.indexOf('/edit-phone') !== -1) {
      return CheckoutStep.EDIT_PHONE;
    }
    if (url.indexOf('/error') !== -1) {
      return CheckoutStep.ERROR;
    }
    if (url.indexOf('/rejected') !== -1) {
      return CheckoutStep.REJECTED;
    }
    if (url.indexOf('/precheck') !== -1) {
      return CheckoutStep.PRECHECK;
    }
    if (url.indexOf('/verify-email') !== -1) {
      return CheckoutStep.VERIFY_EMAIL;
    }
    if (url.indexOf('/email') !== -1) {
      return CheckoutStep.EMAIL_REGISTRATION;
    }
    if (url.indexOf('/auth') !== -1) {
      return CheckoutStep.NO_BANKID;
    }
    if (url.indexOf('no/vipps') !== -1) {
      return CheckoutStep.NO_VIPPS;
    }
    if (url.indexOf('no/invoice/confirm') !== -1) {
      return CheckoutStep.CONFIRM_INVOICE;
    }
    if (url.indexOf('no/installments-selection') !== -1) {
      return CheckoutStep.INSTALLMENTS_SELECTION;
    }
    if (url.indexOf('no/success') !== -1) {
      return CheckoutStep.SUCCESS;
    }
    if (url.indexOf('no/error') !== -1) {
      return CheckoutStep.ERROR;
    }
    if (url.indexOf('no/rejected') !== -1) {
      return CheckoutStep.REJECTED;
    }

    if (url.indexOf('de/verify-email') !== -1) {
      return CheckoutStep.MONTHLY_INVOICE;
    }

    if (url.indexOf('de/installments-selection') !== -1) {
      return CheckoutStep.INSTALLMENTS_SELECTION;
    }

    return CheckoutStep.HOME;
  }
}

export interface CheckoutState {
  currentPageInformation: CurrentPageInformation;
  forceNavigation: boolean;
  id: string;
  interruptionDetails: InterruptionDetails;
  language: string;
  loanOptions: LoanOption[];
  loanOfferId: string;
  market: Market;
  merchantUrls: any;
  paymentInformation: PaymentInformation;
  status: CheckoutStatus;
  totalCheckoutValue: Currency;
  zaverProduct: ZaverProduct;
  checkoutCustomizations: CheckoutCustomizations;
  checkoutToken: string;
}

interface CheckoutCustomizations {
  logoUrl: string;
  merchantTermsUrl: string;
  merchantName: string;
}

export enum CheckoutStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  PURCHASE_CONFIRMATION_IN_PROGRESS = 'PURCHASE_CONFIRMATION_IN_PROGRESS',
  CANCELLED = 'CANCELLED',
}

export enum ZaverProduct {
  ECOM_CHECKOUT = 'ECOM_CHECKOUT',
  IN_STORE_CHECKOUT = 'IN_STORE_CHECKOUT',
  TOKENIZED_PAYMENT = 'TOKENIZED_PAYMENT',
  PAYER_ONBOARDING = 'PAYER_ONBOARDING',
  PRECHECK = 'PRECHECK',
  CASHOUT = 'CASHOUT',
  B2B = 'B2B',
}

export interface PaymentInformation {
  description: string;
  lineItems: LineItem[];
  title: string;
  paymentMethod: PaymentMethod;
}

export interface CurrentPageInformation {
  pageName: CheckoutStep;
  homePage: boolean;
  pageSpecificParameters: any;
}

export enum ResolveType {
  CTA = 'CTA',
  LINK = 'LINK',
}

export interface StateControlParameters {
  currentPageName: CheckoutStep;
  token: string;
}

export interface InterruptionControlParameters extends StateControlParameters {
  resolveType: ResolveType;
}

export interface StateRegistration {
  token: string;
  paymentMethodId: string;
}

export enum CheckoutStep {
  HOME = 'HOME',
  EMAIL_AND_PHONE_REGISTRATION = 'EMAIL_AND_PHONE_REGISTRATION',
  PHONE_VERIFICATION = 'PHONE_VERIFICATION',
  USER_REGISTRATION = 'USER_REGISTRATION',
  PRE_FTS = 'PRE_FTS',
  FTS = 'FTS',
  PART_SUCCESS = 'PART_SUCCESS',
  VERIFY_USER = 'VERIFY_USER',
  SUCCESS = 'SUCCESS',
  RESTORE_ACCOUNT = 'RESTORE_ACCOUNT',
  EDIT_PHONE = 'EDIT_PHONE',
  ERROR = 'ERROR',
  REJECTED = 'REJECTED',
  PRECHECK = 'PRECHECK',
  VERIFY_EMAIL = 'VERIFY_EMAIL',
  NO_BANKID = 'NO_BANKID',
  NO_VIPPS = 'NO_VIPPS',
  EMAIL_REGISTRATION = 'EMAIL_REGISTRATION',
  CONFIRM_INVOICE = 'CONFIRM_INVOICE',
  MONTHLY_INVOICE = 'MONTHLY_INVOICE',
  INSTALLMENTS_SELECTION = 'INSTALLMENTS_SELECTION',
}

export enum InterruptionType {
  ERROR = 'ERROR',
  REJECTION = 'REJECTION',
}
export interface InterruptionDetails {
  interuptionType: InterruptionType;
  shouldDisplaySadFredrik: boolean;
  heading: string;
  message: string;
  ctaLabel: string;
  cancelUrlOverridesCta: string;
  linkLabel: string;
  linkexternaldestination: string;
  cancelurloverrideslink: boolean;
}

export interface PrecheckUpdateRequest {
  value: Currency;
}

export interface registrationBody {
  token: string;
}

export interface StayOrLeave {
  waitForMs: number;
  then: WaitThen;
  url: string;
}

export enum WaitThen {
  /**
   * WAIT implies showing a spinner and a message.
   */
  WAIT = 'WAIT',
  /**
   * STAY means there is nowhere to go to, or possibly that we have given up
   */
  STAY = 'STAY',
  /**
   * LEAVE means the payer should be sent to their next destination, e.g. the success URL
   */
  LEAVE = 'LEAVE',
}
