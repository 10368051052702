import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StateService } from '@app/service/state/state.service';
import { RouteComponent } from '../../route.component';
import { UserService } from '@app/service/user-service/user.service';
import { HeaderService } from '@app/service/header/header.service';
import { Market } from '@app/model/payment-request/payment-request.dto';
import { Location } from '@angular/common';

@Component({
  selector: 'app-de-fts',
  templateUrl: './de-fts.component.html',
  styleUrls: ['../../page-shared.css', './de-fts.component.scss'],
})
export class FtsComponent extends RouteComponent implements OnInit, OnDestroy {
  loading = false;
  error = false;
  sessionApiKey: string;
  transactionId: string;

  @ViewChild('ftsContainer') ftsContainer;
  ftsDiv: any;
  headScript: any;
  bodyScript: any;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected stateService: StateService,
    protected headerService: HeaderService,
    private userService: UserService,
    private location: Location
  ) {
    super(router, route, stateService, headerService);
  }

  ngOnInit() {
    this.setup();
  }

  ngOnDestroy(): void {
    this.removeScript();
  }

  async setup(): Promise<void> {
    await this.fetchFtsSession();
    await this.loadFtsHeadScript();
  }

  async fetchFtsSession() {
    const res = await this.userService.getX2saSession();
    this.sessionApiKey = res.sessionKey;
    this.transactionId = res.transactionId;
    this.loading = false;
  }

  removeScript() {
    this.headScript?.bodyScript?.remove();
    this.headScript?.remove();
  }

  async loadFtsHeadScript(): Promise<void> {
    this.removeScript();

    this.headScript = document.createElement('script');
    this.headScript.src = 'https://api.xs2a.com/xs2a.js';
    this.headScript.type = 'text/javascript';

    this.headScript.sessionApiKey = this.sessionApiKey;
    this.headScript.selectPreferredBankCountry = !!this.state?.market;
    this.headScript.preferredBankCountry = Market[this.state.market];

    // Wait for headscript to load before initializing body script
    this.headScript.addEventListener('load', function () {
      this.bodyScript = document.createElement('script');
      this.bodyScript.type = 'text/javascript';
      this.bodyScript.textContent = `
        xs2a.finish(function() {
            // Called when the session is finished
            window.dispatchEvent(new CustomEvent('finish'));
        });

        xs2a.error(function(errorCode, messages, recoverable) {
          window.dispatchEvent(new CustomEvent('log-error', {
            "detail": {
              "errorCode": errorCode,
              "messages": messages,
              recoverable: recoverable
             }}
          ));
        });

        xs2a.useBaseStyles();

        if(${this.selectPreferredBankCountry}) {
          xs2a.render(() => {
            if(xs2a.isBank()) {
              const xs2aBankSelectElement = document.querySelector('div#XS2A-Form > div.xs2a-select > select#XS2A-country_id')
              if(xs2a.isCountryInitialized === null && xs2aBankSelectElement) {
                xs2aBankSelectElement.value = ${this.preferredBankCountry}
                xs2a.isCountryInitialized = true;
              }
            }
          })
        };

        xs2a.render(() => {
          document.querySelectorAll('[type="password"]').forEach(input => input.autocomplete = 'current-password');
        });

        xs2a.configure({
          'logo-height': 1,
          'logo-variation': 'white',
        });

        xs2a.abort(() =>  {
          window.dispatchEvent(new CustomEvent('abort'));
        });

        xs2a.init(document.getElementById('XS2A-Form'), '${this.sessionApiKey}');`;

      document.getElementsByTagName('body')[0].appendChild(this.bodyScript);
    });

    document.getElementsByTagName('head')[0].appendChild(this.headScript);
  }

  @HostListener('window:log-error', ['$event'])
  logError(e: CustomEvent) {
    this.userService
      .logFtsError(e.detail.errorCode, e.detail.messages, e.detail.recoverable)
      .subscribe((res) => console.log(res));
  }

  @HostListener('window:finish')
  async finishSuccessfulWizard() {
    this.loading = true;
    await this.userService.fetchFtsReport(this.transactionId);
    this.navigate();
  }

  @HostListener('window:abort')
  abortUnsuccessfulWizard() {
    this.error = true;
  }

  handleCtaClick() {
    this.location.back();
  }
}
