import { Component, OnInit } from '@angular/core';
import { QueryParamsService } from '@app/service/query-params/query-params.service';

@Component({
  selector: 'no-auth-callback',
  template: '',
})
export class NoAuthCallbackComponent implements OnInit {
  private queryParamsService: QueryParamsService;
  constructor() {}

  ngOnInit(): void {
    let params = new URLSearchParams(window.location.search);
    let success = params.get('success');
    let transactionId = params.get('transaction_id');

    window.close();
    window.opener.postMessage({
      success: success == 'true',
      transactionId: transactionId,
    });
  }
}
