import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {SERVER_BASE_URL} from '../../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BankinfoService {

  constructor(private http: HttpClient) {
  }

  tinkLink: string;

  private tinkInfo: TinkInfo;

  public setTinkLink(tinkLink: string): void {
    this.tinkLink = tinkLink;
  }

  public getTinkLink(taskId: string): Observable<TinkLink> {
    return this.http.get<TinkLink>(`${SERVER_BASE_URL}/instantdebit/tinkLink/?taskId=${taskId}`);
  }


  public setTinkInfo(tinkCode: string, state: string, credentialsId: string): void {
    this.tinkInfo = {
      tinkCode: tinkCode,
      state: state,
      credentialsId: credentialsId
    };
  }

  public getTinkInfo(): TinkInfo {
    if (this.tinkInfo) {
      return this.tinkInfo;
    } else {
      return null;
    }
  }

  public getAvailableBanks(taskId: string, market: string) {
    let params = new HttpParams();

    if (taskId) {
      params = params.append('taskId', taskId);
    }
    if (market) {
      params = params.append('market', market);
    }

    return this.http.get<AvailableBanks>(`${SERVER_BASE_URL}/bankinfo/available`, {params: params});
  }

  public getTinkReference(tinkCode: string, taskId: string, credentialsId: string) {
    let params = new HttpParams();

    params = params.append('code', tinkCode);
    params = params.append('taskId', taskId);
    if (credentialsId) {
      params = params.append('credentialsId', credentialsId);
    }
    return this.http.get<TinkReference>(`${SERVER_BASE_URL}/instantdebit/reference`, {params: params});
  }

  public getTinkReference2(accountVerificationReportId: string, taskId: string) {
    let params = new HttpParams();

    params = params.append('accountVerificationReportId', accountVerificationReportId);
    params = params.append('taskId', taskId);

    return this.http.get<boolean>(`${SERVER_BASE_URL}/instantdebit/tinkCallback`, {params: params});
  }

  public getTinkReference3(tinkCode: string, taskId: string, credentialsId?: string) {
    let params = new HttpParams();

    params = params.append('taskId', taskId);
    params = params.append('code', tinkCode);
    if (credentialsId) {
      params = params.append('credentialsId', credentialsId);
    }

    // return this.http.get<boolean>(`${SERVER_BASE_URL}/bankinfo/tinkTransactionsCallback`, {params: params});
    return this.http.get<boolean>(`${SERVER_BASE_URL}/bankinfo/reference`, {params: params});
  }

}

export class AvailableBanks {
  market: string;
  banks: BankInfo[];
  authUrl: string;
}

export class BankInfo {
  authUrl: string;
  displayName: string;
  logoUrl: string;
}

export class TinkInfo {
  tinkCode: string;
  state: string;
  credentialsId: string;
}

export class TinkReference {
  reference: string;
  bankName: string;
}

export interface TinkLink {
  link: string;
  error?: string;
}
