import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RouteComponent } from '../../route.component';
import { StateService } from 'src/app/service/state/state.service';
import {
  DEbirthDateValidator,
  email_validation_regexp,
} from 'src/assets/val/regexp';
import {
  UserRegistrationForm,
  UserRegistrationDto,
  UserService,
  UserFormDataVM,
  UserRegistrationVM,
} from 'src/app/service/user-service/user.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { HeaderService } from '@app/service/header/header.service';
import { SelectorItem } from '@app/component/selector/selector.component';

export interface Title {
  label: string;
  value: string;
}

export interface Country {
  label: string;
  value: string;
  imgSrc?: string;
}

@Component({
  selector: 'app-de-user-registration',
  templateUrl: './de-user-registration.component.html',
  styleUrls: ['../../page-shared.css', './de-user-registration.component.scss'],
})
export class DeUserRegistrationComponent
  extends RouteComponent
  implements OnInit, OnDestroy
{
  titles: Title[] = [
    { label: 'Anrede', value: null },
    { label: 'Herr', value: 'Herr' },
    { label: 'Frau', value: 'Frau' },
    { label: 'Divers', value: 'Divers' },
  ];

  countries: Country[] = [
    { label: 'Land', value: null },
    { label: 'Deutschland', value: 'DE', imgSrc: 'assets/flags/de.svg' },
    { label: 'Österreich', value: 'AT', imgSrc: 'assets/flags/at.svg' },
  ];

  loading = true;
  form: FormGroup;
  formSubscription: Subscription;
  navigationInProgress = false;
  showError = false;
  userRegistrationDto: UserRegistrationDto = null;
  editPreviewData = false;
  infoIsEditable: boolean;
  displayPhoneNumber: string;
  modalActive = false;
  selectorItems: SelectorItem[];
  noInputDisplayed: boolean;
  inputSize: 'small' | 'medium' | 'large';
  invalidDataInPreview = false;
  invalidForms = '';
  selectedCountry: Country = null;
  userRegistrationVM: UserRegistrationVM;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected stateService: StateService,
    protected headerService: HeaderService,
    private userService: UserService
  ) {
    super(router, route, stateService, headerService);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.inputSize = event.target.innerWidth < 520 ? 'medium' : 'large';
  }

  async ngOnInit(): Promise<void> {
    try {
      await this.fetchExistingInfo();
    } catch (error) {
      this.handleError(error);
    }

    this.setUpForm(this.userRegistrationDto);
    this.getPreviwData();
    this.phoneString();
    this.infoEditable();

    this.inputSize = window.innerWidth < 520 ? 'medium' : 'large';

    this.selectorItems = [
      {
        tab: 'Zahlungsbedingungen',
        title: 'Bedingungen für den Kauf auf Rechnung',
        descriptions: [
          {
            text: 'Wenn Sie sich für die Zahlung per Rechnung entscheiden, bieten wir Ihnen die Möglichkeit, innerhalb von 14 Tagen ab dem Datum der Rechnungsstellung zu zahlen.',
          },
        ],
      },
    ];

    this.loading = false;
  }

  ngOnDestroy(): void {
    this.formSubscription && this.formSubscription.unsubscribe();
  }

  async routeToCancelUrl(): Promise<void> {
    this.navigationInProgress = true;
    try {
      const resp = await this.userService.cancelPayment();
      this.navigationInProgress = false;
      if (!resp.error) {
        window.location.href = this.state.merchantUrls.onCancelUrl;
      }
    } catch (error) {
      console.log(error);
    }
  }

  setSelectedCountry(value): void {
    this.form.controls.countryCode.setValue(value);
    this.selectedCountry = this.countries.find(
      (country) => country.value === value
    );
  }

  keyToString(key): string {
    const object = {
      email: 'E-Mail Adresse',
      firstName: 'Vorname',
      familyName: 'Nachname',
      birthDate: 'Geburtsdatum',
      streetName: 'Straße',
      houseNumber: 'Hausnummer',
      zipCode: 'Portleitzahl',
      cityName: 'Stadt/Ort',
      countryCode: 'Land',
    };
    return object[key];
  }

  validatePreviewData(): void {
    const invalidForms = Object.keys(this.form.controls).filter((key) => {
      if (this.form.controls[key].invalid) {
        return key;
      }
    });

    this.invalidForms = invalidForms.reduce(
      (tot, curr) => tot + (tot && ', ') + this.keyToString(curr),
      ''
    );

    this.invalidDataInPreview =
      invalidForms.filter(
        (item) =>
          this.displayInput(this.userRegistrationVM[item]) &&
          !this.userRegistrationVM[item].editable
      ).length > 0;
  }

  saveFormData() {
    if (this.navigationInProgress || this.form.invalid) {
      this.showError = true;
    } else {
      this.editPreviewData = false;
      this.getPreviwData();
    }
  }

  async handleCtaClick(): Promise<void> {
    this.validatePreviewData();
    if (this.navigationInProgress || this.form.invalid) {
      this.showError = true;
    } else {
      this.navigationInProgress = true;
      try {
        await this.userService.registerUser(this.packageUserRegistrationForm());
        this.navigate();
      } catch (err) {
        this.handleError(err);
      }
    }
    this.navigationInProgress = false;
  }

  phoneString(): void {
    const phoneNumberString = this.userRegistrationVM.phoneNumber.value;
    if (!phoneNumberString) {
      return;
    }

    this.displayPhoneNumber = `${phoneNumberString.slice(
      0,
      4
    )} • • • ${phoneNumberString.slice(7)}`;
  }

  infoEditable(): void {
    const values = Object.values(this.userRegistrationVM);
    this.infoIsEditable = values
      .map((item) => !this.displayInput(item) && item.editable)
      .includes(true);
  }

  packageUserRegistrationForm(): UserRegistrationForm {
    const birthdate = this.form.controls.birthDate.value;
    const day = birthdate.slice(0, 2);
    const month = birthdate.slice(3, 5);
    const year = birthdate.slice(6, 10);
    const isoBirthDate = new Date(`${year}-${month}-${day}`).toISOString();

    return {
      firstName: this.form.controls.firstName.value.trim(),
      familyName: this.form.controls.familyName.value.trim(),
      streetName: this.form.controls.streetName.value.trim(),
      houseNumber: this.form.controls.houseNumber.value.trim(),
      zipCode: this.form.controls.zipCode.value.trim(),
      cityName: this.form.controls.cityName.value.trim(),
      countryCode: this.form.controls.countryCode.value.trim(),
      birthDate: isoBirthDate,
      email: this.form.controls.email.value.toLowerCase().trim(),
    };
  }

  getPreviewBackgroundColor(): void {
    const UserRegistrationDataVM = Object.values(this.userRegistrationVM);
    this.noInputDisplayed =
      UserRegistrationDataVM.filter((item) => {
        return this.displayInput(item);
      }).length === 0;
  }

  async fetchExistingInfo(): Promise<void> {
    try {
      this.userRegistrationDto = await this.userService.getActiveUserInfo();
    } catch (err) {
      this.handleError(err);
    }
  }

  abortEditInfo(): void {
    this.setUpForm(this.userRegistrationDto);
    this.editPreviewData = false;
    this.getPreviewBackgroundColor();
  }

  setModalActive(value: boolean): void {
    this.modalActive = value;
  }

  getPreviwData(): void {
    this.userRegistrationVM = {
      birthDate: {
        value: this.form.controls.birthDate.value,
        editable: this.userRegistrationDto.mayEditBirthDate,
      },
      countryCode: {
        value: this.form.controls.countryCode.value,
        editable: this.userRegistrationDto.countryCode.editable,
      },
      cityName: {
        value: this.form.controls.cityName.value,
        editable: this.userRegistrationDto.cityName.editable,
      },
      email: {
        value: this.form.controls.email.value,
        editable: this.userRegistrationDto.email.editable,
      },
      familyName: {
        value: this.form.controls.familyName.value,
        editable: this.userRegistrationDto.familyName.editable,
      },
      firstName: {
        value: this.form.controls.firstName.value,
        editable: this.userRegistrationDto.firstName.editable,
      },
      houseNumber: {
        value: this.form.controls.houseNumber.value,
        editable: this.userRegistrationDto.houseNumber.editable,
      },
      phoneNumber: {
        value: this.userRegistrationDto.phoneNumber.value,
        editable: this.userRegistrationDto.phoneNumber.editable,
      },
      streetName: {
        value: this.form.controls.streetName.value,
        editable: this.userRegistrationDto.streetName.editable,
      },
      zipCode: {
        value: this.form.controls.zipCode.value,
        editable: this.userRegistrationDto.zipCode.editable,
      },
    };

    this.getPreviewBackgroundColor();
  }

  editData() {
    this.editPreviewData = true;
    this.getPreviewBackgroundColor();
  }

  displayInput(userForm: UserFormDataVM) {
    return (userForm.editable && this.editPreviewData) || !userForm.value;
  }

  setUpForm(dto: UserRegistrationDto): void {
    //TODO: synk validators with backend
    const textValidators = [Validators.required, Validators.maxLength(100)];

    const numberValidators = [
      Validators.required,
      Validators.pattern('^[0-9]*$'),
    ];

    this.form = new FormGroup({
      email: new FormControl(
        dto.email.value,
        dto.email.editable && [
          Validators.required,
          Validators.pattern(email_validation_regexp),
        ]
      ),
      firstName: new FormControl(
        dto.firstName.value,
        dto.firstName.editable && textValidators
      ),
      familyName: new FormControl(
        dto.familyName.value,
        dto.familyName.editable && textValidators
      ),
      birthDate: new FormControl(dto.birthDate, [
        Validators.required,
        Validators.pattern(DEbirthDateValidator),
      ]),
      streetName: new FormControl(
        dto.streetName.value,
        dto.streetName.editable && [...textValidators]
      ),
      houseNumber: new FormControl(
        dto.houseNumber.value,
        dto.houseNumber.editable && [Validators.required]
      ),
      zipCode: new FormControl(
        dto.zipCode.value && dto.zipCode.value.replace(/\s/g, ''),
        dto.zipCode.editable && [
          ...numberValidators,
          Validators.minLength(4),
          Validators.maxLength(5),
        ]
      ),
      cityName: new FormControl(
        dto.cityName.value,
        dto.cityName.editable && textValidators
      ),
      countryCode: new FormControl(
        dto.countryCode.value,
        dto.countryCode.editable && [Validators.required]
      ),
    });

    this.selectedCountry = this.countries.find(
      (country) => country.value === this.form.controls.countryCode.value
    );

    this.formSubscription = this.form.controls.birthDate.valueChanges.subscribe(
      (value: string) => {
        let valueArray = value.replace(/[^0-9]/g, '').split('');
        const insert = (arr: string[], index: number, value: string) =>
          (arr = [...arr.slice(0, index), value, ...arr.slice(index)]);

        if (valueArray.length >= 2) {
          valueArray = insert(valueArray, 2, '.');
        }

        if (valueArray.length >= 5) {
          valueArray = insert(valueArray, 5, '.');
        }

        const formattedValue = valueArray.join('');
        if (formattedValue) {
          this.form.patchValue(
            { birthDate: formattedValue },
            { emitEvent: false }
          );
        }
      }
    );
  }
}

const germanAddress = /^[A-Za-zÄäÖöÜüß\s.,()]+$/;
