import { Injectable } from '@angular/core';
import { QueryParams } from './query-params.class';

@Injectable({
  providedIn: 'root',
})
export class QueryParamsService {
  constructor() {}

  private static parseQuery(queryString: string): QueryParams {
    const pairs = (
      queryString[0] === '?' ? queryString.substring(1) : queryString
    ).split('&');

    const query = {};
    for (let i = 0; i < pairs.length; i++) {
      const pair = pairs[i].split('=');
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }

    return query;
  }

  getQueryParams(): QueryParams {
    const queryString = window.location.search;

    return QueryParamsService.parseQuery(queryString);
  }

  /**
   * Cleaned up code from source:
   * from:  https://stackoverflow.com/questions/10970078/
   *        modifying-a-query-string-without-reloading-the-page
   */
  private removeURLParameter(url: string, parameter) {
    const urlParts: string[] = url.split('?');
    if (urlParts.length >= 2) {
      const prefix = encodeURIComponent(parameter) + '=';
      const pars = urlParts[1].split(/[&;]/g);

      for (let i = pars.length; i-- > 0; ) {
        if (pars[i].lastIndexOf(prefix, 0) !== -1) {
          pars.splice(i, 1);
        }
      }

      url = urlParts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
      return url;
    } else {
      return url;
    }
  }

  public setQueryParam(param: string, value: string): void {
    if (!value || value === '') {
      return;
    }

    if (history.replaceState) {
      let currentUrl = window.location.href;
      currentUrl = this.removeURLParameter(currentUrl, param);

      let queryStart;
      if (currentUrl.indexOf('?') !== -1) {
        queryStart = '&';
      } else {
        queryStart = '?';
      }

      const newUrl = currentUrl + queryStart + param + '=' + value;
      this.applyNewUrl(newUrl);
    }
    return;
  }

  getClientSideParams(): string {
    const urlParts: string[] = window.location.href.split('#');
    return urlParts.length > 1 ? decodeURIComponent(urlParts[1]) : '';
  }

  public removeClientParam(url: string): string {
    const urlParts: string[] = url.split('#');
    return urlParts.length > 1 ? urlParts[0] : url;
  }

  public setClientSideParam(value: string): void {
    if (!value) return;

    if (history.replaceState) {
      let cleanedUrl = this.removeClientParam(window.location.href);
      const newUrl = cleanedUrl + '#' + encodeURIComponent(value);
      this.applyNewUrl(newUrl);
    }
    return;
  }

  private applyNewUrl(newUrl: string) {
    window.history.replaceState({ path: newUrl }, '', newUrl);
  }

  public clearQueryParam(param: string) {
    const newUrl = this.removeURLParameter(window.location.href, param);
    this.applyNewUrl(newUrl);
  }
}
