import { AfterViewInit, Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DepositUrlDto } from './deposit-url.dto';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import {
  environment,
  SERVER_BASE_URL,
} from '../../../environments/environment';
import { PreventBackToSettlementOrAuthGuard } from '../../guard/prevent-back-to-settlement-or-auth-guard.service';
import { EnvIdentifier } from '../../../environments/environment-types';
import { Store } from '@ngxs/store';
import { routeNames } from '../../../assets/val/route-constants';
import {
  Currency,
  Market,
  PaymentMethod,
  PaymentRequestDto,
  PaymentRequestStatus,
} from '../../model/payment-request/payment-request.dto';
import { DepositReqDto } from './deposit-req.dto';
import { QueryParamsService } from '../../service/query-params/query-params.service';
import { LoggingService } from 'src/app/service/logging-service/logging.service';
import { ErrorDialogComponent } from 'src/app/component/dialog/error/error-dialog.component';
import { AppDeepLinkService } from 'src/app/service/app-deeplink/app-deep-link.service';
import { MatDialog } from '@angular/material/dialog';
import { DepositStatus } from '@app/service/query-params/query-params.class';

@Component({
  selector: 'app-transfer',
  templateUrl: './transfer.component.html',
  styleUrls: ['./transfer.component.css', '../page-shared.css'],
})
export class TransferComponent implements OnInit, AfterViewInit, OnDestroy {
  static readonly MESSAGE_BODY_SUCCESSFUL_TRANSFER = 'transit';
  static readonly MESSAGE_BODY_FAILED_TRANSFER = 'fail';
  static readonly BANK_ID_MESSAGE_METHOD_OPEN = 'OPEN_APP';
  static readonly LOCAL_STORAGE_KEY_IFRAME_URL = 'frameurl';

  public trustlyUrl: any;
  public useIframe: boolean;
  private paymentRequestId: string;
  private autoNavigationTimeout: any;
  private frameEventListener = this.handleFrameMessage.bind(this);

  private runningInDevEnv: boolean =
    environment.envIdentifier === EnvIdentifier.STAGING ||
    environment.envIdentifier === EnvIdentifier.LOCAL ||
    environment.envIdentifier === EnvIdentifier.TEST;
  amount: any;
  currency: Currency;
  title: any;

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private router: Router,
    private store: Store,
    private dialogService: MatDialog,
    private preventBackToSettlementOrAuthGuard: PreventBackToSettlementOrAuthGuard,
    private loggingService: LoggingService,
    private deepLinkService: AppDeepLinkService,
    private queryParamsService: QueryParamsService
  ) {}

  ngOnDestroy(): void {
    localStorage.removeItem(TransferComponent.LOCAL_STORAGE_KEY_IFRAME_URL);
    window.removeEventListener('message', this.frameEventListener, false);
  }

  ngOnInit() {
    this.paymentRequestId = this.store.snapshot().paymentRequest.request.id;
    this.amount = this.store.snapshot().paymentRequest.request.amount;
    this.currency = this.store.snapshot().paymentRequest.request.currency;
    this.useIframe =
      this.store.snapshot().paymentRequest.request.market != Market.DK; // TODO

    let longTitle = this.store.snapshot().paymentRequest.request.title;
    this.title =
      longTitle.length > 16 ? longTitle.substring(0, 15) + '...' : longTitle;

    if (this.useIframe) {
      if (this.runningInDevEnv) {
        this.http
          .get<boolean>(`${environment.transferApiBaseUrl}/enabled`)
          .subscribe((mockingEnabled: boolean) => {
            if (mockingEnabled) {
              this.finalizeIFrameSetupMock();
            } else {
              this.finalizeIFrameSetup();
            }
          });
        return;
      }
      this.finalizeIFrameSetup();
    } else {
      // using Trustly redirect (no iframe)

      const depositStatus =
        this.queryParamsService.getQueryParams().depositStatus;
      if (depositStatus == DepositStatus.PENDING) {
        // start polling
        this.handleDepositPendingSuccess();
      } else if (depositStatus == DepositStatus.FAIL) {
        // let user go back to select payment methods
        this.handleDepositFailure();
      } else {
        // no deposit created yet
        this.setupRedirectFlow();
      }
    }
  }

  private setupRedirectFlow() {
    // probably null, depending on market settings...
    const personalNumber = localStorage.getItem('pnr');

    const params = `id=${this.paymentRequestId}&methodType=${PaymentMethod.TRUSTLY}`;

    // here we set successUrl & failUrl for the redirect flow (i.e. no iframe)
    // return to this route but with a status=PENDING parameter for "success" and status=FAIL otherwise
    const dto = new DepositReqDto(
      personalNumber,
      `/${routeNames.BANK_TRANSFER_FRAME}/?${params}&depositStatus=${DepositStatus.PENDING}`,
      `/${routeNames.BANK_TRANSFER_FRAME}/?${params}&depositStatus=${DepositStatus.FAIL}`
    );

    const successHandler = (response: DepositUrlDto) => {
      const depositUrl = response.depositUrl;
      this.trustlyUrl =
        this.sanitizer.bypassSecurityTrustResourceUrl(depositUrl);
      window.location.href = depositUrl;
    };
    this.initiateDeposit(dto, successHandler);
  }

  finalizeIFrameSetup() {
    const personalNumber = localStorage.getItem('pnr');
    const iframeUrl = localStorage.getItem(
      TransferComponent.LOCAL_STORAGE_KEY_IFRAME_URL
    );

    const params = `id=${this.paymentRequestId}&shouldOpenBankId=false&methodType=${PaymentMethod.TRUSTLY}`;

    if (iframeUrl) {
      this.trustlyUrl =
        this.sanitizer.bypassSecurityTrustResourceUrl(iframeUrl);
      return;
    }

    const SuccessHandler = (response: DepositUrlDto) => {
      const depositUrl = `${response.depositUrl}&NotifyParent=1`;
      this.trustlyUrl =
        this.sanitizer.bypassSecurityTrustResourceUrl(depositUrl);

      localStorage.setItem(
        TransferComponent.LOCAL_STORAGE_KEY_IFRAME_URL,
        depositUrl
      );
    };

    const dto = new DepositReqDto(
      personalNumber,
      `/${routeNames.SETTLE}/?${params}`,
      `/${routeNames.HOME}/?${params}`
    );

    this.initiateDeposit(dto, SuccessHandler);
  }

  private initiateDeposit(
    dto: DepositReqDto,
    successHandler: (response: DepositUrlDto) => void
  ) {
    this.http
      .post(`${SERVER_BASE_URL}/request/settle/trustly/`, dto, {
        withCredentials: true,
      })
      .subscribe(successHandler, (err): void => {
        if (err.error === 'invalid NIN') {
          this.handleInvalidNiN(dto);
        } else if (err.error === 'invalid buyer') {
          this.handleInvalidBuyer(dto);
        } else {
          this.router.navigate([routeNames.ERROR], {
            queryParams: { error: null },
            queryParamsHandling: 'merge',
          });
        }
      });
  }

  finalizeIFrameSetupMock() {
    this.http
      .post(
        `${environment.transferApiBaseUrl}/init`,
        {
          paymentRequestId: this.paymentRequestId,
          nin: localStorage.getItem('pnr'),
        },
        { withCredentials: true }
      )
      .subscribe((n: { sessionId: string }) => {
        this.trustlyUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          `${environment.transferFrameBaseUrl}/${n.sessionId}`
        );
      });
  }

  handleInvalidBuyer(dto: DepositReqDto) {
    const dialogConfiguration = {
      maxHeight: '50vh',
      maxWidth: '80vw',
      data: {
        message: 'Tekniskt fel',
        buttons: [
          {
            title: 'Pröva en annan betalmetod',
            callback: () => {
              this.router.navigate([routeNames.HOME], {
                queryParamsHandling: 'preserve',
              });
            },
          },
        ],
      },
    };
    this.dialogService.open(ErrorDialogComponent, dialogConfiguration);
    this.loggingService.reportCrash(
      'got invalid-buyer-response in trustly transfer',
      'request=' + JSON.stringify(dto),
      null
    );
  }

  private handleInvalidNiN(dto: DepositReqDto) {
    localStorage.removeItem('pnr');
    const dialogConfiguration = {
      maxHeight: '50vh',
      maxWidth: '80vw',
      data: {
        heading: 'Personnumret var ogiltigt',
        buttons: [
          {
            title: 'Försök igen',
            callback: () => {
              this.router.navigate([routeNames.PERSONAL_NUMBER_ENTRY], {
                queryParamsHandling: 'preserve',
              });
            },
          },
        ],
      },
    };
    this.dialogService.open(ErrorDialogComponent, dialogConfiguration);
    this.loggingService.reportCrash(
      'got invalid-NIN-response in trustly transfer',
      'request=' + JSON.stringify(dto)
    );
  }

  ngAfterViewInit(): void {
    window.addEventListener('message', this.frameEventListener, false);

    this.navToSuccessPageIfPaymentSucceeded();
    this.setAutoNavigationOnSuccessPolling();
  }

  setAutoNavigationOnSuccessPolling() {
    this.autoNavigationTimeout = window.setTimeout(() => {
      this.navToSuccessPageIfPaymentSucceeded();
    }, 3000);
  }

  // If we have already recieved callback from Trustly then we can go directly to the success-page.
  navToSuccessPageIfPaymentSucceeded() {
    this.http
      .get<PaymentRequestDto>(
        `${SERVER_BASE_URL}/request/public/${this.paymentRequestId}`
      )
      .subscribe((response) => {
        if (response.status === PaymentRequestStatus.SETTLED) {
          clearTimeout(this.autoNavigationTimeout);
          this.navigate(routeNames.SUCCESS);
        }
      });
  }

  // This is when the payer has successfully completed the deposit with Trustly (iframe or no-frame).
  // The payment request however will not be settled until we recieve callback from Trustly.
  handleDepositPendingSuccess() {
    const paymentRequestId = this.store.snapshot().paymentRequest.request.id;

    this.http
      .get<PaymentRequestDto>(
        `${SERVER_BASE_URL}/request/public/${paymentRequestId}`
      )
      .subscribe((response) => {
        this.loggingService.log('Received respose', response);
        if (response.status === PaymentRequestStatus.SETTLED) {
          // The payment request is settled now: Go to success page.
          this.navigate(routeNames.SUCCESS);
        } else {
          // The payment request is not settled yet: Go to the settlement polling page.
          this.preventBackToSettlementOrAuthGuard.deactivate();
          this.navigate(routeNames.SETTLE);
        }
      });
  }

  navigate(navigationTarget: string) {
    this.router
      .navigate([navigationTarget], {
        queryParams: {
          shouldOpenBankId: 'false',
          depositStatus: null, // clear depositStatus
        },
        queryParamsHandling: 'merge',
      })
      .then(
        () => this.preventBackToSettlementOrAuthGuard.activate(),
        (rejection) => {
          this.loggingService.error(
            `Navigation from ${routeNames.BANK_TRANSFER_FRAME} to ${navigationTarget} was\
                rejected.`,
            rejection
          );
        }
      );
  }

  handleFrameMessage(message: MessageEvent) {
    this.loggingService.log('frameMessage from trustly: ', message);

    this.preventBackToSettlementOrAuthGuard.deactivate();

    try {
      // Try to parse the message as JSON - this should only happen if the message about BankID as
      // only these frame messages are JSON. The other messages denoting failed and successful
      // transfers respectively are short plaintext strings.
      const data: any = JSON.parse(message.data);
      const bankIdMessage: BankIdFrameMessage = data as BankIdFrameMessage;

      if (
        bankIdMessage &&
        TransferComponent.BANK_ID_MESSAGE_METHOD_OPEN === bankIdMessage.method
      ) {
        this.deepLinkService.startBankIdFullUrl(bankIdMessage.appURL);
      }
    } catch (e) {
      if (this.runningInDevEnv) {
        this.loggingService.warn(
          'Trustly frame message not parseable as JSON - will be matched ' +
            'against predefined plain text message data',
          e
        );
      }
      // We could not parse the data as JSON - continue to switch over the plain text message data.
    }

    this.loggingService.log(JSON.stringify(message.data));

    switch (message.data) {
      case TransferComponent.MESSAGE_BODY_SUCCESSFUL_TRANSFER:
        localStorage.removeItem(TransferComponent.LOCAL_STORAGE_KEY_IFRAME_URL);
        this.handleDepositPendingSuccess();
        return;

      case TransferComponent.MESSAGE_BODY_FAILED_TRANSFER:
        localStorage.removeItem(TransferComponent.LOCAL_STORAGE_KEY_IFRAME_URL);
        this.handleDepositFailure();
        return;

      default:
        this.loggingService.log('Unhandled message', message);
        break;
    }
  }

  private handleDepositFailure() {
    const dialogConfiguration = {
      maxHeight: '50vh',
      maxWidth: '80vw',
      data: {
        buttons: [
          {
            title: 'Välj en annan betalmetod',
            callback: () => {
              this.router
                .navigate([routeNames.HOME], {
                  queryParams: {
                    depositStatus: null, // clear depositStatus
                  },
                  queryParamsHandling: 'merge',
                })
                .then(
                  () => this.preventBackToSettlementOrAuthGuard.activate(),
                  (rejection) => {
                    this.loggingService.error(
                      `Navigation from ${routeNames.BANK_TRANSFER_FRAME} to ${routeNames.HOME} was rejected.`,
                      rejection
                    );
                  }
                );
            },
          },
        ],
      },
    };
    this.dialogService.open(ErrorDialogComponent, dialogConfiguration);
  }

  reroute(): void {
    this.router.navigate([routeNames.HOME], {
      queryParamsHandling: 'merge',
    });
  }
}

/**
 * Sent by the Trustly iframe when we need to open the BankId app - note that this has a different
 * (dynamic) format than the other messages sent from the frame which are simple strings.
 */
class BankIdFrameMessage {
  method: string;
  appURL: string;
}
