import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { personalNumberValidator } from '../../validation/personal-number.validator';
import { Router } from '@angular/router';
import { routeNames } from '../../../assets/val/route-constants';
import { CustomerJourneyService } from '../../service/customer-journey/customer-journey.service';
import { TransferComponent } from '../transfer/transfer.component';
import { LoggingService } from 'src/app/service/logging-service/logging.service';
import { InfoPrefillService } from '@app/service/info-prefill/info-prefill.service';
import { QueryParamsService } from '../../service/query-params/query-params.service';
import { CreditService } from '../../service/credit/credit.service';
import { PaymentRequestDto } from '@app/model/payment-request/payment-request.dto';

@Component({
  selector: 'app-pnr-entry',
  styleUrls: ['./pnr-entry.component.css', '../page-shared.css'],
  templateUrl: 'pnr-entry.component.html',
})
export class PnrEntryComponent implements OnInit {
  showError: boolean = false;
  paymentRequestId: string;
  paymentRequest: PaymentRequestDto;
  form: FormGroup;
  private routeNames = routeNames;

  constructor(
    private router: Router,
    private queryParamsService: QueryParamsService,
    private creditService: CreditService,
    private customerJourneyService: CustomerJourneyService,
    private loggingService: LoggingService,
    private prefillService: InfoPrefillService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.paymentRequestId = this.queryParamsService.getQueryParams().id;

    if (this.paymentRequestId) {
      this.creditService
        .getPaymentRequestPublicInfo(this.paymentRequestId)
        .subscribe((res) => {
          this.paymentRequest = res;
        });
    }

    this.setupForm();
  }

  setupForm(): void {
    const preFilled = localStorage.getItem('pnr');
    const initialPnr = !!preFilled && preFilled.length > 0 ? preFilled : '';

    this.form = this.formBuilder.group({
      pnr: [
        initialPnr || '',
        [
          Validators.required,
          Validators.minLength(12),
          Validators.maxLength(12),
          personalNumberValidator(),
          Validators.pattern(
            '^((19|20)\\d{2})(0[1-9]|1[0-2])(0[1-9]|[1-2]\\d|3[0-1])[-+]*(\\d{4})$'
          ),
        ],
      ],
    });
  }

  public continue() {
    this.updateErrorMessages();
    if (this.form.valid) {
      this.saveProvidedPnr();
      this.navigate();
    }
  }

  updateErrorMessages() {
    this.showError = !this.showError;
  }

  public saveProvidedPnr() {
    this.prefillService.setLocalNin(this.form.value.pnr.trim());
    localStorage.setItem('pnr', this.form.value.pnr.trim());
  }

  private navigate() {
    this.customerJourneyService.getNextRoute().subscribe((route) => {
      // This needs to be cleared if the user previously has made a failed transfer attempt that
      // for some reason was not duly handled - it is a property that holds the iframe source url
      // (and hence the associated Trustly session) needed to restore the iframe after switching
      // from BankID, should e.g. Safari decide to reload the page.
      if (route === `/${this.routeNames.BANK_TRANSFER_FRAME}`) {
        localStorage.removeItem(TransferComponent.LOCAL_STORAGE_KEY_IFRAME_URL);
      }

      this.router
        .navigate([route], {
          queryParamsHandling: 'preserve',
        })
        .then(
          () => {},
          (rejection) => {
            this.loggingService.error(
              `Navigation from ${routeNames.PERSONAL_NUMBER_ENTRY} to ${route} was rejected.`,
              rejection
            );
          }
        );
    });
  }

  reroute(): void {
    this.router.navigate([routeNames.HOME], {
      queryParamsHandling: 'merge',
    });
  }
}
