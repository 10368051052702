import { Component, Input, ViewChild } from '@angular/core';
import {
  SelectorComponent,
  SelectorItem,
} from '@app/component/selector/selector.component';
import { Currency } from '@app/model/currency';
import { RouteComponent } from '@app/route/route.component';
import { ActivatedRoute, Router } from '@angular/router';
import { StateService } from '@app/service/state/state.service';
import { HeaderService } from '@app/service/header/header.service';
import { Market } from '@app/model/payment-request/payment-request.dto';
import { LoanOptionVM } from '@app/route/shared/LoanOptionVM';
import { DeLoanOptionService } from '@app/service/de-loan-option/de-loan-option.service';
import { LoanOption } from '@app/route/shared/LoanOption';

@Component({
  selector: 'app-de-precheck-success',
  templateUrl: './de-precheck-success.component.html',
  styleUrls: [
    '../../../../route/page-shared.css',
    './de-precheck-success.component.scss',
  ],
})
export class DePrecheckSuccessComponent extends RouteComponent {
  @Input() amount: Currency;

  availableLoanOptions: LoanOptionVM[] = null;
  selectorItems: SelectorItem[] = null;
  selectedItem: SelectorItem = null;
  modalActive: boolean = false;
  Market = Market;
  currentLoanTermsStr: string = '';

  @ViewChild('selector1') modalSelector: SelectorComponent;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected stateService: StateService,
    protected headerService: HeaderService,
    private loanOptionService: DeLoanOptionService
  ) {
    super(router, route, stateService, headerService);
  }

  async ngOnInit(): Promise<void> {
    this.availableLoanOptions = this.transformDtoTOVM(
      this.state.loanOptions.sort((a, b) => a.monthlyCost - b.monthlyCost)
    );
  }

  toggleModal(loanOption: LoanOption): void {
    if (loanOption) {
      this.setModalInfo(loanOption);
    }
    this.modalActive = !this.modalActive;
  }

  setModalInfo(loanOption: LoanOption) {
    this.currentLoanTermsStr = this.loanOptionService.buildLoantermsString(
      this.transformDtoTOVM([loanOption])[0],
      this.state.totalCheckoutValue
    );

    this.selectorItems = [
      {
        tab: 'Zahlungsbedingungen',
        title: 'Bedingungen für den Kauf auf Raten',
        descriptions: [{ text: this.currentLoanTermsStr }],
        ...(!!'loanOption.loanTermsURL' && {
          links: [
            {
              text: 'Beispiel: Allgemeine Geschäftsbedingungen für den Ratenkauf.',
              onClick: () =>
                this.openURLinNewWindow('https://zaver.com/ratenzahlung'),
            },
          ],
        }),
      },
    ];

    this.selectedItem = this.selectorItems[0];
  }

  openURLinNewWindow(url: string): void {
    window.open(url, '_blank');
  }

  transformDtoTOVM(loanOptions: LoanOption[]): LoanOptionVM[] {
    return loanOptions.map((loanOption) => ({
      badgeText: null,
      bulletPoints: null,
      color: '#00ac84',
      link: {
        text: 'AGB lesen',
        onClick: () => {
          this.toggleModal(loanOption);
        },
      },
      monthlyCost: loanOption.monthlyCost,
      nominalInterest: loanOption.nominalInterest,
      numberOfMonths: loanOption.numberOfMonths,
      radioButtonColor: '#db305e',
      title: this.buildLoanOptionTitle(loanOption),
      termId: loanOption.termId,
      effectiveInterest: loanOption.effectiveInterest,
      initialFee: loanOption.initialFee,
      monthlyFee: loanOption.monthlyFee,
      totalAmount: loanOption.totalAmount,
      loanTermsURL: loanOption.loanTermsURL,
      secciURL: loanOption.secciURL,
    }));
  }

  buildLoanOptionTitle(loanOption: LoanOption): string {
    return `${loanOption.monthlyCost.toLocaleString('de')} € mtl. für ${
      loanOption.numberOfMonths
    } Mon`;
  }
}

export interface SummaryEntry {
  monthlyCost: string;
  numberOfMonths: number;
  nominalInterest: string;
}

export interface PrecheckLoanOptions {
  summaryEntries: SummaryEntry[];
}
