import {Injectable} from '@angular/core';
import {Observable, Subscription, timer} from 'rxjs';
import {Store} from '@ngxs/store';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {SERVER_BASE_URL} from 'src/environments/environment';
import {getErrorMessage, SwishError, SwishErrorCode, SwishResponseDto} from './swish-response.dto';
import {filter, map, startWith, switchMap, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {PreventBackToSettlementOrAuthGuard} from 'src/app/guard/prevent-back-to-settlement-or-auth-guard.service';
import {routeNames} from 'src/assets/val/route-constants';
import {QueryParamsService} from '../query-params/query-params.service';
import {MatDialog} from '@angular/material/dialog';
import {ErrorDialogComponent} from 'src/app/component/dialog/error/error-dialog.component';
import {LoggingService} from '../logging-service/logging.service';

@Injectable({
  providedIn: 'root',
})

export class SwishService {

  resumePolling(swishId: string) {
    this.activeDepositId = swishId;
    this.startPolling();
  }

  private activeDepositId: string;
  private pollingSubscription: Subscription;

  constructor(private store: Store,
              private queryParamsService: QueryParamsService,
              private http: HttpClient,
              private router: Router,
              private dialogService: MatDialog,
              private preventBackToSettlementOrAuthGuard: PreventBackToSettlementOrAuthGuard,
              private loggingService: LoggingService) {
  }


  initSwishMobilePayment(): Observable<SwishResponseDto> {
    return this.http.post<SwishResponseDto>(`${SERVER_BASE_URL}/request/settle/swish/`,
      {withCredentials: true}).pipe(
      map((res) => {
        this.queryParamsService.setQueryParam('swishId', res.id);
        this.activeDepositId = res.id;
        this.startPolling();
        return res;
      })
    );
  }


  initSwishPayment(phoneNumber: string): Observable<SwishResponseDto> {
    return this.http.post<SwishResponseDto>(`${SERVER_BASE_URL}/request/settle/swish/`,
      {'phoneNumber': phoneNumber}, {withCredentials: true}).pipe(
      tap((res) => {
          this.queryParamsService.setQueryParam('swishId', res.id);
          this.activeDepositId = res.id;
          this.startPolling();
        }
      ));
  }

  cancelCurrentSwishPayment(): void {
    if (this.activeDepositId) {
      this.http.delete<SwishResponseDto>(`${SERVER_BASE_URL}/request/settlement/swish/${this.activeDepositId}`,
        {withCredentials: true}).subscribe(
        (res) => {
          this.stopPolling();
        },
        (err) => this.handleLateError(err)
      );
    }
  }

  private stopPolling() {
    this.queryParamsService.clearQueryParam('swishId');
    if (this.pollingSubscription) {
      this.pollingSubscription.unsubscribe();
    }
    this.activeDepositId = null;
  }


  startPolling() {
    this.pollingSubscription =
      timer(100, 1000)
        .pipe(
          startWith(0),
          switchMap(() => this.http.get<SwishResponseDto>(`${SERVER_BASE_URL}/request/settlement/swish/${this.activeDepositId}`)),
          filter((res) => res.status !== 'CREATED'),
        ).subscribe(
        (res) => this.handleUpdate(res),
        (err) => this.handleLateError(err)
      );
  }

  public isPolling(): boolean {
    return this.pollingSubscription !== undefined && !this.pollingSubscription.closed;
  }


  handleLateError(errorResponse: HttpErrorResponse): void {
    this.stopPolling();
    let errors: SwishError[];
    errors = errorResponse.error.errors;
    if (errors && errors.some((err) => err.unrecoverable)) {
      this.navigateOnHardError(errors[0].errorCode);
    } else {
      const dialogConfiguration = {
        maxHeight: '50vh',
        maxWidth: '80vw',
        data: {
          buttons: [
            {
              title: 'Välj en annan betalmetod',
              callback: () => {
                this.router.navigate(
                  [routeNames.HOME],
                  {queryParamsHandling: 'preserve'}
                );
              }
            }
          ]
        }
      };
      this.dialogService.open(ErrorDialogComponent, dialogConfiguration);

      this.clearSwishIdAndNavigate(routeNames.HOME);
    }
  }

  handleUpdate(res: SwishResponseDto): void {
    this.stopPolling();
    if (res.status === 'PAID') {
      this.navigateOnSuccess();
    } else if (res.status === 'DECLINED' || res.status === 'ERROR' || res.status === 'CANCELLED') {
      this.clearSwishIdAndNavigate(routeNames.HOME);
    }
  }

  private navigateOnHardError(error: SwishErrorCode): void {
    this.queryParamsService.setQueryParam('error', getErrorMessage(error));
    this.clearSwishIdAndNavigate(routeNames.ERROR);
  }

  private navigateOnSuccess(): void {
    this.clearSwishIdAndNavigate(routeNames.SUCCESS);
  }

  clearSwishIdAndNavigate(navigationTarget: string) {
    this.router.navigate(
      [navigationTarget],
      {
        queryParamsHandling: 'merge',
        queryParams: {'swishId': null}
      }).then(
      () => this.preventBackToSettlementOrAuthGuard.activate(),
      rejection => {
        this.loggingService.error(
          `Navigation from ${routeNames.SWISH} to ${navigationTarget} was rejected.`,
          rejection
        );
      }
    );
  }
}
