<app-de-wrapper *ngIf="noAvailablePaymentMethods || noResponseError">
  <div style="display: flex; flex-direction: column; align-items: center">
    <img class="general-error-icon" src="assets/icon/smiley-sad-nerd.svg" />
    <h3 class="general-error-text">Etwas ist schief gelaufen.</h3>
  </div>
</app-de-wrapper>

<app-de-wrapper *ngIf="state.status === 'CANCELLED'">
  <div style="display: flex; flex-direction: column; align-items: center">
    <img class="general-error-icon" src="assets/icon/smiley-sad-nerd.svg" />
    <h3 class="general-error-text">
      Die Zahlungsaufforderung wurde annuliert.
    </h3>
  </div>
</app-de-wrapper>

<ng-container
  *ngIf="!noAvailablePaymentMethods && !loading && state.status !== 'CANCELLED'"
>
  <div class="page-body-de">
    <div class="contents-container-de">
      <app-expandable-list-item
        *ngFor="let methodVM of paymentMethodsVM"
        [title]="methodVM.title"
        [description]="methodVM.subTitle"
        [imgSrc]="methodVM.imgSrc"
        [bulletPoints]="methodVM.bulletPoints"
        [color]="color"
        [selected]="methodVM === selectedPaymentMethodVM"
        [isFirstItem]="methodVM === paymentMethodsVM[0]"
        [isLastItem]="
          methodVM === paymentMethodsVM[paymentMethodsVM.length - 1]
        "
        (click)="selectPaymentMethod(methodVM)"
        [showTermsLink]="selectedPaymentMethodVM.type === 'INVOICE'"
        [linkText]="selectedPaymentMethodVM.type === 'INVOICE' && 'AGB lesen'"
        (onTermsClick)="
          selectedPaymentMethodVM.type === 'INVOICE' && openModalWithTerms()
        "
      ></app-expandable-list-item>

      <div
        class="bottom-section-wrapper"
        *ngIf="state.market === Market.AT || state.market === Market.DE"
      >
        <app-payment-request-info
          [lineItems]="state.paymentInformation.lineItems"
          [value]="state.totalCheckoutValue"
          [market]="state.market"
          (onDetailsClick)="getModalPurchaseInfo()"
        ></app-payment-request-info>

        <zaver-primary-button
          [text]="'Weiter'"
          (click)="handleContinueClick()"
          [disabled]="navigationInProgress"
        ></zaver-primary-button>

        <p class="disclaimer-paragraph">
          Indem ich fortfahre, stimme ich
          <a
            class="disclaimer-link"
            target="_blank"
            [href]="'https://zaver.com/de/bedingungen'"
            >Zaver´s Nutzungsbedingungen</a
          >,
          <a
            class="disclaimer-link"
            target="_blank"
            [href]="'https://zaver.com/de/datenschutz'"
            >Datenschutz</a
          >
          und
          <a
            class="disclaimer-link"
            target="_blank"
            [href]="'https://zaver.com/de/cookies'"
            style="white-space: nowrap"
            >Cookie-Richtlinie</a
          >&nbsp;zu.&nbsp;<a
            class="disclaimer-link"
            target="_blank"
            [href]="getSekkiLink()"
            >Impressum</a
          >.
        </p>
      </div>

      <div class="bottom-section-wrapper" *ngIf="state.market === Market.NO">
        <app-payment-request-info
          [lineItems]="state.paymentInformation.lineItems"
          [value]="state.totalCheckoutValue"
          [market]="state.market"
          (onDetailsClick)="getModalPurchaseInfo()"
        ></app-payment-request-info>

        <zaver-primary-button
          [text]="'Fortsette'"
          (click)="handleContinueClick()"
          [disabled]="navigationInProgress"
        ></zaver-primary-button>

        <p class="disclaimer-paragraph">
          Ved å fortsette er jeg enig
          <a
            class="disclaimer-link"
            target="_blank"
            [href]="'https://zaver.com/se/anvandarvillkor'"
            >Zavers vilkår og betingelser</a
          >, og bekreft at jeg har lest Zavers
          <a
            class="disclaimer-link"
            target="_blank"
            [href]="'https://zaver.com/se/integritetspolicy'"
            >Personvernerklæring</a
          >
          og
          <a
            class="disclaimer-link"
            target="_blank"
            [href]="'https://zaver.com/se/cookies'"
            >Cookie policy</a
          >, og aksepterer betalingsansvar.
          <a class="disclaimer-link" target="_blank" [href]="getSekkiLink()"
            >Vis SEKKI.</a
          >
        </p>
      </div>
    </div>
  </div>

  <div
    style="margin-top: 20px"
    class="footer-logo-wrapper"
    *ngIf="state.market === Market.AT || state.market === Market.DE"
  >
    <div>
      <img src="/assets/img/Zaver_Logotype_Circle_Coal_DE.svg" alt="" />
    </div>
  </div>

  <zaver-checkout-modal
    *ngIf="state.market === Market.AT || state.market === Market.DE"
    id="details-modal"
    [buttonText]="'Schließen'"
    [active]="modalActive"
    [logo]="'assets/img/Zaver_Logotype_Circle_Coal_DE.svg'"
    (onClose)="deactivateModal()"
  >
    <app-selector
      #selector
      *ngIf="!!selectorItems"
      [selectorItems]="selectorItems"
      [selectedItem]="selectedItem"
    ></app-selector>
  </zaver-checkout-modal>
</ng-container>
