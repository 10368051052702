<div class="page-body" *ngIf="!navigationInProgress">
  <div class="banner">
    <div class="back-wrapper">
      <img
        src="assets/icon/Arrow-Left.svg"
        alt=""
        class="back-button"
        (click)="reroute()"
      />
      <span class="back-text" (click)="reroute()">Tillbaka</span>
    </div>
    <div class="mini-phone-button" (click)="reroute()">
      <img
        src="assets/icon/Navigation-Arrow-Blueberry-Left.svg"
        alt=""
        style="padding-right: 4px"
      />
      <label>Tillbaka</label>
    </div>

    <div class="page-counter">Sid {{ step }} av 2</div>

    <div class="mobile-void"></div>
    <div class="mini-phone-button" (click)="handleContinueClick()">
      <label>Fortsätt</label>
      <img
        src="assets/icon/Navigation-Arrow-Blueberry-Right.svg"
        alt=""
        style="padding-left: 4px"
      />
    </div>
  </div>

  <div class="contents-wrapper">
    <div class="contents-container">
      <div class="information-header-desktop" *ngIf="manualMode === false">
        <span>
          <img
            src="/assets/icon/Icon-Info.svg"
            alt=""
            style="margin-bottom: -3px; padding-right: 4px"
          />
          I enlighet med gällande lagstiftning behöver vi ställa några frågor om
          din ekonomi.
        </span>
      </div>

      <div
        class="information-header-mobile"
        *ngIf="step === '1' && manualMode === false"
      >
        <span>
          <img
            src="/assets/icon/icon_info.svg"
            alt=""
            style="margin-bottom: -3px; padding-right: 4px"
          />
          I enlighet med gällande lagstiftning behöver vi ställa några frågor om
          din ekonomi.
        </span>
      </div>

      <div *ngIf="setupReady">
        <!--Sliders mode-->
        <ng-container *ngIf="manualMode === false">
          <div [ngSwitch]="step" *ngIf="setupReady">
            <div class="leq-sliders-container">
              <!-- Sliders page 1 -->
              <div *ngSwitchCase="'1'" class="form-wrapper-sliders">
                <h4>Hur många barn försörjer du?</h4>

                <zaver-list-slider
                  [ariaLabel]="'Ange antal barn'"
                  [listOptions]="leqKidsAnswers"
                  [(value)]="sliderInputNumberOfChildren"
                  (completedChange)="sliderValueChanged()"
                >
                </zaver-list-slider>

                <h4 style="align-self: center; max-width: 250px">
                  Hur mycket betalar du för övriga krediter per månad?
                </h4>

                <zaver-list-slider
                  [ariaLabel]="'Ange belopp'"
                  [listOptions]="leqCreditAnswers"
                  [(value)]="sliderInputCreditCosts"
                  (completedChange)="sliderValueChanged()"
                >
                </zaver-list-slider>

                <h4 style="align-self: center; max-width: 260px">
                  Vad betalar du för boende i månaden? Inkludera inte bolån.
                </h4>

                <zaver-list-slider
                  [ariaLabel]="'Ange belopp'"
                  [listOptions]="leqLivingAnswers"
                  [(value)]="sliderInputHousingCosts"
                  (completedChange)="sliderValueChanged()"
                >
                </zaver-list-slider>
              </div>
              <!-- Sliders page 2 -->
              <div *ngSwitchCase="'2'" class="form-wrapper-sliders">
                <h4 class="first-page-two-el">
                  Vad har du för månadsinkomst efter skatt?
                </h4>

                <zaver-list-slider
                  [ariaLabel]="'Ange belopp'"
                  [listOptions]="leqSalaryAnswers"
                  [(value)]="sliderInputSalary"
                  (completedChange)="sliderValueChanged()"
                >
                </zaver-list-slider>

                <h4 style="align-self: center; max-width: 270px">
                  Uppskatta dina övriga levnadskostnader (ex mobil).
                </h4>

                <zaver-list-slider
                  [ariaLabel]="'Ange belopp'"
                  [listOptions]="leqMiscAnswers"
                  [(value)]="sliderInputMiscExpenses"
                  (completedChange)="sliderValueChanged()"
                >
                </zaver-list-slider>

                <h4 class="select-title">Vad har du för sysselsättning?</h4>

                <select [(ngModel)]="employment">
                  <option
                    *ngFor="let entry of leqOccupationAnswers"
                    [ngValue]="entry.value"
                  >
                    {{ entry.label }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </ng-container>

        <!-- Manual input mode -->
        <ng-container *ngIf="manualMode === true">
          <div [ngSwitch]="step" *ngIf="setupReady">
            <!-- Manual page 1 -->

            <div *ngSwitchCase="'1'" class="form-wrapper-manual">
              <form [formGroup]="firstForm">
                <div class="form-input-container">
                  <zaver-animated-form-input
                    [ariaLabel]="'Ange antal barn'"
                    title="Hur många barn försörjer du?"
                    placeholder="Antal barn"
                    [formControlName]="'numberOfChildren'"
                    inputMode="numeric"
                    [errorMessage]="'Vänligen ange en siffra mellan 0 och 50'"
                    [error]="
                      firstForm.controls.numberOfChildren.invalid && showErrors
                    "
                    [errorIcon]="'assets/icon/icon_error.svg'"
                  >
                  </zaver-animated-form-input>
                </div>

                <div class="form-input-container">
                  <zaver-animated-form-input
                    [ariaLabel]="'Ange belopp'"
                    title="Hur mycket betalar du för övriga krediter per månad?"
                    placeholder="Ange belopp (kr)"
                    [formControlName]="'creditCosts'"
                    (onKeyboardEnter)="removeAndroidKeyboardIfAny()"
                    inputMode="numeric"
                    [errorMessage]="
                      'Vänligen ange en siffra mellan 0 och 1 000 000'
                    "
                    [error]="
                      firstForm.controls.creditCosts.invalid && showErrors
                    "
                    [errorIcon]="'assets/icon/icon_error.svg'"
                  >
                  </zaver-animated-form-input>
                </div>

                <div class="form-input-container">
                  <zaver-animated-form-input
                    [ariaLabel]="'Ange belopp'"
                    title="Vad betalar du för boende i månaden? Inkludera inte bolån."
                    placeholder="Ange belopp (kr)"
                    [formControlName]="'housingCosts'"
                    (onKeyboardEnter)="removeAndroidKeyboardIfAny()"
                    inputMode="numeric"
                    [errorMessage]="
                      'Vänligen ange en siffra mellan 0 och 1 000 000'
                    "
                    [error]="
                      firstForm.controls.housingCosts.invalid && showErrors
                    "
                    [errorIcon]="'assets/icon/icon_error.svg'"
                  >
                  </zaver-animated-form-input>
                </div>
              </form>
            </div>

            <!-- Manual page 2 -->
            <div *ngSwitchCase="'2'" class="form-wrapper-manual">
              <form [formGroup]="secondForm">
                <div class="form-input-container first-page-two-el">
                  <zaver-animated-form-input
                    [formControlName]="'salary'"
                    [ariaLabel]="'Ange belopp'"
                    title="Vad har du för månadsinkomst efter skatt?"
                    placeholder="Ange belopp (kr)"
                    inputMode="numeric"
                    (onKeyboardEnter)="removeAndroidKeyboardIfAny()"
                    [errorMessage]="
                      'Vänligen ange en siffra mellan 0 och 1 000 000'
                    "
                    [error]="secondForm.controls.salary.invalid && showErrors"
                    [errorIcon]="'assets/icon/icon_error.svg'"
                  >
                  </zaver-animated-form-input>
                </div>

                <div class="form-input-container">
                  <zaver-animated-form-input
                    [formControlName]="'miscExpenses'"
                    [ariaLabel]="'Ange belopp'"
                    title="Uppskatta dina övriga levnadskostnader (ex mobil)."
                    placeholder="Ange belopp (kr)"
                    inputMode="numeric"
                    (onKeyboardEnter)="removeAndroidKeyboardIfAny()"
                    [errorMessage]="
                      'Vänligen ange en siffra mellan 0 och 1 000 000'
                    "
                    [error]="
                      secondForm.controls.miscExpenses.invalid && showErrors
                    "
                    [errorIcon]="'assets/icon/icon_error.svg'"
                  >
                  </zaver-animated-form-input>
                </div>
              </form>

              <div class="form-input-container">
                <h4>Vad har du för sysselsättning?</h4>
                <select [(ngModel)]="employment">
                  <option
                    *ngFor="let entry of leqOccupationAnswers"
                    [ngValue]="entry.value"
                  >
                    {{ entry.label }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </ng-container>
      </div>

      <zaver-primary-button text="Fortsätt" (click)="handleContinueClick()">
      </zaver-primary-button>

      <!-- Toggle manual/sliders -->

      <zaver-button-link
        style="margin-top: 16px"
        (click)="toggleManualMode()"
        [text]="manualMode ? 'Återgå till sliders' : 'Ange manuella värden'"
      ></zaver-button-link>
    </div>
  </div>
</div>

<div class="page-body" *ngIf="navigationInProgress">
  <div class="contents-wrapper">
    <div class="contents-container">
      <app-spinner></app-spinner>
    </div>
  </div>
</div>
