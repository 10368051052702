import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import { CreditOptionDto } from '../../model/credit/credit-option.dto';
import { Router } from '@angular/router';
import { SERVER_BASE_URL } from '../../../environments/environment';
import { Subscription } from 'rxjs';
import { CreditService } from '../../service/credit/credit.service';
import { CreditDecisionService } from '../../service/credit-decision/credit-decision.service';
import { routeNames } from '../../../assets/val/route-constants';
import { HttpClient } from '@angular/common/http';
import { PreventBackToSettlementOrAuthGuard } from 'src/app/guard/prevent-back-to-settlement-or-auth-guard.service';
import { PaymentRequestDto } from '../../model/payment-request/payment-request.dto';
import { LoggingService } from 'src/app/service/logging-service/logging.service';
import { QueryParamsService } from '../../service/query-params/query-params.service';
import {
  SelectorItem,
  SelectorComponent,
} from '@app/component/selector/selector.component';
import { ValuePipe } from '@app/shared/pipe/value.pipe';

@Component({
  selector: 'app-installments-selection',
  templateUrl: './installments-selection.component.html',
  styleUrls: ['./installments-selection.component.scss', '../page-shared.css'],
})
export class InstallmentsSelectionComponent implements OnInit {
  modalActive: boolean;
  selectorItems: SelectorItem[];

  public loading = true;
  public error = false;
  public navigating = false;

  availableInstallmentPlans: CreditOptionDto[];
  originalFilteredInstallmentPlans: CreditOptionDto[];
  filteredInstallmentPlans: CreditOptionDto[];
  presentedInstallmentPlans: CreditOptionDto[];

  selectedInstallmentPlan: CreditOptionDto;
  paymentRequestId: string;
  bankIdMessage: string;
  stateSub: Subscription;
  paymentRequest: PaymentRequestDto;

  selectedPaymentMethodIdentifier: string;

  currentLoanTermsStr: string;

  includedTerms: number[];
  merchantPreferredTerms: number[];

  @ViewChild('selector') modalSelector: SelectorComponent;

  constructor(
    private store: Store,
    private router: Router,
    private queryParamsService: QueryParamsService,
    private creditService: CreditService,
    private creditDecisionService: CreditDecisionService,
    private http: HttpClient,
    private preventBackToSettlementOrAuthGuard: PreventBackToSettlementOrAuthGuard,
    private loggingService: LoggingService,
    private valuePipe: ValuePipe
  ) {}

  ngOnInit() {
    this.paymentRequest = this.store.snapshot().paymentRequest.request;
    this.paymentRequestId = this.paymentRequest.id;

    this.selectedPaymentMethodIdentifier =
      this.queryParamsService.getQueryParams().method;

    this.creditService
      .fetchInstallments(this.selectedPaymentMethodIdentifier)
      .subscribe(
        (res) => {
          this.availableInstallmentPlans = this.sortByMonthlyCost(
            res.installmentOptions
          );

          this.merchantPreferredTerms = res.preferredInstallmentPlanDurations;

          this.filteredInstallmentPlans = this.filterInstallmentPlans(
            this.availableInstallmentPlans
          );
          this.originalFilteredInstallmentPlans = this.filteredInstallmentPlans;

          this.presentedInstallmentPlans = this.filteredInstallmentPlans;
          this.select(0);

          this.loading = false;
        },
        (err) => {
          this.handleInstallmentsNotAvailableError(err.error.type);
          this.error = true;
          this.loading = false;
        }
      );
  }

  handleInstallmentsNotAvailableError(errorIdentifier: string) {
    this.creditDecisionService.handleCreditDecisionDenied(
      errorIdentifier,
      routeNames.SELECT_INSTALLMENT_PLAN
    );
  }

  allInstallmentPlansIsShown() {
    return this.presentedInstallmentPlans === this.availableInstallmentPlans;
  }

  filterInstallmentPlans(installmentPlans: CreditOptionDto[]) {
    if (this.paymentRequest.amount <= 50000) {
      return this.trimInstallmentPlanOptions(installmentPlans, [12, 24, 36]);
    } else {
      let longestZeroInterestTerm = 0;
      for (let plan of installmentPlans) {
        if (
          plan.nominalInterest < 0.001 &&
          plan.numberOfMonths > longestZeroInterestTerm
        ) {
          longestZeroInterestTerm = plan.numberOfMonths;
        }
      }

      if (longestZeroInterestTerm > 0) {
        return this.trimInstallmentPlanOptions(installmentPlans, [
          longestZeroInterestTerm,
        ]);
      }

      return this.trimInstallmentPlanOptions(installmentPlans, []);
    }
  }

  trimInstallmentPlanOptions(
    installmentPlans: CreditOptionDto[],
    preferredTerms: number[]
  ): CreditOptionDto[] {
    let includedTerms: number[] = [];
    let includedOptions: CreditOptionDto[] = [];

    //Given installment plans need to be sorted
    const longestTerm = installmentPlans[0].numberOfMonths;
    const middlemost =
      installmentPlans[Math.floor(installmentPlans.length / 2)].numberOfMonths;
    const shortestTerm =
      installmentPlans[installmentPlans.length - 1].numberOfMonths;

    if (this.merchantPreferredTerms && this.merchantPreferredTerms.length > 0) {
      for (let termDuration of this.merchantPreferredTerms) {
        for (let installmentPlan of installmentPlans) {
          if (
            installmentPlan.numberOfMonths === termDuration &&
            includedTerms.length < 4 &&
            includedTerms.indexOf(termDuration) === -1
          ) {
            includedTerms.push(termDuration);
          }
        }
      }
    }

    //If merchant preferred terms do not fill available slots, fall back on otherwise specified logic
    if (preferredTerms && preferredTerms.length > 0) {
      for (let termDuration of preferredTerms) {
        for (let installmentPlan of installmentPlans) {
          if (
            installmentPlan.numberOfMonths === termDuration &&
            includedTerms.length < 4 &&
            includedTerms.indexOf(termDuration) === -1
          ) {
            includedTerms.push(termDuration);
          }
        }
      }
    }

    //Else, fall back to displaying longest, shortest and middlemost
    if (includedTerms.length < 4 && includedTerms.indexOf(longestTerm) === -1) {
      includedTerms.push(longestTerm);
    }
    if (
      includedTerms.length < 4 &&
      includedTerms.indexOf(shortestTerm) === -1
    ) {
      includedTerms.push(shortestTerm);
    }
    if (includedTerms.length < 4 && includedTerms.indexOf(middlemost) === -1) {
      includedTerms.push(middlemost);
    }

    for (let term of includedTerms) {
      for (let installmentPlan of installmentPlans) {
        if (installmentPlan.numberOfMonths === term) {
          includedOptions.push(installmentPlan);
        }
      }
    }

    return this.sortByMonthlyCost(includedOptions);
  }

  sortByMonthlyCost(installmentPlans: CreditOptionDto[]): CreditOptionDto[] {
    return installmentPlans.sort((a, b) => a.monthlyCost - b.monthlyCost);
  }

  select(index: number) {
    this.selectedInstallmentPlan = this.presentedInstallmentPlans[index];
    this.currentLoanTermsStr = this.buildLoanTermsStr(
      this.selectedInstallmentPlan
    );

    const totalAmountStr =
      this.paymentRequest.lineItems.length > 0
        ? 'Totalbelopp inkl. moms:'
        : 'Totalbelopp:';

    this.selectorItems = [
      {
        tab: 'Ditt köp',
        title:
          this.paymentRequest.lineItems.length > 0
            ? 'Sammanfattning'
            : this.paymentRequest.title,
        descriptions: [{ text: this.paymentRequest.description }],
        lineItems:
          this.paymentRequest.lineItems.length > 0
            ? this.paymentRequest.lineItems
            : null,
        totalValue: `${totalAmountStr} ${this.valuePipe.transform(
          this.paymentRequest.value
        )}`,
      },
      {
        tab: 'Betalningsvillkor',
        title: 'Villkor för delbetalning',
        descriptions: [{ text: this.currentLoanTermsStr }],
      },
    ];
  }

  getTitleString(monthlyCost: number, numberOfMonths: number): string {
    const baseTitle = `${monthlyCost.toLocaleString('sv', {
      maximumFractionDigits: 0,
    })} SEK/mån i ${numberOfMonths} månader`;
    if (this.paymentRequest.amountDenotesCreditLimit) {
      return 'Max ' + baseTitle;
    }

    return baseTitle;
  }

  getBulletPoints(item: CreditOptionDto) {
    return [`Årsränta ${(item.nominalInterest * 100).toFixed(2)}%`];
  }

  initSign() {
    const termId: string = this.selectedInstallmentPlan.termId;
    this.navigating = true;
    this.preventBackToSettlementOrAuthGuard.deactivate();

    this.router
      .navigate([routeNames.SETTLE], {
        queryParams: {
          termId: termId,
          shouldOpenBankId: 'true',
        },
        queryParamsHandling: 'merge',
      })
      .then(
        () => {
          this.navigating = false;
          this.preventBackToSettlementOrAuthGuard.activate();
        },
        (rejection) => {
          this.loggingService.error(
            `Navigation from ${routeNames.SELECT_INSTALLMENT_PLAN} to ${routeNames.SETTLE} was rejected.`,
            rejection
          );
        }
      );
  }

  openChoiceTerms() {
    const loanOfferId: string = this.selectedInstallmentPlan.termId;

    this.http
      .get(`${SERVER_BASE_URL}/doc/invoice/draft/${loanOfferId}`)
      .subscribe(
        (contractDto: any) => {
          window.location.href = contractDto.contractUrl;
        },
        (error: any) => {
          // TODO show error msg?
          this.loggingService.log(
            'Error when fetching invoice contract draft',
            error
          );
        }
      );
  }

  isFirstItem(index: number) {
    return index === 0;
  }

  isLastItem(index: number) {
    return (
      index === this.presentedInstallmentPlans.length - 1 &&
      this.availableInstallmentPlans.length <= 3
    );
  }

  openSekki() {
    const contractUrl = `${SERVER_BASE_URL}/doc/public/secci`;
    window.open(contractUrl);
  }

  openTerms() {
    const docUrl = `${SERVER_BASE_URL}/doc/public/tos`;
    window.open(docUrl);
  }

  activateModal(activeTab: string) {
    this.modalActive = true;
    this.modalSelector.selectItemByTab(activeTab);
  }

  deactivateModal() {
    this.modalActive = false;
  }

  buildLoanTermsStr(selectedInstallmentPlan): string {
    return `Valt kreditbelopp ${
      this.paymentRequest.amount
    }\xa0kr, delbetalning ${
      selectedInstallmentPlan.numberOfMonths
    } mån. Totala summa av betalningar är ${
      selectedInstallmentPlan.totalAmount
    }\xa0kr och den effektiva räntan är ${this.getFormattedInterest(
      selectedInstallmentPlan.effectiveInterest
    )}%. Rörlig årsränta ${this.getFormattedInterest(
      selectedInstallmentPlan.nominalInterest
    )}%, uppläggningsavgift ${
      selectedInstallmentPlan.initialFee
    }\xa0kr, administrativ avgift ${
      selectedInstallmentPlan.monthlyFee
    }\xa0kr/mån och månadsbelopp ${
      selectedInstallmentPlan.monthlyCost
    }\xa0kr totala summan av betalningar ${
      selectedInstallmentPlan.totalAmount
    }\xa0kr.`;
  }

  getFormattedInterest(interest: number): string {
    if (!interest) {
      interest = 0;
    }
    return (interest * 100).toFixed(2);
  }

  toggleExpandedView() {
    if (this.presentedInstallmentPlans === this.availableInstallmentPlans) {
      this.filteredInstallmentPlans = this.originalFilteredInstallmentPlans;
      let lowestDifferenceInMonthlyCost: number = 50000;
      let planWithClosestMonthlyCost: CreditOptionDto;

      //Find the plan out of the originally displayed options with the closest monthly cost to the currently selected one
      for (let installmentPlan of this.filteredInstallmentPlans) {
        let differenceInMonthlyCost = Math.abs(
          this.selectedInstallmentPlan.monthlyCost - installmentPlan.monthlyCost
        );
        if (differenceInMonthlyCost < lowestDifferenceInMonthlyCost) {
          lowestDifferenceInMonthlyCost = differenceInMonthlyCost;
          planWithClosestMonthlyCost = installmentPlan;
        }
      }

      //And replace it with the currently selected one in the non expanded view
      let newFilteredInstallmentPlans: CreditOptionDto[] = [];
      for (let installmentPlan of this.filteredInstallmentPlans) {
        if (
          installmentPlan.monthlyCost !== planWithClosestMonthlyCost.monthlyCost
        ) {
          newFilteredInstallmentPlans.push(installmentPlan);
        } else {
          newFilteredInstallmentPlans.push(this.selectedInstallmentPlan);
        }
      }
      this.filteredInstallmentPlans = newFilteredInstallmentPlans;

      this.presentedInstallmentPlans = this.filteredInstallmentPlans;
      this.select(
        this.presentedInstallmentPlans.indexOf(this.selectedInstallmentPlan)
      );
    } else {
      this.presentedInstallmentPlans = this.availableInstallmentPlans;
      this.select(
        this.presentedInstallmentPlans.indexOf(this.selectedInstallmentPlan)
      );
    }
  }
}
