import {Component, OnDestroy, OnInit} from '@angular/core';
import {routeNames, routeParamNames} from '../../../assets/val/route-constants';
import {ActivatedRoute, Router} from '@angular/router';
import {InstantDebitService} from '../../service/details/instant-debit.service';
import {BankinfoService} from '@app/service/bankinfo/bankinfo.service';

@Component({
  selector: 'app-accounts-landing',
  templateUrl: './accounts-landing.component.html',
})
export class AccountsLandingComponent implements OnInit, OnDestroy {

  public readonly LOADING_VAL_MSG = 'Vänta...';

  private taskId: string;
  private code: string;
  private credentialsId: string = null;

  tinkSuccess: boolean;
  loading: boolean;

  sub: any;
  page: any;
  targetRoute: any;

  constructor(
    private detailsService: InstantDebitService,
    private bankinfoService: BankinfoService,
    private route: ActivatedRoute,
    private router: Router) {

  }

  ngOnInit() {
    this.loading = true;
    this.sub = this.route.queryParams.subscribe(params => {
      console.log(params);
      if (params.code) {
        this.taskId = params.state;
        this.code = params.code;

        if (params.credentialsId) {
          this.credentialsId = params.credentialsId;
        }

        this.getTinkReference(this.code, this.taskId, this.credentialsId);

      } else if (params.state && params.error) {
        // this.errorService.updateErrorMessage('Uppkopplingen mot banken misslyckades');
        // this.errorService.setErrorInfoMessage('Du kanske valde en bank där du inte har
        // några registrerade konton? Klicka på knappen nedan för att återgå till bankmenyn.')
        // this.errorService.updateErrorMessage(params.error);
        // if (params.message) {
        //   this.errorService.setErrorInfoMessage(params.message);
        // }
        // this.errorService.setErrorReroute(routeNames.BANKS);
        this.router.navigate([routeNames.ERROR, params.state]);
      } else {
        this.loading = false;
        this.tinkSuccess = false;
      }
    });

  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  getTinkReference(code: string, taskId: string, credentialsId: string) {
    this.bankinfoService.getTinkReference(code, taskId, credentialsId).subscribe( res => {
      this.nextPage();
    }, err => {
        this.detailsService.getBankInfoAvailable(this.taskId).subscribe(res => {
        if (res === true) {
          this.router.navigate([routeNames.ACCOUNT_SELECT, this.taskId]);
        } else {
          this.loading = false;
          this.tinkSuccess = true;
        }
      });
    });
  }

  nextPage() {
    this.targetRoute = routeNames.ACCOUNT_SELECT;
    this.router.navigate([this.targetRoute, this.taskId]);
  }

}
