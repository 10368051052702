export const routeNames = {
  EMAIL_AND_INVOICE: 'email',
  INVOICE_CONFIRM: 'invoice/confirm',
  LEQ_SUMMARY: 'leq/summary',
  LEQ: 'leq',
  SELECT_INSTALLMENT_PLAN: 'term-select',
  BANK_TRANSFER_FRAME: 'transfer',
  SETTLE: 'settle',
  SUCCESS: 'success',
  AUTH: 'auth',
  ERROR: 'error',
  PERSONAL_NUMBER_ENTRY: 'pnr-entry',
  HOME: '',
  SWISH: 'swish',
  VIPPS: 'vipps',
  TINK_LAUNCH: 'tink-launch',
  ACCOUNT_CONFIRM: 'account-confirm',
  ACCOUNT_LANDING: 'account-landing',
  ACCOUNT_SELECT: 'account-select',
  TRUSTLY_DIRECT_DEBIT: 'direct-debit',
  TRUSTLY_DIRECT_DEBIT_EMAIL: 'tdd-landing-email',
};

export const routeParamNames = {
  LEQ_STEP: 'step',
  TASK_ID: 'task',
};

export enum AtRoute {
  HOME = 'at',
  EMAIL_AND_PHONE_REGISTRATION = 'at/email-and-phone-registration',
  PHONE_VERIFICATION = 'at/phone-verification',
  USER_REGISTRATION = 'at/user-registration',
  PRE_FTS = 'at/pre-fts',
  FTS = 'at/fts',
  PART_SUCCESS = 'at/part-success',
  VERIFY_USER = 'at/verify-user',
  SUCCESS = 'at/success',
  ACCOUNT_RESTORATION = 'at/account-restoration',
  EDIT_PHONE = 'at/edit-phone',
  ERROR = 'at/error',
  REJECTED = 'at/rejected',
  VERIFY_EMAIL = 'at/verify-email',
  PRECHECK = 'at/precheck',
  MONTHLY_INVOVOICE = 'at/monthly-invoice',
  INSTALLMENTS_SELECTION = 'at/installments-selection',
}

export enum DeRoute {
  HOME = 'de',
  EMAIL_AND_PHONE_REGISTRATION = 'de/email-and-phone-registration',
  PHONE_VERIFICATION = 'de/phone-verification',
  USER_REGISTRATION = 'de/user-registration',
  PRE_FTS = 'de/pre-fts',
  FTS = 'de/fts',
  PART_SUCCESS = 'de/part-success',
  VERIFY_USER = 'de/verify-user',
  SUCCESS = 'de/success',
  ACCOUNT_RESTORATION = 'de/account-restoration',
  EDIT_PHONE = 'de/edit-phone',
  ERROR = 'de/error',
  REJECTED = 'de/rejected',
  VERIFY_EMAIL = 'de/verify-email',
  PRECHECK = 'de/precheck',
  MONTHLY_INVOVOICE = 'de/monthly-invoice',
  INSTALLMENTS_SELECTION = 'de/installments-selection',
  GENERALERROR = 'de/general-error',
}

export enum NoRoute {
  EMAIL_AND_INVOICE = 'no/email',
  INVOICE_CONFIRM = 'no/invoice/confirm',
  LEQ_SUMMARY = 'no/leq/summary',
  LEQ = 'no/leq',
  INSTALLMENTS_SELECTION = 'no/installments-selection',
  BANK_TRANSFER_FRAME = 'no/transfer',
  SETTLE = 'no/settle',
  SUCCESS = 'no/success',
  AUTH = 'no/auth',
  AUTH_CALLBACK = 'no/auth-callback',
  ERROR = 'no/error',
  REJECTED = 'no/rejected',
  PERSONAL_NUMBER_ENTRY = 'no/pnr-entry',
  HOME = 'no',
  VIPPS = 'no/vipps',
}
