import { EnvIdentifier, LogLevel } from "./environment-types";


export const environment = {
  production: true,
  envIdentifier: EnvIdentifier.STAGING,
  logSettings: { minLevels: 
    { 
      "telemetry" : LogLevel.WARN,
      "console" : LogLevel.ALL,
      "stash" : LogLevel.DEBUG
    },
    routerLog: false
  },
  transferApiBaseUrl: 'https://server.staging.zaver.se/checkout/testing/transfer',
  transferFrameBaseUrl: 'https://test-transfer.zaver.se/home'
};

export const SERVER_BASE_URL = 'https://server.staging.zaver.se/checkout/payer';
