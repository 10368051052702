import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DeBaseComponent {
  noResponseError = false;
  internalServerError = false;

  handleError(error: any): void {
    console.log(error);
    const httpError: HttpErrorResponse = JSON.parse(JSON.stringify(error));
    if (httpError.status === 0) {
      this.noResponseError = true;
    }
    console.log(httpError.status);
    if (httpError.status === 500) {
      this.internalServerError = true;
    }
  }

  resetError(): void {
    this.noResponseError = false;
  }
}
