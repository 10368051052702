import { AfterViewInit, Component } from '@angular/core';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { RoutingHistoryService } from './service/routing-history/routing-history.service';
import { LoggingService } from './service/logging-service/logging.service';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from './component/dialog/error/error-dialog.component';
import { routeNames } from '@assets/val/route-constants';
import { Market } from './model/payment-request/payment-request.dto';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements AfterViewInit {
  public navigating: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private routingHistoryService: RoutingHistoryService,
    private loggingService: LoggingService,
    private dialogService: MatDialog
  ) {
    this.navigating = true;
    this.routingHistoryService.loadRouting();
    this.loggingService.renewContextId();
  }

  ngAfterViewInit() {
    const market = window.location.pathname?.slice(1, 3).toUpperCase();
    if (market === Market.DE || market === Market.AT) {
      let link: any = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.head.appendChild(link);
      }
      link.href = 'assets/icon/Zaver_Symbol_Circle_DE.svg';
    }

    if (!this.storageAvailable('localStorage')) {
      const dialogConfiguration = {
        maxHeight: '50vh',
        maxWidth: '80vw',
        data: {
          message:
            'Det verkar som din enhet är i privat läge - gå ur privat läge för att genomföra betalningen',
          buttons: [
            {
              title: 'ok',
              callback: () => {
                this.router.navigate([routeNames.ERROR], {
                  queryParams: {
                    fatal: 'true',
                    error: 'Gå ur privat läge för att genomföra betalningen',
                  },
                  queryParamsHandling: 'preserve',
                });
              },
            },
          ],
        },
      };
      this.loggingService.reportCrash('localstorage unavailable');
      this.dialogService.open(ErrorDialogComponent, dialogConfiguration);
    } else {
      this.loggingService.setPersistentId();
    }

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.navigating = true;
      } else if (
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel
      ) {
        this.navigating = false;
      }
    });
  }

  //https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
  storageAvailable(type) {
    var storage;
    try {
      storage = window[type];
      var x = '__storage_test__';
      storage.setItem(x, x);
      storage.removeItem(x);
      return true;
    } catch (e) {
      return (
        e instanceof DOMException &&
        // everything except Firefox
        (e.code === 22 ||
          // Firefox
          e.code === 1014 ||
          // test name field too, because code might not be present
          // everything except Firefox
          e.name === 'QuotaExceededError' ||
          // Firefox
          e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
        // acknowledge QuotaExceededError only if there's something already stored
        storage &&
        storage.length !== 0
      );
    }
  }
}
