import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteComponent } from '@app/route/route.component';
import { HeaderService } from '@app/service/header/header.service';
import { ResolveType, StateService } from '@app/service/state/state.service';

@Component({
  selector: 'no-rejected',
  templateUrl: './no-rejected.component.html',
  styleUrls: ['./no-rejected.component.scss'],
})
export class NoRejectedComponent extends RouteComponent implements OnInit {
  loading = false;
  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected stateService: StateService,
    protected headerService: HeaderService
  ) {
    super(router, route, stateService, headerService);
  }

  ngOnInit(): void {}

  handleCtaClick() {
    this.resolveInterruption(ResolveType.CTA);
  }
}
