<app-de-wrapper *ngIf="internalServerError">
  <div style="display: flex; flex-direction: column; align-items: center">
    <img class="general-error-icon" src="assets/icon/smiley-sad-nerd.svg" />
    <h3 class="general-error-text">Etwas ist schief gelaufen.</h3>
  </div>
</app-de-wrapper>

<app-de-wrapper *ngIf="loading">
  <app-spinner></app-spinner>
</app-de-wrapper>

<app-de-wrapper
  *ngIf="!loading && currentView === View.DEFAULT && !internalServerError"
>
  <h1 class="heading">
    {{ state.currentPageInformation.pageSpecificParameters.heading }}
  </h1>
  <p class="info-text confirm-email-text">
    {{ state.currentPageInformation.pageSpecificParameters.subHeading }}
  </p>
  <form [formGroup]="form" id="email-and-phone-form">
    <div class="input-container">
      <zaver-animated-form-input
        [size]="'large'"
        [placeholder]="'E-Mail Adresse'"
        [formControlName]="'email'"
        [error]="form.controls.email.invalid && showError"
        [errorMessage]="'Bitte geben Sie eine gültige E-Mail Adresse ein'"
        [inputMode]="'email'"
        [autoComplete]="'email'"
        [inputType]="'email'"
        [errorIcon]="'assets/icon/icon_error.svg'"
        [infoIcon]="
          !preExistingContactInfo.email.editable
            ? '../../../../assets/icon/icon_info_gray.svg'
            : null
        "
      ></zaver-animated-form-input>
    </div>

    <div class="input-container">
      <zaver-animated-form-input
        [size]="'large'"
        [placeholder]="'Handynummer'"
        [formControlName]="'phoneNumber'"
        [error]="form.controls.phoneNumber.invalid && showError"
        [errorMessage]="'Bitte geben Sie eine gültige Handynummer ein'"
        [inputMode]="'tel'"
        [autoComplete]="'tel'"
        [inputType]="'tel'"
        [showContent]="true"
        [errorIcon]="'assets/icon/icon_error.svg'"
        ><zaver-select-country
          [isMobile]="isMobile"
          [buttonId]="'select-country-code'"
          [items]="AVAILABLECOUNTRYCODES"
          (itemSelected)="setCountry($event)"
          [selectedItem]="selectedcountry"
        ></zaver-select-country>
      </zaver-animated-form-input>
    </div>
  </form>
  <div
    class="checkbox-wrapper"
    *ngIf="
      state.currentPageInformation.pageSpecificParameters &&
      state.currentPageInformation.pageSpecificParameters
        .displaySaveDataCheckbox
    "
  >
    Daten merken
    <zaver-checkbox
      class="checkbox"
      (change)="onChangeRememberMe($event)"
      [(ngModel)]="rememberMe"
    ></zaver-checkbox>
  </div>

  <app-general-error
    style="margin-top: 8px"
    *ngIf="form.valid && noResponseError"
  ></app-general-error>

  <div class="footer-wrapper">
    <div class="footer-container">
      <div class="total-amount" *ngIf="state.totalCheckoutValue">
        <p>Gesamtbetrag:</p>
        <p>{{ state.totalCheckoutValue | value }}</p>
      </div>

      <div class="button-container">
        <zaver-primary-button
          [buttonType]="'submit'"
          [formId]="'email-and-phone-form'"
          [text]="'Weiter'"
          [disabled]="navigationInProgress"
          (click)="handleCtaClick()"
        ></zaver-primary-button>
      </div>

      <app-de-footer
        *ngIf="state.currentPageInformation.homePage"
        [selectorItems]="selectorItems"
      ></app-de-footer>
    </div>
  </div>
</app-de-wrapper>

<app-de-wrapper
  *ngIf="!loading && currentView === View.CONFIRM_EMAIL && !internalServerError"
>
  <h1 class="heading">E-Mail Adresse bestätigen</h1>

  <form
    [formGroup]="confirmEmailForm"
    id="confirm-email-form"
    style="margin-top: 32px"
  >
    <div class="input-container">
      <zaver-animated-form-input
        [size]="'large'"
        [placeholder]="'E-Mail Adresse'"
        [formControlName]="'email'"
        [error]="confirmEmailForm.controls.email.invalid && showError"
        [errorMessage]="'Bitte geben Sie eine gültige E-Mail Adresse ein'"
        [inputMode]="'email'"
        [autoComplete]="'email'"
        [inputType]="'email'"
        [errorIcon]="'assets/icon/icon_error.svg'"
      ></zaver-animated-form-input>
    </div>
    <div class="input-container">
      <zaver-animated-form-input
        [size]="'large'"
        [placeholder]="'E-Mail Adresse'"
        [formControlName]="'confirmEmail'"
        [error]="confirmEmailForm.invalid && showError"
        [errorMessage]="'Bitte geben Sie eine gültige E-Mail Adresse ein'"
        [inputMode]="'email'"
        [autoComplete]="'email'"
        [inputType]="'email'"
        [errorIcon]="'assets/icon/icon_error.svg'"
      ></zaver-animated-form-input>
    </div>
  </form>

  <div style="margin-bottom: 24px"></div>

  <div>
    <app-general-error
      style="margin-bottom: 16px"
      *ngIf="form.valid && noResponseError"
    ></app-general-error>
  </div>

  <div class="footer-wrapper">
    <div class="footer-container">
      <div class="total-amount" *ngIf="state.totalCheckoutValue">
        <p>Gesamtbetrag:</p>
        <p>{{ state.totalCheckoutValue | value }}</p>
      </div>

      <div class="button-container">
        <zaver-primary-button
          [buttonType]="'submit'"
          [formId]="'confirm-email-form'"
          [text]="'Weiter'"
          [disabled]="navigationInProgress"
          (click)="confirmEmail()"
        ></zaver-primary-button>
      </div>
    </div>
  </div>
</app-de-wrapper>

<app-de-wrapper
  *ngIf="!loading && currentView === View.NOT_FOUND && !internalServerError"
>
  <div class="footer-wrapper">
    <div class="footer-container">
      <h3 class="heading">Prüfen Sie Ihre Daten</h3>

      <p class="info-text">
        Die E-Mail Adresse war zuvor mit einer anderen Handynummer verknüpft.
        Bitte ändern Sie Ihre Angaben oder setzen Sie Ihr Zaver-Konto zurück.
      </p>

      <div class="account-details-wrapper">
        <p class="email">{{ form.controls.email.value }}</p>
        <p class="phone">{{ form.controls.phoneNumber.value }}</p>
      </div>

      <div class="button-container">
        <zaver-primary-button
          [text]="'Ändern'"
          [disabled]="navigationInProgress"
          (click)="setCurrentView(View.DEFAULT)"
        ></zaver-primary-button>
      </div>

      <div class="secondary-button-container">
        <zaver-secondary-button-outlined
          [disabled]="navigationInProgress"
          (click)="navigateToAccountRestoration()"
          [text]="'Konto wiederherstellen'"
        ></zaver-secondary-button-outlined>
      </div>
    </div>
  </div>
</app-de-wrapper>
