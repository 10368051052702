<div class="page-body" *ngIf="!loading">
  <div class="banner">
    <img
      src="assets/icon/Arrow-Left.svg"
      alt=""
      class="back-button"
      (click)="reroute()"
    />
    <span class="back-text" (click)="reroute()">Tillbaka</span>
  </div>

  <div class="contents-wrapper">
    <div class="contents-container">
      <!-- Mobile automatic payment layout -->
      <div class="mobile-layout" *ngIf="isPhone && !formLayout && !error">
        <div class="loading-info-container">
          <div class="spinner-container">
            <app-spinner [overrideMargins]="true"> </app-spinner>
          </div>
          <div class="status-message">
            {{ mobileStatusMessage }}
          </div>
        </div>

        <ng-container *ngIf="manualDeeplinkEnabled">
          <div class="alert-container">
            <div class="deeplink-button" (click)="startSwish()">
              Öppna Swish
            </div>
          </div>

          <div class="open-on-other-device" (click)="restartOnOtherDevice()">
            Swisha från en annan enhet
          </div>
        </ng-container>
      </div>

      <!-- Form layout, desktop or other device requested on mobile -->
      <ng-container *ngIf="formLayout && !error && !paymentStatusOtherDevice">
        <div class="page-icon">
          <img
            src="/assets/icon/Icon-Phone-Checkmark-Mobile-Shadow.svg"
            alt=""
          />
        </div>

        <div class="page-text">
          Ange det mobilnummer du vill göra betalningen ifrån.
        </div>

        <form [formGroup]="form">
          <div class="input-container">
            <zaver-animated-form-input
              [size]="'large'"
              [ariaLabel]="'Ange mobilnummer'"
              [formControlName]="'phone'"
              [error]="form.controls.phone.invalid && showError"
              inputMode="tel"
              placeholder="Mobilnummer"
              errorMessage="Vänligen ange ett giltigt mobilnummer (07********* eller +*********)"
              [errorIcon]="'assets/icon/icon_error.svg'"
            ></zaver-animated-form-input>
          </div>
        </form>

        <div class="pr-info" *ngIf="amount">
          <label> Totalbelopp </label>
          <label>
            {{
              amount.toLocaleString('sv', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })
            }}
            SEK
          </label>
        </div>

        <zaver-primary-button
          [text]="'Swisha'"
          [logo]="'/assets/icon/Swish_Logo_Primary.svg'"
          (click)="initPayment()"
        ></zaver-primary-button>
      </ng-container>

      <!-- While payment active on other device -->
      <ng-container *ngIf="!!error || !!paymentStatusOtherDevice">
        <div class="active-payment-wrapper">
          <div class="alert-container" *ngIf="!!error">
            <zaver-alert-box [messageList]="getErrorMessages()" type="WARNING">
            </zaver-alert-box>
          </div>
          <div
            class="alert-container"
            *ngIf="!!paymentStatusOtherDevice && !error"
          >
            <zaver-alert-box [text]="paymentStatusOtherDevice" type="INFO">
            </zaver-alert-box>
          </div>
          <div
            class="cancel-transaction"
            *ngIf="!!paymentStatusOtherDevice"
            (click)="restartOnOtherDevice()"
          >
            Avbryt överföring
          </div>

          <div class="pr-info" *ngIf="amount && (!isPhone || !!error)">
            <label> Totalbelopp </label>
            <label>
              {{
                amount.toLocaleString('sv', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })
              }}
              SEK
            </label>
          </div>

          <zaver-primary-button
            *ngIf="isPhone && !!error"
            [text]="'Försök igen'"
            [loading]="paymentInProgress || isPolling()"
            [disabled]="paymentInProgress || isPolling()"
            [logo]="'/assets/icon/Swish_Logo_Primary.svg'"
            (click)="restartMobilePayment()"
          ></zaver-primary-button>

          <zaver-primary-button
            *ngIf="!isPhone"
            text="Försök igen"
            [loading]="!error"
            [disabled]="!error"
            logo="/assets/icon/Swish_Logo_Primary.svg"
            (click)="restartFormBasedPayment()"
          ></zaver-primary-button>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<div class="page-body" *ngIf="loading">
  <div class="contents-wrapper">
    <div class="contents-container">
      <app-spinner></app-spinner>
    </div>
  </div>
</div>
