import {
  Component,
  EventEmitter,
  ElementRef,
  Input,
  Output,
  OnInit,
  HostListener,
} from '@angular/core';
import { ModalService } from '../../service/modal.service';

@Component({
  selector: 'zaver-checkout-modal',
  templateUrl: './checkout-modal.component.html',
  styleUrls: ['./checkout-modal.component.scss'],
})
export class CheckoutModalComponent implements OnInit {
  @Input() set active(val: boolean) {
    if (val === true) {
      this.setupModal();
    } else {
      this.close();
    }
  }

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent): void {
    if (this.modalActive) {
      this.removeModal();
    }
  }

  @Input() id: string;
  @Input() title: string;
  @Input() logo: string;
  @Input() buttonText: string;
  private element: any;

  @Output()
  onClose = new EventEmitter<any>();
  modalActive: boolean = false;

  constructor(private modalService: ModalService, private el: ElementRef) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    // ensure id attribute exists
    if (!this.id) {
      console.error('modal must have an id');
      return;
    }

    // move element to bottom of page (just before </body>) so it can be displayed above everything else
    document.body.appendChild(this.element);

    // close modal on background click
    this.element.addEventListener('click', (el) => {
      if (el.target.className === 'modal') {
        this.close();
      }
    });

    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.modalService.add(this);
  }

  // remove self from modal service when component is destroyed
  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    document && document.body.removeChild(this.element);
  }

  setupModal() {
    if (!this.id) {
      console.error('modal must have an id');
      return;
    }
    this.modalActive = true;
    document.body.appendChild(this.element);

    this.element.addEventListener('click', (el) => {
      if (el.target.className === 'modal') {
        this.removeModal();
      }
    });

    this.modalService.add(this);
    this.open();
  }

  removeModal() {
    this.modalService.remove(this.id);
    /*this.element.remove();*/
    this.element.removeEventListener('click', (el) => {
      if (el.target.className === 'modal') {
        this.removeModal();
      }
    });

    this.modalActive = false;
    this.close();
    this.onClose.emit();
  }

  // open modal
  open(): void {
    this.element.style.display = 'block';
    document.body.classList.add('modal-open');
    document
      .getElementById(this.id)
      .getElementsByClassName('modal-background')[0]
      .classList.add('fade');
  }

  // close modal
  close(): void {
    this.element.style.display = 'none';
    document.body.classList.remove('modal-open');
    document
      .getElementById(this.id)
      .getElementsByClassName('modal-background')[0]
      .classList.remove('fade');
  }
}
