<app-de-thin-wrapper *ngIf="loading">
  <app-spinner></app-spinner>
</app-de-thin-wrapper>

<app-de-thin-wrapper *ngIf="!loading && !error">
  <noscript>
    <h2>Your browser does not support JavaScript.</h2>
    Please deactivate JavaScript Blocker, AdBlocker etc. to use the service.
  </noscript>
  <div id="XS2A-Form"></div>
</app-de-thin-wrapper>

<app-de-thin-wrapper *ngIf="error">
  <div class="container">
    <div class="img-container">
      <img src="assets/icon/smiley-sad-nerd.svg" class="smiley-icon" alt="" />
    </div>

    <p class="heading">Etwas ist schief gelaufen.</p>

    <p class="info-text">
      Bitte versuchen Sie es erneut. Kontaktieren Sie uns, wenn das Problem
      weiterhin besteht.
    </p>

    <div class="button-wrapper">
      <zaver-secondary-button-outlined
        style="max-width: 240px"
        (click)="handleCtaClick()"
        [text]="'Erneut versuchen'"
        [rounded]="true"
      ></zaver-secondary-button-outlined>
    </div>
  </div>
</app-de-thin-wrapper>
