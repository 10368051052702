import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {SERVER_BASE_URL} from '../../environments/environment';
import {map, tap} from 'rxjs/operators';
import {AuthStateDto} from './auth-state.dto';
import {GuardRedirectService} from '../service/redirect/guard-redirect.service';
import {QueryParamsService} from '../service/query-params/query-params.service';
import {routeNames} from '../../assets/val/route-constants';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private http: HttpClient,
              private router: Router,
              private guardRedirectService: GuardRedirectService,
              private queryParamsService: QueryParamsService) {

  }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {


    return this.http.get<AuthStateDto>(`${SERVER_BASE_URL}/auth/public/state`,
      { withCredentials: true })
      .pipe(tap(res => this.redirectIfNotAuthenticated(res, state)))
      .pipe(map(res => res.authenticated));
  }

  private redirectIfNotAuthenticated(auth: AuthStateDto,
                                     routerState: RouterStateSnapshot) {
    if (auth.authenticated) {
      return;
    }

    let targetPath: string = routerState.url.split('?')[0];
    // Get rid of the '/'
    targetPath = targetPath.substr(1);

    const callback = () => this.router.navigate([routeNames.AUTH], {
      queryParamsHandling: 'merge',
      queryParams: {
        tg: targetPath,
        // remove parameters that can confuse the Auth page
        shouldOpenBankId: null,
        bidRequestId: null,
        termId: null,
        error: null,
        // ...this.queryParamsService.getQueryParams(),
      }
    });

    this.guardRedirectService.addRouteToRedirectionQueue(callback, routeNames.AUTH);

  }

}
