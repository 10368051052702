import { Injectable, OnDestroy } from '@angular/core';
import { environment, SERVER_BASE_URL } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngxs/store';
import { LogLevel } from 'src/environments/environment-types';
import * as StackTrace from 'stacktrace-js';
import * as Sentry from '@sentry/angular';
import { from, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { RoutingHistoryService } from '../routing-history/routing-history.service';
import { PaymentRequestDto } from 'src/app/model/payment-request/payment-request.dto';
import { QueryParamsService } from '../query-params/query-params.service';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class LoggingService implements OnDestroy {
  messageStash: Array<LogMessage> = [];
  frontEndContextId: string;

  constructor(
    private http: HttpClient,
    private store: Store,
    private route: ActivatedRoute,
    private routeingHistoryService: RoutingHistoryService,
    private queryParamsService: QueryParamsService
  ) {}

  ngOnDestroy() {}

  public renewContextId() {
    this.log('updating frontendContext');
    this.frontEndContextId = 'link-checkout-' + this.makeId(2) + Date.now();
    Sentry.setContext('ids', {
      paymentRequestId: this.queryParamsService.getQueryParams().id,
      frontendContext: this.frontEndContextId,
    });
  }

  public setPersistentId() {
    // cannot be done before we've checked if localstorage is available. All localstorage-access should probably be wrapped in a service, or totally avoided.
    if (!localStorage.getItem('zco-persistent-id')) {
      localStorage.setItem(
        'zco-persistent-id',
        'zco-persistent-' + this.makeId(10)
      );
      Sentry.setContext('ids', {
        persistentId: localStorage.getItem('zco-persistent-id'),
      });
    }
  }

  makeId(length: number) {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  public getContextID() {
    return this.frontEndContextId ? this.frontEndContextId : '';
  }

  public logEvent(type: FrontendEventType, message: string): void {
    try {
      this.http
        .post(`${SERVER_BASE_URL}/log/event`, {
          type: type,
          message: message,
        })
        .subscribe();
    } catch (e) {
      this.reportCrash('failed to send event: ', JSON.stringify(e), null);
    }
  }

  public getPersistentId() {
    return localStorage.getItem('zco-persistent-id')
      ? localStorage.getItem('zco-persistent-id')
      : '';
  }

  public exception(message: string, error: Error, ...args: any[]) {
    this._log(message, args, LogLevel.ERROR, error);
  }

  public debug(message: string, ...args: any[]): void {
    this._log(message, args, LogLevel.DEBUG, null);
  }

  public log(message: string, ...args: any[]): void {
    this._log(message, args, LogLevel.LOG, null);
  }

  public warn(message: string, ...args: any[]): void {
    this._log(message, args, LogLevel.WARN, null);
  }

  public error(message: string, ...args: any[]): void {
    this._log(message, args, LogLevel.ERROR, null);
  }

  public manualReport() {
    this._reportCrash('Manually triggered debug dump', null);
  }

  public reportCrash(
    message: string,
    otherInfo?: string,
    stackTrace?: StackTrace.StackFrame[],
    ...args: any[]
  ): void {
    Sentry.captureEvent({ message: message });
    if (
      environment.logSettings.minLevels.telemetry <= LogLevel.ERROR &&
      this.route.snapshot.queryParams.id
    ) {
      this._reportCrash(
        message + ' other info: ' + otherInfo,
        stackTrace,
        args
      );
    }
    if (environment.logSettings.minLevels.console <= LogLevel.ERROR) {
      console.log('crash report: ' + message);
    }
  }

  private _reportCrash(
    message: string,
    stackTrace: StackTrace.StackFrame[],
    ...args: any[]
  ): void {
    try {
      const dump: CrashDump = {
        level: LogLevel[LogLevel.ERROR],
        entry: message + JSON.stringify(args),
        location: window.location.href,
        paymentRequest: this.getPrSummary(),
        stackTrace: stackTrace ? stackTrace.slice(0, 5) : null,
        userAgent: navigator.userAgent,
        routeHistory: this.routeingHistoryService.getHistory(),
        logDump: this.getMessageStash().map((item) => ({
          ...item,
          ...{ stackTrace: null },
        })),
      };
      this.http.post(`${SERVER_BASE_URL}/log/crash`, dump).subscribe(
        () => {
          console.log('sent! Message: ', message);
        },
        () => {
          console.log(
            'Could not send error log! online according to navigator: ',
            navigator.onLine
          );
        }
      );
    } catch (e) {
      Sentry.captureEvent(e);
    }
  }

  private _log(
    message: string,
    args?: any[],
    logLevel?: LogLevel,
    error?: Error
  ) {
    let fullMessage: LogMessage = null;
    if (
      environment.logSettings.minLevels.console <= logLevel ||
      this.queryParamsService.getQueryParams().debug === 'console'
    ) {
      console.log(message);
      if (args && args.length > 0) {
        console.log(args);
      }
    }
    if (environment.logSettings.minLevels.stash <= logLevel) {
      buildMessage
        .bind(this)(error)
        .subscribe((message) => {
          this.stashMessage(message);
        });
    }
    if (
      environment.logSettings.minLevels.telemetry <= logLevel ||
      this.queryParamsService.getQueryParams().debug === 'telemetry'
    ) {
      if (this.route.snapshot.queryParams.id) {
        this.reportLog(message, logLevel, args);
      }
    }

    function buildMessage(innerError): Observable<LogMessage> {
      if (!fullMessage) {
        return from(innerError ? StackTrace.fromError(error) : of(null)).pipe(
          map((trace) => {
            fullMessage = {
              paymentRequest: this.getPrSummary(),
              location: window.location.href,
              timestamp: new Date().toUTCString(),
              level: LogLevel[logLevel],
              entry: innerError
                ? innerError.message
                : message + JSON.stringify(args),
              stackTrace: null,
              userAgent: navigator.userAgent,
            };
            return fullMessage;
          })
        );
      }
      return of(fullMessage);
    }
  }

  reportLog(message: string, logLevel: LogLevel, args?: any[]): void {
    const fullMessage: LogMessage = {
      paymentRequest: this.getPrSummary(),
      location: window.location.href,
      timestamp: new Date().toUTCString(),
      level: LogLevel[logLevel],
      entry: args ? message + JSON.stringify(args) : message,
      stackTrace: null,
      userAgent: navigator.userAgent,
    };
    this.http.post(`${SERVER_BASE_URL}/log/`, fullMessage).subscribe(
      () => {
        console.log('sent! Message: ', message);
      },
      () => {
        console.log('Could not send log');
      }
    );
  }

  stashMessage(message: any) {
    try {
      if (localStorage.getItem('zaverLogStash') != null) {
        const saved = JSON.parse(localStorage.getItem('zaverLogStash'));
        this.messageStash = saved != null ? saved : [];
      }
      this.messageStash.push(message);
      localStorage.setItem('zaverLogStash', JSON.stringify(this.messageStash));
    } catch {
      this.messageStash.push(message);
    }
  }

  getMessageStash() {
    try {
      if (localStorage.getItem('zaverLogStash') != null) {
        this.messageStash = JSON.parse(localStorage.getItem('zaverLogStash'));
      }
    } catch {}
    return this.messageStash != null ? this.messageStash : [];
  }

  getPrSummary(): PaymentRequestLogInfo {
    const pr = this.store.snapshot().paymentRequest
      ? this.store.snapshot().paymentRequest.request
      : (null as PaymentRequestDto);
    if (pr) {
      return {
        id: pr.id,
        title: pr.title,
        status: pr.status,
        settlementMethod: pr.settlementMethod,
      };
    }
    return null;
  }
}

export interface LogMessage {
  timestamp: string;
  location: string;
  level: string;
  paymentRequest: PaymentRequestLogInfo;
  entry: string;
  stackTrace: StackTrace.StackFrame[];
  userAgent: string;
}

export interface CrashDump {
  level: string;
  location: string;
  entry: string;
  paymentRequest: PaymentRequestLogInfo;
  stackTrace: StackTrace.StackFrame[];
  userAgent: string;
  routeHistory: string[];
  logDump: LogMessage[];
}

export interface PaymentRequestLogInfo {
  id: string;
  status: string;
  title: string;
  settlementMethod: string;
}

export interface FrontendEventDto {
  type: FrontendEventType;
  message: String;
}

export enum FrontendEventType {
  DISPLAYED_PAYMENT_OPTIONS = 'DISPLAYED_PAYMENT_OPTIONS',
  GENERAL_NOTE = 'GENERAL_NOTE',
}
