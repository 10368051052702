import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from '@app/service/header/header.service';
import { StateService } from 'src/app/service/state/state.service';
import { UserService } from 'src/app/service/user-service/user.service';
import { RouteComponent } from '../../route.component';

@Component({
  selector: 'app-de-account-restoration',
  templateUrl: './de-account-restoration.component.html',
  styleUrls: [
    '../../page-shared.css',
    './de-account-restoration.component.scss',
  ],
})
export class DeAccountRestorationComponent
  extends RouteComponent
  implements OnInit
{
  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected stateService: StateService,
    protected headerService: HeaderService,
    private userService: UserService
  ) {
    super(router, route, stateService, headerService);
  }

  loading = true;
  showError: boolean = false;
  verificationCode: string;
  email: string;
  validVerificationCodeFormat = false;
  navigationInProgress = false;

  onOtpChange(e: any) {
    this.showError = false;
    this.verificationCode = e;
    this.validVerificationCodeFormat = e.length === 6;
    if (this.validVerificationCodeFormat) {
      this.handleCtaClick();
    }
  }

  ngOnInit(): void {
    this.getinfo();
  }

  async getinfo() {
    const userInfo = await this.userService.getActiveUserInfo();
    this.email = userInfo.email.value;
    this.loading = false;
  }

  async reSendCode(): Promise<void> {
    try {
      await this.userService.resendAccountRestorationEmail();
    } catch (err) {
      this.handleError(err);
    }
  }

  async verifyCode() {
    try {
      const resp = await this.userService.restoreAccount(
        this.verificationCode?.trim()
      );

      resp.success ? await this.navigate() : (this.showError = true);
    } catch (err) {
      this.handleError(err);
    }
  }

  async handleCtaClick() {
    if (this.navigationInProgress || !this.validVerificationCodeFormat) {
      this.showError = true;
    } else {
      this.navigationInProgress = true;
      await this.verifyCode();
    }
    this.navigationInProgress = false;
  }
}
