import { Component, Input, OnInit } from '@angular/core';
import { SelectorItem } from '@app/component/selector/selector.component';
import { Color } from 'web-component-library/projects/component-library/src/public-api';

@Component({
  selector: 'app-de-footer',
  templateUrl: './de-footer.component.html',
  styleUrls: ['../../page-shared.css', './de-footer.component.scss'],
})
export class DeFooterComponent implements OnInit {
  Color = Color;
  modalActive = false;
  @Input() selectorItems: SelectorItem[];
  constructor() {}

  ngOnInit(): void {}
  activateModal() {
    this.modalActive = true;
  }

  deactivateModal() {
    this.modalActive = false;
  }
}
