import { Component, OnInit } from '@angular/core';
import { QueryParamsService } from '../../service/query-params/query-params.service';
import { LoggingService } from 'src/app/service/logging-service/logging.service';
import { Router } from '@angular/router';
import { routeNames } from '@assets/val/route-constants';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css'],
})
export class ErrorComponent implements OnInit {
  errorMessage: string;
  infoMessage: string;

  constructor(
    private queryParamsService: QueryParamsService,
    private loggingService: LoggingService,
    private router: Router
  ) {}

  ngOnInit() {
    const queryMessage = this.queryParamsService.getQueryParams().error;
    this.errorMessage = queryMessage ? queryMessage : 'Ett fel har uppstått';
    //this.infoMessage = 'Vänligen prova att lagga om sidan eller försök igen lite senare.'
    this.loggingService.reportCrash(
      'loaded error page' + 'errormessage=' + this.errorMessage
    );
    if (!(this.queryParamsService.getQueryParams().fatal === 'true')) {
      this.loggingService.log('attempting to navigate to home page');
      this.router
        .navigate([routeNames.HOME], {
          queryParams: {
            fatal: 'true',
            error: null,
          },
          queryParamsHandling: 'merge',
        })
        .then(
          () => {},
          (rejection) => {
            this.loggingService.error(
              `Navigation from ${routeNames.ERROR} to ${routeNames.HOME} was rejected.`,
              rejection
            );
          }
        );
    }
  }
}
