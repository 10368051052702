<app-de-wrapper *ngIf="loading">
  <app-spinner></app-spinner>
</app-de-wrapper>

<div class="page-body">
  <div class="contents-wrapper">
    <div class="contents-container">
      <div class="installments-container">
        <ng-container
          *ngFor="let loanOption of presentedLoanOptions; let i = index"
        >
          <app-installments-item
            [isFirstItem]="i === 0"
            [isLastItem]="
              i === availableloanOptions.length - 1 &&
              equalOrLessThanThreeLoanOptions
            "
            [title]="loanOption.title"
            [selected]="loanOption === selectedLoanOption"
            (click)="
              loanOption !== selectedLoanOption && selectInstallment(loanOption)
            "
            [bulletPoints]="loanOption.bulletPoints"
            [link]="loanOption.link"
            [radioButtonColor]="loanOption.radioButtonColor"
            [color]="loanOption.color"
            [badgeText]="loanOption.badgeText"
          ></app-installments-item>
        </ng-container>
        <button
          *ngIf="!equalOrLessThanThreeLoanOptions"
          class="installments-button"
          (click)="expanded ? viewReducedLoanOptions() : viewAllLoanOptions()"
        >
          {{ expanded ? 'Vis mindre' : 'Vis mer' }}
          <img
            style="margin-left: 4px; display: inline-block"
            [src]="
              expanded
                ? 'assets/icon/Arrow-Up.svg'
                : 'assets/icon/Arrow-Down.svg'
            "
          />
        </button>
      </div>
      <div class="bottom-secetion-wrapper">
        <app-payment-request-info
          [lineItems]="state.paymentInformation.lineItems"
          [value]="state.totalCheckoutValue"
          [market]="state.market"
          (onDetailsClick)="toggleModal()"
        ></app-payment-request-info>

        <div class="button-container">
          <zaver-primary-button
            [text]="'Fullfør kjøp'"
            [disabled]="navigationInProgress"
            (click)="handleCtaClick()"
          ></zaver-primary-button>
        </div>

        <p class="loan-terms-string">{{ currentLoanTermsStr }}</p>
      </div>
    </div>
  </div>
</div>

<zaver-checkout-modal
  *ngIf="selectorItems"
  id="details-modal"
  [active]="modalActive"
  [buttonText]="'Lukk'"
  [logo]="'assets/img/Zaver_Logotype_Circle_Cerise_Coal.svg'"
  (onClose)="toggleModal()"
>
  <app-selector
    #selector
    [selectorItems]="selectorItems"
    [selectedItem]="selectorItems[0]"
  ></app-selector>
</zaver-checkout-modal>
