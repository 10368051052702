import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../service/auth/auth.service';
import { CustomerJourneyService } from '../../service/customer-journey/customer-journey.service';
import {
  ClearPreFilledInfo,
  InitAuthProcess,
  RegisterAuthState,
  SetPreFilledCredentials,
} from '../../ngxs/auth/auth.actions';
import { ENTER } from '@angular/cdk/keycodes';
import { CustomErrorMatcher } from '../../validation/custom.error-matcher';
import { personalNumberValidator } from '../../validation/personal-number.validator';
import { UserAgentService } from '../../service/user-agent/user-agent.service';
import { QueryParamsService } from '../../service/query-params/query-params.service';
import { Subscription } from 'rxjs';
import { LoggingService } from 'src/app/service/logging-service/logging.service';
import { routeNames } from '@assets/val/route-constants';
import { CreditService } from '@app/service/credit/credit.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css', '../page-shared.css'],
})
export class AuthComponent implements OnInit, AfterViewInit, OnDestroy {
  //TEMP
  validators;
  inputPnr: string;
  paymentRequest: any;
  showFormErrors: boolean;
  disableForm: boolean;

  paymentRequestId: string;

  ctaButtonText: string = 'Identifiera';

  manualDeeplinkEnabled: boolean = false;
  bankIdStatusMessage: string = 'Vänta...';
  authErrorMessageList: string[];

  public displayAuthInProgressSpinner = false;
  public loginButtonEnabled = true;
  public loginButtonTextOverride = null;
  public authInProgress = false;
  public authProcessStartedOnServer = false;

  // This variable is bound to the ngxs store and holds it's current personal
  // number value
  public personalNumber = '';

  // The name of a user revisiting the app, populated if the store indicates that
  // there is pre-fill info to be used on the auth page.
  public name;

  public preFilled = false;
  public loadingPreFillInfoFromServer = true;

  public errorStateMatcher = new CustomErrorMatcher(false);
  public shouldDisplayPnrErrorMessageList = false;
  public onPlatformWhereBankIdIsAssumedInstalled = false;
  public navigating = false;

  @ViewChild('personalNumberInput') input;

  private authSubscription: Subscription;
  private stateSubscription: Subscription;
  private hasStartedAuth = false;
  private bankIdActivityTimer: any = null;
  private ignoreNumErrors = 0;
  form: FormGroup;

  constructor(
    private authService: AuthService,
    private customerJourneyService: CustomerJourneyService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private creditService: CreditService,
    private userAgentService: UserAgentService,
    private queryParamsService: QueryParamsService,
    private loggingService: LoggingService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    /*this.paymentRequest =
      this.store.snapshot().paymentRequest.request;*/

    this.paymentRequestId = this.queryParamsService.getQueryParams().id;
    if (this.paymentRequestId) {
      this.creditService
        .getPaymentRequestPublicInfo(this.paymentRequestId)
        .subscribe((res) => {
          this.paymentRequest = res;
        });
    }

    this.loggingService.debug('ixnay ngOnInit');
    this.handlePotentialRedirectFromBankIdApp();
    this.determinePlatformSpecificUi();

    this.bindInstanceFieldToState();
    this.store.dispatch(new SetPreFilledCredentials());
    if (this.onPlatformWhereBankIdIsAssumedInstalled && !this.authInProgress) {
      this.authenticate();
    }
    this.setupForm();
  }

  ngAfterViewInit(): void {
    if (this.input) {
      this.input.setFocus();
    }
  }

  ngOnDestroy(): void {
    if (!!this.stateSubscription) {
      this.stateSubscription.unsubscribe();
      this.stateSubscription = null;
    }
    if (!!this.authSubscription) {
      this.authSubscription.unsubscribe();
      this.authSubscription = null;
    }
  }

  setupForm(): void {
    this.form = this.formBuilder.group({
      pnr: [
        this.inputPnr || '',
        [
          Validators.required,
          Validators.minLength(12),
          Validators.maxLength(12),
          personalNumberValidator(),
          Validators.pattern(
            '^((19|20)\\d{2})(0[1-9]|1[0-2])(0[1-9]|[1-2]\\d|3[0-1])[-+]*(\\d{4})$'
          ),
        ],
      ],
    });
  }

  private handlePotentialRedirectFromBankIdApp() {
    if (this.queryParamsService.getQueryParams().bidRequestId) {
      this.loggingService.debug('ixnay Coming back from bankid app');
      // it seems on iOS Safari when we come back from the bankid-app the page reloads and we get connection errors for
      // HTTP-requests. This might be that the browser actually reloads the page and maybe it also invalidates the SSL
      // connection which then needs renegotiating but our wildcard cert might introduce some further complexity in the mix.
      // For now we try to avoid this by just simply allowing a few errors initially before we consider this failed.
      this.ignoreNumErrors = 1;
      this.setupAuthInProgressSubscription();
      this.authService.resumeAuthenticationOfMobileUser();
      this.hasStartedAuth = true;
    }
  }

  private determinePlatformSpecificUi() {
    this.onPlatformWhereBankIdIsAssumedInstalled =
      this.userAgentService.isMobile();
  }

  /**
   * State binding is currently only used to retrieve an initial value for the
   * personal number field.
   */
  private bindInstanceFieldToState() {
    this.stateSubscription = this.store.subscribe((state) => {
      this.loadingPreFillInfoFromServer =
        !state.auth.personalNumberFormMeta.checkedServerForPreFillInfo;

      // Only bind if there is already a form ngxs state.
      if (state.personalNumberForm) {
        this.personalNumber = state.personalNumberForm.model.personalNumber;
        this.inputPnr = this.personalNumber;
      } else {
        const savedPnr = localStorage.getItem('pnr');

        if (!!savedPnr && savedPnr.length > 0) {
          this.inputPnr = savedPnr;
        }
      }
      this.name = state.auth.personalNumberFormMeta.preFillInfo.name;
      this.preFilled = state.auth.personalNumberFormMeta.preFilled;
    });
  }

  /**
   * This method is supposed to contain all the instance variable updates needed
   * to toggle view changes that makes it clear to the user that an auth
   * process has begun.
   */
  private indicateAuthInProgress(inProgress: boolean) {
    if (inProgress) {
      this.authErrorMessageList = null;
      this.displayAuthInProgressSpinner = true;
      this.authInProgress = true;
      this.loginButtonEnabled = false;
    } else {
      this.authProcessStartedOnServer = false;
      this.displayAuthInProgressSpinner = false;
      this.loginButtonEnabled = true;
      this.authInProgress = false;
      this.manualDeeplinkEnabled = false;
    }
  }

  handleCtaClick() {
    if (!this.onPlatformWhereBankIdIsAssumedInstalled) {
      this.updateErrorMessages();
      if (this.form.invalid) {
        return;
      }
    }
    this.authenticate();
  }

  updateErrorMessages() {
    //TODO this is not a good way to update error messages
    this.showFormErrors = !this.showFormErrors;
  }

  /**
   * Start an auth process by using the injected AuthService provider.
   */
  public authenticate() {
    this.authErrorMessageList = null;
    if (this.authInProgress) {
      this.loggingService.debug(
        'opening bankid-app, not starting subscription'
      );
      this.authService.openBankIdApp();
      return;
    }

    if (this.onPlatformWhereBankIdIsAssumedInstalled) {
      this.loggingService.debug('should start sub');
      this.initAuthForMobile();
      return;
    }

    this.loggingService.debug('should start sub');
    this.initFormBasedAuth();
  }

  private initFormBasedAuth() {
    if (this.form.invalid) {
      console.error(
        'Tried to authenticate an invalid national identification number'
      );
      return;
    }
    this.store.dispatch(new InitAuthProcess(this.form.value.pnr.trim()));
    this.setupAuthInProgressSubscription();
  }

  private initAuthForMobile() {
    // same deal here as in handlePotentialRedirectFromBankIdApp ... sometimes after returning from the BankID app
    // on Safari on iOS we encounter errors even though login is successful
    this.ignoreNumErrors = 1;
    this.authService.authenticateMobileUser();
    this.setupAuthInProgressSubscription();
  }

  private setupAuthInProgressSubscription() {
    // Let the user now what is happening.
    this.indicateAuthInProgress(true);
    this.hasStartedAuth = false;

    const thisComponent = this;

    if (!this.authSubscription) {
      this.authSubscription = this.store.subscribe((updatedState) => {
        const auth: RegisterAuthState = updatedState.auth;
        //thisComponent.bankIdStatusMessage = auth.message; bankIdStatusMessage has been changed
        if (auth.isError) {
          this.loggingService.debug('ixnay Took ERROR path');
          if (thisComponent.ignoreNumErrors-- > 0) {
            this.loggingService.debug(
              'Ignoring error' + thisComponent.ignoreNumErrors
            );
            return;
          }
          thisComponent.handleUnsuccessfulAuth();
          return;
        }

        if (auth.authenticated) {
          this.loggingService.debug('ixnay SUCCESS path!');
          thisComponent.handleSuccessfulAuth();
          return;
        }
        thisComponent.authProcessStartedOnServer = true;

        if (auth.status === 'OUTSTANDING_TRANSACTION') {
          // we are waiting for the user to start their BankID app, then status will be USER_SIGN
          // if the user does not start their app in a few seconds we will reactivate and relabel the button to give them
          // another chance to do it
          if (thisComponent.onPlatformWhereBankIdIsAssumedInstalled) {
            thisComponent.bankIdStatusMessage = 'Vänta...';

            if (!thisComponent.bankIdActivityTimer) {
              thisComponent.bankIdActivityTimer = setTimeout(() => {
                thisComponent.bankIdActivityTimer = null;
                // after a delay; reenable the button so that the user can start their bankid-app
                // with an already ongoing authentication
                clearTimeout(thisComponent.bankIdActivityTimer);
                thisComponent.bankIdActivityTimer = null;
                thisComponent.manualDeeplinkEnabled = true;
              }, 5000);
            }
          }
        }

        // check if the login was cancelled or never started
        if (
          // there might be more statuses that belong here ...
          auth.status === 'USER_CANCEL' ||
          auth.status === 'START_FAILED' ||
          auth.status === 'NO_CLIENT'
        ) {
          // the login was cancelled or never started => reset UI to let user try again
          thisComponent.indicateAuthInProgress(false);
          thisComponent.authErrorMessageList = [
            'Legitimering avbröts.',
            'Vänligen försök igen.',
          ];
          thisComponent.ctaButtonText = 'Försök igen';
          thisComponent.hasStartedAuth = false;
          thisComponent.authInProgress = false;
          thisComponent.manualDeeplinkEnabled = false;
          if (thisComponent.bankIdActivityTimer !== null) {
            clearTimeout(thisComponent.bankIdActivityTimer);
            thisComponent.bankIdActivityTimer = null;
          }
          return;
        }

        if (auth.inProgress) {
          thisComponent.hasStartedAuth = true;
        } else if (thisComponent.hasStartedAuth) {
          // if process goes from active to inactive it is considered failed
          thisComponent.hasStartedAuth = false;
          this.loggingService.debug('ixnay Took [hasStartedAuth] path');
          if (thisComponent.ignoreNumErrors-- > 0) {
            this.loggingService.debug(
              'Ignoring error ' + thisComponent.ignoreNumErrors
            );
            return;
          }
          thisComponent.handleUnsuccessfulAuth();
        }
      });
    }
  }

  public resetErrorStateMatcher() {
    this.errorStateMatcher.setSubmitted(false);
  }

  /**
   * The pre-filled info should be cleared if the user starts editing a pre-filled
   * personal number.
   */
  public clearPreFillInfo() {
    if (this.preFilled) {
      this.clearPreFilledInfo();
    }
  }

  private handleSuccessfulAuth() {
    this.hasStartedAuth = false;
    this.authService.stopPolling();
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
      this.authSubscription = null;
    }

    this.navigating = true;
    this.customerJourneyService.getNextRoute().subscribe((route) => {
      this.router
        .navigate([route], {
          queryParamsHandling: 'preserve',
        })
        .then(() => {
          this.navigating = false;
        });
    });
  }

  private handleUnsuccessfulAuth() {
    this.loggingService.debug('ixnay handleUnsuccessfulAuth :(');
    this.authService.stopPolling();
    this.indicateAuthInProgress(false);
    this.authErrorMessageList = [
      'Legitimering misslyckades.',
      'Vänligen försök igen.',
    ];
    this.ctaButtonText = 'Försök igen';
    this.hasStartedAuth = false;
  }

  public getPnrErrorMessageList(errors): string[] {
    if (errors == null) {
      return null;
    }
    const errorMessages: string[] = [];
    if (errors.hasOwnProperty('required')) {
      errorMessages.push('Ange ett personnummer');
    }

    if (errors.hasOwnProperty('minlength' || 'maxlength')) {
      errorMessages.push('Ett personnummer ska innehålla 12 siffor');
    }

    if (errors.hasOwnProperty('invalidPersonalNumber')) {
      errorMessages.push('Det angivna personnumret är ogiltigt');
    }

    return errorMessages;
  }

  public handleKeydownInPnrInput(event: KeyboardEvent) {
    if (!(event instanceof KeyboardEvent)) {
      return;
    }

    this.resetErrorStateMatcher();
    this.shouldDisplayPnrErrorMessageList = false;

    if (event.keyCode === ENTER) {
      this.authenticate();
    }
  }

  public goBack() {
    this.authService.stopPolling();
    this.router.navigate([routeNames.HOME], {
      queryParamsHandling: 'merge',
    });
  }

  public clearPreFilledInfo() {
    this.store.dispatch(new ClearPreFilledInfo());
  }

  deeplinkToBankId() {
    if (
      this.onPlatformWhereBankIdIsAssumedInstalled &&
      this.manualDeeplinkEnabled
    ) {
      this.authService.openBankIdApp();
    }
  }

  reroute(): void {
    this.router.navigate([routeNames.HOME], {
      queryParamsHandling: 'merge',
    });
  }
}
