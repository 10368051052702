import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { LoggingService } from '../service/logging-service/logging.service';
import { SERVER_BASE_URL } from 'src/environments/environment';

@Injectable()
export class ErrorResponseInterceptor implements HttpInterceptor {
  constructor(private logger: LoggingService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      filter((event) => event instanceof HttpResponse),
      tap(
        () => {},
        (err) => {
          let response = err as HttpResponse<any>;
          if (
            response &&
            !(response.status === 422 && response.url) &&
            response.url.match(`log/crash`) == null
          ) {
            this.logger.reportCrash(
              'unexpected http error response',
              'response: ' + JSON.stringify(response),
              null
            );
          }
        }
      )
    );
  }
}
