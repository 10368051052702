<app-de-thin-wrapper *ngIf="loading">
  <app-spinner></app-spinner>
</app-de-thin-wrapper>

<app-de-thin-wrapper *ngIf="!loading">
  <div class="container">
    <p class="heading">{{ state.interruptionDetails.heading }}</p>

    <p class="info-text">
      {{
        state.interruptionDetails.message.replace(
          '{amount}',
          state.totalCheckoutValue | value
        )
      }}
    </p>

    <div class="button-wrapper">
      <zaver-secondary-button-outlined
        *ngIf="!!state.interruptionDetails.ctaLabel"
        style="max-width: 240px"
        (click)="handleCtaClick()"
        [text]="state.interruptionDetails.ctaLabel"
        [rounded]="true"
        [disabled]="navigationInProgress"
      ></zaver-secondary-button-outlined>
    </div>
    <zaver-button-link
      style="margin-top: 24px"
      *ngIf="!!state.interruptionDetails.linkLabel"
      (click)="handleLinkClick()"
      [text]="state.interruptionDetails.linkLabel"
      [disabled]="navigationInProgress"
    ></zaver-button-link>
  </div>
</app-de-thin-wrapper>

<div *ngIf="state.zaverProduct == 'PRECHECK'" class="logo">
  <img src="/assets/img/Zaver_Logotype_Circle_Coal_DE.svg" alt="Zaver logo" />
</div>
