import {Injectable} from '@angular/core';
import {GuardsCheckEnd, GuardsCheckStart, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {RedirectionQueueEntry} from './redirection-queue-entry';

@Injectable()
export class GuardRedirectService {
  private readonly priorityOrder = [
    'error',
    'auth',
    'success',
    ''
  ];

  private redirectionQueue: RedirectionQueueEntry[] = [];
  private routerEventSub: Subscription;

  constructor(private router: Router) {
    this.routerEventSub = router.events.subscribe(
      event => {
        if (event instanceof GuardsCheckStart) {
          this.handleGuardsCheckStart();
        }

        if (event instanceof GuardsCheckEnd) {
          this.handleGuardsCheckEnd();
        }
      }
    );
  }

  public addRouteToRedirectionQueue(redirectionCallback: Function, route: string) {
    if (this.router.url.substr(1).startsWith(route)) {
      return;
    }

    for (let i = 0; i < this.priorityOrder.length; i++) {
      if (route === this.priorityOrder[i]) {
        this.createRedirectionQueueEntry(i, redirectionCallback);
      }
    }
  }

  private createRedirectionQueueEntry(priority: number, redirectionCallback: Function) {
    const entry = new RedirectionQueueEntry(redirectionCallback, priority);

    // Cancel adding if an entry with the same priority already exists.
    for (let i = 0; i < this.redirectionQueue.length; i++) {
      if (this.redirectionQueue[i].priority === entry.priority) {
        return;
      }
    }

    this.redirectionQueue.push(entry);
  }

  private handleGuardsCheckEnd() {
    // Bail out if no redirects are scheduled.
    if (!this.redirectionQueue.length) {
      return;
    }

    let min = 0;

    for (let i = 0; i < this.redirectionQueue.length; i++) {
      if (this.redirectionQueue[i].priority < min) {
        min = this.redirectionQueue[i].priority;
      }
    }

    this.redirectionQueue[min].redirectionCallback();
  }

  private handleGuardsCheckStart() {
    this.redirectionQueue = [];
  }
}
