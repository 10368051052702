import { Injectable } from '@angular/core';
import {Router, CanDeactivate, NavigationEnd} from '@angular/router';
import { Observable } from 'rxjs';
import {RoutingHistoryService} from '../service/routing-history/routing-history.service';
import {routeNames} from '../../assets/val/route-constants';

@Injectable({
  providedIn: 'root'
})
export class PreventBackToSettlementOrAuthGuard implements CanDeactivate<CanComponentDeactivate> {
  private redirectInitialized: boolean;
  private active: boolean;

  constructor(private router: Router,
              private routingHistoryService: RoutingHistoryService) {
    this.redirectInitialized = false;
    this.active = true;
  }

  public activate() {
    this.active = true;
  }

  public deactivate() {
    this.active = false;
  }

  private settlementOrAuthIsMostRecentRoute(): boolean {
    const previousUrl = this.routingHistoryService.getPreviousUrl();
    return previousUrl.startsWith(`/${routeNames.SETTLE}`) ||
      previousUrl.startsWith(`/${routeNames.AUTH}`);
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {

    if (this.active &&
          this.settlementOrAuthIsMostRecentRoute() &&
          !this.redirectInitialized) {

      this.redirectInitialized = true;
      this.router.navigate([`/${routeNames.HOME}`], {
        queryParamsHandling: 'preserve'
      }).then(() => {
        this.redirectInitialized = false;
      });
    }

    return true;
  }
}

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}
