import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'zaver-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.css']
})
export class RadioButtonComponent implements OnInit {

  @Input() selected: boolean;

  @Input() selectedColor: string = '#e01d78';

  constructor() { }

  ngOnInit(): void {

  }

  getBorderStyle() {
    if (this.selected) {
      return '4px solid ' + this.selectedColor + ' !important';
    } else {
      return '1px solid #b7bcc8 !important';
    }

  }

}
