import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Currency } from '@app/model/currency';

@Component({
  selector: 'app-de-checkout-success',
  templateUrl: './de-checkout-success.component.html',
  styleUrls: [
    '../../../../route/page-shared.css',
    './de-checkout-success.component.scss',
  ],
})
export class DeCheckoutSuccessComponent {
  @Input() amount: Currency;
  @Input() email: string;
  @Input() paymentMethodString: string;

  @Output() onDetailsClick = new EventEmitter();

  ngOnInit(): void {}

  detailsClick() {
    this.onDetailsClick.emit();
  }
}
