<div class="page-body" *ngIf="!loading">
  <div class="contents-wrapper">
    <div class="contents-container">
      <div class="page-icon">
        <img src="/assets/icon/Icon-Invoice-Black.svg" alt="" />
      </div>

      <div class="page-text">Betal innen 14 dager - NOK 0</div>

      <div style="width: 100%; padding-bottom: 36px">
        <hr class="details-divider" />
        <div class="details-container">
          <div class="details-key-entry">
            {{ state.paymentInformation.title }}
          </div>

          <zaver-button-link (click)="toggleModal()" [text]="'Visa detaljer'">
          </zaver-button-link>
        </div>

        <hr class="details-divider" />
        <div class="details-container">
          <div class="details-key-entry">Totale mengden</div>

          <div class="details-value-entry">
            {{ state.totalCheckoutValue | value }}
          </div>
        </div>

        <hr class="details-divider" />
      </div>

      <zaver-primary-button
        [text]="'Fullfør kjøp'"
        [disabled]="navigationInProgress"
        (click)="handleCtaClick()"
      ></zaver-primary-button>

      <div class="terms-row">
        <zaver-button-link
          (click)="openInvoiceTerms()"
          [text]="'Se vilkår'"
        ></zaver-button-link>

        <div *ngIf="showTermsSpinner">
          <div class="dot-spinner-container">
            <app-dot-spinner></app-dot-spinner>
          </div>
        </div>

        <div class="void-to-center-spinner"></div>
      </div>

      <p class="loan-terms-string">{{ loanTermsString }}</p>
    </div>
  </div>
</div>

<zaver-checkout-modal
  *ngIf="selectorItems"
  id="details-modal"
  [active]="modalActive"
  [buttonText]="'Lukk'"
  [logo]="'assets/img/Zaver_Logotype_Circle_Cerise_Coal.svg'"
  (onClose)="toggleModal()"
>
  <app-selector #selector [selectorItems]="selectorItems"></app-selector>
</zaver-checkout-modal>

<div class="page-body" *ngIf="loading">
  <div class="contents-wrapper">
    <div class="contents-container">
      <app-spinner></app-spinner>
    </div>
  </div>
</div>
