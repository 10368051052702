<div class="text">Genehmigte Zahlungsmethoden:</div>

<div class="summary-container">
  <div class="summary-title">
    <div class="summary-title-section">
      <img class="icon-pm" src="assets/icon/Icon-Invoice-Black.svg" />
      <span class="pm-title">Rechnung 14 Tage</span>
    </div>
    <img class="icon-checkmark" src="assets/icon/Checkmark.svg" />
  </div>

  <hr class="details-divider" />
  <div class="amount">{{ amount | value }}</div>
</div>

<div class="summary-container">
  <div class="summary-title">
    <div class="summary-title-section">
      <img class="icon-pm" src="assets/icon/Icon-Cake-Black.svg" />
      <span class="pm-title">Ratenzahlung</span>
    </div>
    <img
      *ngIf="availableLoanOptions.length > 0"
      class="icon-checkmark"
      src="assets/icon/Checkmark.svg"
    />
    <img
      *ngIf="availableLoanOptions.length == 0"
      class="icon-cross"
      src="assets/icon/Icon-Cross.svg"
    />
  </div>

  <div
    *ngIf="availableLoanOptions.length > 0"
    class="installments-items-wrapper"
  >
    <div *ngFor="let item of availableLoanOptions">
      <hr class="details-divider" />
      <div class="item">
        <span>{{ item.title }}</span>
        <span>{{ item.nominalInterest.toFixed(2) }} % Zinssatz</span>
      </div>
      <div class="button-link" *ngIf="item.link">
        <zaver-button-link
          [text]="item.link.text"
          (click)="item.link.onClick()"
        ></zaver-button-link>
      </div>
    </div>
  </div>

  <div
    *ngIf="availableLoanOptions.length == 0"
    class="installments-items-wrapper"
  >
    <hr class="details-divider" />
    <div class="amount">{{ amount | value }}</div>
    <div class="title">
      <span>Der gewählte Betrag ist zu niedrig für diese Zahlungsmethode.</span>
    </div>
  </div>
</div>

<div class="info-text">
  Bitte beachten Sie, dass das Ergebnis nicht verbindlich ist und sich zum
  Zeitpunkt des Kaufs noch ändern kann.
</div>

<zaver-checkout-modal
  [buttonText]="'Schließen'"
  *ngIf="selectorItems"
  id="precheck-details-modal"
  [active]="modalActive"
  [buttonText]="'Schließen'"
  [logo]="'assets/img/Zaver_Logotype_Circle_Coal_DE.svg'"
  (onClose)="toggleModal(null)"
>
  <app-selector
    #selector
    *ngIf="!!selectorItems"
    [selectorItems]="selectorItems"
    [selectedItem]="selectedItem"
  ></app-selector>
</zaver-checkout-modal>
