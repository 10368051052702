import { Component, OnInit } from '@angular/core';
import {
  Validators,
  ValidatorFn,
  FormGroup,
  FormBuilder,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteComponent } from '@app/route/route.component';
import { HeaderService } from '@app/service/header/header.service';
import { NoUserService } from '@app/service/no-user-service/no-user.service';
import { StateService } from '@app/service/state/state.service';
import { StandardCheckoutResponse } from '@app/service/user-service/user.service';
import { email_validation_regexp } from '../../../../assets/val/regexp';

@Component({
  selector: 'no-email-and-invoice',
  templateUrl: './no-email-and-invoice.component.html',
  styleUrls: ['../../page-shared.css', './no-email-and-invoice.component.scss'],
})
export class NoEmailAndInvoiceComponent
  extends RouteComponent
  implements OnInit
{
  validators: ValidatorFn[];
  showError: boolean;
  form: FormGroup;

  public loading = false;
  public shouldDisplayEmailError = false;
  public emailErrorMessages = [
    'Det har oppstått en uventet feil.',
    'Prøv igjen.',
  ];

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected stateService: StateService,
    protected headerService: HeaderService,
    private noUserService: NoUserService,
    private formBuilder: FormBuilder
  ) {
    super(router, route, stateService, headerService);
  }

  ngOnInit(): void {
    console.log(this.state);
    this.setupForm();
  }

  setupForm(): void {
    this.form = this.formBuilder.group({
      email: [
        '',
        [Validators.pattern(email_validation_regexp), Validators.required],
      ],
    });
  }

  async handleCtaClick() {
    if (this.form.valid) {
      try {
        let resp: StandardCheckoutResponse =
          await this.noUserService.registerEmail(this.form.value.email.trim());

        if (resp.success) {
          this.navigate();
        }
      } catch (err) {
        this.handleError(err);
      }
    } else {
      this.showError = true;
    }
  }
}
