export class QueryParams {
  id?: string;
  method?: string;
  methodType?: string;
  bidRequestId?: string;
  shouldOpenBankId?: string;
  error?: string;
  termId?: string;
  swishId?: string;
  poppedSwish?: string;
  vippsId?: string;
  poppedVipps?: string;
  stateId?: string;
  debug?: string;
  fatal?: string;
  depositStatus?: DepositStatus;
  precheckId?: string;
  instantDebitId?: string;
}

export enum DepositStatus {
  PENDING = 'PENDING',
  FAIL = 'FAIL',
}
