<div class="container">
  <h3>Tyvärr kan vi inte bevilja dig den sökta krediten.</h3>
  <p>Beslutet baserar sig på en helhetsbedömning utifrån vår kreditprövning.</p>

  <zaver-secondary-button-outlined
    style="margin: 8px auto"
    [rounded]="true"
    *ngFor="let button of buttons"
    (click)="handleButtonClick(button)"
    [text]="button.title"
  ></zaver-secondary-button-outlined>
</div>
