<h1 class="heading">Danke für Ihren Kauf!</h1>

<div class="text">
  <ng-container *ngIf="!!email">
    <div>Wir senden Ihre Rechnung an:</div>
    <div class="email">{{ email }}</div>
  </ng-container>
</div>

<div class="details-wrapper">
  <hr class="details-divider" />

  <div class="details-container">
    <div class="details-key-entry">Gesamtbetrag</div>
    <div class="details-value-entry">
      {{ amount | value }}
    </div>
  </div>

  <hr class="details-divider" />

  <div class="details-container">
    <div class="details-key-entry">Zahlungsmethode</div>
    <div class="details-value-entry details">{{ paymentMethodString }}</div>
  </div>

  <hr class="details-divider" />
</div>
<zaver-button-link
  class="details-link"
  (click)="detailsClick()"
  [text]="'Details und Bedingungen ansehen'"
></zaver-button-link>
