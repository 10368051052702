import { Host, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SERVER_BASE_URL } from '../../../environments/environment';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class LeqService {
  constructor(private http: HttpClient, private dialogService: MatDialog) {}

  updateLeq(leq: LeqFormDto) {
    this.http
      .post(`${SERVER_BASE_URL}/credit/leq/update`, leq, {
        withCredentials: true,
      })
      .subscribe((response) => {
        //console.log('handle response')
      });
  }

  fetchLeq(): Promise<LeqInfoDto> {
    return this.http
      .get<LeqInfoDto>(`${SERVER_BASE_URL}/credit/leq/get`, {
        withCredentials: true,
      })
      .toPromise();
  }

  submitLeq(leq: LeqFormDto) {
    return this.http.post(`${SERVER_BASE_URL}/credit/leq/submitV2`, leq, {
      withCredentials: true,
    });
  }
}

export class LeqInfoDto {
  numberOfChildren: number;
  creditCosts: number;
  housingCosts: number;
  income: number;
  miscExpenses: number;
  employment: Employment;
  status: KalpStatus;
}

export class LeqFormDto {
  numberOfChildren: number;
  creditCosts: number;
  housingCosts: number;
  income: number;
  miscExpenses: number;
  employment: Employment;
}

export enum Employment {
  PERMANENT = 'PERMANENT',
  PART_TIME = 'PART_TIME',
  STUDENT = 'STUDENT',
  ENTREPRENEUR = 'ENTREPRENEUR',
  RETIRED = 'RETIRED',
  UNEMPLOYED = 'UNEMPLOYED',
}

export enum KalpStatus {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
}
