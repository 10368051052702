import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SERVER_BASE_URL} from '../../../environments/environment';
import {InstantDebitTaskDetails} from './instantDebitTaskDetails';
import {ApprovalConfirmationDto} from './approval-confirmation.dto';
import {CashoutTaskPublicInfoDto} from '@app/service/auth/dto/cashout-task-public-info.dto';

@Injectable({
  providedIn: 'root'
})
export class InstantDebitService {

  constructor(private http: HttpClient) {
  }

  private taskId: string;


  public getDetails(taskId: string): Observable<InstantDebitTaskDetails> {
    if (!this.taskId && taskId) {
      this.taskId = taskId;
    }

    // The id of the task is provided as a request header which is set by the TaskIdInterceptor.
    return this.http.get<InstantDebitTaskDetails>(`${SERVER_BASE_URL}/instantdebit/getAccount`);
  }

  public getPublicInfo(taskId: string): Observable<CashoutTaskPublicInfoDto> {
    if (!this.taskId && taskId) {
      this.taskId = taskId;
    }

    return this.http.get<CashoutTaskPublicInfoDto>(`${SERVER_BASE_URL}/public/info`);
  }

  public getTaskId(): string {
    return this.taskId;
  }

  public registerPartApproval(): Observable<InstantDebitTaskDetails> {
    return this.http.post<InstantDebitTaskDetails>(
      `${SERVER_BASE_URL}/audit/partApprove`,
      {});
  }

  public registerTinkData(tinkStatementId: string) {
    let params = new HttpParams();

    params = params.append('tinkStatementId', tinkStatementId);

    return this.http.get<InstantDebitTaskDetails>(`${SERVER_BASE_URL}/accounts/tinkstatement`, {params: params});
  }

  public getBankInfoAvailable(taskId: string) {
    let params = new HttpParams();

    params = params.append('taskId', taskId);

    return this.http.get<boolean>(`${SERVER_BASE_URL}/instantdebit/accounts/bankinfoAvailable`, {params: params});
  }

  public getAccounts(taskId: string) {
    let params = new HttpParams();

    params = params.append('taskId', taskId);

    return this.http.get<BankAccountListResponse>(`${SERVER_BASE_URL}/instantdebit/getAccountList/`, {params: params});
  }

  public getAccountsReport(taskId: string) {
    let params = new HttpParams();

    params = params.append('taskId', taskId);

    return this.http.get<ReportBankAccountInfo>(`${SERVER_BASE_URL}/instantdebit/accounts/getFromReport`, {params: params});
  }

  public registerAccount(accountNumber: string, clearingNumber: string) {

    let params = new HttpParams();
    if (clearingNumber) {
      params = params.append('clearingNumber', clearingNumber);
    }
    if (accountNumber) {
      params = params.append('accountNumber', accountNumber);
    }

    return this.http.get<InstantDebitTaskDetails>(`${SERVER_BASE_URL}/instantdebit/accounts/register`, {params: params});
  }

  public registerApproval(): Observable<ApprovalConfirmationDto> {
    return this.http.post<ApprovalConfirmationDto>(
      `${SERVER_BASE_URL}/audit/approve`,
      {});
  }
}

export class BankAccountListResponse {
  accounts: BankAccountInfo[];
  paymentRequestId: string;
  bankLogoUrl: string;
}

export class BankAccountInfo {
  id: string;
  accountName: string;
  balance: number;
  currencyCode: string;
  clearingNumber: string;
  accountNumber: string;
  bankName: string;
  bankLogoUrl: string;
  favored: boolean;
}

export class ReportBankAccountInfo {
  accounts: ReportBankAccountInfoEntry[];
}

export class ReportBankAccountInfoEntry {
  id: string;
  accountName: string;
  currencyCode: string;
  clearingNumber: string;
  accountNumber: string;
  bankName: string;
}
