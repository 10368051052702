<div class="page-body" *ngIf="!loading">
  <div class="contents-wrapper">
    <div class="contents-container">
      <!-- Mobile automatic payment layout -->
      <div class="mobile-layout" *ngIf="isPhone && !formLayout && !error">
        <div class="loading-info-container">
          <div class="spinner-container">
            <app-spinner [overrideMargins]="true"> </app-spinner>
          </div>
          <div class="status-message">
            {{ mobileStatusMessage }}
          </div>
        </div>
        <ng-container *ngIf="vippsLandingRedirectUrl && !returnedFromVipps">
          <div class="alert-container">
            <div class="deeplink-button" (click)="openVippsMobile()">
              Åpne Vipps
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!returnedFromVipps">
          <div class="open-on-other-device" (click)="restartOnOtherDevice()">
            Vippse fra en annen enhet
          </div>
        </ng-container>
      </div>

      <!-- Form layout, desktop or other device requested on mobile -->
      <ng-container *ngIf="formLayout && !error && !paymentStatusOtherDevice">
        <div class="page-icon">
          <img
            src="/assets/icon/Icon-Phone-Checkmark-Mobile-Shadow.svg"
            alt=""
          />
        </div>

        <div class="page-text">Tast inn ditt telefonnummer</div>

        <form [formGroup]="form" id="form">
          <div class="input-container">
            <zaver-animated-form-input
              [size]="'large'"
              [ariaLabel]="'Tast inn mobilnummeret ditt'"
              [formControlName]="'phone'"
              [error]="form.controls.phone.invalid && showError"
              inputMode="tel"
              placeholder="Mobilnummer"
              errorMessage="Vennligst skriv inn et gyldig mobilnummer (+47********, 9*********, eller 4*********)"
              [errorIcon]="'assets/icon/icon_error.svg'"
            ></zaver-animated-form-input>
          </div>
        </form>

        <div class="pr-info" *ngIf="state">
          <label> Totale mengden </label>
          <label>
            {{ state.totalCheckoutValue | value }}
          </label>
        </div>

        <zaver-primary-button
          [formId]="'form'"
          [buttonType]="'submit'"
          [text]="'Vippse'"
          [logo]="'/assets/icon/Vipps_Symbol_White_SVG.svg'"
          (click)="initPaymentDesktop()"
        ></zaver-primary-button>
      </ng-container>

      <!-- While payment active on other device -->
      <ng-container *ngIf="!!error || !!paymentStatusOtherDevice">
        <div class="active-payment-wrapper">
          <div class="alert-container" *ngIf="!!error">
            <zaver-alert-box
              [messageList]="[getErrorMessages()]"
              type="WARNING"
            >
            </zaver-alert-box>
          </div>
          <div
            class="alert-container"
            *ngIf="!!paymentStatusOtherDevice && !error"
          >
            <zaver-alert-box [text]="paymentStatusOtherDevice" type="INFO">
            </zaver-alert-box>
          </div>
          <div
            class="cancel-transaction"
            *ngIf="!!paymentStatusOtherDevice"
            (click)="restartOnOtherDevice()"
          >
            Avbryt overføringen
          </div>

          <div class="pr-info" *ngIf="state && (!isPhone || !!error)">
            <label> Totale mengden </label>
            <label>
              {{ state.totalCheckoutValue | value }}
            </label>
          </div>

          <zaver-primary-button
            *ngIf="isPhone && !!error"
            [text]="'Prøv igjen'"
            [loading]="paymentInProgress || isPolling()"
            [disabled]="paymentInProgress || isPolling()"
            [logo]="'/assets/icon/Vipps_Symbol_White_SVG.svg'"
            (click)="restartPaymentMobile()"
          ></zaver-primary-button>

          <zaver-primary-button
            *ngIf="!isPhone"
            text="Prøv igjen"
            [loading]="!error"
            [disabled]="!error"
            logo="/assets/icon/Vipps_Symbol_White_SVG.svg"
            (click)="restartFormBasedPayment()"
          ></zaver-primary-button>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<div class="page-body" *ngIf="loading && !manualDeeplinkEnabled">
  <div class="contents-wrapper">
    <div class="contents-container">
      <app-spinner></app-spinner>
    </div>
  </div>
</div>
