import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-de-thin-wrapper',
  templateUrl: './de-thin-wrapper.component.html',
  styleUrls: ['./de-thin-wrapper.component.scss'],
})
export class DeThinWrapperComponent implements OnInit {
  constructor() {}
  // used for views where we want less margins.
  ngOnInit(): void {}
}
