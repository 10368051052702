import { Component, OnInit } from '@angular/core';
import { SelectorItem } from '@app/component/selector/selector.component';
import {
  Market,
  PaymentMethod,
  PaymentMethodVM,
} from '@app/model/payment-request/payment-request.dto';
import { ActivatedRoute, Router } from '@angular/router';
import { LoggingService } from '@app/service/logging-service/logging.service';
import { QueryParamsService } from '@app/service/query-params/query-params.service';
import { StateService } from '@app/service/state/state.service';
import { ValuePipe } from '@app/shared/pipe/value.pipe';
import { RouteComponent } from '@app/route/route.component';
import { HeaderService } from '@app/service/header/header.service';
import { SERVER_BASE_URL } from 'src/environments/environment';
import { deInvoiceTermsUrl } from '@app/common/de-common';

interface Methodtranslations {
  title: string;
  subTitle: string;
  bulletPoints: string[];
}

export interface AvailablePaymentMethods {
  id: string;
  type: string;
  lowestPossibleMonthlyPayment?: any;
}

@Component({
  selector: 'user-flow-selection',
  templateUrl: './user-flow-selection.component.html',
  styleUrls: ['./user-flow-selection.component.scss', '../page-shared.css'],
})
export class UserFlowSelectionComponent
  extends RouteComponent
  implements OnInit
{
  loading = true;
  Market = Market;
  paymentMethodsVM: PaymentMethodVM[] = null;
  selectedPaymentMethodVM: PaymentMethodVM = null;
  navigationInProgress: boolean = false;
  selectorItems: SelectorItem[] = null;
  selectedItem: SelectorItem = null;
  modalActive: boolean = false;
  noAvailablePaymentMethods = false;
  color = '#e01d78';

  constructor(
    private queryParamsService: QueryParamsService,
    private loggingService: LoggingService,
    private valuePipe: ValuePipe,
    protected router: Router,
    protected stateService: StateService,
    protected headerService: HeaderService,
    protected route: ActivatedRoute
  ) {
    super(router, route, stateService, headerService);
  }

  async ngOnInit(): Promise<void> {
    const token = this.queryParamsService.getQueryParams().id;
    try {
      const paymentMethods: AvailablePaymentMethods[] =
        await this.stateService.getAvailablePaymentMethods(token);

      if (this.state) {
        localStorage.setItem('zco_market', this.state.market);

        if (
          this.state.market === Market.DE ||
          this.state.market === Market.AT
        ) {
          this.color = '#ef3466';
        }
      }

      if (!paymentMethods || paymentMethods?.length === 0) {
        this.noAvailablePaymentMethods = true;
      }

      this.paymentMethodsVM = this.transformDtoToVm(paymentMethods);
      console.log(this.paymentMethodsVM);

      const getPaymentMethodByType = this.paymentMethodsVM.find(
        (method) => method.type === this.state.paymentInformation.paymentMethod
      );

      this.selectedPaymentMethodVM =
        getPaymentMethodByType || this.paymentMethodsVM[0];

      this.loading = false;
    } catch (err) {
      this.handleError(err);
    }
  }

  async handleContinueClick(): Promise<void> {
    if (this.navigationInProgress) {
      return;
    }

    this.loggingService.warn(
      'selected payment method and navigating: ' +
        this.selectedPaymentMethodVM.type
    );

    this.navigationInProgress = true;
    try {
      await this.stateService.registerChosenMethod(
        this.selectedPaymentMethodVM.id
      );
    } catch (error) {
      console.log(error);
    }
    this.navigationInProgress = false;
  }

  deactivateModal(): void {
    this.modalActive = false;
  }

  getSekkiLink = (): String =>
    this.state.market === Market.AT || this.state.market === Market.DE
      ? 'https://zaver.com/de/impressum'
      : `${SERVER_BASE_URL}/doc/public/secci`;

  getModalPurchaseInfo(): void {
    switch (this.state.market) {
      case Market.DE:
      case Market.AT:
        this.selectorItems = [
          {
            tab: 'Ihr Kauf',
            title: 'Zusammenfassung',
            lineItems: this.state.paymentInformation?.lineItems,
            totalValue: `Gesamtbetrag inkl. MwSt: ${this.valuePipe.transform(
              this.state.totalCheckoutValue
            )}`,
          },
        ];
        break;
      case Market.NO:
        this.selectorItems = [
          {
            tab: 'Ditt kjøp',
            title: 'Sammendrag',
            lineItems: this.state.paymentInformation?.lineItems,
            totalValue: `Totalt inkl. moms: ${this.valuePipe.transform(
              this.state.totalCheckoutValue
            )}`,
          },
        ];
        break;
      default:
        break;
    }

    this.selectedItem = this.selectorItems[0];
    this.modalActive = true;
  }

  openModalWithTerms(): void {
    this.getModalPurchaseInfo();
    if (this.selectedPaymentMethodVM.type === PaymentMethod.INVOICE) {
      this.selectorItems = [
        ...this.selectorItems,
        {
          tab: 'Zahlungsbedingungen',
          title: 'Bedingungen für den Kauf auf Rechnung',
          descriptions: [
            {
              text: 'Wenn Sie sich für die Zahlung per Rechnung entscheiden, bieten wir Ihnen die Möglichkeit, innerhalb von 14 Tagen ab dem Datum der Rechnungsstellung zu zahlen.',
            },
          ],
          links: [
            {
              text: 'Allgemeine Zahlungsbedingungen',
              onClick: () => this.openInvoiceTerms(),
            },
            {
              text: this.state.checkoutCustomizations.merchantName + ' AGB',
              onClick: () =>
                this.openURLinNewWindow(
                  this.state.checkoutCustomizations.merchantTermsUrl
                ),
            },
          ],
        },
      ];
    }

    this.selectedItem = this.selectorItems[this.selectorItems.length - 1];
    this.modalActive = true;
  }

  openURLinNewWindow(url: string): void {
    window.open(url, '_blank');
  }

  openInvoiceTerms(): void {
    window.location.href = deInvoiceTermsUrl;
  }

  selectPaymentMethod(method: PaymentMethodVM): void {
    this.selectedPaymentMethodVM = method;
  }

  transformDtoToVm(
    paymentMethods: AvailablePaymentMethods[]
  ): PaymentMethodVM[] {
    return paymentMethods.map((method) => {
      switch (method.type) {
        case PaymentMethod.INVOICE:
          return {
            id: method.id,
            type: method.type,
            title: this.getMethodtranslations(method).title,
            subTitle: this.getMethodtranslations(method).subTitle,
            imgSrc: '/assets/icon/Icon-Invoice.svg',
            bulletPoints: this.getMethodtranslations(method).bulletPoints,
          };
        case PaymentMethod.CREDIT:
          return {
            id: method.id,
            type: method.type,
            title: this.getMethodtranslations(method).title,
            subTitle: this.getMethodtranslations(method).subTitle,
            imgSrc: '/assets/icon/Icon-Cake.svg',
            bulletPoints: this.getMethodtranslations(method).bulletPoints,
          };
        case PaymentMethod.TRUSTLY:
          return {
            id: method.id,
            type: method.type,
            title: this.getMethodtranslations(method).title,
            subTitle: this.getMethodtranslations(method).subTitle,
            imgSrc: '/assets/icon/Icon-Bank.svg',
            bulletPoints: this.getMethodtranslations(method).bulletPoints,
          };
        case PaymentMethod.TOKENIZED_CREDIT_LIMIT:
          return {
            id: method.id,
            type: method.type,
            title: 'Månads rechnung',
            subTitle: 'En rechnung i månaden!',
            imgSrc: '/assets/icon/Icon-Invoice.svg',
            bulletPoints: ['Tokenized fejktura'],
          };
        case PaymentMethod.VIPPS_EXTERNAL:
          return {
            id: method.id,
            type: method.type,
            title: this.getMethodtranslations(method).title,
            subTitle: this.getMethodtranslations(method).subTitle,
            imgSrc: '/assets/icon/Vipps_Symbol_White_SVG.svg',
            bulletPoints: this.getMethodtranslations(method).bulletPoints,
          };
        default:
          return null;
      }
    });
  }

  getMethodtranslations(method: AvailablePaymentMethods): Methodtranslations {
    switch (method.type) {
      case PaymentMethod.TRUSTLY:
        switch (this.state.market) {
          case Market.AT:
          case Market.DE:
            return {
              title: 'Direktüberweisung',
              subTitle: 'Per Online-Banking bezahlen',
              bulletPoints: ['Das Geld wird direkt abgebucht'],
            };
          case Market.NO:
            return {
              title: 'Direkte bankoverføring',
              subTitle: 'Pengene blir trukket fra banken din',
              bulletPoints: ['Godkänn med BankID eller dosa'],
            };
        }
      case PaymentMethod.INVOICE:
        switch (this.state.market) {
          case Market.AT:
          case Market.DE:
            return {
              title: 'Rechnung',
              subTitle: 'Später bezahlen',
              bulletPoints: ['In 14 Tagen bezahlen - 0 EUR'],
            };
          case Market.NO:
            return {
              title: 'Faktura',
              subTitle: 'Betal innen 14 dager - 0 NOK',
              bulletPoints: ['Betal senere, helt gratis!'],
            };
        }
      case PaymentMethod.CREDIT:
        const lowestPossibleMonthlyPayment = (
          method: AvailablePaymentMethods
        ) =>
          method.lowestPossibleMonthlyPayment.toLocaleString('de', {
            maximumFractionDigits: 0,
          });
        switch (this.state.market) {
          case Market.AT:
          case Market.DE:
            return {
              title: 'Ratenzahlung',
              subTitle: `Ab ${lowestPossibleMonthlyPayment(method)} € / Monat`,
              bulletPoints: ['Zahlen Sie in Ihrem Tempo'],
            };
          case Market.NO:
            return {
              title: 'Delbetaling',
              subTitle: `Fra ${lowestPossibleMonthlyPayment(method)} NOK/mnd`,
              bulletPoints: ['Betala i din egen takt'],
            };
        }
      case PaymentMethod.VIPPS_EXTERNAL:
        switch (this.state.market) {
          case Market.NO:
            return {
              title: 'Vipps',
              subTitle: 'Enkel måte å betale',
              bulletPoints: ['Pengene trekkes fra bankkontoen din'],
            };
        }
    }
  }
}
