import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { CustomerJourneyService } from '../../service/customer-journey/customer-journey.service';
import { SERVER_BASE_URL } from '../../../environments/environment';
import { QueryParamsService } from '../../service/query-params/query-params.service';
import {
  AuthorizedPaymentMethod,
  Market,
  PaymentMethod,
  PaymentRequestDto,
} from '../../model/payment-request/payment-request.dto';
import {
  FrontendEventType,
  LoggingService,
} from '@app/service/logging-service/logging.service';
import { SelectorItem } from '@app/component/selector/selector.component';
import { CheckoutState } from '@app/service/state/state.service';
import { ValuePipe } from '@app/shared/pipe/value.pipe';
import { PaymentMethodDetailService } from '@app/service/payment-method-detail/payment-method-details.service';

@Component({
  selector: 'app-payment-method-selection',
  templateUrl: './payment-method-selection.component.html',
  styleUrls: [
    '../page-shared.css',
    './payment-method-selection.component.scss',
  ],
})
export class PaymentMethodSelectionComponent implements OnInit, AfterViewInit {
  loading = true;
  market: Market;

  availablePaymentMethods: AuthorizedPaymentMethod[];
  selectedPaymentMethod: AuthorizedPaymentMethod;
  paymentRequest: PaymentRequestDto;
  navigationInProgress: boolean;
  selectorItems: SelectorItem[];
  selectedItem: SelectorItem;

  modalActive: boolean;
  modalLogo: string;
  ctaLabel: string;
  public activeTab: string;

  state: CheckoutState;
  Market = Market;

  constructor(
    private store: Store,
    private customerJourneyService: CustomerJourneyService,
    private router: Router,
    private queryParamsService: QueryParamsService,
    private loggingService: LoggingService,
    private valuePipe: ValuePipe,
    private paymentDetail: PaymentMethodDetailService
  ) {}

  async ngOnInit() {
    this.paymentRequest = this.store.snapshot().paymentRequest.request;

    if (this.paymentRequest) {
      localStorage.setItem('zco_market', this.paymentRequest.market);
    }

    this.availablePaymentMethods = this.paymentRequest.availableMethods;

    this.market = this.paymentRequest.market;
    this.ctaLabel = this.paymentDetail.setupMarketBasedLabels(this.market);

    this.modalLogo = 'assets/img/Zaver_Logotype_Circle_Cerise_Coal.svg';
    if (
      this.availablePaymentMethods == null ||
      this.availablePaymentMethods.length === 0
    ) {
      // If there are no applicable payment methods for this payment request (usually due to misconfiguration of
      // the merchant) we end up here.
      return;
    }

    this.setPaymentMethodSelection();
    this.loading = false;
  }

  ngAfterViewInit() {
    this.loggingService.logEvent(
      FrontendEventType.DISPLAYED_PAYMENT_OPTIONS,
      'displayed options: ' +
        JSON.stringify(
          this.paymentRequest.availableMethods.map((pm) => pm.type)
        )
    );
  }

  setPaymentMethodSelection() {
    const selectedMethodId = this.queryParamsService.getQueryParams().method;
    const selectedMethodIndex = this.availablePaymentMethods.findIndex(
      (method) => method.id === selectedMethodId
    );
    this.handlePaymentMethodSelection(
      selectedMethodIndex !== -1 ? selectedMethodIndex : 0
    );
  }

  handlePaymentMethodSelection(index: number) {
    if (
      this.availablePaymentMethods == null ||
      this.availablePaymentMethods.length === 0
    ) {
      return;
    }

    const methodIdentifier = this.availablePaymentMethods[index];
    this.selectedPaymentMethod = this.availablePaymentMethods[index];
    this.setChosenMethodQueryParam(methodIdentifier);
  }

  getPaymentMethodViewContext(method: AuthorizedPaymentMethod) {
    return this.paymentDetail.getPaymentMethodViewContext(
      method,
      this.market,
      this.paymentRequest
    );
  }

  handleContinueClick() {
    if (this.navigationInProgress) {
      return;
    }
    this.loggingService.warn(
      'selected payment method and navigating: ' +
        this.selectedPaymentMethod.type
    );

    this.navigationInProgress = true;

    this.handleNavigation();
  }

  handleNavigation() {
    this.customerJourneyService
      .beginJourney(this.selectedPaymentMethod.type)
      //this.customerJourneyService.getNextRoute()
      .subscribe((route) => {
        this.loggingService.warn('Attempting to navigate to route: ' + route);
        this.router
          .navigate([route], { queryParamsHandling: 'merge' })
          .then(() => {
            this.navigationInProgress = false;
          })
          .catch((err) => {
            this.navigationInProgress = false;
            this.loggingService.reportCrash(
              'Navigation from landing page failed.' +
                'message: ' +
                err.message,
              null,
              err
            );

            /*StackTrace.fromError(err).then(trace => {
            this.loggingService.reportCrash('logging from payment method selection failed', trace);
          })*/
          });
      });
  }

  getSekkiLink = (): String => `${SERVER_BASE_URL}/doc/public/secci`;

  getTermsLink = (): string => 'https://zaver.com/se/anvandarvillkor';

  getPrivacyPolicyLink = (): string => 'https://zaver.com/se/integritetspolicy';

  getCookiePolicyLink = (): string => 'https://zaver.com/se/cookies';

  setChosenMethodQueryParam(method: AuthorizedPaymentMethod) {
    // Await end of navigation to assert that the query params
    // will be brought into the UrlTree

    const queryParams = this.queryParamsService.getQueryParams();

    if (
      method.id === queryParams.method &&
      method.type === queryParams.methodType
    ) {
      return;
    }

    const urlTree = this.router.parseUrl(this.router.url);

    for (const key in queryParams) {
      if (queryParams.hasOwnProperty(key)) {
        urlTree.queryParams[key] = queryParams[key];
      }
    }

    urlTree.queryParams['method'] = method.id;
    urlTree.queryParams['methodType'] = method.type;

    // Navigate and unsubscribe

    this.router.navigateByUrl(urlTree);
  }
  // TODO: this should be activateModal

  deactivateModal() {
    this.modalActive = false;
  }

  shouldShowTermsLink(method: AuthorizedPaymentMethod) {
    return method.type === PaymentMethod.INSTANT_DEBIT;
  }

  setModalInfo(information?: 'terms'): void {
    const lineItems = this.paymentRequest.lineItems.length > 0;
    const totalValue = lineItems ? 'Totalbelopp inkl. moms:' : 'Totalbelopp:';

    this.selectorItems = [
      {
        tab: 'Ditt köp',
        title: lineItems ? 'Sammanfattning' : this.paymentRequest.title,
        lineItems: lineItems ? this.paymentRequest.lineItems : null,
        descriptions: [{ text: this.paymentRequest.description }],
        totalValue: `${totalValue} ${this.valuePipe.transform(
          this.paymentRequest.value
        )}`,
      },
    ];
    this.selectedItem = this.selectorItems[0];
    if (information === 'terms') {
      if (this.selectedPaymentMethod.type === PaymentMethod.INSTANT_DEBIT) {
        this.selectorItems = [
          ...this.selectorItems,
          {
            tab: 'Betalningsvillkor',
            title: 'Villkor för direktbetalning',
            descriptions: [
              {
                title: '',
                text: 'Betalning via Autogiro hanteras av vår samarbetspartner Trustly.',
              },
              {
                title: 'Misslyckad autogirodebitering',
                text: 'Skulle täckning saknas på ditt konto när betalningstransaktionen ska genomföras kommer betalningen istället hanteras av Zaver och du kommer få en faktura från Zaver (Frink AB). Genom att godkänna betalning genom Autogiro godkänner du således att Zaver, vid misslyckad autogirodebitering, ställer ut en faktura till dig. Zaver ansvarar då, istället för Trustly, för att genomföra relevant betaltjänst så du som konsument uppfyller dina åtaganden mot handlaren.',
              },
              {
                title: 'Avgifter och dröjsmålsränta',
                text: 'Fakturan ska betalas inom den tid som anges på fakturan. Vi förbehåller oss rätten att ta ut en administrationsavgift på upp till 49 kr på fakturan. Vid sen betalning av fakturan har vi rätt att ta ut en påminnelseavgift om 60 kr samt debitera dröjsmålsränta på det förfallna beloppet till dess att full betalning sker. Dröjsmålsräntan utgår med 24 % per år. Sker ingen betalning därefter kan fordran komma att överlämnas för inkassohantering där ytterligare inkassokostnader kan tillkomma.',
              },
              {
                title: 'Kreditprövning',
                text: 'Notera att en kreditprövning görs när du ger ditt medgivande till betalning via Autogiro vilket i vissa fall innebär att en kreditupplysning inhämtas från extern part. Du får då en kopia på kreditupplysningen per fysisk och/eller digital post.',
              },
              {
                title: 'Personuppgifter',
                text: 'Zaver behandlar dina personuppgifter för att uppfylla våra åtaganden enligt detta avtal och för de övriga syften som redovisas i vår Integritetspolicy.',
              },
            ],
          },
        ];
      }
      this.selectedItem = this.selectorItems[1];
    }

    this.modalActive = true;
  }
}
