import { Component, OnInit, Input } from '@angular/core';
@Component({
  selector: 'zaver-primary-button',
  templateUrl: './primary-button.component.html',
  styleUrls: ['./primary-button.component.scss'],
})
export class PrimaryButtonComponent implements OnInit {
  @Input() text: string = '';
  @Input() loading = false;
  @Input() disabled = false;
  @Input() logo: string = '';
  @Input() size: 'x-small' | 'small' | 'medium' | 'large' = 'medium';
  @Input() color: 'black' | 'cerise' | 'de-cerise' = 'black';
  @Input() rounded = false;
  @Input() iconUrl: string;
  @Input() buttonType: string = 'button';
  @Input() formId: string = '';
  constructor() {}

  ngOnInit(): void {}

  stopIfDisabled(event: MouseEvent) {
    if (this.disabled) {
      event.stopPropagation();
    }
  }
}
