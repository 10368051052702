<div class="page-body">
  <div class="contents-wrapper">
    <div class="contents-container">
      <div class="success-icon-container">
        <img src="/assets/img/Icon-Checkmark-Badge.svg" alt="" />
      </div>

      <div class="success-message">{{title}}</div>
      <div class="info-message">
       {{settlementMessage}}
      </div>

      <div class="details-list-wrapper smallest-mobile-hide">
        <hr class="details-divider" *ngIf="amount" />
        <div class="details-container" *ngIf="amount">
          <div class="details-key-entry">Totalbelopp</div>

          <div class="details-value-entry" *ngIf="amount">
            {{
              amount.toLocaleString('sv', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })
            }}
            {{ currency }}
          </div>
        </div>

        <hr class="details-divider" *ngIf="paymentMethod" />
        <div class="details-container" *ngIf="paymentMethod">
          <div class="details-key-entry">Betalsätt</div>

          <div class="details-value-entry">
            {{ paymentMethod }}
          </div>
        </div>

        <hr class="details-divider" *ngIf="paymentMethod || amount" />
      </div>

      <div class="buttons-desktop">
        <div class="app-button" (click)="openTab(APP_STORE_LINK)">
          <img src="assets/icon/Icon-Appstore.svg" alt="" />
          App Store
        </div>

        <div class="app-button" (click)="openTab(PLAY_STORE_LINK)">
          <img src="assets/icon/Icon-Googleplay.svg" alt="" />
          Google Play
        </div>
      </div>

      <div class="buttons-mobile">
        <div class="app-button" (click)="openTab(APP_STORE_LINK)">
          <img src="assets/icon/Icon-Appstore.svg" alt="" />
          App Store
        </div>

        <div class="app-button" (click)="openTab(PLAY_STORE_LINK)">
          <img src="assets/icon/Icon-Googleplay.svg" alt="" />
          Google Play
        </div>
      </div>
    </div>
  </div>
</div>
