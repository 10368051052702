import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  deInvoiceTermsUrl,
  supportedCountryCodes,
} from '@app/common/de-common';
import {
  availableCountryCodes,
  setCountryFlag,
} from '@app/common/select-country';
import { SelectorItem } from '@app/component/selector/selector.component';
import {
  Market,
  PaymentMethod,
} from '@app/model/payment-request/payment-request.dto';
import { HeaderService } from '@app/service/header/header.service';
import { UserAgentService } from '@app/service/user-agent/user-agent.service';
import { ValuePipe } from '@app/shared/pipe/value.pipe';
import { Subscription } from 'rxjs/internal/Subscription';
import { StateService } from 'src/app/service/state/state.service';
import {
  EmailAndPhone,
  UserRegistrationDto,
  UserService,
} from 'src/app/service/user-service/user.service';
import { email_validation_regexp } from 'src/assets/val/regexp';
import {
  Color,
  formatPhoneNumber,
  Item,
  phoneNumberValidator,
} from 'web-component-library/projects/component-library/src/public-api';
import { RouteComponent } from '../../route.component';

enum View {
  DEFAULT = 'DEFAULT',
  NOT_FOUND = 'NOT_FOUND',
  CONFIRM_EMAIL = 'CONFIRM_EMAIL',
}
@Component({
  selector: 'app-de-email-and-phone-registration',
  templateUrl: './de-email-and-phone-registration.component.html',
  styleUrls: [
    '../../page-shared.css',
    './de-email-and-phone-registration.component.scss',
  ],
})
export class DeEmailAndPhoneRegistrationComponent
  extends RouteComponent
  implements OnInit, OnDestroy
{
  form: FormGroup;
  confirmEmailForm: FormGroup;
  loading = true;
  rememberMe = true;
  showError = false;
  currentView = View.DEFAULT;
  navigationInProgress = false;
  headingText: string;
  infoText: string;
  imgSrc: string;
  existingUser = false;
  selectorItems: SelectorItem[];
  modalActive = false;
  modalLogo = '';
  isMobile: boolean;
  preExistingContactInfo: UserRegistrationDto;
  View = View;
  Color = Color;
  readonly AVAILABLECOUNTRYCODES = availableCountryCodes;
  selectedcountry: Item = this.AVAILABLECOUNTRYCODES[2];
  formSubscription: Subscription;
  inputSize: 'small' | 'medium' | 'large';

  constructor(
    private formBuilder: FormBuilder,
    protected router: Router,
    private valuePipe: ValuePipe,
    protected route: ActivatedRoute,
    protected stateService: StateService,
    protected headerService: HeaderService,
    private userService: UserService,
    private userAgentService: UserAgentService
  ) {
    super(router, route, stateService, headerService);
  }

  async ngOnInit(): Promise<void> {
    this.isMobile = this.userAgentService.isMobile();
    try {
      this.preExistingContactInfo =
        await this.stateService.getPreExistingContactInfo();
    } catch (error) {
      this.handleError(error);
    }

    this.preExistingContactInfo.phoneNumber.value = this.appendCountryCode(
      this.preExistingContactInfo.phoneNumber.value
    );

    this.selectedcountry = setCountryFlag(
      this.preExistingContactInfo.phoneNumber.value,
      this.selectedcountry
    );

    this.rememberMe =
      localStorage.getItem('zco_de_save_remember_me') === 'true';

    this.form = this.formBuilder.group({
      email: [
        {
          value:
            this.preExistingContactInfo.email.value ||
            localStorage.getItem('zco_de_email') ||
            '',
          disabled: !this.preExistingContactInfo.email.editable,
        },
        [Validators.pattern(email_validation_regexp), Validators.required],
      ],
      phoneNumber: [
        {
          value:
            this.preExistingContactInfo.phoneNumber.value ||
            localStorage.getItem('zco_de_phone_number') ||
            '+49 ',
          disabled: !this.preExistingContactInfo.phoneNumber.editable,
        },
        [phoneNumberValidator(supportedCountryCodes), Validators.required],
      ],
    });

    this.loading = false;

    this.formSubscription =
      this.form.controls.phoneNumber.valueChanges.subscribe((value) => {
        this.selectedcountry = setCountryFlag(value, this.selectedcountry);
      });

    this.modalLogo = 'assets/img/Zaver_Logotype_Circle_Coal_DE.svg';

    this.selectorItems = [
      {
        tab: 'Ihr Kauf',
        title: 'Zusammenfassung',
        lineItems: this.state.paymentInformation?.lineItems,
        totalValue: `Gesamtbetrag inkl. MwSt: ${this.valuePipe.transform(
          this.state.totalCheckoutValue
        )}`,
      },
    ];

    if (this.state.paymentInformation.paymentMethod === PaymentMethod.INVOICE) {
      this.selectorItems = [
        ...this.selectorItems,
        {
          tab: 'Zahlungsbedingungen',
          title: 'Bedingungen für den Kauf auf Rechnung',
          descriptions: [
            {
              text: 'Wenn Sie sich für die Zahlung per Rechnung entscheiden, bieten wir Ihnen die Möglichkeit, innerhalb von 14 Tagen ab dem Datum der Rechnungsstellung zu zahlen.',
            },
          ],
          links: [
            {
              text: 'Allgemeine Zahlungsbedingungen',
              onClick: () => this.openInvoiceTerms(),
            },
            {
              text: this.state.checkoutCustomizations.merchantName + ' AGB',
              onClick: () =>
                this.openURLinNewWindow(
                  this.state.checkoutCustomizations.merchantTermsUrl
                ),
            },
          ],
        },
      ];
    }
  }

  appendCountryCode(phoneNumber: string): string {
    const countryCodes = {
      [Market.DE]: '+49 ',
      [Market.AT]: '+43 ',
    };

    if (
      phoneNumber?.length > 1 &&
      phoneNumber.slice(0, 1) !== '+' &&
      phoneNumber.slice(0, 2) !== '00'
    ) {
      return countryCodes[this.state.market] + phoneNumber;
    } else {
      return phoneNumber;
    }
  }

  openURLinNewWindow(url: string): void {
    window.open(url, '_blank');
  }

  openInvoiceTerms(): void {
    window.location.href = deInvoiceTermsUrl;
  }

  ngOnDestroy() {
    this.formSubscription && this.formSubscription.unsubscribe();
  }

  setCountry(selected) {
    this.form.controls.phoneNumber.setValue(selected.value);
  }

  onChangeRememberMe(event: any): void {
    localStorage.setItem('zco_de_save_remember_me', event.target.checked);
  }

  saveInformation(formData: EmailAndPhone): void {
    if (this.rememberMe) {
      localStorage.setItem(
        'zco_de_email',
        this.rememberMe ? formData.email : ''
      );
      localStorage.setItem(
        'zco_de_phone_number',
        this.rememberMe ? formData.phoneNumber : ''
      );
    }
  }

  async navigateToAccountRestoration(): Promise<void> {
    this.navigationInProgress = true;
    try {
      await this.userService.sendAccountRestorationEmail();
      await this.navigate();
    } catch (err) {
      this.handleError(err);
    }
    this.navigationInProgress = false;
  }

  setCurrentView(view: View): void {
    this.currentView = view;
  }

  async registerAndNavigate(formData: EmailAndPhone) {
    this.navigationInProgress = true;
    try {
      const resp = await this.userService.registerEmailAndPhone(formData);
      resp.success
        ? await this.navigate()
        : this.setCurrentView(View.NOT_FOUND);
    } catch (error) {
      this.handleError(error);
    }
    this.navigationInProgress = false;
  }

  async checkExistingUser(formData: EmailAndPhone) {
    const resp = await this.userService.checkExistingUser(formData);
    this.existingUser = resp.success;
  }

  async handleCtaClick() {
    this.navigationInProgress = true;
    if (this.form.valid) {
      const formData: EmailAndPhone = {
        email: this.form.controls.email.value.trim().toLowerCase(),
        phoneNumber: formatPhoneNumber(this.form.controls.phoneNumber.value),
      };
      try {
        await this.checkExistingUser(formData);
        if (this.existingUser) {
          this.saveInformation(formData);
          this.registerAndNavigate(formData);
        } else {
          this.confirmEmailForm = this.formBuilder.group(
            {
              email: [
                {
                  value: this.form.value.email,
                  disabled: true,
                },
                [
                  Validators.pattern(email_validation_regexp),
                  Validators.required,
                ],
              ],
              confirmEmail: [
                '',
                [
                  Validators.pattern(email_validation_regexp),
                  Validators.required,
                ],
              ],
            },
            {
              validators: [confirmEmailValidation],
            }
          );
          this.showError = false;
          this.currentView = View.CONFIRM_EMAIL;
        }
      } catch (error) {
        this.handleError(error);
      }
    } else {
      this.showError = true;
    }
    this.navigationInProgress = false;
  }

  confirmEmail(): void {
    if (this.confirmEmailForm.valid) {
      const formData: EmailAndPhone = {
        email: this.form.controls.email.value.trim().toLowerCase(),
        phoneNumber: formatPhoneNumber(this.form.controls.phoneNumber.value),
      };
      this.registerAndNavigate(formData);
    } else {
      this.showError = true;
    }
  }

  activateModal() {
    this.modalActive = true;
  }

  deactivateModal() {
    this.modalActive = false;
  }
}

const confirmEmailValidation: ValidatorFn = (
  control: AbstractControl
): ValidationErrors => {
  const email = control.get('email');
  const confirmEmail = control.get('confirmEmail');

  return email &&
    confirmEmail &&
    email.value.toLowerCase() !== confirmEmail.value.toLowerCase()
    ? { confirmEmail: true }
    : null;
};
