import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { QueryParamsService } from '../query-params/query-params.service';
import { PaymentRequestDto } from '@app/model/payment-request/payment-request.dto';
import { CreditService } from '../credit/credit.service';
import { Observable } from 'rxjs';
import { StateService } from '../state/state.service';
import { HeaderService } from '../header/header.service';
import { NoUserService } from '../no-user-service/no-user.service';
import { VippsRequestParams, VippsResponse } from './vipps-response.dto';
import { StandardCheckoutResponse } from '../user-service/user.service';

@Injectable({
  providedIn: 'root',
})
export class VippsService {
  constructor(
    private store: Store,
    private queryParamsService: QueryParamsService,
    private creditService: CreditService,
    protected route: ActivatedRoute,
    protected stateService: StateService,
    protected headerService: HeaderService,
    private noUserService: NoUserService
  ) {}

  async getPaymentRequest() {
    const paymentRequestId = this.queryParamsService.getQueryParams().id;
    let paymentRequest: PaymentRequestDto;
    console.warn('fetching payment request');

    try {
      paymentRequest = await this.creditService
        .getPaymentRequestPublicInfo(paymentRequestId)
        .toPromise();
    } catch (err) {
      paymentRequest = this.store.snapshot().paymentRequest.request;
    }

    return paymentRequest;
  }

  public async initPayment(params: VippsRequestParams): Promise<VippsResponse> {
    return await this.noUserService.vippsDeposit(params);
  }

  public openVippsDesktop(url: string) {
    console.warn('opening vipps desktop link: ', url);
    if (!!url) {
      this.noUserService.openVippsDesktop(url);
    }
  }

  public openVippsMobile(url: string) {
    console.warn('opening vipps mobile link: ', url);
    if (!!url) {
      this.noUserService.openVippsMobile(url);
    }
  }

  getStatus(): Observable<VippsResponse> {
    return this.noUserService.vippsGetStatus();
  }

  async cancelCurrentVippsPayment(
    vippsId: string
  ): Promise<StandardCheckoutResponse> {
    return await this.noUserService.vippsCancel(vippsId).toPromise();
  }

  public clearAllQueryParameters() {
    this.queryParamsService.clearQueryParam('poppedVipps');
    this.queryParamsService.clearQueryParam('vippsId');
    this.queryParamsService.clearQueryParam('error');
    this.queryParamsService.clearQueryParam('status');
  }
}
