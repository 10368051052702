<h3>{{ heading }}</h3>
<p>{{ reason }}</p>

<zaver-secondary-button-outlined
  style="margin: 8px auto"
  [rounded]="true"
  *ngFor="let button of buttons"
  (click)="handleButtonClick(button)"
  [text]="button.title"
></zaver-secondary-button-outlined>
