import {Component, OnInit} from '@angular/core';
import {BankAccountInfo, BankAccountListResponse, InstantDebitService} from '../../service/details/instant-debit.service';
import {routeNames, routeParamNames} from '../../../assets/val/route-constants';
import {ActivatedRoute, Router} from '@angular/router';
import {BankinfoService} from '@app/service/bankinfo/bankinfo.service';
import {QueryParamsService} from '@app/service/query-params/query-params.service';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.css',
  ]
})
export class AccountsComponent implements OnInit {
  routeName = routeNames.ACCOUNT_SELECT; // for code search

  public loadingDetails: boolean;

  public readonly LOADING_VAL_MSG = 'Vänta...';

  private taskId: string;

  bankAccountList: BankAccountListResponse;
  accounts: BankAccountInfo[];

  // bankAccountList: ReportBankAccountInfo;
  // accounts: ReportBankAccountInfoEntry[];

  loading = true;
  private paymentRequestID: string;

  constructor(private detailsService: InstantDebitService,
              private bankinfoService: BankinfoService,
              private route: ActivatedRoute,
              private router: Router,
              private queryParamsService: QueryParamsService
  ) {

    this.loadingDetails = true;
  }

  ngOnInit() {
    this.taskId = this.route.snapshot.paramMap.get(`${routeParamNames.TASK_ID}`);
    console.log('taskId: ' + JSON.stringify(this.route.snapshot.queryParamMap));
    this.detailsService.getAccounts(this.taskId).subscribe(res => {
      this.bankAccountList = res;
      this.paymentRequestID = res.paymentRequestId;
      this.queryParamsService.setQueryParam('id', res.paymentRequestId);
      console.log(res);
      if (res.accounts) {
        this.accounts = res.accounts;
      }
      this.loading = false;
    });
  }

  selectAccount(account: BankAccountInfo) {
    this.detailsService.registerAccount(account.accountNumber, account.clearingNumber).subscribe(res => {
      console.log('response: ' + res);
      this.router.navigate([routeNames.SETTLE], {queryParams: {id: this.paymentRequestID}, queryParamsHandling: 'merge'});
    });
  }

  reroute() {
    this.router.navigate([routeNames.HOME, this.taskId],{queryParams: {id: this.paymentRequestID}, queryParamsHandling: 'merge'});
  }
}
