import { Action, State, StateContext } from '@ngxs/store';
import {
  InitInvoiceSettlement,
  RegisterPaymentRequestInfo,
  PurgeAvailablePaymentMethods,
  RegisterBankIdSignatureStatus,
} from './payment-request.actions';
import { tap } from 'rxjs/operators';
import {
  PaymentRequestDto,
  AuthorizedPaymentMethod,
  PaymentMethod,
} from '../../model/payment-request/payment-request.dto';
import { HttpClient } from '@angular/common/http';
import { SERVER_BASE_URL } from '../../../environments/environment';
import {
  BankIdSignatureStatusDto,
  BankIdSignatureStatusIdentifier,
} from '../../model/payment-request/bank-id-signature-status.dto';
import { Injectable } from '@angular/core';

export class PaymentRequestStateModel {
  loaded: boolean;
  bankIdStatus: BankIdSignatureStatusDto;
  errors: string[];
  request: PaymentRequestDto;
}

@State<PaymentRequestStateModel>({
  name: 'paymentRequest',
  defaults: {
    loaded: false,
    bankIdStatus: {
      status: '',
      autoStartToken: '',
      message: '',
    },
    errors: [],
    request: {
      id: '',
      status: '',
      value: null,
      amount: 0,
      currency: null,
      market: null,
      language: null,
      amountDenotesCreditLimit: false,
      lowestPossibleMonthlyPayment: 0,
      title: '',
      description: '',
      merchantDetails: {
        companyName: '',
        logoUrl: '',
      },
      availableMethods: [],
      settlementMethod: null,
      phoneNumber: null,
      paymentChannel: null,
      lineItems: null,
      cancelUrl: '',
    },
  },
})
@Injectable()
export class PaymentRequestState {
  constructor(private http: HttpClient) {}

  private static patchStateToProcessing(
    patchState: any,
    state: PaymentRequestStateModel
  ) {
    patchState({
      ...state,
      settlementStatus: {
        ...state.bankIdStatus,
        processing: true,
      },
    });
  }

  @Action(RegisterBankIdSignatureStatus)
  registerBankIdSignatureStatus(
    ctx: StateContext<PaymentRequestStateModel>,
    action: RegisterBankIdSignatureStatus
  ) {
    const { patchState, getState } = ctx;

    const state = getState();

    PaymentRequestState.patchStateToProcessing(patchState, state);

    // Preserve the auto start token
    if (action.statusDto.status === BankIdSignatureStatusIdentifier.STARTED) {
      patchState({
        ...state,
        bankIdStatus: action.statusDto,
      });
    } else {
      patchState({
        ...state,
        bankIdStatus: {
          ...getState().bankIdStatus,
          status: action.statusDto.status,
          message: action.statusDto.message,
        },
      });
    }
  }

  @Action(InitInvoiceSettlement)
  initInvoiceSettlement(ctx: StateContext<PaymentRequestStateModel>, _) {
    const state = ctx.getState();
    const { patchState } = ctx;

    // Directly patch the state to be 'processing' as the server
    // call below may take a few seconds to resolve.
    PaymentRequestState.patchStateToProcessing(patchState, state);

    // Init process on server side
    return this.http
      .post(
        `${SERVER_BASE_URL}/request/settle/invoice`,
        {},
        { withCredentials: true }
      )
      .pipe(
        tap(
          (result: BankIdSignatureStatusDto) => {
            patchState({
              ...state,
              bankIdStatus: result,
            });
            // Temporary error handling.
          },
          () => {
            patchState({
              ...state,
              bankIdStatus: {
                ...state.bankIdStatus,
                message: 'Något gick fel :(',
              },
            });
          }
        )
      );
  }

  @Action(RegisterPaymentRequestInfo)
  getPaymentRequestInfo(
    ctx: StateContext<PaymentRequestStateModel>,
    action: RegisterPaymentRequestInfo
  ) {
    const { patchState, getState } = ctx;

    const currentId = getState().request.id;

    if (currentId !== action.payload.paymentRequest.id) {
      patchState({
        ...getState(),
        request: {
          id: '',
          status: '',
          value: null,
          amount: 0,
          currency: null,
          market: null,
          language: null,
          amountDenotesCreditLimit: false,
          lowestPossibleMonthlyPayment: 0,
          title: '',
          description: '',
          merchantDetails: {
            companyName: '',
            logoUrl: '',
          },
          availableMethods: [],
          settlementMethod: null,
          phoneNumber: null,
          paymentChannel: null,
          lineItems: null,
          cancelUrl: '',
        },
      });
    }

    patchState({
      loaded: true,
      request: action.payload.paymentRequest,
    });
  }

  @Action(PurgeAvailablePaymentMethods)
  purgeAvailablePaymentMethods(
    ctx: StateContext<PaymentRequestStateModel>,
    action: PurgeAvailablePaymentMethods
  ) {
    const { getState, patchState } = ctx;

    const available: AuthorizedPaymentMethod[] =
      getState().request.availableMethods.filter((methodType) => {
        return !action.paymentMethodIdentifiers.includes(
          methodType.type.toString()
        );
      });

    patchState({
      ...getState(),
      request: {
        ...getState().request,
        availableMethods: available,
      },
    });
  }
}
