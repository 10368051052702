import { Component, Input, OnInit } from '@angular/core';
import { Market } from '@app/model/payment-request/payment-request.dto';

@Component({
  selector: 'app-general-error',
  templateUrl: './general-error.component.html',
  styleUrls: ['./general-error.component.scss'],
})
export class GeneralErrorComponent implements OnInit {
  @Input() text: string = '';
  constructor() {}

  getText(): void {
    const market = window.location.pathname.slice(1, 3).toUpperCase();
    switch (market) {
      case Market.DE:
        this.text =
          'Hoppla! Da ist etwas schief gelaufen. Bitte versuchen Sie es erneut.';
        break;
      case Market.NO:
        this.text = 'No text';
        break;
      default:
        break;
    }
  }

  ngOnInit(): void {
    this.getText();
  }
}
