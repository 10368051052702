<div id="container">
  <div id="content-hugger">
    <div
      *ngIf="displayBackButton"
      id="back-button"
      class="back-button"
      (click)="back()"
    >
      <img src="assets/icon/Arrow-Left.svg" alt="" />
    </div>
    <div
      *ngIf="displayZaverLogo()"
      id="zaver-logo"
      class="logo-wrapper"
      (click)="report()"
    >
      <img
        src="/assets/img/Zaver_Logotype_Circle_Cerise_Coal.svg"
        alt="Zaver logo"
      />
    </div>
    <div class="logo-wrapper" *ngIf="market === 'DE'">
      <img
        class="header-logo"
        [src]="
          displayMerchantLogo
            ? partnerLogoUri
            : '/assets/img/Zaver_Logotype_Circle_Coal_DE.svg'
        "
        alt=""
        (error)="hideMerchantLogo()"
      />
    </div>
    <button
      [attr.disabled]="navigationInProgress ? '' : null"
      class="close-icon"
      *ngIf="market === 'DE' && cancelUrl && checkoutStatus !== 'COMPLETED'"
      (click)="routeToCancelUrl()"
    >
      <img src="/assets/icon/Navigation-Exit-Close.svg" />
    </button>

    <div
      *ngIf="displayMerchantLogo && market !== 'DE'"
      class="partner-logo-wrapper"
    >
      <img
        *ngIf="partnerLogoUri"
        [src]="partnerLogoUri"
        (error)="hideMerchantLogo()"
      />
    </div>
  </div>
</div>
