<div class="page-body">
  <div class="contents-wrapper">
    <div *ngIf="waitFor == 'BANKID'" class="contents-container">
      <div class="page-icon">
        <img src="/assets/icon/BankID-Vector-Logo.svg" alt="" />
      </div>

      <div class="alert-container">
        <zaver-alert-box type="INFO" [text]="getMessage(paymentRequestStatus)">
        </zaver-alert-box>
      </div>

      <div class="alert-container" *ngIf="showStartBankIdButton">
        <div class="deeplink-button" (click)="deeplinkToBankId()">
          Öppna BankID appen
        </div>
      </div>

      <!-- Never clickable. Only on desktop and displays spinner -->
      <zaver-primary-button
        style="margin-top: 48px"
        *ngIf="onDesktop"
        [text]="'Starta BankID'"
        [loading]="true"
        [disabled]="true"
        [logo]="'../../../../assets/img/bankid.png'"
      ></zaver-primary-button>
    </div>

    <div *ngIf="waitFor != 'BANKID'" class="contents-container">
      <div class="alert-container">
        <zaver-alert-box type="INFO" [text]="getMessage(paymentRequestStatus)">
        </zaver-alert-box>
      </div>

      <!-- Never clickable. Only on desktop and displays spinner -->
      <zaver-primary-button
        style="margin-top: 48px"
        *ngIf="onDesktop"
        [text]="'Inväntar svar'"
        [loading]="true"
        [disabled]="true"
      ></zaver-primary-button>
    </div>
  </div>
</div>
