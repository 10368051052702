import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { CustomerJourneyService } from '../../service/customer-journey/customer-journey.service';
import { SERVER_BASE_URL } from '../../../environments/environment';
import { QueryParamsService } from '../../service/query-params/query-params.service';
import { CreditService } from '../../service/credit/credit.service';
import { CreditDecisionService } from '../../service/credit-decision/credit-decision.service';
import { CustomErrorMatcher } from '../../validation/custom.error-matcher';
import { PreventBackToSettlementOrAuthGuard } from '../../guard/prevent-back-to-settlement-or-auth-guard.service';
import { routeNames } from '../../../assets/val/route-constants';
import { HttpClient } from '@angular/common/http';
import { CreditOptionDto } from 'src/app/model/credit/credit-option.dto';
import { LoggingService } from 'src/app/service/logging-service/logging.service';
import { SelectorItem } from '@app/component/selector/selector.component';
import { ValuePipe } from '@app/shared/pipe/value.pipe';

@Component({
  selector: 'app-invoice-confirm',
  templateUrl: './invoice-confirm.component.html',
  styleUrls: ['./invoice-confirm.component.css', '../page-shared.css'],
})
export class InvoiceConfirmComponent implements OnInit {
  modalActive: boolean;

  public loading = true;
  private selectedPaymentMethodIdentifier: string;
  public invoiceMode: boolean;
  public paymentRequest: any;
  public navigationInProgress: boolean;
  public showTermsSpinner: boolean;
  public invoiceInfo: any;
  public fallbackOffer: CreditOptionDto;
  public errorStateMatcher = new CustomErrorMatcher(false);
  public shouldDisplayEmailError = false;

  public email: string;

  fallbackInfo: string;
  error: boolean = false;

  selectorItems: SelectorItem[];
  selectedItem: SelectorItem;
  constructor(
    private store: Store,
    private customerJourneyService: CustomerJourneyService,
    private router: Router,
    private queryParamsService: QueryParamsService,
    private creditService: CreditService,
    private creditDecisionService: CreditDecisionService,
    private preventBackToSettlementOrAuthGuard: PreventBackToSettlementOrAuthGuard,
    private http: HttpClient,
    private loggingService: LoggingService,
    private valuePipe: ValuePipe
  ) {}

  ngOnInit(): void {
    this.paymentRequest = this.store.snapshot().paymentRequest.request;

    this.selectedPaymentMethodIdentifier =
      this.queryParamsService.getQueryParams().method;

    this.creditService
      .fetchInvoiceTerms(this.selectedPaymentMethodIdentifier)
      .subscribe(
        (res) => {
          this.invoiceInfo = res;
          if (res.fallbackOffer) {
            this.fallbackOffer = res.fallbackOffer;
            this.fallbackInfo = this.buildLoanTermsStr();
          }
          this.prepareModalInformation();
          this.loading = false;

          if (!this.invoiceInfo) {
            this.router.navigate([routeNames.ERROR], {
              queryParams: {
                fatal: 'true',
                error: 'Lyckades inte hämta faktura-information',
              },
              queryParamsHandling: 'preserve',
            });
          }
        },
        (err) => {
          this.error = true;
          this.loading = false;
          this.creditDecisionService.handleCreditDecisionDenied(
            err.error.type,
            routeNames.INVOICE_CONFIRM
          );
        }
      );
  }

  handleCtaClick() {
    if (this.navigationInProgress) {
      return;
    }

    // Reaching this point will mean some form of navigation.
    this.navigationInProgress = true;
    this.preventBackToSettlementOrAuthGuard.deactivate();

    const creditOption: CreditOptionDto = this.invoiceInfo;

    this.customerJourneyService.getNextRoute().subscribe((route) => {
      this.router
        .navigate([route], {
          queryParams: {
            shouldOpenBankId: 'true',
            termId: creditOption.termId,
          },
          queryParamsHandling: 'merge',
        })
        .then(
          () => {
            this.navigationInProgress = false;
            this.preventBackToSettlementOrAuthGuard.activate();
          },
          (rejection) => {
            this.loggingService.error(
              `Navigation from ${routeNames.INVOICE_CONFIRM} to ${route} was rejected.`,
              rejection
            );
          }
        );
    });
  }

  openInvoiceTerms() {
    const creditOption: CreditOptionDto = this.invoiceInfo;
    this.showTermsSpinner = true;

    this.http
      .get(`${SERVER_BASE_URL}/doc/invoice/draft/${creditOption.termId}`)
      .subscribe(
        (contractDto: any) => {
          this.showTermsSpinner = false;
          window.location.href = contractDto.contractUrl;
        },
        (error: any) => {
          // TODO show error msg?
          this.loggingService.log(
            'Error when fetching invoice contract draft',
            error
          );
          this.showTermsSpinner = false;
        }
      );
  }

  getFormattedInterest(interest: number): string {
    if (!interest) {
      interest = 0;
    }
    return (interest * 100).toFixed(2);
  }

  openDetailsModal() {
    this.modalActive = true;
  }

  deactivateModal() {
    this.modalActive = false;
  }

  reroute(): void {
    this.router.navigate([routeNames.EMAIL_AND_INVOICE], {
      queryParamsHandling: 'merge',
    });
  }

  prepareModalInformation() {
    const totalAmountStr =
      this.paymentRequest.lineItems.length > 0
        ? 'Totalbelopp inkl. moms:'
        : 'Totalbelopp:';

    this.selectorItems = [
      {
        tab: 'Ditt köp',
        title:
          this.paymentRequest.lineItems.length > 0
            ? 'Sammanfattning'
            : this.paymentRequest.title,
        descriptions: [{ text: this.paymentRequest.description }],
        lineItems:
          this.paymentRequest.lineItems.length > 0
            ? this.paymentRequest.lineItems
            : null,
        totalValue: `${totalAmountStr} ${this.valuePipe.transform(
          this.paymentRequest.value
        )}`,
      },
    ];
    if (this.fallbackOffer) {
      this.selectorItems = [
        ...this.selectorItems,
        {
          tab: 'Betalningsvillkor',
          title: 'Villkor för faktura',
          descriptions: [{ text: this.buildLoanTermsStr() }],
        },
      ];
      this.selectedItem = this.selectorItems[1];
    }
  }

  buildLoanTermsStr(): string {
    return `När du väljer att betala med faktura erbjuder vi dig möjligheten att betala ${
      this.invoiceInfo.numberOfDaysUntilFinalDueDate
    } dagar från det datumet som din faktura genererades. Om fakturan inte betalas inom ${
      this.invoiceInfo.numberOfDaysUntilFinalDueDate
    } dagar blir betalplanen ${
      this.fallbackOffer.numberOfMonths
    } mån delbetalning. Ett sådant annuitetslån får en rörlig årsränta på ${this.getFormattedInterest(
      this.fallbackOffer.nominalInterest
    )}%, uppl.avg. ${this.fallbackOffer.initialFee.toFixed(
      0
    )}\xa0kr, admin. avg. ${this.fallbackOffer.monthlyFee.toFixed(
      0
    )}\xa0kr/mån, effektiv ränta ${this.getFormattedInterest(
      this.fallbackOffer.effectiveInterest
    )}%, och månadsbelopp ${this.fallbackOffer.monthlyCost.toFixed(
      0
    )}\xa0kr totala summan av betalningar ${this.fallbackOffer.totalAmount.toFixed(
      0
    )}\xa0kr.`;
  }
}
